import { Injectable, NgModule } from '@angular/core';
import { AppConfigService, OAuthenticationService } from '@imc/core/services';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import {
  defaultDataIdFromObject,
  InMemoryCache,
  NormalizedCacheObject,
} from 'apollo-cache-inmemory';
import { CachePersistor } from 'apollo-cache-persist';
import { ApolloClientOptions } from 'apollo-client';
import { ApolloLink, NextLink, Operation } from 'apollo-link';
import { RestLink } from 'apollo-link-rest';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ErrorService } from './services/error-handling/error.service';

const uri: string = 'https://gql.teradatacloud.io/graphql';

const responseTransformerDefault: RestLink.ResponseTransformer = async (
  response: any
) => response.json().then(({ result }: any) => result);

const responseTransformerOriginal: RestLink.ResponseTransformer = async (
  response: any
) => response.json().then((result: any) => result);

const cache: InMemoryCache = new InMemoryCache({
  dataIdFromObject: (object: any) => {
    switch (object.__typename) {
      case 'BackupJob':
        return object.jobId;
      case 'Customer':
        return `${object.customerId}`;
      case 'slaReport':
        return `${object.customerId}:${object.siteId}:${object.reportId}`;
      case 'BackupJobFullInfo':
        return `BackupJobFullInfo:${object.jobDetails.jobId}`;
      case 'Site':
        return object.siteId;
      case 'Ticket':
        return object.ticketId;
      case 'User':
        return object.userId;
      default:
        return defaultDataIdFromObject(object); // fall back to default handling
    }
  },
});

// tslint:disable-next-line:no-floating-promises
const persistor: CachePersistor<NormalizedCacheObject> = new CachePersistor({
  cache,
  storage: window.sessionStorage,
});

const SERVICES_WITHOUT_BEARER_PREFIX: string[] = [
  'notify-service',
  'baas-api',
  'subscriptions',
  'metrics-agent-service',
  'workorders',
  'product-offers',
  'offerings',
  'icaws.intellicloud.teradata.com/v1/sites',
  'https://plate-pub.stage.icaws.intellicloud.teradata.com',
  'https://plate-pub.preprod.icaws.intellicloud.teradata.com',
  'https://plate-pub.prod.icaws.intellicloud.teradata.com',
  'security-rules',
];
// const QUERIES_WITHOUT_BEARER_PREFIX: string[] = ['notifyUserInfo', 'backupJobsList'];

@Injectable()
export class ApolloInitializer {
  private readonly isAuthenticated: Observable<boolean>;

  constructor(
    private readonly httpLink: HttpLink,
    private readonly oauthService: OAuthenticationService,
    private readonly appConfig: AppConfigService,
    private readonly errorService: ErrorService,
    private readonly _authService: OAuthenticationService
  ) {
    this.isAuthenticated = this.oauthService.isAuthenticated$;
  }

  public createApollo(): ApolloClientOptions<any> {
    // TODO: Uncomment below, add it to other links and remove customFetch when rest-link take previous context correctly
    // const authLink: ApolloLink = setContext((operation: GraphQLRequest, { headers }: any) => {
    //   console.warn('authLink, operation:', operation);
    //   this.isAuthenticated
    //     .pipe(map(
    //       (isAuthenticated: boolean) => {
    //         let useBearer: boolean = true;
    //         if (QUERIES_WITHOUT_BEARER_PREFIX.find((queryName: string) => queryName === operation.operationName)) {
    //           useBearer = false;
    //         }

    //         return {
    //           headers: {
    //             ...headers,
    //             accept: 'application/json',
    //             authorization: isAuthenticated ? `${useBearer ? 'Bearer ' : ''}${this.oauthService.accessToken}` : ''
    //           }
    //         };
    //       }

    //     ))
    //     .pipe(tap((context) => {
    //       console.warn('authLink, headers:', context.headers)
    //     }))
    //     .toPromise()
    //   }
    // );
    const defaultUri: string = this.appConfig.getConfig().endpoints.apiDomain;

    const restEndpoints: {} = {
      policy: {
        uri: this.appConfig.getConfig().endpoints.policy,
        responseTransformer: responseTransformerOriginal,
      },
      baas: {
        uri: this.appConfig.getConfig().endpoints.baas,
        responseTransformer: responseTransformerOriginal,
      },
      icaws: {
        uri: this.appConfig.getConfig().endpoints.icaws,
        responseTransformer: responseTransformerDefault,
      },
      icawsSqle: {
        uri: this.appConfig.getConfig().endpoints.icawsSqle,
        responseTransformer: responseTransformerDefault,
      },
      icawsOffering: {
        uri: this.appConfig.getConfig().endpoints.offeringApiEndpoint,
        responseTransformer: responseTransformerOriginal,
      },
      metricsAgentProxy: {
        uri: this.appConfig.getConfig().endpoints.apiDomain,
        responseTransformer: responseTransformerOriginal,
      },
      consumption: {
        uri: this.appConfig.getConfig().endpoints.consumption,
        responseTransformer: responseTransformerOriginal,
      },
      threshold: {
        uri: this.appConfig.getConfig().endpoints.threshold,
        responseTransformer: responseTransformerDefault,
      },
      tickets: {
        uri: `${
          this.appConfig.getConfig().endpoints.apiDomain
        }/tickets-service/v2`,
        responseTransformer: responseTransformerDefault,
      },
      privateLink: {
        uri: this.appConfig.getConfig().endpoints.privatelinkUrl,
        responseTransformer: responseTransformerOriginal,
      },
      securityRules: {
        uri: `${
          this.appConfig.getConfig().endpoints.apiDomain
        }/management-console`,
        responseTransformer: responseTransformerOriginal,
      },
      // Add new REST endpoints here.
    };

    const restLink: ApolloLink = new RestLink({
      uri: defaultUri,
      responseTransformer: responseTransformerDefault,
      endpoints: restEndpoints,
      customFetch: this.customFetch,
    });
    const gqlLink: ApolloLink = this.httpLink.create({ uri });
    const afterwareLink: ApolloLink = new ApolloLink(
      (operation: Operation, forward: NextLink) =>
        forward(operation).map((response: any) => {
          const context: Record<string, any> = operation.getContext();

          if (context.restResponses[0].headers) {
            response.data.headers = {};
            context.restResponses[0].headers.forEach(
              (val: string, name: string) => {
                response.data.headers[name] = val;
              }
            );
          }

          return response;
        })
    );

    return {
      link: ApolloLink.from([afterwareLink, restLink, gqlLink]),
      cache,
      // defaultOptions: { // Disable graphql cache
      //   watchQuery: {
      //     fetchPolicy: 'network-only',
      //     errorPolicy: 'ignore',
      //   },
      //   query: {
      //     fetchPolicy: 'network-only',
      //     errorPolicy: 'all',
      //   }
      // },
      // connectToDevTools: true // enable apollo chrome developer tools
    };
  }

  private readonly customFetch: RestLink.CustomFetch = async (
    requestUrl: string,
    requestParams: any
  ) =>
    new Promise((resolve: any, reject: any) => {
      this.isAuthenticated.pipe(first()).subscribe(
        (isAuthenticated: boolean) => {
          if (isAuthenticated) {
            requestParams.headers = this.appendAuthToken(
              requestUrl,
              requestParams.headers
            );
            resolve(fetch(requestUrl, requestParams));
          } else {
            reject(new Error('User is not authenticated!'));
          }
        },
        (error: any) => {
          reject(
            new Error('Error when subscribe to isAuthenticated observable!')
          );
        }
      );
    });

  private readonly appendAuthToken = (
    url: string,
    requestHeaders: Headers
  ): Headers => {
    const accessToken: string = this.oauthService.accessToken;
    let useBearer: boolean = true;
    if (
      SERVICES_WITHOUT_BEARER_PREFIX.find(
        (prefix: string) => url.indexOf(prefix) !== -1
      )
    ) {
      useBearer = false;
    }
    requestHeaders.set(
      'authorization',
      `${useBearer ? 'Bearer ' : ''}${accessToken}`
    );

    return requestHeaders;
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    ApolloInitializer,
    {
      provide: APOLLO_OPTIONS,
      useFactory: (init: ApolloInitializer) => init.createApollo(),
      deps: [ApolloInitializer],
    },
    {
      provide: 'ApolloPersistor',
      useValue: persistor,
    },
  ],
})
export class GraphQLModule {}
