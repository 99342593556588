import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

//  This pipe replaces _ and - with space. Suited for converting a snakecase and kebab case strings to be displayed as label
@Pipe({
  name: 'displayAsLabel',
})
export class DisplayAsLabelPipe implements PipeTransform {
  transform(text: any): string {
    if (text === null || text === undefined || text === '') {
      return 'N/A';
    }

    if (typeof text === 'number') {
      return `${text}`;
    }

    return new TitleCasePipe().transform(text.replace(/[-_]/g, ' '));
  }
}
