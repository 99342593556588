/**
 * This transforms utility convertes sites service v1/v2 models into IMC compatible site model.
 */

// TODO: Move to GraphQL server.

import {
  GenericApp,
  MleApp,
  Node,
  Site,
  SqleApp,
} from '../../models/sites.model';
import {
  GenericAppV2,
  MleAppV2,
  NodeV1,
  NodeV2,
  SiteV1,
  SiteV2,
  SqleAppV2,
} from '../models/sites-service.model';

const databaseNodeType: string = 'database';
const gbInTb: number = 1024;
const gibiToTb: number = Math.pow(1024, 3) / Math.pow(1000, 4);

const generateDisplayName: Function = (site: any): string => {
  const siteName: string =
    site.supportSiteId || site.siteName || site.imcEntityName;

  return site.siteTDPID && siteName
    ? `${site.siteTDPID} (${siteName})`
    : siteName || site.siteId;
};

const generateDisplayLocation: Function = (
  cloudPlatform: string,
  region: string
): string =>
  `${cloudPlatform && cloudPlatform.toUpperCase()}${
    region ? `/${region}` : ''
  }`;

const transformV1Node: Function = (v1Node: NodeV1): Node => {
  if (!v1Node) {
    return undefined;
  }
  const node: Node = {
    name: v1Node.name || v1Node.nodeName,
    ipAddress: v1Node.hostname || v1Node.privateIp || v1Node.internalIpAddress,
    publicIpAddress: v1Node.publicIp,
    status: v1Node.status,
    instanceType: v1Node.instanceType || v1Node.nodeSize,
    cidr: v1Node.cidr,
    vpcCidr: v1Node.vpcCidr,
  };

  return node;
};

const transformV1SiteToSqleApp: Function = (v1Site: SiteV1): SqleApp => {
  if (!v1Site) {
    return undefined;
  }
  // data for sqle app for v1 site originates from the v1 site object
  const storageSizePerNodeTB: number = v1Site.storageSize
    ? parseInt(v1Site.storageSize, 10)
    : undefined;
  const sqleApp: SqleApp = {
    version: v1Site.version,
    status: v1Site.dbStatus || 'Unknown',
    operationalStatus: v1Site.operationalStatus || v1Site.status,
    instanceSize: v1Site.dbSize,
    nodeCount: v1Site.dbNodeCount,
    baseNodeCount: v1Site.baseDBNodeCount,
    storageSizePerNodeTB: v1Site.storageSize,
    totalVolumeSizeGibiB:
      storageSizePerNodeTB &&
      v1Site.dbNodeCount &&
      (storageSizePerNodeTB * v1Site.dbNodeCount) / gibiToTb,
    purpose: v1Site.purpose,
    dbVmConfig: v1Site.dbVmConfig,
    hsnStatus: v1Site.hsnStatus,
    actualHsnNodeCount: v1Site.actualHsnNodeCount,
    expectedHsnNodeCount: v1Site.expectedHsnNodeCount,
    availableDbConfigs: v1Site.availableDbConfigs,
    incNodeOptions: v1Site.incNodeOptions,
    region: v1Site.region,
    entitledStorage: v1Site.entitledStorage,
    nodes: [],
  };
  if (!v1Site.nodes) {
    return sqleApp;
  }

  // extract db nodes for sqle app
  const v1Nodes: NodeV1[] = v1Site.nodes;
  v1Nodes.forEach((v1Node: NodeV1) => {
    const node: Node = transformV1Node(v1Node);
    v1Node.type = v1Node.type && v1Node.type.toLowerCase();
    if (v1Node.type === databaseNodeType) {
      sqleApp.nodes.push(node);
    }
  });

  return sqleApp;
};

const transformV1NodesToGenericApps: Function = (
  v1Nodes: NodeV1[]
): GenericApp[] => {
  if (!v1Nodes) {
    return undefined;
  }
  const genericAppsByType: any = {};
  // v1 nodes include both db nodes and nodes for other applications (e.g. dsc, viewpoint, etc.)
  v1Nodes.forEach((v1Node: NodeV1) => {
    const node: Node = transformV1Node(v1Node);
    v1Node.type = v1Node.type && v1Node.type.toLowerCase();
    if (v1Node.type !== databaseNodeType) {
      if (genericAppsByType[v1Node.type]) {
        if (!genericAppsByType.nodes) {
          genericAppsByType.nodes = [];
        }
        genericAppsByType.nodes.push(node);
      } else {
        genericAppsByType[v1Node.type] = {
          name: v1Node.type,
          type: v1Node.type,
          operationalStatus: v1Node.status,
          nodes: [node],
        };
      }
    }
  });

  return Object.values(genericAppsByType);
};

const transformV1Site: Function = (v1Site: SiteV1): Site => {
  if (!v1Site) {
    return undefined;
  }

  const sqleApp: SqleApp = transformV1SiteToSqleApp(v1Site);
  const genericApps: GenericApp[] = transformV1NodesToGenericApps(v1Site.nodes);

  const transformedSite: Site = {
    displayName: generateDisplayName(v1Site),
    displayLocation: generateDisplayLocation(
      v1Site.cloudPlatform,
      v1Site.region
    ),
    siteId: v1Site.siteId,
    supportSiteId: v1Site.supportSiteId || v1Site.siteName,
    customerId: v1Site.customerId,
    purpose: v1Site.purpose,
    cloudPlatform: v1Site.cloudPlatform && v1Site.cloudPlatform.toLowerCase(),
    operationalStatus: v1Site.operationalStatus,
    targetSLA: v1Site.targetSLA,
    region: v1Site.region,
    isVCM: String(v1Site.isVCM).toLowerCase() === 'true',
    genericApps,
    sqleApp,
    features: v1Site.features,
    resourceVersion: 'v1',
    status: v1Site.dbStatus,
  };

  return transformedSite;
};

const transformV2Node: Function = (v2Node: NodeV2): Node => {
  if (!v2Node) {
    return undefined;
  }
  const node: Node = {
    usedFor: v2Node.used_for,
    ipAddress: v2Node.ip_address,
    publicIpAddress: v2Node.nat_ip_address,
    osType: v2Node.os_type,
    osVersion: v2Node.os_version,
    status: v2Node.operational_status_display_value,
    purpose: v2Node.purpose,
  };

  return node;
};

const transformV2GenericApp: Function = (
  v2GenericApp: GenericAppV2
): GenericApp => {
  if (!v2GenericApp) {
    return undefined;
  }
  const genericApp: GenericApp = {
    name: v2GenericApp.name,
    operationalStatus: v2GenericApp.operational_status_display_value,
    purpose: v2GenericApp.purpose,
    usedFor: v2GenericApp.used_for,
    type: v2GenericApp.category,
    shortDescription: v2GenericApp.short_description,
    version: v2GenericApp.version,
  };
  if (v2GenericApp.nodes) {
    // tslint:disable-next-line:no-unnecessary-callback-wrapper
    genericApp.nodes = v2GenericApp.nodes.map((v2Node: NodeV2) =>
      transformV2Node(v2Node)
    );
  }

  return genericApp;
};

const transformV2SqleApp: Function = (
  v2SqleApp: SqleAppV2,
  region: string,
  cidr: string
): SqleApp => {
  if (!v2SqleApp) {
    return undefined;
  }
  const totalVolumeSizeGibiB: number = v2SqleApp.volume_size
    ? parseInt(v2SqleApp.volume_size, 10)
    : undefined;
  const numberOfNodes: number = v2SqleApp.current_node_ct
    ? parseInt(v2SqleApp.current_node_ct, 10)
    : undefined;
  const sqleApp: SqleApp = {
    version: v2SqleApp.version,
    status: v2SqleApp.state,
    region,
    cidr,
    operationalStatus: v2SqleApp.operational_status_display_value,
    instanceSize: v2SqleApp.instance_size,
    nodeCount: numberOfNodes,
    baseNodeCount: v2SqleApp.base_node_ct
      ? parseInt(v2SqleApp.base_node_ct, 10)
      : undefined,
    totalVolumeSizeGibiB,
    storageSizePerNodeTB:
      totalVolumeSizeGibiB &&
      numberOfNodes &&
      `${(totalVolumeSizeGibiB * gibiToTb) / numberOfNodes}TB`,
    usedFor: v2SqleApp.used_for,
  };
  if (v2SqleApp.nodes) {
    // tslint:disable-next-line:no-unnecessary-callback-wrapper
    sqleApp.nodes = v2SqleApp.nodes.map((v2Node: NodeV2) =>
      transformV2Node(v2Node)
    );
  }

  return sqleApp;
};

const transformV2MleApp: Function = (v2MleApp: MleAppV2): MleApp => {
  const currentNodeCount: number = v2MleApp.current_node_ct
    ? v2MleApp.current_node_ct
    : 0;

  return {
    state: v2MleApp.state,
    current_node_ct: currentNodeCount,
  };
};

const transformV2Site: Function = (v2Site: SiteV2): Site => {
  if (!v2Site) {
    return undefined;
  }

  // supporting single sqle app per site
  const sqleApp: SqleApp = transformV2SqleApp(
    v2Site.sqleApps && v2Site.sqleApps[0],
    v2Site.region,
    v2Site.cidr
  );

  const genericApps: GenericApp[] =
    v2Site.genericApps &&
    // tslint:disable-next-line:no-unnecessary-callback-wrapper
    v2Site.genericApps.map((v2GenericApp: GenericAppV2) =>
      transformV2GenericApp(v2GenericApp)
    );

  const mleApps: MleApp[] = v2Site.mleApps
    ? v2Site.mleApps.map((value: MleAppV2, index: number, arr: MleAppV2[]) =>
        transformV2MleApp(value)
      )
    : undefined;

  const transformedSite: Site = {
    displayName: generateDisplayName(v2Site),
    displayLocation: generateDisplayLocation(
      v2Site.cloudPlatform,
      v2Site.region
    ),
    siteId: v2Site.siteId || v2Site.imcEntityName,
    supportSiteId: v2Site.supportSiteId || v2Site.imcEntityName,
    customerId: v2Site.customerId || v2Site.imcCustomerId,
    purpose: v2Site.purpose,
    cloudPlatform: v2Site.cloudPlatform && v2Site.cloudPlatform.toLowerCase(),
    status: v2Site.status,
    operationalStatus: v2Site.operationalStatus,
    targetSLA: v2Site.targetSLA ? parseInt(v2Site.targetSLA, 10) : undefined,
    resourceVersion: v2Site.resourceVersion,
    isVCM: String(v2Site.isVCM).toLowerCase() === 'true',
    region: v2Site.region,
    genericApps,
    sqleApp,
    mleApps,
    features: v2Site.features ? v2Site.features : undefined,
    u_account_id: v2Site.u_account_id,
    subAccountId: v2Site.subAccountId,
  };

  return transformedSite;
};

export const transformSite: Function = (site: SiteV1 | SiteV2): Site => {
  if ('resourceVersion' in site && site.resourceVersion === 'v2') {
    return transformV2Site(site);
  } else {
    return transformV1Site(site);
  }
};
