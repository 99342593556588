export enum PolicyResources {
  Tickets = 'Tickets',
  Sites = 'Sites',
  Database = 'Database',
  AzureSubscription = 'AzureSubscription',
  Users = 'Users',
  Metrics = 'Metrics',
  BaasSkippedObj = 'BaasSkippedObj',
  BaaS = 'BaaS',
  Policy = 'Policy',
  WorkOrders = 'WorkOrders',
}
