import { AwsInstanceInfo } from '../models/aws-instance-info.model';
import { CloudPlatformInstanceInfo } from '../models/cloud-platform-instance-info.model';

export const CLOUD_PLATFORM_INSTANCE_INFO: CloudPlatformInstanceInfo = {
  // TODO: instance types mappings should be removed once configurations finalized
  awsInstanceTypes: {
    // m4
    'm4.4xlarge': 'small',
    'm4.10xlarge': 'medium',
    'm4.16xlarge': 'large',
    // m5
    'm5.4xlarge': 'm5small',
    'm5.12xlarge': 'm5medium',
    'm5.24xlarge': 'm5large',
  },
  awsInstanceSizes: {
    // m4
    small: ['small', 'medium', 'large'],
    medium: ['small', 'medium', 'large'],
    large: ['small', 'medium', 'large'],
    // m5
    m5small: ['m5small', 'm5medium', 'm5large'],
    m5medium: ['m5small', 'm5medium', 'm5large'],
    m5large: ['m5small', 'm5medium', 'm5large'],
  },
  aws: {
    // m4
    small: {
      name: 'Small Instance',
      installation: 'm4.4xlarge',
      cpu: '16',
      memory: '64GB',
      tcore: '2.23',
    },
    medium: {
      name: 'Medium Instance',
      installation: 'm4.10xlarge',
      cpu: '40',
      memory: '160GB',
      tcore: '5.26',
    },
    large: {
      name: 'Large Instance',
      installation: 'm4.16xlarge',
      cpu: '64',
      memory: '256GB',
      tcore: '9.57',
    },
    // m5
    m5small: {
      name: 'Small Instance',
      installation: 'm5.4xlarge',
      cpu: '16',
      memory: '64GB',
      tcore: '2.60',
    },
    m5medium: {
      name: 'Medium Instance',
      installation: 'm5.12xlarge',
      cpu: '48',
      memory: '192GB',
      tcore: '6.80',
    },
    m5large: {
      name: 'Large Instance',
      installation: 'm5.24xlarge',
      cpu: '96',
      memory: '384GB',
      tcore: '13.60',
    },
  },
};

export const DEFAULT_INSTANCE_INFO: AwsInstanceInfo = {
  name: 'Unknown Instance Details',
  installation: '',
  cpu: '',
  memory: '',
  tcore: '',
};
