import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthenticationService } from '@imc/core/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'imc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private isAuthenticatedSubscription: Subscription;

  constructor(
    private authService: OAuthenticationService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.isAuthenticatedSubscription = this.authService.isAuthenticated$.subscribe(
      (isAuthenticated: boolean) => {
        if (isAuthenticated) {
          const redirectUrl: string =
            localStorage.getItem('redirect_url') || '/home';
          this.router.navigate([redirectUrl]);
        }
      }
    );
  }

  public ngOnDestroy(): void {
    if (this.isAuthenticatedSubscription) {
      this.isAuthenticatedSubscription.unsubscribe();
    }
  }

  public login($event: any): void {
    $event.preventDefault();
    this.authService.useIntelliCloudAD();
    const redirectUrl: string = localStorage.getItem('redirect_url') || '/home';
    this.authService.login(redirectUrl);
  }

  public loginCorp($event: any): void {
    $event.preventDefault();
    this.authService.useCorpAD();
    const redirectUrl: string = localStorage.getItem('redirect_url') || '/home';
    this.authService.login(redirectUrl);
  }
}
