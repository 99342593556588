import { Injectable } from '@angular/core';
import { Site } from '@imc/core/models/sites.model';
import { TmcInstanceInfo } from '../../models/tmc-instance-info.model';

@Injectable()
export class TmcInstanceInfoService {
  getInstanceInfo(site: Site): TmcInstanceInfo {
    const tmcInstanceTypes: Map<string, TmcInstanceInfo> = new Map();

    tmcInstanceTypes.set('ccu-0', {
      name: 'CCU Instance',
      installation: '',
      cpu: '2',
      memory: '128GB',
    });
    tmcInstanceTypes.set('ccu-1', {
      name: 'CCU Instance',
      installation: '',
      cpu: '2',
      memory: '256GB',
    });
    tmcInstanceTypes.set('ccu-2', {
      name: 'CCU Instance',
      installation: '',
      cpu: '2',
      memory: '256GB',
    });
    tmcInstanceTypes.set('ifx-0', {
      name: 'IFX Instance',
      installation: '',
      cpu: '2',
      memory: '512GB',
      tcore: '53',
    });
    tmcInstanceTypes.set('ifx-1', {
      name: 'IFX Instance',
      installation: '',
      cpu: '2',
      memory: '512GB',
      tcore: '97',
    });
    tmcInstanceTypes.set('ibx-0', {
      name: 'IBX Instance',
      installation: '',
      cpu: '2',
      memory: '256GB',
      tcore: '6.57',
    });
    tmcInstanceTypes.set('ibx-1', {
      name: 'IBX Instance',
      installation: '',
      cpu: '2',
      memory: '512GB',
      tcore: '6.57',
    });

    if (site.sqleApp && site.sqleApp.instanceSize) {
      if (tmcInstanceTypes.has(site.sqleApp.instanceSize)) {
        return tmcInstanceTypes.get(site.sqleApp.instanceSize);
      }
    }
  }
}
