import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';

@Component({
  selector: 'replication-details-dialog',
  templateUrl: './replicationDetailsDialog.component.html',
  styleUrls: ['./replicationDetailsDialog.component.scss'],
})
export class ReplicationDetailsDialogComponent {
  replicationDetails: any;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  close() {
    this.dialog.closeAll();
  }

  ngOnInit() {
    this.replicationDetails = this.data;
  }
}
