import { Injectable } from '@angular/core';
import { Site } from '@imc/core/models/sites.model';
import { AzureInstanceInfo } from '../../models/azure-instance-info.model';

@Injectable()
export class AzureInstanceInfoService {
  getInstanceInfo(site: Site): AzureInstanceInfo {
    let instanceInfo: AzureInstanceInfo;

    if (site.sqleApp && site.sqleApp.instanceSize && site.sqleApp.dbVmConfig) {
      instanceInfo = {
        name: site.sqleApp.instanceSize,
        cpus: site.sqleApp.dbVmConfig.cpus,
        sku: site.sqleApp.dbVmConfig.sku,
        memory: site.sqleApp.dbVmConfig.memory,
        tcores: site.sqleApp.dbVmConfig.tcores,
      };
    }

    return instanceInfo;
  }

  getAvailableInstancesInfo(site: Site): any {
    if (site.sqleApp && site.sqleApp.availableDbConfigs) {
      return this.sortedConfigs(site.sqleApp.availableDbConfigs);
    }
  }

  getAvailableInstanceSizes(site: Site): any {
    if (site.sqleApp && site.sqleApp.availableDbConfigs) {
      return [].concat.apply(
        site.sqleApp.availableDbConfigs.map((dbconfig: any) =>
          Object.keys(dbconfig).toString()
        )
      );
    }
  }

  isNodeScalingPossible(baseDBNodeCount: number, dbNodeCount: number): boolean {
    if (baseDBNodeCount === null || dbNodeCount < baseDBNodeCount) {
      return false;
    }
    // if base node count is >33 then there'ss be no option to scale
    if (baseDBNodeCount > 32) {
      return false;
    }

    return true;
  }

  private sortedConfigs(availableConfigs: any): any {
    const sortOrder: string[] = ['Small', 'Medium', 'Large'];

    return availableConfigs.sort((config1: string, config2: string) => {
      const size1: string = Object.keys(config1)[0];
      const size2: string = Object.keys(config2)[0];

      const comparison: number =
        sortOrder.indexOf(size1) > sortOrder.indexOf(size2) ? 1 : -1;

      return comparison;
    });
  }
}
