import {
  ComponentFactoryResolver,
  Directive,
  Input,
  ViewContainerRef,
} from '@angular/core';

import { AwsSiteOverviewComponent } from '../_modules/aws/layouts/aws-site-overview/aws-site-overview.component';
import { AzureSiteOverviewComponent } from '../_modules/azure/layouts/azure-site-overview/azure-site-overview.component';
import { DynamicLayoutComponent } from '../_modules/ecosystems-shared/components/dynamic-layout/dynamic-layout.component';
import { TmcSiteOverviewComponent } from '../_modules/tmc/layouts/tmc-site-overview/tmc-site-overview.component';
import { DefaultSiteOverviewComponent } from '../layouts/default-site-overview/default-site-overview.component';

@Directive({
  selector: '[imcSiteHost]',
})
export class ImcSiteHostDirective {
  private readonly layoutMappings: any = {
    aws: AwsSiteOverviewComponent,
    azure: AzureSiteOverviewComponent,
    tmc: TmcSiteOverviewComponent,
    default: DefaultSiteOverviewComponent,
  };

  @Input('imcSiteHost') set siteInfo(siteInfo: any) {
    if (!siteInfo.siteId) {
      return;
    }

    const currentLayout: any = this.getCurrentLayout(siteInfo);

    const factory: any = this.componentFactoryResolver.resolveComponentFactory(
      currentLayout
    );
    this.viewContainerRef.clear();
    const componentRef: any = this.viewContainerRef.createComponent(factory);

    // set component context
    const instance: any = <DynamicLayoutComponent>componentRef.instance;
    instance.siteInfo = siteInfo;
    instance.loading = false;
  }
  constructor(
    public viewContainerRef: ViewContainerRef,
    private readonly componentFactoryResolver: ComponentFactoryResolver
  ) {}

  private getCurrentLayout(siteInfo: any): DynamicLayoutComponent {
    let selectedLayout: DynamicLayoutComponent;

    if (siteInfo && siteInfo.cloudPlatform) {
      selectedLayout = this.layoutMappings[
        siteInfo.cloudPlatform.toLowerCase()
      ];
    }

    return selectedLayout || this.layoutMappings.default;
  }
}
