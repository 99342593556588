import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ElasticityService {
  private siteDbStatusChanged$: BehaviorSubject<any> = new BehaviorSubject('');
  private refreshSiteInfo$: BehaviorSubject<any> = new BehaviorSubject({});

  siteDbStatusChanged(status: string): void {
    this.siteDbStatusChanged$.next(status);
  }

  refreshSiteInfo(): void {
    this.refreshSiteInfo$.next({});
  }

  getDbSiteStatus(): Observable<string> {
    return this.siteDbStatusChanged$.asObservable();
  }

  getRefreshStatus(): Observable<{}> {
    return this.refreshSiteInfo$.asObservable();
  }
}
