import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { concat, forkJoin, Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  first,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';

import { Customer } from '@imc-features/ecosystems/_modules/ecosystems-shared/models/customer.model';
import { SiteService } from '@imc-features/ecosystems/_modules/ecosystems-shared/services/site-service/ecosystems-site.service';
import { PolicyOperations } from '@imc/core/models/policyOperations.enum';
import { PolicyResources } from '@imc/core/models/policyResources.enum';

import { AzureSubscription } from '../../models/azure-subscription.model';
import { AzureSubscriptionService } from '../../services/azure-subscriptions.service';

@Component({
  selector: 'imc-edit-azure-subscription',
  templateUrl: './edit-azure-subscription.component.html',
  styleUrls: ['./edit-azure-subscription.component.scss'],
})
export class EditAzureSubscriptionComponent implements OnInit {
  private disableSubmit: boolean;
  readonly deactivateCtrlName: string = 'deactivate';
  readonly subscriptionNameCtrlName: string = 'subscriptionName';
  readonly subscriptionOwnerCtrlName: string = 'owner';
  readonly subscriptionCustomerCtrlName: string = 'customerName';
  readonly subscriptionCommentsCtrlName: string = 'comments';
  editForm$: Observable<any>;
  submitOperationInProgress$: Observable<boolean>;
  status?: boolean;
  deactivateCtrlTitle: string;
  submitButtonName: string;
  subscriptionId: string;

  PolicyOperations = PolicyOperations;
  PolicyResources = PolicyResources;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly subscriptionService: AzureSubscriptionService,
    private readonly siteService: SiteService
  ) {}

  ngOnInit(): void {
    this.editForm$ = this.route.params.pipe(
      switchMap((parameters: Params) => {
        this.disableSubmit = false;
        this.status = undefined;
        this.subscriptionId = parameters.id;

        return this.initializeForm(parameters.id);
      })
    );
  }

  displayCustomerName(customer?: Customer): string | undefined {
    return customer ? customer.name : '';
  }

  undoSuffixButtonValue(
    controlName: string,
    formGroup: FormGroup,
    originalValue: string
  ): any {
    const control: AbstractControl = formGroup.get(controlName);
    const currentValue: string = control.value;

    return currentValue === originalValue ||
      !formGroup.get(this.deactivateCtrlName).value
      ? { disabled: true, revert: undefined }
      : { disabled: false, revert: () => control.setValue(originalValue) };
  }

  customerSuffixButtonValue(editForm: any): any {
    if (!editForm.formGroup.get(this.deactivateCtrlName).value) {
      return { disabled: true, changeCustomerValue: undefined };
    }

    const customerControl: AbstractControl = editForm.formGroup.get(
      this.subscriptionCustomerCtrlName
    );
    const originalCustomerId: string = editForm.subscription.customerId;
    const val: any = customerControl.value;
    const currentCustomerId: string =
      !val || typeof val === 'string' ? undefined : val.customerId;
    if (originalCustomerId === currentCustomerId) {
      return {
        disabled: true,
        changeCustomerValue: undefined,
      };
    }

    return {
      disabled: false,
      changeCustomerValue: () =>
        customerControl.setValue(editForm.subscription.customerName),
    };
  }

  commentsSuffixButtonValue(editForm: any): any {
    if (!editForm.formGroup.get(this.deactivateCtrlName).value) {
      return {
        disabled: true,
        toolTip: 'azure_subscription_undo_value',
        icon: 'undo',
        changeCommentValue: undefined,
      };
    }
    const commentsControl: AbstractControl = editForm.formGroup.get(
      this.subscriptionCommentsCtrlName
    );
    const currentValue: string = commentsControl.value;
    if (
      !isNullOrWhitespace(editForm.subscription.comments) &&
      currentValue === editForm.subscription.comments
    ) {
      return {
        disabled: false,
        toolTip: 'azure_subscription_delete_comment',
        icon: 'close',
        changeCommentValue: () => commentsControl.setValue(''),
      };
    } else if (
      (!isNullOrWhitespace(currentValue) ||
        !isNullOrWhitespace(editForm.subscription.comments)) &&
      currentValue !== editForm.subscription.comments
    ) {
      return {
        disabled: false,
        toolTip: 'azure_subscription_undo_value',
        icon: 'undo',
        changeCommentValue: () =>
          commentsControl.setValue(editForm.subscription.comments),
      };
    }

    return {
      disabled: true,
      toolTip: 'azure_subscription_undo_value',
      icon: 'undo',
      changeCommentValue: undefined,
    };
  }

  isDeactivateSelected(formGroup: FormGroup): boolean {
    return !formGroup.get(this.deactivateCtrlName).value;
  }

  toggleDeactivateControl(formGroup: FormGroup): void {
    const controlNames: string[] = [
      this.subscriptionNameCtrlName,
      this.subscriptionOwnerCtrlName,
      this.subscriptionCustomerCtrlName,
      this.subscriptionCommentsCtrlName,
    ];

    if (this.isDeactivateSelected(formGroup)) {
      controlNames.forEach((controlName: string) => {
        formGroup.get(controlName).disable();
      });
      this.deactivateCtrlTitle = 'azure_subscription_slide_edit';
      this.submitButtonName = 'azure_subscriptions_deactivate';
    } else {
      controlNames.forEach((controlName: string) => {
        formGroup.get(controlName).enable();
      });
      this.deactivateCtrlTitle = 'azure_subscription_slide_deactivate';
      this.submitButtonName = 'save_changes';
    }
  }

  showHint(value: string): string {
    return value && value.trim().length > 0 ? `was: ${value}` : '';
  }

  showError(formGroup: FormGroup, controlName: string): string {
    const control: AbstractControl = formGroup.get(controlName);
    let message: string;
    if (
      control.enabled &&
      control.invalid &&
      (control.dirty || control.touched)
    ) {
      if (control.errors.required) {
        message = `required`;
      } else if (control.errors.subscriptionIdAlreadyExists) {
        message = control.errors.subscriptionIdAlreadyExists.message;
      } else if (control.errors.subscriptionIdNotUuid) {
        message = control.errors.subscriptionIdNotUuid.message;
      } else if (control.errors.invalidCustomer) {
        message = control.errors.invalidCustomer.message;
      } else {
        message = 'error'; // there is a bug in the validation code, if we get here.
      }
    }

    return message;
  }

  shouldDisableSubmit(
    formGroup: FormGroup,
    subscription: AzureSubscription
  ): boolean {
    if (this.disableSubmit) {
      return true;
    }

    if (!formGroup.get(this.deactivateCtrlName).value) {
      return false;
    }

    return this.shouldDisableSubmitForEdit(formGroup, subscription);
  }

  onSubmit(formGroup: FormGroup, subscription: AzureSubscription): void {
    if (this.shouldDisableSubmit(formGroup, subscription)) {
      return;
    }

    this.disableSubmit = true;
    formGroup.disable();
    if (!formGroup.get(this.deactivateCtrlName).value) {
      this.submitOperationInProgress$ = concat(
        of(true),
        this.submitFormForDeactivate(subscription.id)
      );
    } else {
      this.submitOperationInProgress$ = concat(
        of(true),
        this.submitFormForEdit(formGroup, subscription),
        this.resetFormAndUpdateSubscriptionAfterEdit(formGroup, subscription)
      );
    }
  }

  private submitFormForDeactivate(subscriptionId: string): Observable<boolean> {
    return this.subscriptionService.deactivateSubscription(subscriptionId).pipe(
      switchMap((status: boolean) => {
        this.status = status;

        return of(false);
      }),
      first()
    );
  }

  private submitFormForEdit(
    formGroup: FormGroup,
    subscription: AzureSubscription
  ): Observable<boolean> {
    const customer: Customer = formGroup.get(this.subscriptionCustomerCtrlName)
      .value;
    const commentsVal: string = formGroup.get(this.subscriptionCommentsCtrlName)
      .value;
    const comments: string =
      commentsVal || commentsVal.trim().length === 0 ? null : commentsVal;

    return this.subscriptionService
      .editSubscription(
        subscription.id,
        formGroup.get(this.subscriptionNameCtrlName).value,
        formGroup.get(this.subscriptionOwnerCtrlName).value,
        customer.customerId,
        customer.name,
        comments
      )
      .pipe(
        switchMap((status: boolean) => {
          this.status = status;

          return of(true);
        }),
        first()
      );
  }

  private shouldDisableSubmitForEdit(
    formGroup: FormGroup,
    subscription: AzureSubscription
  ): boolean {
    if (formGroup.invalid) {
      return true;
    }

    const name: string = formGroup.get(this.subscriptionNameCtrlName).value;
    const owner: string = formGroup.get(this.subscriptionOwnerCtrlName).value;
    const customer: Customer = formGroup.get(this.subscriptionCustomerCtrlName)
      .value;
    const comments: string = formGroup.get(this.subscriptionCommentsCtrlName)
      .value;

    return (
      name === subscription.name &&
      owner === subscription.owner &&
      customer.customerId === subscription.customerId &&
      customer.name === subscription.customerName &&
      comments === subscription.comments
    );
  }

  private resetFormAndUpdateSubscriptionAfterEdit(
    formGroup: FormGroup,
    subscription: AzureSubscription
  ): Observable<boolean> {
    return of(false).pipe(
      delay(3000),
      map((status: boolean) => {
        if (this.status === true) {
          subscription.name = emptyOrValue(
            formGroup.get(this.subscriptionNameCtrlName).value
          );
          subscription.owner = emptyOrValue(
            formGroup.get(this.subscriptionOwnerCtrlName).value
          );
          subscription.comments = emptyOrValue(
            formGroup.get(this.subscriptionCommentsCtrlName).value
          );
          const customer: Customer = formGroup.get(
            this.subscriptionCustomerCtrlName
          ).value;
          subscription.customerId = customer.customerId;
          subscription.customerName = customer.name;
        }

        this.disableSubmit = false;
        this.status = undefined;
        formGroup.enable();

        return false;
      }),
      first()
    );
  }

  private initializeForm(subscriptionId: string): Observable<any> {
    return forkJoin(
      this.subscriptionService.getSubscriptionById(subscriptionId),
      this.siteService.listCustomers()
    ).pipe(
      switchMap(
        ([subscription, customers]: [
          AzureSubscription,
          Map<string, Customer>
        ]) => {
          let currentCustomer: Customer;
          if (customers.has(subscription.customerId)) {
            currentCustomer = customers.get(subscription.customerId);
            customers.delete(subscription.customerId);
          } else {
            currentCustomer = {
              customerId: subscription.customerId,
              name: subscription.customerName,
            };
          }

          const customersArr: Customer[] = [];
          customers.forEach((value: Customer) => customersArr.push(value));
          const sortedCustomers: Customer[] = customersArr.sort(
            (a: Customer, b: Customer) =>
              a.name < b.name ? -1 : a.name > b.name ? 1 : 0
          );

          return this.initializeFormWithSubscription(subscription, [
            currentCustomer,
            ...sortedCustomers,
          ]);
        }
      ),
      catchError((error: any, caught: Observable<any>) =>
        of({
          error: true,
          formGroup: undefined,
          customerOptions$: undefined,
          subscription: undefined,
        })
      )
    );
  }

  private initializeFormWithSubscription(
    subscription: AzureSubscription,
    customers: Customer[]
  ): Observable<any> {
    if (!subscription) {
      return of({
        error: true,
        formGroup: undefined,
        customerOptions$: undefined,
        subscription: undefined,
      });
    }

    this.initializeDynamicControlTitles();
    const customerNameControl: FormControl = new FormControl(customers[0], [
      Validators.required,
      invalidCustomerValidator,
    ]);
    const customerOptions$: Observable<
      Customer[]
    > = customerNameControl.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      startWith(''),
      map((value: string | Customer | undefined) =>
        typeof value === 'string' ? value : value.name
      ),
      map((value: string) => filterCustomer(value, customers))
    );
    const formGroup: FormGroup = new FormGroup({
      deactivate: new FormControl(subscription.status),
      subscriptionName: new FormControl(subscription.name, Validators.required),
      owner: new FormControl(subscription.owner, Validators.required),
      customerName: customerNameControl,
      comments: new FormControl(subscription.comments),
    });

    return of({ error: false, formGroup, customerOptions$, subscription });
  }

  private initializeDynamicControlTitles(): void {
    this.deactivateCtrlTitle = 'azure_subscription_slide_deactivate';
    this.submitButtonName = 'save_changes';
  }
}

const filterCustomer: Function = (
  value: string,
  customers: Customer[]
): Customer[] => {
  const filterValue: string =
    value || value.trim().length === 0 ? value.toLowerCase() : undefined;

  return filterValue
    ? customers.filter((customer: Customer) =>
        customer.name.toLowerCase().includes(filterValue)
      )
    : customers;
};

const emptyOrValue: Function = (value: string): string => (!value ? '' : value);

const isNullOrWhitespace: Function = (value: string): boolean =>
  !value || value.trim().length === 0;

const invalidCustomerValidator: ValidatorFn = (
  control: AbstractControl
): { [key: string]: any } | null =>
  typeof control.value === 'string'
    ? { invalidCustomer: { value: control.value, message: 'invalid_customer' } }
    : null;
