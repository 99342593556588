export enum DbState {
  START,
  STOP,
  CHANGE,
  FAIL,
}

export const DbStatusStrings: any = {
  started: {
    dbState: DbState.START,
    dbMessage: 'Database Running',
  },
  stopped: {
    dbState: DbState.STOP,
    dbMessage: 'Stopped',
  },
  resizing: {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Resize Operation In-Progress',
  },
  starting: {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Start Operation In-Progress',
  },
  stopping: {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Stop Operation In-Progress',
  },
  scaling: {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Scale Out/In Operation In-Progress',
  },
  'enabling scale out/in': {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Enabling Scale Out/In Operation In-Progress',
  },
  enabling_scale_out_in: {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Enabling Scale Out/In Operation In-Progress',
  },
  enabled_scale_out_in: {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Enabling Scale Out/In Operation In-Progress',
  },
  scaling_out: {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Scale-Out Operation In-Progress',
  },
  scaling_in: {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Scale-In Operation In-Progress',
  },
  scaled_in: {
    dbState: DbState.START,
    dbMessage: 'Database Running',
  },
  scaled_out: {
    dbState: DbState.START,
    dbMessage: 'Database Running',
  },
  'ebs-expanding': {
    dbState: DbState.CHANGE,
    dbMessage: 'Database EBS Volume Up In-Progress',
  },
  expanding_storage: {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Storage Expansion In-Progress',
  },
  getting_storage_options: {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Get Storage Options In-Progress',
  },
  'failed resize': {
    dbState: DbState.FAIL,
    dbMessage: 'Database Resize Operation Failed',
  },
  failed_resize: {
    dbState: DbState.FAIL,
    dbMessage: 'Database Resize Operation Failed',
  },
  'failed start': {
    dbState: DbState.FAIL,
    dbMessage: 'Database Start Operation Failed',
  },
  failed_start: {
    dbState: DbState.FAIL,
    dbMessage: 'Database Start Operation Failed',
  },
  'failed stop': {
    dbState: DbState.FAIL,
    dbMessage: 'Database Stop Operation Failed',
  },
  failed_stop: {
    dbState: DbState.FAIL,
    dbMessage: 'Database Stop Operation Failed',
  },
  'failed enable scale out/in': {
    dbState: DbState.FAIL,
    dbMessage: 'Database Enabling Scale Out/In Operation Failed',
  },
  failed_enable_scale_out_in: {
    dbState: DbState.FAIL,
    dbMessage: 'Database Enabling Scale Out/In Operation Failed',
  },
  'failed scaling': {
    dbState: DbState.FAIL,
    dbMessage: 'Database Scaling Operation Failed',
  },
  failed_scaling: {
    dbState: DbState.FAIL,
    dbMessage: 'Database Scaling Operation Failed',
  },
  'failed scaling in': {
    dbState: DbState.FAIL,
    dbMessage: 'Database Scale-In Operation Failed',
  },
  failed_scale_in: {
    dbState: DbState.FAIL,
    dbMessage: 'Database Scale-In Operation Failed',
  },
  'failed scaling out': {
    dbState: DbState.FAIL,
    dbMessage: 'Database Scale-Out Operation Failed',
  },
  failed_scale_out: {
    dbState: DbState.FAIL,
    dbMessage: 'Database Scale-Out Operation Failed',
  },
  failed_expand_storage: {
    dbState: DbState.FAIL,
    dbMessage: 'Database Storage Expansion Failed',
  },
  'failed ebs expansion': {
    dbState: DbState.FAIL,
    dbMessage: 'Database EBS Volume Up Failed',
  },
  failed_get_storage_options: {
    dbState: DbState.START,
    dbMessage: 'Database Get Storage Options Failed',
  },
  failed_hsn_launch: {
    dbState: DbState.START,
    dbMessage: 'Database Hsn Launch Failed',
  },
  failed_hsn_vm_start: {
    dbState: DbState.START,
    dbMessage: 'Database Hsn VM Start Failed',
  },
  // v2 related status values (GET - new sqle api)
  // may not be needed incase we decide to not poll on GET api but depend on SNOW
  'start succeeded': {
    dbState: DbState.START,
    dbMessage: 'Database Running',
  },
  'stop succeeded': {
    dbState: DbState.STOP,
    dbMessage: 'Database Running',
  },
  'scale succeeded': {
    dbState: DbState.START,
    dbMessage: 'Database Running',
  },
  'storage_size succeeded': {
    dbState: DbState.START,
    dbMessage: 'Database Running',
  },
  'instance_type succeeded': {
    dbState: DbState.START,
    dbMessage: 'Database Running',
  },
  'start failed': {
    dbState: DbState.FAIL,
    dbMessage: 'Database Start Operation Failed',
  },
  'stop failed': {
    dbState: DbState.FAIL,
    dbMessage: 'Database Stop Operation Failed',
  },
  'scale failed': {
    dbState: DbState.FAIL,
    dbMessage: 'Database Scale Operation Failed',
  },
  'storage_size failed': {
    dbState: DbState.FAIL,
    dbMessage: 'Database Storage Expansion Failed',
  },
  'instance_type failed': {
    dbState: DbState.FAIL,
    dbMessage: 'Database Resize Operation Failed',
  },
  'start in_progress': {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Start Operation In-Progress',
  },
  'stop in_progress': {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Stop Operation In-Progress',
  },
  'scale in_progress': {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Scale Operation In-Progress',
  },
  'storage_size in_progress': {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Storage Expansion In-Progress',
  },
  'instance_type in_progress': {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Resize Operation In-Progress',
  },
  // v2 related status values (SNOW CI v2)
  Running: {
    dbState: DbState.START,
    dbMessage: 'Database Running',
  },
  Stopped: {
    dbState: DbState.STOP,
    dbMessage: 'Stopped',
  },
  Hibernating: {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Hibernating',
  },
  'Resize Operation In-Progress': {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Resize Operation In-Progress',
  },
  'Start Operation In-Progress': {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Start Operation In-Progress',
  },
  'Stop Operation In-Progress': {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Stop Operation In-Progress',
  },
  'Scale Out/In Operation In-Progress': {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Scale Out/In Operation In-Progress',
  },
  'Enabling Scale Out/In Operation In-Progress': {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Enabling Scale Out/In Operation In-Progress',
  },
  'Scale-Out Operation In-Progress': {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Scale-Out Operation In-Progress',
  },
  'Scale-In Operation In-Progress': {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Scale-In Operation In-Progress',
  },
  'EBS Volume Up In-Progress': {
    dbState: DbState.CHANGE,
    dbMessage: 'Database EBS Volume Up In-Progress',
  },
  'Storage Expansion In-Progress': {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Storage Expansion In-Progress',
  },
  'Get Storage Options In-Progress': {
    dbState: DbState.CHANGE,
    dbMessage: 'Database Get Storage Options In-Progress',
  },
  'Resize Operation Failed': {
    dbState: DbState.FAIL,
    dbMessage: 'Database Resize Operation Failed',
  },
  'Start Operation Failed': {
    dbState: DbState.FAIL,
    dbMessage: 'Database Start Operation Failed',
  },
  'Stop Operation Failed': {
    dbState: DbState.FAIL,
    dbMessage: 'Database Stop Operation Failed',
  },
  'Enabling Scale Out/In Operation Failed': {
    dbState: DbState.FAIL,
    dbMessage: 'Database Enabling Scale Out/In Operation Failed',
  },
  'Scaling Operation Failed': {
    dbState: DbState.FAIL,
    dbMessage: 'Database Scaling Operation Failed',
  },
  'Scale-In Operation Failed': {
    dbState: DbState.FAIL,
    dbMessage: 'Database Scale-In Operation Failed',
  },
  'Storage Expansion Failed': {
    dbState: DbState.FAIL,
    dbMessage: 'Database Storage Expansion Failed',
  },
  'EBS Volume Up Failed': {
    dbState: DbState.FAIL,
    dbMessage: 'Database EBS Volume Up Failed',
  },
  'Get Storage Options Failed': {
    dbState: DbState.START,
    dbMessage: 'Database Get Storage Options Failed',
  },
};
