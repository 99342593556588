import { Injectable } from '@angular/core';
import { listSites, Site } from '@imc/core';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { forkJoin, from, Observable } from 'rxjs';
import {
  filter,
  groupBy,
  map,
  mergeMap,
  retry,
  switchMap,
  toArray,
} from 'rxjs/operators';

const cloudDefaultSla: number = 99.9;

@Injectable()
export class SystemDashboardService {
  constructor(private readonly apollo: Apollo) {}

  public getLowestAvailability(reportMonth: string): Observable<any> {
    const site$: Observable<any[]> = this.getSites();

    const sitesWithProblem$: Observable<any> = site$.pipe(
      // tslint:disable-next-line:no-unnecessary-callback-wrapper
      switchMap((sites: any) => from(sites)),
      filter((site: any) => site.customerName),
      groupBy((site: any) => site.customerId),
      mergeMap((group: any) =>
        forkJoin(
          this.getSitesSlaReports(group.key, reportMonth),
          group.pipe(toArray())
        )
      ),
      map((sites: any) => {
        sites[1].map((site: any) => {
          site.availability = sites[0][site.siteId];
          if (
            !site.targetSLA &&
            ['aws', 'azure', 'tmc'].includes(site.cloudPlatform)
          ) {
            site.targetSLA = cloudDefaultSla;
          }

          return site;
        });

        return sites;
      }),
      mergeMap((sites: any) => from(sites[1])),
      filter((site: any) => site.availability < site.targetSLA)
    );

    return sitesWithProblem$;
  }

  private getSites(): Observable<Site[]> {
    return listSites(this.apollo);
  }

  private getSitesSlaReports(
    customerId: string,
    reportMonth: string,
    monthsAgo: number = 2
  ): Observable<any> {
    const _query: string = gql`
      {
        slaReports(customerId: $customerId, monthsAgo: $monthsAgo)
          @rest(
            type: "slaReport"
            path: "/service-health/availabilityReports/{args.customerId}?monthsAgo={args.monthsAgo}"
          ) {
          siteId
          customerId
          startDate
          availabilityPercentage
          reportId
        }
      }
    `;

    return this.apollo
      .query<any>({
        query: _query,
        variables: { customerId, monthsAgo },
      })
      .pipe(
        map((responseBody: ApolloQueryResult<any>) => {
          if (responseBody.errors) {
            throw new Error(JSON.stringify(responseBody.errors));
          }

          const slaReports: any[] =
            (responseBody.data && responseBody.data.slaReports) || [];

          const availabilityMap: any = slaReports
            .filter(
              (slaReport: any) =>
                slaReport.startDate.substr(0, 7) === reportMonth
            )
            .reduce((acc: any, report: any) => {
              acc.customerId = report.customerId;
              if (report.siteId && report.availabilityPercentage) {
                if (!acc[report.siteId]) {
                  acc[report.siteId] = report.availabilityPercentage;
                } else {
                  acc[report.siteId] = Math.min(
                    report.availabilityPercentage,
                    acc[report.siteId]
                  );
                }
              }

              return acc;
            }, {});

          return availabilityMap;
        }),
        retry(2)
      );
  }
}
