import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TdMediaService } from '@covalent/core';
import { CovalentBaseEchartsModule } from '@covalent/echarts/base';
import { CovalentLineEchartsModule } from '@covalent/echarts/line';
import { CovalentToolboxEchartsModule } from '@covalent/echarts/toolbox';
import { CovalentTooltipEchartsModule } from '@covalent/echarts/tooltip';
import { CoreModule, ImcMaterialModule } from '@imc/core';
import { SharedModule } from '@imc/shared/shared.module';
import { ApplicationOverviewComponent } from './cards/application-overview/application-overview.component';
import { EcosystemsBackupsCardComponent } from './cards/ecosystems-backups-card/ecosystems-backups-card.component';
import { MetricsToolComponent } from './cards/metrics-tool/metrics-tool.component';
import { MetricsToolRegistration } from './cards/metrics-tool/metrics-tool.registration.component';
import { SiteInfoComponent } from './cards/site-info/site-info.component';
import { SqlEngineComponent } from './cards/sql-engine/sql-engine.component';
import { UtilInfoComponent } from './cards/util-info/util-info.component';
import { BackupsService } from './services/backups-service/backups.service';
import { ElasticityService } from './services/elasticity-service/elasticity.service';
import { MetricsAgentProxy } from './services/metrics-agent-proxy/metrics-agent-proxy.service';
import { MetricsGraphService } from './services/metrics-graph-service/metrics-graph.service';
import { SiteService } from './services/site-service/ecosystems-site.service';

@NgModule({
  declarations: [
    EcosystemsBackupsCardComponent,
    SiteInfoComponent,
    SqlEngineComponent,
    ApplicationOverviewComponent,
    UtilInfoComponent,
    MetricsToolComponent,
    MetricsToolRegistration,
  ],
  exports: [
    EcosystemsBackupsCardComponent,
    SiteInfoComponent,
    SqlEngineComponent,
    ApplicationOverviewComponent,
    UtilInfoComponent,
    MetricsToolComponent,
    MetricsToolRegistration,
  ],
  imports: [
    CommonModule,
    CoreModule,
    RouterModule,
    ImcMaterialModule,
    SharedModule,
    CovalentBaseEchartsModule,
    CovalentTooltipEchartsModule,
    CovalentToolboxEchartsModule,
    CovalentLineEchartsModule,
  ],
  providers: [
    TdMediaService,
    BackupsService,
    MetricsAgentProxy,
    MetricsGraphService,
    SiteService,
    ElasticityService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EcosystemsSharedModule {}
