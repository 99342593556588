import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ListTicketsRequest,
  Ticket,
  TicketsResponse,
} from '../models/ticket.model';

@Injectable()
export class DashboardTicketsService {
  constructor(private readonly apollo: Apollo) {}

  async getPaginatedTickets(
    request: ListTicketsRequest,
    startKey?: string
  ): Promise<TicketsResponse> {
    const ticketsQuery: any = gql`
      query tickets {
        listTicketsResponse(
          limit: $limit
          fields: $fields
          customerTickets: $customerTickets
          blacklistCustomerIds: $blacklistCustomerIds
          updatedAfterUTC: $updatedAfterUTC
        ) @rest(type: "Ticket", path: "/management-console/tickets?{args}") {
          updatedOn
          priority
          state
          shortDescription
          number
          ticketId
          ticketType
          customerName
          customerId
        }
      }
    `;

    const queryInput: any = {
      query: ticketsQuery,
      variables: {
        limit: request.limit,
        fields: request.fields,
        customerTickets: request.customerTickets,
        blacklistCustomerIds: request.blacklistCustomerIds,
        updatedAfterUTC: request.updatedAfterUTC,
      },
      context: {
        headers: {},
      },
      fetchPolicy: 'no-cache',
    };

    if (startKey) {
      queryInput.context.headers['x-page-exclusive-start-key'] = startKey;
    }

    const ticketsResult: Observable<TicketsResponse> = await this.apollo
      .query<any>(queryInput)
      .pipe(
        map((responseBody: any) => {
          if (responseBody.errors) {
            throw new Error('Fetching ticket information failed');
          }

          return responseBody.data;
        }),
        map((response: any) => {
          const ticketsResponse: TicketsResponse = {
            tickets: response.listTicketsResponse,
            lastEvaluatedKey: response.headers['x-page-last-evaluated-key'],
          };

          return ticketsResponse;
        })
      );

    const ticketsResultPromise: Promise<
      TicketsResponse
    > = ticketsResult.toPromise();

    return ticketsResultPromise.catch(() => undefined);
  }

  /**
   * Get Tickets with Filters
   */
  getTickets(
    limit: number,
    customerId?: string,
    priority?: string,
    status?: string
  ): Observable<Ticket[]> {
    let path: string = `/management-console/tickets?limit=${limit}`;

    if (customerId !== null && customerId !== undefined) {
      path = `${path}&customerId=${customerId}`;
    }
    if (priority !== null && priority !== undefined) {
      path = `${path}&priority=${priority}`;
    }
    if (status !== null && status !== undefined) {
      path = `${path}&status=${status}`;
    }

    const ticketsQuery: any = gql`
      query tickets {
        tickets(path: $path) @rest(type: "Ticket", path: $path) {
          updatedOn
          priority
          state
          shortDescription
          number
          ticketId
          ticketType
          customerName
          customerId
        }
      }
    `;

    return this.apollo
      .query<any>({
        query: ticketsQuery,
        variables: { path },
      })
      .pipe(
        map((responseBody: any) => {
          if (responseBody.errors) {
            throw new Error('Fetching ticket information failed');
          }

          return responseBody.data.tickets;
        })
      );
  }
}
