import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { EcosystemOption } from '../../models/productOffering.model';
import { BASE_OPTIONS, OPTION5 } from './ecosystemOptions';

@Component({
  selector: 'imc-aws-ecosystem-configuration',
  templateUrl: './ecosystemConfiguration.component.html',
  styleUrls: ['./ecosystemConfiguration.component.scss'],
})
export class EcosystemConfigurationComponent implements OnInit {
  @Output() nextStep: EventEmitter<any> = new EventEmitter();

  ecosystemOptions;
  ecosystemColumnsToDisplay = [
    'Feature',
    'InstanceType',
    'InstanceCount',
    'EnableOption',
  ];
  ecosystemConfigurationMap = {
    'Data Mover': {
      instanceType: 'dataMoverVmSize',
      instanceCount: 'dataMoverInstanceCount',
    },
  };
  dataMoverInstanceType = OPTION5.instanceTypes[0].value;
  dataMoverInstanceCount = OPTION5.instanceCount[0].value;

  ngOnInit() {
    this.ecosystemOptions = BASE_OPTIONS;
  }

  isFormInvalid() {
    return (
      this.dataMoverInstanceCount === null ||
      this.dataMoverInstanceType === null
    );
  }

  goToNextStep() {
    this.nextStep.emit({
      dataMoverInstanceType: this.dataMoverInstanceType,
      dataMoverInstanceCount: this.dataMoverInstanceCount,
      dataMoverAvailable: this.ecosystemOptions[1].isEnabled,
    });
  }

  isEcosystemOptionEnabled() {
    return true;
  }

  private ecosystemAppsChangeEvent(element: EcosystemOption, event): any {
    if (element === null) {
      this.ecosystemOptions.forEach((ecoSystemOption) => {
        ecoSystemOption.isEnabled = event.checked;
      });
    } else {
      element.isEnabled = event.checked;
    }
  }
}
