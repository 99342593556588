import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Site } from '@imc/core';
import { SiteService } from '@imc/core/services/site-service/site.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'imc-site-landing-container',
  templateUrl: './site-landing.component.html',
  styleUrls: ['./site-landing.component.scss'],
})
export class SiteLandingComponent implements OnInit, OnDestroy {
  private routeSubscription: Subscription;
  private getSiteInfoSubscription: Subscription;

  public siteId: string = '';
  siteInfo: Site;

  constructor(
    private route: ActivatedRoute,
    private siteService: SiteService
  ) {}

  public ngOnInit(): void {
    this.getCurrentSite();
  }

  public ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }

    if (this.getSiteInfoSubscription) {
      this.getSiteInfoSubscription.unsubscribe();
    }
  }

  private getCurrentSite(): void {
    this.routeSubscription = this.route.params.subscribe((params: any) => {
      if (params.siteId) {
        this.siteId = params.siteId;
        this.getSiteInfo();
      }
    });
  }

  private getSiteInfo(): void {
    this.getSiteInfoSubscription = this.siteService
      .getSiteInfo({ siteId: this.siteId })
      .subscribe((siteInfo: Site) => {
        this.siteInfo = siteInfo;
      });
  }
}
