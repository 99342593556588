import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerUserRoles } from '@imc/core/models/roles.enum';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { filter, flatMap, map } from 'rxjs/operators';
import { UserRoleInfo } from '../../models/userRoleInfo.model';
import { OAuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class PolicyService {
  private readyToGetPolicyRole: Observable<boolean>;
  private userRoleInfo: UserRoleInfo;
  private userRoleInfoSubject$: ReplaySubject<UserRoleInfo> = new ReplaySubject<
    UserRoleInfo
  >(1);
  public userRoleInfo$: Observable<
    UserRoleInfo
  > = this.userRoleInfoSubject$.asObservable();

  public isCustomer$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private readonly apollo: Apollo,
    private readonly oauthService: OAuthenticationService,
    private readonly router: Router
  ) {
    this.readyToGetPolicyRole = combineLatest(
      this.oauthService.isAuthenticated$,
      this.oauthService.isDoneLoading$,
      (a, b) => a && b
    );

    this.userRoleInfo$.subscribe((data: UserRoleInfo) => {
      this.isCustomer$.next(CustomerUserRoles[data.currentRole] ? true : false);
    });
  }

  public setupPolicyRole(): void {
    this.readyToGetPolicyRole
      .pipe(
        filter((readyToGetPolicyRole: boolean) => readyToGetPolicyRole),
        flatMap(() => this.getUserRoleInfo())
      )
      .subscribe(
        (userRoleInfo: UserRoleInfo) => {
          this.userRoleInfo = userRoleInfo;
          this.userRoleInfoSubject$.next(userRoleInfo);
        },
        (error) => {
          // logout when the policy call fails
          this.oauthService.logout(true);
          // TODO: auth token should be blacklisted after logging out
          this.router.navigate(['error'], {
            queryParams: {
              error: 'unauthorized',
              error_description:
                "Failed to retrieve current user's role information",
              content:
                'Encountered an error when retrieving your role information.' +
                'Try logging in again and if the issue persists please contact an administrator for assistance.',
            },
          });
        }
      );
  }

  public postChangeRole(selectedRole: string): Observable<any> {
    const mutation: any = gql`
      mutation postChangeRole($payload: any!) {
        postChangeRole(input: $payload)
          @rest(
            type: "Boolean"
            path: "/assumeRole"
            method: "POST"
            endpoint: "policy"
          ) {
          # GraphQL comment: UnusedResponse is not a keyword. It is just a name provided in the result to tell the
          # the developer that the response is unused. GraphQL mutations expect values that are returned just like
          # queries.
          UnusedResponse
        }
      }
    `;

    const variables: any = {
      payload: {
        targetRole: selectedRole,
      },
    };

    return this.apollo.mutate({ mutation, variables }).pipe(
      map((responseBody: any) => {
        if (responseBody.errorCode) {
          throw new Error(responseBody.message);
        }
        this.userRoleInfo.setCurrentRoleWithRawData(selectedRole);
        this.userRoleInfoSubject$.next(this.userRoleInfo);
        return;
      })
    );
  }

  /**
   * Get user's role related information from policy service
   */
  private getUserRoleInfo(): Observable<UserRoleInfo> {
    const userInfoQuery: any = gql`
      {
        userRoleInfo
          @rest(type: "userRoleInfo", path: "/roles", endpoint: "policy") {
          allowedRoles
          currentRole
        }
      }
    `;

    const noCache: string = 'no-cache';
    const queryInput: any = {
      query: userInfoQuery,
      context: {
        headers: {},
      },
      fetchPolicy: noCache,
    };
    return this.apollo.query<any>(queryInput).pipe(
      map((responseBody: any) => {
        if (responseBody.errors) {
          throw new Error(JSON.stringify(responseBody.errors));
        }
        const policyData: any = responseBody.data.userRoleInfo;
        const userRoleData = new UserRoleInfo();
        userRoleData.setAllowedRolesWithRawData(policyData.allowedRoles);
        userRoleData.setCurrentRoleWithRawData(policyData.currentRole);
        userRoleData.managedCustomers = policyData.managedCustomers;
        return userRoleData;
      })
    );
  }
}
