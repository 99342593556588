export const ScaleOutStringValues = {
  Disabled: 'Scaling-Disabled',
  '1x': '1x',
  '2x': '2x',
  '4x': '4x',
};

export const ScaleOutNicReserveFactor = {
  'Scaling-Disabled': 1,
  '1x': 4,
  '2x': 2,
  '4x': 1,
};

export const DBNodeCount = {
  MIN_NODE_COUNT: 2,
  MAX_NODE_COUNT_4X: 16,
  MAX_NODE_COUNT_2X: 32,
  MAX_NODE_COUNT: 64,
  MAX_SUBPOOLS_PER_NODE: 8,
  MAX_SUPPORTED_SCALE_FACTOR: 4,
};
