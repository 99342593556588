export const ADVANCED_TIER_FEATURES = {
  name: 'Advanced',
  features: [
    {
      category: 'Database Features',
      attributes: [
        { featureName: 'Intelligent Memory', value: 'Included' },
        { featureName: 'In-Memory Optimization', value: 'Not Offered' },
        { featureName: 'Columnar', value: 'Included' },
        { featureName: 'Temporal', value: 'Included' },
        { featureName: 'Row-level Security', value: 'Included' },
        { featureName: 'Secure Zones', value: 'Included' },
        {
          featureName: 'Workload Management',
          value: 'Integrated Workload Management',
        },
        {
          displayFeatureProperties: 1,
          featureProperties: [
            {
              featurePropertyValues: [{ value: 'None', displayName: 'None' }],
              featurePropertyName: 'Concurrent Query Limit',
            },
            {
              featurePropertyValues: [{ value: '2-64', displayName: '2-64' }],
              featurePropertyName: 'Node Limit',
            },
          ],
          featureName: 'Scalability',
          value: 'Included',
        },
        {
          displayFeatureProperties: 1,
          featureProperties: [
            {
              featurePropertyValues: [
                { value: 'Medium', displayName: 'Medium' },
                { value: 'Large', displayName: 'Large' },
              ],
              featurePropertyName: 'Scale Up/Down',
            },
            {
              featurePropertyValues: [
                {
                  value: "Upto 4x ('x' is the base node count)",
                  displayName: "Upto 4x ('x' is the base node count)",
                },
              ],
              featurePropertyName: 'Scale Out/In',
            },
          ],
          featureName: 'Elasticity',
          value: 'Included',
        },
      ],
    },
    {
      category: 'Ecosystem Applications',
      attributes: [
        {
          featureProperties: [
            {
              featurePropertyValues: [
                { value: 'Standard_m4_16xl', displayName: 'm4.16xl' },
              ],
              featurePropertyName: 'Instance Type',
            },
            {
              featurePropertyValues: [{ value: '1', displayName: '1' }],
              featurePropertyName: 'Instance Count',
            },
          ],
          featureName: 'Viewpoint (Multi-System)',
          value: 'Included',
          version: { major: '16.20', minor: '00.05' },
        },
        {
          featureProperties: [
            {
              featurePropertyValues: [
                { value: 'Standard_m4_4xl', displayName: 'm4.4xl' },
              ],
              featurePropertyName: 'Instance Type',
            },
            {
              featurePropertyValues: [{ value: '1', displayName: '1' }],
              featurePropertyName: 'Instance Count',
            },
          ],
          featureName: 'REST Services',
          value: 'Included',
        },
        {
          featureProperties: [
            {
              featurePropertyValues: [
                { value: 'Standard_m4_4xl', displayName: 'Small (m4.4xl)' },
              ],
              featurePropertyName: 'Instance Type',
            },
            {
              featurePropertyValues: [
                { value: '0', displayName: '0' },
                { value: '1', displayName: '1' },
              ],
              featurePropertyName: 'Instance Count',
            },
          ],
          featureName: 'Data Mover',
          value: 'Included',
          displayFeatureProperties: 1,
          optional: 1,
          version: { major: '16.20', minor: '09.00' },
        },
        {
          featureProperties: [
            {
              featurePropertyValues: [
                { value: 'Standard_m4_4xl', displayName: 'm4.4xl' },
              ],
              featurePropertyName: 'Instance Type',
            },
            {
              featurePropertyValues: [{ value: '1', displayName: '1' }],
              featurePropertyName: 'Instance Count',
            },
          ],
          featureName: 'Data Stream Utility',
          value: 'Included',
          version: { major: '16.20', minor: '00.05' },
        },
        {
          featureProperties: [
            {
              featurePropertyValues: [
                { value: 'Standard_m4_4xl', displayName: 'm4.4xl' },
              ],
              featurePropertyName: 'Instance Type',
            },
            {
              featurePropertyValues: [{ value: '1', displayName: '1' }],
              featurePropertyName: 'Instance Count',
            },
          ],
          featureName: 'Server Management',
          value: 'Included',
          version: { major: '12.07', minor: '05.00-1' },
        },
        {
          featureName: 'Teradata Tools and Utilities',
          value: 'Included',
          optional: 1,
        },
      ],
    },
    {
      category: 'Upgrade Options',
      attributes: [
        {
          displayFeatureProperties: 1,
          featureProperties: [
            {
              featurePropertyValues: [
                { value: 'Standard_m4_10xl', displayName: 'Medium (m4.10xl)' },
                { value: 'Standard_m4_16xl', displayName: 'Large (m4.16xl)' },
              ],
              featurePropertyName: 'Instance Type',
            },
            {
              featurePropertyValues: [
                { value: '2', displayName: '2' },
                { value: '3', displayName: '3' },
                { value: '4', displayName: '4' },
              ],
              featurePropertyName: 'Instance Count',
            },
          ],
          featureName: 'Data Mover',
          value: 'charges apply',
          version: { major: '16.20', minor: '09.00' },
        },
      ],
    },
    {
      category: 'Customer Support',
      attributes: [
        { featureName: 'Support Included', value: 'Premier Cloud' },
        { featureName: 'Server Management', value: 'Included' },
      ],
    },
    {
      category: 'Upsell Features',
      attributes: [{ featureName: 'QueryGrid Manager', value: 'Separate' }],
    },
  ],
};

export const ENTERPRISE_TIER_FEATURES = {
  name: 'Enterprise',
  features: [
    {
      category: 'Database Features',
      attributes: [
        { featureName: 'Intelligent Memory', value: 'Included' },
        { featureName: 'In-Memory Optimization', value: 'Included' },
        { featureName: 'Columnar', value: 'Included' },
        { featureName: 'Temporal', value: 'Included' },
        { featureName: 'Row-level Security', value: 'Included' },
        { featureName: 'Secure Zones', value: 'Included' },
        {
          featureName: 'Workload Management',
          value: 'Active System Management',
        },
        {
          displayFeatureProperties: 1,
          featureProperties: [
            {
              featurePropertyValues: [{ value: 'None', displayName: 'None' }],
              featurePropertyName: 'Concurrent Query Limit',
            },
            {
              featurePropertyValues: [{ value: '2-64', displayName: '2-64' }],
              featurePropertyName: 'Node Limit',
            },
          ],
          featureName: 'Scalability',
          value: 'Included',
        },
        {
          displayFeatureProperties: 1,
          featureProperties: [
            {
              featurePropertyValues: [
                { value: 'Medium', displayName: 'Medium' },
                { value: 'Large', displayName: 'Large' },
              ],
              featurePropertyName: 'Scale Up/Down',
            },
            {
              featurePropertyValues: [
                {
                  value: "Upto 4x ('x' is the base node count)",
                  displayName: "Upto 4x ('x' is the base node count)",
                },
              ],
              featurePropertyName: 'Scale Out/In',
            },
          ],
          featureName: 'Elasticity',
          value: 'Included',
        },
      ],
    },
    {
      category: 'Ecosystem Applications',
      attributes: [
        {
          featureProperties: [
            {
              featurePropertyValues: [
                { value: 'Standard_m4_16xl', displayName: 'm4.16xl' },
              ],
              featurePropertyName: 'Instance Type',
            },
            {
              featurePropertyValues: [{ value: '1', displayName: '1' }],
              featurePropertyName: 'Instance Count',
            },
          ],
          featureName: 'Viewpoint (Multi-System)',
          value: 'Included',
          version: { major: '16.20', minor: '00.05' },
        },
        {
          featureProperties: [
            {
              featurePropertyValues: [
                { value: 'Standard_m4_4xl', displayName: 'm4.4xl' },
              ],
              featurePropertyName: 'Instance Type',
            },
            {
              featurePropertyValues: [{ value: '1', displayName: '1' }],
              featurePropertyName: 'Instance Count',
            },
          ],
          featureName: 'REST Services',
          value: 'Included',
        },
        {
          featureProperties: [
            {
              featurePropertyValues: [
                { value: 'Standard_m4_4xl', displayName: 'Small (m4.4xl)' },
              ],
              featurePropertyName: 'Instance Type',
            },
            {
              featurePropertyValues: [
                { value: '0', displayName: '0' },
                { value: '1', displayName: '1' },
              ],
              featurePropertyName: 'Instance Count',
            },
          ],
          featureName: 'Data Mover',
          value: 'Included',
          displayFeatureProperties: 1,
          optional: 1,
          version: { major: '16.20', minor: '09.00' },
        },
        {
          featureProperties: [
            {
              featurePropertyValues: [
                { value: 'Standard_m4_4xl', displayName: 'm4.4xl' },
              ],
              featurePropertyName: 'Instance Type',
            },
            {
              featurePropertyValues: [{ value: '1', displayName: '1' }],
              featurePropertyName: 'Instance Count',
            },
          ],
          featureName: 'Data Stream Utility',
          value: 'Included',
          version: { major: '16.20', minor: '00.05' },
        },
        {
          featureProperties: [
            {
              featurePropertyValues: [
                { value: 'Standard_m4_4xl', displayName: 'm4.4xl' },
              ],
              featurePropertyName: 'Instance Type',
            },
            {
              featurePropertyValues: [{ value: '1', displayName: '1' }],
              featurePropertyName: 'Instance Count',
            },
          ],
          featureName: 'Server Management',
          value: 'Included',
          version: { major: '12.07', minor: '05.00-1' },
        },
        {
          featureName: 'Teradata Tools and Utilities',
          value: 'Included',
          optional: 1,
        },
      ],
    },
    {
      category: 'Upgrade Options',
      attributes: [
        {
          displayFeatureProperties: 1,
          featureProperties: [
            {
              featurePropertyValues: [
                { value: 'Standard_m4_10xl', displayName: 'Medium (m4.10xl)' },
                { value: 'Standard_m4_16xl', displayName: 'Large (m4.16xl)' },
              ],
              featurePropertyName: 'Instance Type',
            },
            {
              featurePropertyValues: [
                { value: '2', displayName: '2' },
                { value: '3', displayName: '3' },
                { value: '4', displayName: '4' },
              ],
              featurePropertyName: 'Instance Count',
            },
          ],
          featureName: 'Data Mover',
          value: 'charges apply',
          version: { major: '16.20', minor: '09.00' },
        },
      ],
    },
    {
      category: 'Customer Support',
      attributes: [
        { featureName: 'Support Included', value: 'Premier Cloud' },
        { featureName: 'Server Management', value: 'Included' },
      ],
    },
    {
      category: 'Upsell Features',
      attributes: [{ featureName: 'QueryGrid Manager', value: 'Separate' }],
    },
  ],
};

export const BASE_TIER_FEATURES = {
  name: 'Base',
  features: [
    {
      category: 'Database Features',
      attributes: [
        { featureName: 'Columnar', value: 'Included' },
        { featureName: 'Temporal', value: 'Included' },
        { featureName: 'Row-level Security', value: 'Included' },
        { featureName: 'Secure Zones', value: 'Included' },
        {
          displayFeatureProperties: 1,
          featureProperties: [
            {
              featurePropertyValues: [{ value: '15', displayName: '15' }],
              featurePropertyName: 'Concurrent Query Limit',
            },
            {
              featurePropertyValues: [{ value: '2-64', displayName: '2-64' }],
              featurePropertyName: 'Node Limit',
            },
          ],
          featureName: 'Scalability',
          value: 'Included',
        },
        {
          displayFeatureProperties: 1,
          featureProperties: [
            {
              featurePropertyValues: [
                { value: 'Medium', displayName: 'Medium' },
                { value: 'Large', displayName: 'Large' },
              ],
              featurePropertyName: 'Scale Up/Down',
            },
            {
              featurePropertyValues: [
                {
                  value: "Upto 4x ('x' is the base node count)",
                  displayName: "Upto 4x ('x' is the base node count)",
                },
              ],
              featurePropertyName: 'Scale Out/In',
            },
          ],
          featureName: 'Elasticity',
          value: 'Included',
        },
      ],
    },
    {
      category: 'Ecosystem Applications',
      attributes: [
        {
          featureProperties: [
            {
              featurePropertyValues: [
                { value: 'Standard_m4_16xl', displayName: 'm4.16xl' },
              ],
              featurePropertyName: 'Instance Type',
            },
            {
              featurePropertyValues: [{ value: '1', displayName: '1' }],
              featurePropertyName: 'Instance Count',
            },
          ],
          featureName: 'Viewpoint (Multi-System)',
          value: 'Included',
          version: { major: '16.20', minor: '00.05' },
        },
        {
          featureProperties: [
            {
              featurePropertyValues: [
                { value: 'Standard_m4_4xl', displayName: 'm4.4xl' },
              ],
              featurePropertyName: 'Instance Type',
            },
            {
              featurePropertyValues: [{ value: '1', displayName: '1' }],
              featurePropertyName: 'Instance Count',
            },
          ],
          featureName: 'REST Services',
          value: 'Included',
        },
        {
          featureProperties: [
            {
              featurePropertyValues: [
                { value: 'Standard_m4_4xl', displayName: 'Small (m4.4xl)' },
              ],
              featurePropertyName: 'Instance Type',
            },
            {
              featurePropertyValues: [
                { value: '0', displayName: '0' },
                { value: '1', displayName: '1' },
              ],
              featurePropertyName: 'Instance Count',
            },
          ],
          featureName: 'Data Mover',
          value: 'Included',
          displayFeatureProperties: 1,
          optional: 1,
          version: { major: '16.20', minor: '09.00' },
        },
        {
          featureProperties: [
            {
              featurePropertyValues: [
                { value: 'Standard_m4_4xl', displayName: 'm4.4xl' },
              ],
              featurePropertyName: 'Instance Type',
            },
            {
              featurePropertyValues: [{ value: '1', displayName: '1' }],
              featurePropertyName: 'Instance Count',
            },
          ],
          featureName: 'Data Stream Utility',
          value: 'Included',
          version: { major: '16.20', minor: '00.05' },
        },
        {
          featureProperties: [
            {
              featurePropertyValues: [
                { value: 'Standard_m4_4xl', displayName: 'm4.4xl' },
              ],
              featurePropertyName: 'Instance Type',
            },
            {
              featurePropertyValues: [{ value: '1', displayName: '1' }],
              featurePropertyName: 'Instance Count',
            },
          ],
          featureName: 'Server Management',
          value: 'Included',
          version: { major: '12.07', minor: '05.00-1' },
        },
        {
          featureName: 'Teradata Tools and Utilities',
          value: 'Included',
          optional: 1,
        },
      ],
    },
    {
      category: 'Upgrade Options',
      attributes: [
        {
          displayFeatureProperties: 1,
          featureProperties: [
            {
              featurePropertyValues: [
                { value: 'Standard_m4_10xl', displayName: 'Medium (m4.10xl)' },
                { value: 'Standard_m4_16xl', displayName: 'Large (m4.16xl)' },
              ],
              featurePropertyName: 'Instance Type',
            },
            {
              featurePropertyValues: [
                { value: '2', displayName: '2' },
                { value: '3', displayName: '3' },
                { value: '4', displayName: '4' },
              ],
              featurePropertyName: 'Instance Count',
            },
          ],
          featureName: 'Data Mover',
          value: 'charges apply',
          version: { major: '16.20', minor: '09.00' },
        },
      ],
    },
    {
      category: 'Customer Support',
      attributes: [
        { featureName: 'Support Included', value: 'Premier Cloud' },
        { featureName: 'Server Management', value: 'Included' },
      ],
    },
    {
      category: 'Upsell Features',
      attributes: [{ featureName: 'QueryGrid Manager', value: 'Separate' }],
    },
  ],
};
