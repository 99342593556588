import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DbInstanceHours } from '../models/metering.model';

@Injectable()
export class DashboardMeteringService {
  constructor(private apollo: Apollo) {}

  getDbInstanceHours(
    serviceId: string,
    siteId: string,
    granularity: string,
    startDate: string,
    endDate: string,
    customerId: string
  ): Observable<DbInstanceHours[]> {
    const dbInstanceHoursQuery: any = gql`
      query dbInstanceHours {
        dbInstanceHours(
          serviceId: $serviceId
          siteId: $siteId
          granularity: $granularity
          startDate: $startDate
          endDate: $endDate
          customerId: $customerId
        )
          @rest(
            type: "DbInstanceHours"
            path: "/meterreports-service/v2/reports?{args}"
          ) {
          siteId
          meterName
          resourceName
          resourceType
          total
          reportingAggregation
          periodUsageList
        }
      }
    `;

    return this.apollo
      .query<any>({
        query: dbInstanceHoursQuery,
        variables: {
          serviceId,
          siteId,
          granularity,
          startDate,
          endDate,
          customerId,
        },
      })
      .pipe(
        map((responseBody: any) => {
          if (responseBody.errors) {
            throw new Error('There was an error fetching db instance hours!');
          }

          return responseBody.data.dbInstanceHours;
        })
      );
  }
}
