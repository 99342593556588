import { Component, Input, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Site } from '@imc/core';
import { PolicyOperations } from '@imc/core/models/policyOperations.enum';
import { PolicyResources } from '@imc/core/models/policyResources.enum';
import { SiteService } from '@imc/core/services/site-service/site.service';

@Component({
  selector: 'imc-customer-database-sites',
  templateUrl: './customer-database-sites.component.html',
  styleUrls: ['./customer-database-sites.component.scss'],
})
export class CustomerDatabaseSitesComponent {
  // Using ngIf in the template with the matSort placed within
  // the scope that is not immediately exercised will result in
  // injecting a null object, if we directly collected it in a
  // variable.
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @Input() set customerId(id: string) {
    this.custId = id;
    this.sites$ = this.getDatabaseSites(id);
  }

  private readonly dataSource: MatTableDataSource<Site>;
  readonly columns: string[] = [
    'name',
    'purpose',
    'plan',
    // 'opStatus'
  ];
  sites$: Observable<any>;
  noData: boolean = false;
  custId: string;

  PolicyOperations = PolicyOperations;
  PolicyResources = PolicyResources;

  constructor(
    private readonly router: Router,
    private readonly siteService: SiteService
  ) {
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.sortingDataAccessor = (item: Site, property: string) =>
      property === this.columns[0] ? item.displayName : item[property];
  }

  viewSiteDetails(site: Site): void {
    this.router.navigate(['/sites', site.siteId]);
  }

  navigateCreateAwsSite(): void {
    this.router.navigate(['/sites', 'create', 'aws', this.custId]);
  }

  navigateCreateAzureSite(): void {
    this.router.navigate(['/sites', 'create', 'azure', this.custId]);
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private getDatabaseSites(customerId: string): Observable<any> {
    return this.siteService.listSites({ customerId }).pipe(
      tap((sites: Site[]) => (this.noData = sites.length === 0)),
      map((sites: Site[]) => this.convertToTable(sites)),
      catchError((error: any, caught: Observable<any>) => {
        this.noData = true;

        return of({ dataSource: undefined, error: true });
      })
    );
  }

  private convertToTable(sites: Site[]): any {
    this.dataSource.data = sites;

    return { dataSource: this.dataSource, error: false };
  }
}
