import gql from 'graphql-tag';

export const sitesListFragment: any = gql`
  fragment SitesListFragment on Site {
    imcCustomerId
    imcEntityName
    siteId
    siteName
    siteTDPID
    supportSiteId
    region
    cloudPlatform
    purpose
    dbStatus
    customerId
    status
    operationalStatus
    isVCM
    resourceVersion
    targetSLA
  }
`;
