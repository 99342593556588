import { TitleCasePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Site } from '@imc/core';
import { ColorPaletteService } from '@imc/core/services/color-palette/color-palette.service';
import { SiteService } from '@imc/core/services/site-service/site.service';
import { Subscription } from 'apollo-client/util/Observable';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'imc-geo-distribution',
  templateUrl: './geo-distribution.component.html',
  styleUrls: ['./geo-distribution.component.scss'],
})
export class GeoDistributionComponent implements OnInit, OnDestroy {
  private _dbServiceSubscriptionHandle: Subscription;

  loaderKey: string = 'geoDistributionLoader';
  loading: boolean = true;
  error: boolean = false;
  awsColorCode: string;
  azureColorCode: string;
  tmcColorCode: string;
  awsXAxis: number[] = [];
  awsYAxis: string[] = [];
  azureXAxis: number[] = [];
  azureYAxis: string[] = [];
  tmcXAxis: number[] = [];
  tmcYAxis: string[] = [];

  constructor(
    private readonly siteService: SiteService,
    private readonly _colorPaletteService: ColorPaletteService
  ) {}

  ngOnInit(): void {
    this.awsColorCode = this._colorPaletteService.getAwsColor();
    this.azureColorCode = this._colorPaletteService.getAzureColor();
    this.tmcColorCode = this._colorPaletteService.getTMCColor();
    this.getCustomerDatabaseSites();
  }

  ngOnDestroy(): void {
    if (this._dbServiceSubscriptionHandle) {
      this._dbServiceSubscriptionHandle.unsubscribe();
    }
  }

  getCustomerDatabaseSites(): void {
    const aws: Map<string, number> = new Map();
    const azure: Map<string, number> = new Map();
    const tmc: Map<string, number> = new Map();

    this._dbServiceSubscriptionHandle = this.siteService
      .listSites()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        (siteList: Site[]) => {
          this.prepareChartData(aws, azure, tmc, siteList);
          this.awsXAxis = Array.from(aws.values());
          this.awsYAxis = Array.from(aws.keys());
          this.azureXAxis = Array.from(azure.values());
          this.azureYAxis = Array.from(azure.keys());
          this.tmcXAxis = Array.from(tmc.values());
          this.tmcYAxis = Array.from(tmc.keys());
        },
        (error: any) => {
          this.error = true;
        }
      );
  }

  prepareChartData(
    aws: Map<string, number>,
    azure: Map<string, number>,
    tmc: Map<string, number>,
    dbSites: Site[]
  ): void {
    dbSites.forEach((site: Site) => {
      if (site && site.region && site.cloudPlatform) {
        switch (site.cloudPlatform) {
          case 'aws':
            this.updateMap(site.region, aws);
            break;

          case 'azure':
            this.updateMap(site.region, azure);
            break;

          case 'tmc':
            this.updateMap(site.region, tmc);
            break;

          default:
        }
      }
    });
  }

  updateMap(region: string, platform: Map<string, number>): void {
    const key: string = new TitleCasePipe().transform(region);
    if (platform.has(key)) {
      const val: number = platform.get(key) + 1;
      platform.set(key, val);
    } else {
      platform.set(key, 1);
    }
  }
}
