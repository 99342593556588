import { Component } from '@angular/core';
import { TdMediaService } from '@covalent/core';

@Component({
  selector: 'imc-aws-dashboard',
  templateUrl: './aws-dashboard.component.html',
  styleUrls: ['./aws-dashboard.component.scss'],
})
export class AwsDashboardComponent {
  constructor(public media: TdMediaService) {}
}
