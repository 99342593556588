import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { TdLoadingService } from '@covalent/core';
import { TicketService } from '@imc-features/customer/services/ticket.service';
import { AppConfigService, OAuthenticationService } from '@imc/core/services';

@Component({
  selector: 'imc-new-site-dialog',
  templateUrl: './new-site-dialog.component.html',
  styleUrls: ['./new-site-dialog.component.scss'],
})
export class NewSiteDialogComponent implements OnInit {
  companyId: string;
  requestError: boolean;

  constructor(
    private readonly appConfig: AppConfigService,
    private snackbar: MatSnackBar,
    private ticketService: TicketService,
    private authService: OAuthenticationService,
    private tdLoadingService: TdLoadingService,
    public newSiteDialog: MatDialogRef<NewSiteDialogComponent>
  ) {}

  ngOnInit(): void {
    this.getCompanyId();
  }

  async getCompanyId(): Promise<void> {
    try {
      const companyId: string = `${
        this.appConfig.getConfig().auth.namespace
      }customerId`;
      const userIdentity: any = await this.authService
        .getIdentityClaims()
        .toPromise();
      this.companyId = userIdentity[companyId];
    } catch (e) {
      throw new Error(e);
    }
  }

  async submitNewSiteRequest(): Promise<void> {
    try {
      this.tdLoadingService.register('load');
      await this.ticketService.requestNewSite(this.companyId).toPromise();
      this.openSnackBar();
      this.newSiteDialog.close();
    } catch (e) {
      this.requestError = true;
    } finally {
      this.tdLoadingService.resolve('load');
    }
  }

  private openSnackBar(): void {
    this.snackbar.open('New Site Request Created', 'CLOSE', { duration: 3000 });
  }
}
