import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { OAuthenticationService } from '../services';

/**
 * This login-redirect guard is only used by /login page, when user is already
 * logged in, and user is trying to visit /login page, this guard should prevent
 * it, and redirect to either localstorage.redirect_url or /home.
 * In other words, this guard returns negate of isAuthenticated, and do redirect
 * when isAuthenticated is true.
 */
@Injectable({
  providedIn: 'root',
})
export class LoginRedirectGuard implements CanActivate {
  private isAuthenticated: boolean;

  constructor(
    private readonly authService: OAuthenticationService,
    private router: Router
  ) {
    this.authService.isAuthenticated$.subscribe(
      (i: boolean) => (this.isAuthenticated = i)
    );

    // If user visits td/login intentionally, then we set the preferCorp to true
    this.authService.preferCorp = true;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isDoneLoading$
      .pipe(
        tap(() => {
          if (this.isAuthenticated) {
            const redirectUrl: string =
              localStorage.getItem('redirect_url') || '/home';
            this.router.navigateByUrl(redirectUrl);
          }
        })
      )
      .pipe(map(() => !this.isAuthenticated));
  }
}
