export enum availableOps {
  START,
  STOP,
  SCALE_UP_DOWN,
  VOLUME_EXPANSION,
  NODE_SCALING,
}

export const elasticityOps: any = [
  { label: 'Start', value: availableOps.START, feature: 'HIB_FEATURE_NAME' },
  { label: 'Stop', value: availableOps.STOP, feature: 'HIB_FEATURE_NAME' },
  {
    label: 'Scale Up/Down',
    value: availableOps.SCALE_UP_DOWN,
    feature: 'SCA_FEATURE_NAME',
  },
  {
    label: 'Expand Volume',
    value: availableOps.VOLUME_EXPANSION,
    feature: 'EBS_VOLUME_FEATURE_NAME',
  },
  {
    label: 'Node Operations',
    value: availableOps.NODE_SCALING,
    feature: 'SCALE_OUT_IN_FEATURE_NAME',
  },
];
