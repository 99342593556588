import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChange,
  ViewChild,
} from '@angular/core';
import { MatTabChangeEvent } from '@angular/material';
import { tdCollapseAnimation } from '@covalent/core/common';
import { TdLoadingService } from '@covalent/core/loading';
import { TdChartComponent } from '@covalent/echarts/base';
import {
  GraphDataResponse,
  MetricDatapoint,
} from '../../models/metrics-graph.model';

import { PolicyUserRole } from '@imc/core/models/roles.enum';
import { Site } from '@imc/core/models/sites.model';
import { UserRoleInfo } from '@imc/core/models/userRoleInfo.model';
import { PolicyService } from '@imc/core/services/policy-service/policy.service';
import { Subscription } from 'rxjs';
import { ProxyFunctionResponseData } from '../../models/metrics-agent-proxy.model';
import { MetricsAgentProxy } from '../../services/metrics-agent-proxy/metrics-agent-proxy.service';
import { MetricsGraphService } from '../../services/metrics-graph-service/metrics-graph.service';

@Component({
  selector: 'imc-util-info',
  templateUrl: './util-info.component.html',
  styleUrls: ['./util-info.component.scss'],
  animations: [
    tdCollapseAnimation, // using implicit anchor name 'tdCollapse' in template
  ],
})
export class UtilInfoComponent implements OnChanges, OnDestroy {
  private subscription: Subscription;
  @ViewChild('refresh2') refresh2: TdChartComponent;
  @Input() siteInfo: Site;

  siteId: string;
  overlayStarSyntax: boolean = false;

  overlayDemo: any = {
    name: '',
    description: '',
  };
  loadingGraph: boolean = true;
  siteCloudPlatform: string = undefined;
  subscriptionObject: Subscription;
  userRoleInfoSubscription: Subscription;
  triggerState: boolean;
  regTool: boolean = false;
  fetchDataComplete: boolean = false;
  emptyResponseData: boolean = false;
  scale: boolean = false;
  theCheckboxScale: boolean = false;
  timeSelectIndex: number = 0;
  error: boolean = false;
  cpuPercentAverage: string = '0';
  diskPercentAverage: string = '0';

  siteDisplayName: string = undefined;

  config: any = {
    xAxis: [
      {
        show: true,
        type: 'time',
        boundaryGap: false,
        maxInterval: 3600 * 1000 * 24 * 2,
      },
    ],
    yAxis: [
      {
        show: true,
        type: 'value',
        axisLabel: {
          inside: true,
        },
        scale: true,
        min: 0,
        max: 100,
      },
    ],
    series: [
      {
        name: 'CPU',
        type: 'line',
        itemStyle: {
          opacity: 0.95,
          color: '#F2724B',
        },
        data: [],
      },
      {
        name: 'Disk',
        type: 'line',
        itemStyle: {
          opacity: 0.95,
          color: '#3A4950',
        },
        data: [],
      },
    ],
    tooltip: {
      show: true,
      trigger: 'axis',
      showContent: true,
    },
  };

  changeLog: string[] = [];
  metricsRegData: any = undefined;

  // Link to open a ticket to onboard a site.
  openTicketPath: any[];
  isSysOpsUser: boolean = false;

  constructor(
    private readonly metricsGraphService: MetricsGraphService,
    private readonly metricsAgentproxy: MetricsAgentProxy,
    private readonly _loadingService: TdLoadingService,
    private readonly policyService: PolicyService
  ) {}

  async ngOnInit() {
    this.siteDisplayName = this.siteInfo.displayName;
    this.siteId = this.siteInfo.siteId;
    this.openTicketPath = ['/create-ticket'];
  }

  toolButton(): void {
    this.regTool = !this.regTool;
  }
  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }

    if (this.userRoleInfoSubscription) {
      this.userRoleInfoSubscription.unsubscribe();
    }
  }
  async ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (this.userRoleInfoSubscription) {
      this.userRoleInfoSubscription.unsubscribe();
    }
    // Resolve if the current user is SysOps or not
    this.userRoleInfoSubscription = this.policyService.userRoleInfo$.subscribe(
      (role: UserRoleInfo) => {
        if (
          role.currentRole === PolicyUserRole.FullSysops ||
          role.currentRole === PolicyUserRole.ReadOnlySysops ||
          role.currentRole === PolicyUserRole.ReadOnlyLimitedSysops
        ) {
          this.isSysOpsUser = true;
        } else {
          this.isSysOpsUser = false;
        }
      }
    );

    this.siteCloudPlatform = this.siteInfo.cloudPlatform
      ? this.siteInfo.cloudPlatform.toLowerCase()
      : undefined;
    this.siteId = this.siteInfo.siteId;
    this.siteDisplayName = this.siteInfo.displayName;
    this.emptyResponseData = false;
    this._loadingService.register('fetchDataComplete');
    if (this.timeSelectIndex === 0) {
      this.fetchData_1_day();
    } else if (this.timeSelectIndex === 1) {
      this.fetchData_1_week();
    } else if (this.timeSelectIndex === 2) {
      this.fetchData_1_month();
    } else if (this.timeSelectIndex === 3) {
      this.fetchData_3_months();
    }

    const getParam: any = {
      function: {
        name: 'get_site_registration_details',
        invocation_type: 'RequestResponse',
      },
      payload: {
        site_id: this.siteInfo.siteId,
      },
    };

    this.loadingGraph = true;
    this.metricsRegData = undefined;
    this.subscriptionObject = this.metricsAgentproxy
      .invokeFunction(getParam)
      .subscribe((getData: any) => {
        if (getData.status) {
          const res: ProxyFunctionResponseData = getData.data;
          if (res.function_return_status && res.function_data) {
            const x: any = res.function_data;
            this.metricsRegData = x;
          }
        }
        this.loadingGraph = false;
      });
  }

  scaleCheckBox(): void {
    if (this.theCheckboxScale) {
      this.config.yAxis[0].max = undefined;
    } else {
      this.config.yAxis[0].max = 100;
    }
    this.refreshView();
  }

  refreshView(): void {
    if (this.refresh2) {
      this.refresh2.render();
    }
  }

  toggleOverlayStarSyntax(): void {
    if (!this.fetchDataComplete) {
      this._loadingService.register('fetchDataComplete');
    } else {
      this._loadingService.resolve('fetchDataComplete');
    }
  }

  tabSelect(event: MatTabChangeEvent): void {
    this.fetchDataComplete = false;
    this._loadingService.register('fetchDataComplete');
    if (event.index === 0) {
      this.timeSelectIndex = 0;
      this.fetchData_1_day();
    } else if (event.index === 1) {
      this.timeSelectIndex = 1;
      this.fetchData_1_week();
    } else if (event.index === 2) {
      this.timeSelectIndex = 2;
      this.fetchData_1_month();
    } else if (event.index === 3) {
      this.timeSelectIndex = 3;
      this.fetchData_3_months();
    }
  }

  fetchData_1_day(): void {
    const now: Date = new Date();
    const dayAgo: Date = new Date();
    dayAgo.setDate(dayAgo.getDate() - 1);
    this.fetchData(this.siteInfo.siteId, dayAgo, now);
  }

  fetchData_1_week(): void {
    const now: Date = new Date();
    const dayAgo: Date = new Date();
    dayAgo.setDate(dayAgo.getDate() - 7);
    this.fetchData(this.siteInfo.siteId, dayAgo, now);
  }

  fetchData_1_month(): void {
    const now: Date = new Date();
    const monthAgo: Date = new Date();
    monthAgo.setMonth(monthAgo.getMonth() - 1);
    this.fetchData(this.siteInfo.siteId, monthAgo, now);
  }

  fetchData_3_months(): void {
    const now: Date = new Date();
    const threeMonthAgo: Date = new Date();
    threeMonthAgo.setMonth(threeMonthAgo.getMonth() - 3);
    this.fetchData(this.siteInfo.siteId, threeMonthAgo, now);
  }

  fetchData(siteId: string, startDate: Date, endDate: Date): void {
    this.subscription = this.metricsGraphService
      .fetchData(siteId, startDate, endDate, 350)
      .subscribe(
        (response: GraphDataResponse) => {
          this.emptyResponseData = false;
          if (response === undefined) {
            this.emptyResponseData = true;

            return;
          }

          if (
            response.graphData.cpu_percentage_utilization &&
            response.graphData.cpu_percentage_utilization.datapoints &&
            response.graphData.cpu_percentage_utilization.datapoints.length ===
              0 &&
            response.graphData.disk_percentage_utilization &&
            response.graphData.disk_percentage_utilization.datapoints &&
            response.graphData.disk_percentage_utilization.datapoints.length ===
              0
          ) {
            this.emptyResponseData = true;
          }

          const cpuList: MetricDatapoint[] =
            response.graphData.cpu_percentage_utilization.datapoints;

          /***** CPU Utilization */
          const lineCPU: any = {
            name: 'CPU',
            clipOverflow: true,
            type: 'line',
            smooth: true,
            symbolSize: 1,
            itemStyle: {
              opacity: 0.95,
              color: '#7690f5',
            },
            data: [],
          };

          let element: MetricDatapoint;
          let cpuTotal: number = 0;
          for (element of cpuList) {
            const d: Date = new Date(element.Timestamp);
            const v: string = element.Average.toFixed(3);
            cpuTotal += element.Average;
            const item: any = {
              name: d.toISOString(),
              value: [d.toISOString(), v],
            };
            lineCPU.data.push(item);
          }
          this.cpuPercentAverage = (cpuTotal / cpuList.length).toFixed(3);

          // sort by name. This is needed to make sure the graph is plotted in chronological order.
          lineCPU.data.sort((a: any, b: any) => {
            const nameA: string = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB: string = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          });

          /***** Disk Utilization */
          const lineDisk: any = {
            name: 'Disk',
            type: 'line',
            smooth: true,
            symbolSize: 1,
            animation: true,
            itemStyle: {
              opacity: 0.95,
              color: '#FFA500',
            },
            data: [],
          };

          const diskList: MetricDatapoint[] =
            response.graphData.disk_percentage_utilization.datapoints;

          // element: MetricDatapoint;
          let diskTotal: number = 0;
          for (element of diskList) {
            const d: Date = new Date(element.Timestamp);
            const v: string = element.Average.toFixed(3);
            diskTotal += element.Average;
            const item: any = {
              name: d.toISOString(),
              value: [d.toISOString(), v],
            };
            lineDisk.data.push(item);
          }
          this.diskPercentAverage = (diskTotal / diskList.length).toFixed(3);

          // sort by name. This is needed to make sure the graph is plotted in chronological order.
          lineDisk.data.sort((a: any, b: any) => {
            const nameA: string = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB: string = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          });

          this.config.series = [];
          this.config.series.push(lineCPU);
          this.config.series.push(lineDisk);
        },
        (error: any) => {
          this.error = true;
        },
        () => {
          // Finally refresh the graph
          if (this.metricsRegData) {
            this.refresh2.render();
          }
          this.fetchDataComplete = true;
          this._loadingService.resolve('fetchDataComplete');
        }
      );
  }
}
