export const ManagementConsoleUri = 'management-console';
export const ManagementConsoleChildUriSysOps = 'sys-ops';
export const ManagementConsoleChildUriBusinessOps = 'business-ops';
export const CreateSiteUri = 'create-site';
export const CustomerDashboardUri = 'customer-dashboard';
export const CustomerUserManagementUri = 'users';
export const CustomerMeteringUri = 'metering';
export const AssumeRoleUri = 'assume-role';
export const CustomerTicketCreateUri = 'create-ticket';
export const CustomerTicketCreateConfirmationUri = 'ticket-confirmation';
export const TicketsUri = 'tickets';
export const PageNotFoundUri = '404';
export const DataSeedingServiceUri = 'dss';
export const UnauthorizedUserUri = 'unauthorized';
export const NotAuthorizedForSystemUri = 'not-authorized';
export const UserProfileErrorUri = 'user-profile-error';
export const HomeUri = 'home';
export const DashboardUri = 'dashboard';
export const NetworkSettingsUri = 'network-settings';
export const AvailabilityReportsUriV1 = 'availability-reports';
export const AvailabilityReportsUriV2 = 'site-availability-reports';
export const InstanceServiceUri = 'instance';
export const SkippedObjectsUri = 'skipped-objects';
export const NotificationUri = 'notifications';
export const EcosystemsUri = 'sites';
export const SettingsUri = 'settings';
export const NotificationSettingsUri = 'notification-settings';
export const BackupSettingsUri = 'backup-settings';
export const NoCustomerFoundUri = 'no-customer';
export const ExternalUri = 'external';
export const OnboardingSiteUri = 'onboarding';
export const UserDetailsUri = 'user-details';
export const BackupJobsSummaryUri = 'backups';
export const SupportUri = 'support';
export const ReportsUri = 'reports';
export const MeteringUri = 'metering';
export const HomepageUri = 'homepage';
export const ErrorUri = 'error';
