import { Component, ElementRef, Input } from '@angular/core';
import { TimeFilterTabViewModel } from './timeFilterTab.viewModel';

@Component({
  selector: 'time-filter-tab',
  templateUrl: './timeFilterTab.html',
  styleUrls: ['./timeFilterTab.scss'],
})
export class TimeFilterTabComponent {
  @Input() _vm: TimeFilterTabViewModel;

  initialAriaLabel: string;

  constructor(public element: ElementRef) {}

  ngOnInit() {
    this.initialAriaLabel = this._vm.displayValue;
    this._vm.ariaLabel = this._vm.displayValue;
    this._vm.analyticsEvent = {
      eventKey: 'utilization_time_span',
      actionString: this._vm.displayValue,
    };
  }

  getIndex() {
    return this._vm.index;
  }

  onTabSelected() {
    this._vm.ariaLabel = 'Selected: ' + this.initialAriaLabel;
    this.element.nativeElement.children[0].blur();
  }

  onTabDeselected() {
    this._vm.ariaLabel = this.initialAriaLabel;
  }
}
