import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  DbState,
  DbStatusStrings,
} from '@imc-features/ecosystems/_modules/ecosystems-shared/constants/db-state.constants';
import { ElasticityService } from '@imc-features/ecosystems/_modules/ecosystems-shared/services/elasticity-service/elasticity.service';
import { Site, SqleApp } from '@imc/core/models/sites.model';
import { AppConfigService } from '@imc/core/services';
import { Subscription } from 'rxjs';
import { ScaleOutInMap } from '../../models/scaleOutInRequest.model';
import { AwsInstanceInfoService } from '../../services/aws-instance-info-service/aws-instance-info.service';
import { IcawsService } from '../../services/icaws-service/icaws.service';
import { GoogleAnalyticsService } from '@imc/core/services/google-analytics/google-analytics.service';

@Component({
  selector: 'imc-aws-scale-in-out-database',
  templateUrl: './scale-in-out-database.component.html',
  styleUrls: ['./scale-in-out-database.component.scss'],
})
export class ScaleInOutDatabaseComponent implements OnInit, OnDestroy {
  private dbStatusStrings: any = DbStatusStrings;
  private icawsSubscription: Subscription;
  private useLegacyApis: boolean = true;
  @Input() siteInfo: Site;
  dbStateOptions: any = DbState;
  operationInProgress: boolean = false;
  failedOperation: boolean = false;
  displayStatus: boolean = false;
  dbState: number;
  dbMessage: string = '';
  toBeDisabled: string = '';
  disableButton: boolean = false;
  isScalingPossible: boolean = true;
  choicesMap: ScaleOutInMap;
  choicesMapKeys: string[];
  selectedScaleOption: string;

  analyticsEvent: any = {
    awsScaleInOut: {},
  };

  constructor(
    private readonly analyticsService: GoogleAnalyticsService,
    private readonly icawsService: IcawsService,
    private readonly awsInstanceInfoService: AwsInstanceInfoService,
    private readonly appConfig: AppConfigService,
    private readonly elasticityService: ElasticityService
  ) {}

  ngOnInit(): void {
    this.useLegacyApis = this.appConfig.getConfig().legacy.sqle;
    if (this.siteInfo.sqleApp) {
      this.getDbInfo();
      this.isScalingPossible = this.awsInstanceInfoService.isNodeScalingPossible(
        this.siteInfo.sqleApp.baseNodeCount,
        this.siteInfo.sqleApp.nodeCount
      );
      if (this.isScalingPossible) {
        this.choicesMap = this.awsInstanceInfoService.getNodeScalingChoicesMap(
          this.siteInfo.sqleApp.baseNodeCount
        );
        this.choicesMapKeys = Object.keys(this.choicesMap);
        this.toBeDisabled = this.findCurrentValue(
          this.siteInfo.sqleApp.baseNodeCount,
          this.siteInfo.sqleApp.nodeCount
        );
        this.selectedScaleOption = this.toBeDisabled;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.icawsSubscription) {
      this.icawsSubscription.unsubscribe();
    }
  }

  getDbInfo(): void {
    const dbStatus: string = this.siteInfo.sqleApp.status;
    // Incase the db does not have db status
    if (!dbStatus) {
      this.dbState = this.dbStatusStrings.started.dbState;
      this.dbMessage = this.dbStatusStrings.started.dbMessage;
    } else {
      // Incase siteInfo.dbStatus is not in the mapping, setting default to started
      const status: any = this.dbStatusStrings[dbStatus];
      this.dbState = status
        ? status.dbState
        : this.dbStatusStrings.started.dbState;
      this.dbMessage = status
        ? status.dbMessage
        : this.dbStatusStrings.started.dbMessage;
    }
  }

  onSubmit(formGroup: any): void {
    this.operationInProgress = true;
    const currDbStatus: string = this.siteInfo.sqleApp.status;
    const currOprStatus: string = this.siteInfo.sqleApp.operationalStatus;
    // Immediate update on UI
    this.disableButton = true;
    this.siteInfo.sqleApp.status = 'scaling';
    this.siteInfo.sqleApp.operationalStatus = 'not operational';
    this.getDbInfo();

    this.getScaleType();
    this.analyticsService.sendAnalyticsEvent(this.analyticsEvent.awsScaleInOut);

    if (this.useLegacyApis) {
      // call old apis
      this.icawsSubscription = this.icawsService
        .putDatabaseSiteScale(
          this.siteInfo,
          this.generateRequestBody(this.selectedScaleOption)
        )
        .subscribe(
          (success: any) => {
            // add any other operations on a success call
            this.operationInProgress = false;
            this.elasticityService.siteDbStatusChanged(this.dbMessage);
            this.elasticityService.refreshSiteInfo();
          },
          (error: any) => {
            this.failedOperation = true;
            this.operationInProgress = false;
            this.siteInfo.sqleApp.status = currDbStatus;
            this.siteInfo.sqleApp.operationalStatus = currOprStatus;
          }
        )
        .add(() => {
          this.displayStatus = true;
          this.getDbInfo();
        });
    } else {
      // call new sqle apis
      this.icawsSubscription = this.icawsService
        .postDatabaseSqleAppScale(
          this.siteInfo,
          this.getTargetScale(this.selectedScaleOption)
        )
        .subscribe(
          (success: any) => {
            // add any other operations on a success call
            this.operationInProgress = false;
            this.elasticityService.siteDbStatusChanged(this.dbMessage);
            this.elasticityService.refreshSiteInfo();
          },
          (error: any) => {
            this.failedOperation = true;
            this.siteInfo.sqleApp.status = currDbStatus;
            this.siteInfo.sqleApp.operationalStatus = currOprStatus;
            this.operationInProgress = false;
          }
        )
        .add(() => {
          this.displayStatus = true;
          this.getDbInfo();
        });
    }
  }

  // tslint:disable-next-line:prefer-function-over-method
  private findCurrentValue(
    baseNodeCount: number,
    currentNodeCount: number
  ): string {
    let result: number;
    if (baseNodeCount && baseNodeCount > 0) {
      result = currentNodeCount / baseNodeCount;
    } else {
      result = 1; // setting default to '1x'
    }

    return `${result}x`;
  }

  // TODO: Below function is to support v1site
  // can be deprecated once IMC 1.5 is totally integrated with new apis (v2 site)
  private generateRequestBody(selectionMade: string): any {
    const multiple: number =
      this.choicesMap[selectionMade] / this.siteInfo.sqleApp.baseNodeCount;
    const request: any = { multiply: multiple };

    return request;
  }

  private getTargetScale(selectionMade: string): number {
    const multiple: number =
      this.choicesMap[selectionMade] / this.siteInfo.sqleApp.baseNodeCount;

    return multiple;
  }

  private getScaleType(): string {
    let scaleType: string;

    if (
      this.siteInfo.sqleApp.nodeCount >
      this.getTargetScale(this.selectedScaleOption)
    ) {
      this.analyticsEvent.awsScaleInOut.eventKey = 'aws_scale_in';
    } else {
      this.analyticsEvent.awsScaleInOut.eventKey = 'aws_scale_out';
    }
    return scaleType;
  }
}
