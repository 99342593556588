// TODO this service has been temporaily added until ui-platform is imported;
// Once ui-platform is available, this service will be removed in favor of VantageErrorService

import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  IAlertConfig,
  TdAlertDialogComponent,
  TdDialogService,
} from '@covalent/core/dialogs';

export class IMCError {
  message: string;
  error: number;
}

@Injectable()
export class ErrorService {
  constructor(private _dialogService: TdDialogService) {}

  open(error: IMCError): MatDialogRef<TdAlertDialogComponent> {
    if (error) {
      const config: IAlertConfig = {
        title: 'There was a problem',
        message: error.message,
        disableClose: true,
        closeButton: 'Close',
      };
      if (error.error) {
        config.message += ` (${error.error.toString()})`;
      }
      if (config.message || config.title) {
        return this._dialogService.openAlert(config);
      }
    }
  }
}
