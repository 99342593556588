import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

import { cidr_network } from '../../models/regex';
import {
  BIT_COUNT_OF_IP_V4,
  IP_COUNT_FOR_GATEWAY_SUBNET,
  RESERVED_IP_COUNT_FOR_VM_SUBNET,
  SUBNET_COUNT_PER_SITE,
} from '../../models/site-constants';

const DB_NODE_COUNT: string = 'dbNodeCount';
const NETWORK_CIDR: string = 'networkCidr';
const CURRENT_SCALEOUT_STATE: string = 'currentScaleOutState';
const CIDR_RANGE_ERROR: string = 'invalidNetworkCidrRange';

@Injectable()
export class NetworkCidrValidationService {
  public networkCidrValidateRange(incScaleOptions: number[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let dbNodeCount: number = control.parent.controls[DB_NODE_COUNT].value;
      this.deleteCidrRangeError(control.parent.controls[DB_NODE_COUNT]);

      const networkCidr: string = control.parent.controls[NETWORK_CIDR].value;
      this.deleteCidrRangeError(control.parent.controls[NETWORK_CIDR]);

      this.deleteCidrRangeError(
        control.parent.controls[CURRENT_SCALEOUT_STATE]
      );

      if (networkCidr === null) {
        return null;
      }

      // Validate Cidr string
      const validateCidrResult: {
        [key: string]: any;
      } = this.networkCidrValidate(networkCidr);
      if (validateCidrResult !== null) {
        return validateCidrResult;
      }

      if (dbNodeCount === null) {
        return null;
      }

      // There will be 2 subnets:
      //     vmSubnet: (DB nodes) + 10 (TD ecosystem components) + 5 (reserved for Azure)
      //               (RESERVED_IP_COUNT_FOR_VM_SUBNET is 15)
      //     gatewaySubnet: 27 (VPN Gateway) + 5 (reserved for Azure). round up to 32(IP_COUNT_FOR_GATEWAY_SUBNET)
      // Note: To deploy an ExpressRoute gateway and a vpn gateway within the same gateway subnet,
      //     the cidr of gateway subnet should be /27 or larger, as required by Azure.

      // Adjusting the dbNodeCount based on largest incremental node count.
      if (!incScaleOptions || incScaleOptions.length === 0) {
        return null;
      }
      dbNodeCount = incScaleOptions[incScaleOptions.length - 1];

      const vmSubnetIpCountPowerTwo: number = this.roundUpPowerTwo(
        dbNodeCount + RESERVED_IP_COUNT_FOR_VM_SUBNET
      );
      const gatewaySubnetIpCountPowerTwo: number = IP_COUNT_FOR_GATEWAY_SUBNET;
      const cidrFragments: string[] = networkCidr.split('/');
      const ipNetworkPrefix: number = parseInt(cidrFragments[1], 10);

      // To make sure we don't waste any ip range provided by the CIDR input, we evenly distribute the extra ip
      // range into the 2 subnets: vmSubnet, gatewaySubnet
      // There are totally 32 (BIT_COUNT_OF_IP_V4) bits for a IPv4 address. 32 - ipNetworkPrefix is the number of
      // bits which are used for the range of ip addresses.
      // 32 - ipNetworkPrefix - Math.ceil(Math.log2(SUBNET_COUNT_PER_SITE)) is the number of bits which are used
      // for the range of ip addresses for each subnet. For example, a CIDR is: 10.10.10.0/25 which is the range
      // of 10.10.10.0 ~ 10.10.10.127
      // (00001010.00001010.00001010.00000000 to 00001010.00001010.00001010.11111111).
      // For a 2-subnet site, each subnet gets 32 - 25 - 1 = 6 bits of range for its ip addresses.
      // So the 2 subnets are assigned the following ip ranges:
      // 00001010.00001010.00001010.00000000 to 00001010.00001010.00001010.00111111
      // 00001010.00001010.00001010.01000000 to 00001010.00001010.00001010.01111111
      //
      const ipCountPerSubnet: number = Math.pow(
        2,
        BIT_COUNT_OF_IP_V4 -
          ipNetworkPrefix -
          Math.ceil(Math.log2(SUBNET_COUNT_PER_SITE))
      );
      if (
        vmSubnetIpCountPowerTwo > ipCountPerSubnet ||
        gatewaySubnetIpCountPowerTwo > ipCountPerSubnet
      ) {
        return { invalidNetworkCidrRange: networkCidr };
      }

      return null;
    };
  }

  private networkCidrValidate(value: string): { [key: string]: any } {
    const failResult: any = { invalidNetworkCidr: value };
    const successResult: { [key: string]: any } = null;

    // Check whether the CIDR is any pattern like: [0-255].[0-255].[0-255].[0-255]/[16-32]
    // value = "10.10.10.0/16";
    const regexPass: boolean = cidr_network.test(value);

    if (!regexPass) {
      return failResult;
    }

    // Then check whether the CIDR has correct format. E.g. 10.10.10.0/16 is not valid because the last 16 bits
    // are not all '0'.
    const parts: string[] = value.split('/');
    const ip: string = parts[0];
    const suffixNumber: number = parseInt(parts[1], 10);
    const ipParts: string[] = ip.split('.');
    let ipNumber: number = 0;
    let radix: number = 1;
    for (let i: number = ipParts.length - 1; i >= 0; i -= 1) {
      ipNumber += parseInt(ipParts[i], 10) * radix;
      radix *= 256;
    }

    for (let i: number = 0; i < 32 - suffixNumber; i += 1) {
      // tslint:disable-next-line:no-bitwise
      if ((ipNumber & 0x1) !== 0) {
        return failResult;
      }
      ipNumber = ipNumber >> 1; // tslint:disable-line:no-bitwise
    }

    // All validation passed.
    return successResult;
  }

  private deleteCidrRangeError(node: any): void {
    if (node.hasError(CIDR_RANGE_ERROR)) {
      node.setErrors(null);
    }
  }

  private roundUpPowerTwo(count: number): number {
    if (count <= 0) {
      return 1;
    }

    const power = Math.ceil(Math.log2(count));

    return Math.pow(2, power);
  }
}
