import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'imc-azure-customer-tickets',
  templateUrl: './azure-customer-tickets.component.html',
  styleUrls: ['./azure-customer-tickets.component.scss'],
})
export class AzureCustomerTicketsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
