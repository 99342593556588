import { Injectable } from '@angular/core';
import {
  CLOUD_PLATFORM_INSTANCE_INFO,
  DEFAULT_INSTANCE_INFO,
} from '../../constants/site-instance-info.constants';
import { AwsInstanceInfo } from '../../models/aws-instance-info.model';
import { ScaleOutInMap } from '../../models/scaleOutInRequest.model';

@Injectable()
export class AwsInstanceInfoService {
  getInstanceInfo(dbSize: string): AwsInstanceInfo {
    const normalizedDbSize: string = this.getNormalizedAwsInstanceSizeFromDbsize(
      dbSize
    );

    const instanceInfo: AwsInstanceInfo =
      CLOUD_PLATFORM_INSTANCE_INFO.aws[normalizedDbSize];

    return instanceInfo || DEFAULT_INSTANCE_INFO;
  }

  getAvailableInstanceSizes(dbSize: string): string[] {
    const normalizedDbSize: string = this.getNormalizedAwsInstanceSizeFromDbsize(
      dbSize
    );

    return (
      CLOUD_PLATFORM_INSTANCE_INFO.awsInstanceSizes[normalizedDbSize] || []
    );
  }

  getNormalizedAwsInstanceSizeFromDbsize(dbSize: string): string {
    return CLOUD_PLATFORM_INSTANCE_INFO.awsInstanceTypes[dbSize] || dbSize;
  }

  isEbsScalingPossible(currentStorageSize: string): boolean {
    if (
      currentStorageSize === null ||
      +currentStorageSize.split('T')[0] >= 60
    ) {
      return false;
    }

    return true;
  }

  isNodeScalingPossible(baseDBNodeCount: number, dbNodeCount: number): boolean {
    if (baseDBNodeCount === null || dbNodeCount < baseDBNodeCount) {
      return false;
    }
    // if base node count is >33 then there'ss be no option to scale
    if (baseDBNodeCount > 32) {
      return false;
    }

    return true;
  }

  getNodeScalingChoicesMap(baseNodeCount: number): ScaleOutInMap {
    const choicesMap: ScaleOutInMap = {};

    if (baseNodeCount) {
      choicesMap['1x'] = baseNodeCount;

      // this is to ensure that the 2x option is valid
      // in the future, this'll be retrieved from db node
      if (baseNodeCount <= 32) {
        choicesMap['2x'] = baseNodeCount * 2;

        // this is to ensure that the 4x option is valid
        // in the future, this'll be retrieved from db node
        if (baseNodeCount <= 16) {
          choicesMap['4x'] = baseNodeCount * 4;
        }
      }
    }

    return choicesMap;
  }
}
