export interface ProductOffer {
  productOfferId: string;
  productOffer: string;
  platform: string;
  offerDetails: OfferDetails;
  status: OfferStatusType;
}

export interface OfferDetails {
  automationTemplate: AutomationTemplateDetails;
  osVersion: OsVersion;
  dbVersions: Version[];
  regions: string[];
  storageSizes: string[];
  storageTypes: string[];
  purposeOptions: string[];
  timezones: string[];
  tiers: ProductTier[];
  vmConfigs: VMConfig[];
  version: Version;
}

export interface OsVersion {
  slesBaseOs: string;
  kernel: string;
}

export interface AutomationTemplateDetails {
  templateVersion: string;
  baseTemplateJson: string;
}

export interface Version {
  major: string;
  minor: string;
}

export interface VMConfig {
  size?: string;
  sku: string;
  cpus: string;
  memory?: string;
  tcores?: string;
  io?: string;
  name?: string;
  svgIcon?: any;
  svgSelectedIcon?: any;
  cpu?: string;
  tcore?: string;
}

export interface ProductTier {
  name: string;
  features: FeatureDetail[];
}

export interface FeatureDetail {
  category: string;
  attributes: FeatureAttribute[];
}

export interface FeatureAttribute {
  featureName: string;
  value: string;
  version?: Version;
  displayFeatureProperties?: boolean;
  featureProperties?: FeatureProperty[];
  optional?: boolean;
}

export interface FeatureProperty {
  featurePropertyName: string;
  featurePropertyValues: FeaturePropertyValue[];
}

export interface NodeLimit {
  min: number;
  max: number;
}

export interface FeaturePropertyValue {
  displayName: string;
  value: string;
}

export type OfferStatusType = 'active' | 'inactive';

export const OfferStatus = {
  Active: 'active' as OfferStatusType,
  Inactive: 'inactive' as OfferStatusType,
};

export interface EcosystemOption {
  name: string;
  version: Version;
  instanceTypes: InstanceType[];
  instanceCount: InstanceCount[];
  isEnabled: boolean;
}

export interface InstanceType {
  displayValue: string;
  value: string;
  offeringType: string;
}

export interface InstanceCount {
  displayValue: string;
  value: number;
  offeringType: string;
}

export interface AvailableDBConfigs {
  [dbSize: string]: [VMConfig];
}
