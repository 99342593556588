import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TdMediaService } from '@covalent/core';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  exhaustMap,
  finalize,
  first,
  map,
  switchMapTo,
  takeWhile,
} from 'rxjs/operators';

import {
  LastEvaluatedKey,
  NotifyModel,
  NotifyResponse,
  NotifyViewModel,
} from '../../models/notify.model';
import {
  NotificationPollerService,
  NotificationsAlert,
} from '../../services/notification-poller.service';
import { NotifyService } from '../../services/notify.service';
import { MatDialogRef, MatDialog } from '@angular/material';
import { DocConfigService } from '@imc/core/services/doc-config/doc-config.service';

@Component({
  selector: 'imc-notify-details',
  templateUrl: './notify-details.component.html',
  styleUrls: ['./notify-details.component.scss'],
})
export class NotifyDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('helpDialog') publishDialogTemplate: TemplateRef<any>;
  private subscription: Subscription;

  notifySummaryList: NotifyModel[] = [];
  filteredData: NotifyViewModel[] = [];
  selectedNotificationId: string = undefined;

  lastSeenTimestamp: string;
  lastFetchedTimestamp: string;
  lastEvaluatedKey: LastEvaluatedKey = undefined;
  scroll$: Subject<any>;
  openDialog: MatDialogRef<any>;
  notificationDoc: string;

  constructor(
    private readonly notificationPoller: NotificationPollerService,
    private readonly notifyService: NotifyService,
    private readonly route: ActivatedRoute,
    public media: TdMediaService,
    private dialog: MatDialog,
    private docConfigService: DocConfigService
  ) {
    this.notificationDoc = this.docConfigService.getDocConfig()[
      'notifications'
    ];
  }

  ngOnInit(): void {
    this.scroll$ = new Subject<any>();

    // tslint:disable-next-line: typedef
    const previousNotifications = <T>(source: Observable<T>) =>
      source.pipe(
        exhaustMap(() =>
          this.notifyService.getNotifications(
            `<${this.lastFetchedTimestamp}`,
            this.lastEvaluatedKey
          )
        ),
        map((notifyResponse: NotifyResponse) => {
          this.processNotifications(notifyResponse.items);
          this.lastEvaluatedKey = notifyResponse.lastEvaluatedKey;

          // return indicator if more messages are available
          return notifyResponse.lastEvaluatedKey ? true : false;
        })
      );

    this.subscription = this.route.paramMap
      .pipe(
        map((params: ParamMap) => {
          this.selectedNotificationId = params.get('id');
        }),
        switchMapTo(this.notificationPoller.newNotifications),
        first(
          (notificationsAlert: NotificationsAlert) =>
            notificationsAlert.fetchedTimestamp !== undefined
        ),
        map((notificationsAlert: NotificationsAlert) => {
          this.lastSeenTimestamp = notificationsAlert.ackTimestamp;
          this.lastFetchedTimestamp = notificationsAlert.fetchedTimestamp;
        }),
        previousNotifications,
        first(),
        switchMapTo(this.notificationPoller.newNotifications)
      )
      .subscribe((notificationsAlert: NotificationsAlert) => {
        if (notificationsAlert.notifications.length > 0) {
          this.processNotifications(notificationsAlert.notifications);
          this.notificationPoller.updateLastSeenTimeStamp();
        }
      });

    this.subscription.add(
      this.scroll$
        .pipe(
          previousNotifications,
          takeWhile((val: boolean) => val),
          finalize(() => 'done')
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  openHelpDialog(): void {
    this.openDialog = this.dialog.open(this.publishDialogTemplate);
  }

  closeHelpDialog(): void {
    this.openDialog.close();
  }

  processNotifications(notifications: NotifyModel[]): void {
    if (Array.isArray(notifications)) {
      this.notifySummaryList = this.notifySummaryList.concat(notifications);

      this.applyFilters(this.notifySummaryList);
    }
  }

  applyFilters(notifications: NotifyModel[]): void {
    // set default filter data with sorted set
    notifications.sort((a: NotifyModel, b: NotifyModel) =>
      a.receivedTimestamp === b.receivedTimestamp
        ? 0
        : +(b.receivedTimestamp > a.receivedTimestamp) || -1
    );
    this.filteredData = notifications;
  }
}
