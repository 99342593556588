import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { Customer } from '../models/customer.model';

@Injectable()
export class CustomerService {
  constructor(private readonly apollo: Apollo) {}

  public getCustomerById(customerId: string): Observable<Customer> {
    const query: any = gql`
      query customerById {
        customer(id: $customerId)
          @rest(
            type: "Customer"
            path: "/sites-service/v2/customers/{args.id}"
            endpoint: "default"
          ) {
          customerId
          name
          cloudId
        }
      }
    `;

    return this.apollo.query<any>({ query, variables: { customerId } }).pipe(
      map((responseBody: any) => {
        if (responseBody.errors) {
          throw new Error('unable to fetch the customer name');
        }

        return responseBody.data && responseBody.data.customer;
      }),
      first()
    );
  }
}
