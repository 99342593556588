import { EcosystemOption } from '../../models/productOffering.model';

const OPTION1: EcosystemOption = {
  instanceCount: [{ displayValue: '1', value: 1, offeringType: 'included' }],
  instanceTypes: [{ displayValue: '', value: '', offeringType: 'included' }],
  isEnabled: true,
  name: 'Viewpoint (Multi-System)',
  version: { major: '16.20', minor: '00.05' },
};

const OPTION2: EcosystemOption = {
  instanceCount: [{ displayValue: '1', value: 1, offeringType: 'included' }],
  instanceTypes: [
    {
      displayValue: 'DS2v2',
      value: 'Standard_DS2_v2',
      offeringType: 'included',
    },
  ],
  isEnabled: true,
  name: 'REST Services',
  version: null,
};

const OPTION3: EcosystemOption = {
  instanceCount: [{ displayValue: '1', value: 1, offeringType: 'included' }],
  instanceTypes: [
    {
      displayValue: 'DS3v2',
      value: 'Standard_DS3_v2',
      offeringType: 'included',
    },
  ],
  isEnabled: true,
  name: 'Data Stream Utility',
  version: { major: '16.20', minor: '00.05' },
};

const OPTION4: EcosystemOption = {
  instanceCount: [{ displayValue: '1', value: 1, offeringType: 'included' }],
  instanceTypes: [
    {
      displayValue: 'DS3v2',
      value: 'Standard_DS3_v2',
      offeringType: 'included',
    },
  ],
  isEnabled: true,
  name: 'Server Management',
  version: { major: '12.07', minor: '05.00-1' },
};

export const OPTION5: EcosystemOption = {
  instanceCount: [
    { displayValue: '0', value: 0, offeringType: 'included' },
    { displayValue: '1', value: 1, offeringType: 'included' },
    { displayValue: '2', value: 2, offeringType: 'included' },
    { displayValue: '3', value: 3, offeringType: 'included' },
  ],
  instanceTypes: [
    { displayValue: 'm4.xlarge', value: 'm4.xlarge', offeringType: 'included' },
    {
      displayValue: 'm4.2xlarge',
      value: 'm4.2xlarge',
      offeringType: 'included',
    },
    {
      displayValue: 'm4.4xlarge',
      value: 'm4.4xlarge',
      offeringType: 'included',
    },
    {
      displayValue: 'm4.10xlarge',
      value: 'm4.10xlarge',
      offeringType: 'included',
    },
  ],
  isEnabled: true,
  name: 'Data Mover',
  version: { major: '16.20', minor: '09.00' },
};

const OPTION6: EcosystemOption = {
  instanceCount: [{ displayValue: '1', value: 1, offeringType: 'included' }],
  instanceTypes: [
    {
      displayValue: 'DS2v2',
      value: 'Standard_DS2_v2',
      offeringType: 'included',
    },
  ],
  isEnabled: false,
  name: 'Load Server',
  version: null,
};

const OPTION7: EcosystemOption = {
  instanceCount: [{ displayValue: '1', value: 1, offeringType: 'included' }],
  instanceTypes: [
    {
      displayValue: 'DS2v2',
      value: 'Standard_DS2_v2',
      offeringType: 'included',
    },
  ],
  isEnabled: false,
  name: 'Query Grid Manager',
  version: null,
};

const OPTION8: EcosystemOption = {
  instanceCount: [{ displayValue: '1', value: 1, offeringType: 'included' }],
  instanceTypes: [
    {
      displayValue: 'DS2v2',
      value: 'Standard_DS2_v2',
      offeringType: 'included',
    },
  ],
  isEnabled: false,
  name: 'Ecosystem Manager',
  version: null,
};

export const BASE_OPTIONS = [OPTION1, OPTION5];
