import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';

import { Job } from '../../models/backups.model';

@Component({
  selector: 'mc-backup-editor-form',
  templateUrl: './backupEditorForm.component.html',
  styleUrls: ['./backupEditorForm.component.scss'],
})
export class BackupEditorFormComponent {
  @Input() set selectedBackupJob(backupJob: Job) {
    this.backupJobName = backupJob.name;
    this.retentionPeriod = backupJob.noOfRetentionCopies;
    if (backupJob.backupJobSchedule) {
      this.runInterval = backupJob.backupJobSchedule.runInterval;
      this.runUnit = backupJob.backupJobSchedule.runUnit;
      const startByDateUtc: Date = new Date(
        new Date(backupJob.backupJobSchedule.startByTime).toUTCString()
      );
      const startByDateUtcMonth: string = startByDateUtc
        .getUTCMonth()
        .toString()
        .padStart(2, '0');
      const startByDateUtcDay: string = startByDateUtc
        .getUTCDate()
        .toString()
        .padStart(2, '0');
      this.startDate = `${startByDateUtcMonth}/${startByDateUtcDay}/${startByDateUtc.getUTCFullYear()}`; // 'MM/DD/YYYY'
      this.startDateFormControl = new FormControl(new Date(this.startDate));
      this.backupStartHourTime = startByDateUtc
        .getUTCHours()
        .toString()
        .padStart(2, '0');
      this.backupStartMinuteTime = startByDateUtc
        .getUTCMinutes()
        .toString()
        .padStart(2, '0');

      if ('autoAbortInMin' in backupJob) {
        this.autoAbortCheckedStatus = backupJob.isAutoAbortActive;
        const hoursMinutes = BackupEditorFormComponent.convertMinutesToHHMM(
          backupJob.autoAbortInMin
        );
        this.autoAbortHours = BackupEditorFormComponent.convertNumberToViewValue(
          hoursMinutes.hours
        );
        this.autoAbortMinutes = BackupEditorFormComponent.convertNumberToViewValue(
          hoursMinutes.minutes
        );
        this.abortTimeChanged();
      }
    }
  }

  analyticsEvent = {
    open: {
      eventKey: 'backup_editor_open',
    },
    backupJobName: {
      eventKey: 'backup_editor_backupjob_name',
    },
    runUnit: {
      eventKey: 'backup_editor_run_unit',
    },
    runInterval: {
      eventKey: 'backup_editor_run_interval',
    },
    retentionPeriod: {
      eventKey: 'backup_editor_retention_period',
    },
    startHourTimeOfBackup: {
      eventKey: 'backup_editor_backup_start_hour_time',
    },
    startMinuteTimeOfBackup: {
      eventKey: 'backup_editor_backup_start_minute_time',
    },
    save: {
      eventKey: 'backup_editor_save',
    },
    cancel: {
      eventKey: 'backup_editor_cancel',
    },
  };

  runUnitOptions = [
    { value: 'DAY', viewValue: 'Daily' },
    { value: 'WEEK', viewValue: 'Weekly' },
    { value: 'MONTH', viewValue: 'Monthly' },
  ];

  startHourTimes = [
    { value: '00', viewValue: '00' },
    { value: '01', viewValue: '01' },
    { value: '02', viewValue: '02' },
    { value: '03', viewValue: '03' },
    { value: '04', viewValue: '04' },
    { value: '05', viewValue: '05' },
    { value: '06', viewValue: '06' },
    { value: '07', viewValue: '07' },
    { value: '08', viewValue: '08' },
    { value: '09', viewValue: '09' },
    { value: '10', viewValue: '10' },
    { value: '11', viewValue: '11' },
    { value: '12', viewValue: '12' },
    { value: '13', viewValue: '13' },
    { value: '14', viewValue: '14' },
    { value: '15', viewValue: '15' },
    { value: '16', viewValue: '16' },
    { value: '17', viewValue: '17' },
    { value: '18', viewValue: '18' },
    { value: '19', viewValue: '19' },
    { value: '20', viewValue: '20' },
    { value: '21', viewValue: '21' },
    { value: '22', viewValue: '22' },
    { value: '23', viewValue: '23' },
  ];

  startMinuteTimes = [
    { value: '00', viewValue: '00' },
    { value: '01', viewValue: '01' },
    { value: '02', viewValue: '02' },
    { value: '03', viewValue: '03' },
    { value: '04', viewValue: '04' },
    { value: '05', viewValue: '05' },
    { value: '06', viewValue: '06' },
    { value: '07', viewValue: '07' },
    { value: '08', viewValue: '08' },
    { value: '09', viewValue: '09' },

    { value: '10', viewValue: '10' },
    { value: '11', viewValue: '11' },
    { value: '12', viewValue: '12' },
    { value: '13', viewValue: '13' },
    { value: '14', viewValue: '14' },
    { value: '15', viewValue: '15' },
    { value: '16', viewValue: '16' },
    { value: '17', viewValue: '17' },
    { value: '18', viewValue: '18' },
    { value: '19', viewValue: '19' },

    { value: '20', viewValue: '20' },
    { value: '21', viewValue: '21' },
    { value: '22', viewValue: '22' },
    { value: '23', viewValue: '23' },
    { value: '24', viewValue: '24' },
    { value: '25', viewValue: '25' },
    { value: '26', viewValue: '26' },
    { value: '27', viewValue: '27' },
    { value: '28', viewValue: '28' },
    { value: '29', viewValue: '29' },

    { value: '30', viewValue: '30' },
    { value: '31', viewValue: '31' },
    { value: '32', viewValue: '32' },
    { value: '33', viewValue: '33' },
    { value: '34', viewValue: '34' },
    { value: '35', viewValue: '35' },
    { value: '36', viewValue: '36' },
    { value: '37', viewValue: '37' },
    { value: '38', viewValue: '38' },
    { value: '39', viewValue: '39' },

    { value: '40', viewValue: '40' },
    { value: '41', viewValue: '41' },
    { value: '42', viewValue: '42' },
    { value: '43', viewValue: '43' },
    { value: '44', viewValue: '44' },
    { value: '45', viewValue: '45' },
    { value: '46', viewValue: '46' },
    { value: '47', viewValue: '47' },
    { value: '48', viewValue: '48' },
    { value: '49', viewValue: '49' },

    { value: '50', viewValue: '50' },
    { value: '51', viewValue: '51' },
    { value: '52', viewValue: '52' },
    { value: '53', viewValue: '53' },
    { value: '54', viewValue: '54' },
    { value: '55', viewValue: '55' },
    { value: '56', viewValue: '56' },
    { value: '57', viewValue: '57' },
    { value: '58', viewValue: '58' },
    { value: '59', viewValue: '59' },
  ];

  @ViewChild('deleteRetentionDialog')
  deleteRetentionDialogTemplate: TemplateRef<any>;
  openDialog: MatDialogRef<any>;

  backupJobName: string;
  retentionPeriod: number;
  runInterval: number;
  runUnit: string;
  startDate: string;
  backupStartHourTime: string;
  backupStartMinuteTime: string;
  startDateFormControl: FormControl = new FormControl(new Date());

  autoAbortCheckedStatus: boolean = false;
  autoAbortHours: string = '00';
  autoAbortMinutes: string = '00';
  nextAutoAbortTimeString: string = '';

  @Output() close = new EventEmitter();
  @Output() onSubmit = new EventEmitter();
  @ViewChild(NgForm) form: NgForm;

  constructor(private dialog: MatDialog) {}
  static generateAutoAbortTimeString(
    startHours,
    startMinutes,
    maxHours,
    maxMinutes
  ) {
    let hours = startHours + maxHours;
    let dayString = 'same day';
    if (hours >= 24) {
      hours -= 24;
      dayString = 'next day';
    }

    let minutes = startMinutes + maxMinutes;
    if (minutes >= 60) {
      const extraHours = Math.floor(minutes / 60);
      minutes = minutes - extraHours * 60;
      hours += extraHours;
    }

    return (
      BackupEditorFormComponent.convertNumberToViewValue(hours) +
      ' : ' +
      BackupEditorFormComponent.convertNumberToViewValue(minutes) +
      ' UTC ' +
      dayString
    );
  }

  static convertMinutesToHHMM(minutes) {
    let hours = 0;
    let min = 0;
    hours = Math.floor(minutes / 60);
    min = minutes - hours * 60;

    return {
      hours,
      minutes: min,
    };
  }

  static convertNumberToViewValue(num) {
    let numStr = num + '';
    if (num < 10) {
      numStr = '0' + numStr;
    }

    return numStr;
  }

  closeDialog(event) {
    this.clearForm();
  }

  openConfirmationDialog() {
    this.openDialog = this.dialog.open(this.deleteRetentionDialogTemplate);
  }

  abortTimeChanged() {
    this.nextAutoAbortTimeString = BackupEditorFormComponent.generateAutoAbortTimeString(
      parseInt(this.backupStartHourTime, 10),
      parseInt(this.backupStartMinuteTime, 10),
      parseInt(this.autoAbortHours, 10),
      parseInt(this.autoAbortMinutes, 10)
    );
  }

  closeConfirmationDialog(deleted?: boolean) {
    if (this.openDialog) {
      this.openDialog.close();
      if (deleted) {
        const data = this.createOnSubmitData(this.form);
        this.onSubmit.emit(data);
      }
      this.clearForm();
      this.close.emit();
    }
  }

  clearForm() {
    this.form.resetForm();
    this.close.emit();
  }

  submit(form) {
    if (form.valid) {
      // Check the retention period for confirmation
      this.startDate = new Date(this.startDate).toString();
      if (this.retentionPeriod > form.value.retentionPeriod) {
        // Open Confirmation dialog
        this.openConfirmationDialog();
      } else {
        const data = this.createOnSubmitData(form);
        this.onSubmit.emit(data);
        this.form.resetForm();
        this.close.emit();
      }
    }

    return form.valid;
  }

  private createOnSubmitData(form): any {
    const data = { ...form.value };
    const nonUTCDate: Date = new Date(this.startDateFormControl.value);

    // -- Seconds not added as the seconds value is not taken from UI --
    const newDate: Date = new Date(
      nonUTCDate.getFullYear(),
      nonUTCDate.getMonth(),
      nonUTCDate.getDate(),
      parseInt(this.backupStartHourTime, 10),
      parseInt(this.backupStartMinuteTime, 10)
    );
    data.startDate = newDate.toISOString();
    data.isAutoAbortActive = this.autoAbortCheckedStatus;
    data.autoAbortInMin = this.convertAutoAbortUIToMinutes();
    return data;
  }

  private convertAutoAbortUIToMinutes() {
    const autoAbortHoursToMinutes: number =
      parseInt(this.autoAbortHours, 10) * 60;

    return autoAbortHoursToMinutes + parseInt(this.autoAbortMinutes, 10);
  }
}
