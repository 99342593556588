import { Component, OnInit } from '@angular/core';
import { DynamicLayoutComponent } from '@imc-features/ecosystems/_modules/ecosystems-shared/components/dynamic-layout/dynamic-layout.component';
import { isSiteFeatureEnabled } from '@imc-features/ecosystems/_modules/ecosystems-shared/utilities/site-feature-utility/site-feature-utility';
import { PolicyUserRole } from '@imc/core/models/roles.enum';
import { UserRoleInfo } from '@imc/core/models/userRoleInfo.model';
import { PolicyService } from '@imc/core/services/policy-service/policy.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
  availableOps,
  elasticityOpsCustomer,
  elasticityOpsSysops,
} from '../../constants/db-elasticity-operations.constants';
import { dbFeatureStrings } from '../../constants/db-features.constants';

@Component({
  selector: 'imc-azure-site-elasticity',
  templateUrl: './azure-site-elasticity.component.html',
  styleUrls: ['./azure-site-elasticity.component.scss'],
})
export class AzureSiteElasticityComponent extends DynamicLayoutComponent
  implements OnInit {
  operationSelected: any;
  isSysOpsUser: boolean = false;
  public elasticityOperations: Observable<any[]>;
  public availableOps: any = availableOps;

  constructor(private readonly policyService: PolicyService) {
    super();
  }

  ngOnInit(): void {
    this.elasticityOperations = this.policyService.userRoleInfo$.pipe(
      switchMap((role: UserRoleInfo) => this.getElasticityOperations(role))
    );
  }

  setOperation(event: string): void {
    this.operationSelected = event;
  }

  private getElasticityOperations(role: UserRoleInfo): Observable<any[]> {
    let elasticityOps: any;
    if (
      role.currentRole === PolicyUserRole.FullSysops ||
      role.currentRole === PolicyUserRole.ReadOnlySysops ||
      role.currentRole === PolicyUserRole.ReadOnlyLimitedSysops
    ) {
      elasticityOps = elasticityOpsSysops;
    } else {
      elasticityOps = elasticityOpsCustomer;
    }

    const elasticityOpsNew: any[] = elasticityOps.filter((op: any) => {
      if (
        op.feature !== undefined &&
        dbFeatureStrings[op.feature] !== undefined
      ) {
        if (
          isSiteFeatureEnabled(
            this.siteInfo,
            dbFeatureStrings.DB_COMPONENT_NAME,
            dbFeatureStrings[op.feature]
          )
        ) {
          return true;
        }
      } else {
        // operation is not restricted by a site feature flag
        return true;
      }

      return false;
    });

    return of(elasticityOpsNew);
  }
}
