import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { PolicyOperations } from '@imc/core/models/policyOperations.enum';
import { PolicyResources } from '@imc/core/models/policyResources.enum';
import { Site, SqleApp } from '@imc/core/models/sites.model';
import { Subscription } from 'rxjs';
import { ElasticityService } from '../../services/elasticity-service/elasticity.service';

@Component({
  selector: 'imc-sql-engine',
  templateUrl: './sql-engine.component.html',
  styleUrls: ['./sql-engine.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SqlEngineComponent implements OnInit, OnChanges, OnDestroy {
  private elasticitySubscription: Subscription;
  @Input() site: Site;
  @Input() loaderKey: string = 'sqlEngineLoader';
  @Input() loading: boolean = true;
  @Input() error: any;

  subtitle: string = '';
  sqleApp: SqleApp;
  storageSizePerNode: string;

  public PolicyOperations: any = PolicyOperations;
  public PolicyResources: any = PolicyResources;

  constructor(
    private readonly router: Router,
    private readonly elasticityService: ElasticityService,
    private readonly changeDetectionRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.elasticitySubscription = this.elasticityService
      .getDbSiteStatus()
      .subscribe(async (status: string) => {
        if (this.sqleApp && status) {
          this.sqleApp.status = status;
          this.changeDetectionRef.detectChanges();
          this.changeDetectionRef.markForCheck();
        }
      });
  }

  ngOnChanges(): void {
    if (this.site !== undefined) {
      this.subtitle = `Information on ${this.site.displayName}`;
      this.sqleApp = this.site.sqleApp || {};
      this.storageSizePerNode =
        this.sqleApp.storageSizePerNodeTB &&
        `${parseInt(this.sqleApp.storageSizePerNodeTB, 10)}TB per node`;
    }
  }

  ngOnDestroy(): void {
    this.elasticitySubscription.unsubscribe();
  }

  editSite(): void {
    this.router.navigate([
      'sites',
      this.site.siteId,
      { outlets: { side: 'elasticity' } },
    ]);
  }
}
