import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AssumeRoleUri,
  NotificationUri,
  ReportsUri,
  SettingsUri,
  SupportUri,
} from '@imc/core';
import { AuthenticatedUserGuard, CustomerGuard } from '@imc/core/guards/';

import { SiteSelectionComponent } from '@imc-features/customer/pages/site-selection/site-selection.component';
import { CustomerDashboardComponent } from '@imc-features/dashboard/pages/customer-dashboard/customer-dashboard.component';
import { IntellicloudSystemDetailsComponent } from '@imc-features/dashboard/pages/intellicloud-system-details/intellicloud-system-details.component';
import { ListCustomersComponent } from '@imc-features/dashboard/pages/list-customers/list-customers.component';
import { SiteLandingComponent } from '@imc-features/dashboard/pages/site-landing/site-landing.component';
import { SystemDashboardComponent } from '@imc-features/dashboard/pages/system-dashboard/system-dashboard.component';
import { LoginRedirectGuard } from '@imc/core/guards/login-redirect.guard';
import { SysOpsGuard } from '@imc/core/guards/sysops.guard';
import { AssumeRoleComponent } from '@imc/core/pages/assume-role/assume-role.component';
import { LoginComponent } from '@imc/core/pages/login/login.component';
import { ErrorComponent } from '@imc/shared/pages/error/error.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [AuthenticatedUserGuard],
    children: [
      {
        path: '',
        component: SiteSelectionComponent,
        canActivate: [CustomerGuard],
      },
    ],
  },
  {
    path: 'switch',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'td/login',
    component: LoginComponent,
    canActivate: [LoginRedirectGuard],
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: 'home',
    canActivate: [AuthenticatedUserGuard],
    children: [
      {
        path: '',
        component: SystemDashboardComponent,
        canActivate: [SysOpsGuard],
      },
    ],
  },
  {
    path: 'customers',
    component: ListCustomersComponent,
    canActivate: [AuthenticatedUserGuard, SysOpsGuard],
  },
  {
    path: 'customer-dashboard/:customerId',
    component: CustomerDashboardComponent,
    canActivate: [AuthenticatedUserGuard],
  },
  {
    path: AssumeRoleUri,
    component: AssumeRoleComponent,
    canActivate: [AuthenticatedUserGuard],
  },
  {
    path: 'users',
    canActivate: [AuthenticatedUserGuard],
    loadChildren: '../__feature-modules/users/users.module#UsersModule',
  },
  {
    path: SupportUri,
    canActivate: [AuthenticatedUserGuard],
    loadChildren: '../__feature-modules/support/support.module#SupportModule',
  },
  {
    path: 'sites/:siteId/monitoring',
    canActivate: [AuthenticatedUserGuard],
    loadChildren:
      '../__feature-modules/monitoring/monitoring.module#MonitoringModule',
  },
  {
    path: 'sites/:siteId/monitoring/metering',
    canActivate: [AuthenticatedUserGuard],
    loadChildren:
      '../__feature-modules/metering/metering.module#MeteringModule',
  },
  {
    path: 'sites/:siteId/engine/new-sql',
    canActivate: [AuthenticatedUserGuard],
    loadChildren: '../__feature-modules/engine/engine.feature#EngineFeature',
  },
  {
    path: 'sites/:siteId',
    canActivate: [AuthenticatedUserGuard],
    component: SiteLandingComponent,
  },
  {
    path: 'sites',
    canActivate: [AuthenticatedUserGuard],
    loadChildren:
      '../__feature-modules/ecosystems/ecosystems.module#EcosystemsModule',
  },
  {
    path: 'features',
    canActivate: [AuthenticatedUserGuard],
    loadChildren:
      '../__feature-modules/example-features/feature.module#FeatureModule',
  },
  {
    path: ReportsUri,
    canActivate: [AuthenticatedUserGuard],
    loadChildren: '../__feature-modules/reports/reports.module#ReportsModule',
  },
  {
    path: SettingsUri,
    canActivate: [AuthenticatedUserGuard],
    loadChildren:
      '../__imc/core/_modules/settings/settings.module#SettingsModule',
  },
  {
    path: NotificationUri,
    canActivate: [AuthenticatedUserGuard],
    loadChildren: '../__imc/core/_modules/notify/notify.module#NotifyModule',
  },
  {
    path: 'intellicloud-system-details',
    canActivate: [AuthenticatedUserGuard, SysOpsGuard],
    component: IntellicloudSystemDetailsComponent,
  },
  {
    path: '**',
    component: ErrorComponent,
    data: { error: '404' },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      relativeLinkResolution: 'corrected',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
