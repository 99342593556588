/**
 * Note: Current Google Analytics account is only supporting 20 custom metrics and custom dimensions
 *
 * Steps to track events
 *
 * 1) Create custom metric on GoogleAnalytics across different environments (Admin -> Custom Definitions)
 * 2) Add analyticsEvent in components need tracking (i.e start-database.component.ts)
 * 3) Map according eventKey in analyticsEventTracking.map.ts
 * 4) Track events in event listener functions from the same components(i.e onSubmit)
 */
export const trackedEvents = {
  // Navigation - existed event
  nav_ticket_details: {
    metric: 'metric1',
    eventFields: {
      eventCategory: 'Navigation',
      eventAction: 'click - ticket',
      eventLabel: 'View Ticket Details',
    },
    eventValue: 1,
  },
  aws_db_start: {
    metric: 'metric2',
    eventFields: {
      eventCategory: 'AWS DB start',
      eventAction: 'click - awsDbStart',
      eventLabel: 'AWS DB Start',
    },
    eventValue: 1,
  },
  azure_db_start: {
    metric: 'metric3',
    eventFields: {
      eventCategory: 'Azure DB start',
      eventAction: 'click - azureDbStart',
      eventLabel: 'Azure DB Start',
    },
    eventValue: 1,
  },
  aws_db_stop: {
    metric: 'metric4',
    eventFields: {
      eventCategory: 'AWS DB stop',
      eventAction: 'click - awsDbStop',
      eventLabel: 'AWS DB Stop',
    },
    eventValue: 1,
  },
  azure_db_stop: {
    metric: 'metric5',
    eventFields: {
      eventCategory: 'Azure DB stop',
      eventAction: 'click - azureDbStop',
      eventLabel: 'Azure DB Stop',
    },
    eventValue: 1,
  },
  aws_expand_volume: {
    metric: 'metric6',
    eventFields: {
      eventCategory: 'AWS Expand Volume',
      eventAction: 'click - awsExpandVolume',
      eventLabel: 'AWS Expand Volume',
    },
    eventValue: 1,
  },
  aws_scale_up: {
    metric: 'metric7',
    eventFields: {
      eventCategory: 'AWS Scale Up',
      eventAction: 'click - awsScaleUp',
      eventLabel: 'AWS Scale Up',
    },
    eventValue: 1,
  },
  aws_scale_down: {
    metric: 'metric8',
    eventFields: {
      eventCategory: 'Azure Scale Down',
      eventAction: 'click - awsScaleDown',
      eventLabel: 'AWS Scale Down',
    },
    eventValue: 1,
  },
  azure_scale_up: {
    metric: 'metric9',
    eventFields: {
      eventCategory: 'Azure Scale Up',
      eventAction: 'click - azureScaleUp',
      eventLabel: 'Azure Scale Up',
    },
    eventValue: 1,
  },
  azure_scale_down: {
    metric: 'metric10',
    eventFields: {
      eventCategory: 'Azure Scale Down',
      eventAction: 'click - azureScaleDown',
      eventLabel: 'Azure Scale Down',
    },
    eventValue: 1,
  },
  aws_scale_in: {
    metric: 'metric11',
    eventFields: {
      eventCategory: 'AWS Scale In',
      eventAction: 'click - awsScaleIn',
      eventLabel: 'AWS Scale In',
    },
    eventValue: 1,
  },
  aws_scale_out: {
    metric: 'metric12',
    eventFields: {
      eventCategory: 'AWS Scale Out',
      eventAction: 'click - awsScaleOut',
      eventLabel: 'Azure Scale Out',
    },
    eventValue: 1,
  },
  azure_scale_in: {
    metric: 'metric13',
    eventFields: {
      eventCategory: 'Azure Scale In',
      eventAction: 'click - azureScaleIn',
      eventLabel: 'Azure Scale In',
    },
    eventValue: 1,
  },
  azure_scale_out: {
    metric: 'metric14',
    eventFields: {
      eventCategory: 'Azure Scale Out',
      eventAction: 'click - azureScaleOut',
      eventLabel: 'Azure Scale Out',
    },
    eventValue: 1,
  },
  azure_hsn_launch: {
    metric: 'metric15',
    eventFields: {
      eventCategory: 'Azure HSN Launch',
      eventAction: 'click - azureHsnLaunch',
      eventLabel: 'Azure HSN Launch',
    },
    eventValue: 1,
  },
  azure_hsn_update: {
    metric: 'metric16',
    eventFields: {
      eventCategory: 'Azure HSN Update',
      eventAction: 'click - azureHsnUpdate',
      eventLabel: 'Azure HSN Update',
    },
    eventValue: 1,
  },
  azure_hsn_delete: {
    metric: 'metric17',
    eventFields: {
      eventCategory: 'Azure HSN Delete',
      eventAction: 'click - azureHsnDelete',
      eventLabel: 'Azure HSN Delete',
    },
    eventValue: 1,
  },
};
