import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Customer } from '../models/customer.model';

interface CustomerQueryRecord {
  number: string;
  customerId: string;
  name: string;
  cloudId: string;
  customerOnboard: string;
}

@Injectable()
export class DashboardCustomerService {
  constructor(private readonly apollo: Apollo) {}

  public getCustomerById(customerId: string): Observable<Customer> {
    // TODO: use this query, when we open up by the getCustomerByID API in sites service.
    const query: any = gql`
      query customerById {
        customer(id: $customerId)
          @rest(
            type: "Customer"
            path: "/sites-service/v2/customers/{args.id}"
            endpoint: "default"
          ) {
          customerId
          name
        }
      }
    `;

    return this.apollo.query<any>({ query, variables: { customerId } }).pipe(
      map((responseBody) => {
        if (responseBody.errors) {
          throw new Error('unable to fetch the customer name');
        }

        return responseBody.data && responseBody.data.customer;
      })
    );
  }

  public listCustomers(): Observable<Customer[]> {
    const listCustomersQuery: any = gql`
      query listCustomers {
        listCustomersResponse
          @rest(type: "Customer", path: "/sites-service/v2/customers") {
          customerId
          name
          cloudId
          customerOnboard
          number: u_teradata_customer_number
        }
      }
    `;

    return this.apollo
      .query<any>({
        query: listCustomersQuery,
      })
      .pipe(
        map((responseBody: any) => {
          if (responseBody.errors) {
            throw new Error(JSON.stringify(responseBody.errors));
          }

          return responseBody.data && responseBody.data.listCustomersResponse;
        }),
        map((customers: CustomerQueryRecord[]) =>
          customers
            // tslint:disable-next-line:no-unnecessary-callback-wrapper
            .map((customer: CustomerQueryRecord) =>
              mapRecordToCustomer(customer)
            )
            .sort((a: Customer, b: Customer) => (a.name > b.name ? 1 : -1))
        )
      );
  }
}

const mapRecordToCustomer: Function = (
  record: CustomerQueryRecord
): Customer => ({
  customerId: record.customerId,
  name: record.name,
  cloudId: record.cloudId,
  customerOnboard: record.customerOnboard === 'true' ? 'Yes' : 'No',
});
