import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  GraphDataResponse,
  Metric,
  SeriesItem,
} from '../../models/metrics-graph.model';

@Injectable()
export class MetricsGraphService {
  constructor(private readonly apollo: Apollo) {}

  fetchData(
    siteId: string,
    startDate: Date,
    endDate: Date,
    dataPoints: number
  ): Observable<GraphDataResponse> {
    const namespace: string = 'intellicloud';
    const graphData: string = 'true';

    let startTime: string = startDate.toISOString();
    let endTime: string = endDate.toISOString();

    const splitStartTime = startTime.split('T');
    splitStartTime[1] = '00:00:00.000Z';
    startTime = splitStartTime.join().replace(',', 'T');

    const splitEndTime = endTime.split('T');
    splitEndTime[1] = '00:00:00.000Z';
    endTime = splitEndTime.join().replace(',', 'T');

    const graphListQuery: any = gql`
      query graphList {
        graphList(
          siteId: $siteId
          startTime: $startTime
          endTime: $endTime
          namespace: $namespace
          dataPoints: $dataPoints
          graphData: $graphData
        )
          @rest(
            type: "GraphData"
            path: "/service-health/site-usages?siteId={args.siteId}&startTime={args.startTime}&endTime={args.endTime}&namespace={args.namespace}&dataPoints={args.dataPoints}&graphData={args.graphData}"
          ) {
          siteId
          graphData
        }
      }
    `;
    return this.apollo
      .query<any>({
        query: graphListQuery,
        variables: {
          siteId,
          startTime,
          endTime,
          namespace,
          dataPoints,
          graphData,
        },
      })
      .pipe(
        map((responseBody: any) => {
          if (responseBody.errors) {
            // Error condition
            throw new Error('Fetching Graph Data failed');
          }

          const graphDataResponse: GraphDataResponse = responseBody.data;
          // Success condition
          return responseBody.data.graphList;
        })
      );
  }
}
