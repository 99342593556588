import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { StepState } from '@covalent/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Customer } from '../../models/customer.model';
import { CustomerService } from '../../services/customer.service';
import { ICAWSOfferingService } from '../../services/icawsOffering.service';

@Component({
  selector: 'imc-aws-create-sites-card',
  templateUrl: './create-sites-card.component.html',
  styleUrls: ['./create-sites-card.component.scss'],
})
export class CreateSitesCardComponent implements OnInit {
  readonly regionsWithEKS: string[] = [
    'us-east-1',
    'us-east-2',
    'us-west-2',
    'eu-west-1',
    'eu-west-2',
    'eu-west-3',
    'eu-central-1',
    'eu-north-1',
    'ap-southeast-1',
    'ap-southeast-2',
    'ap-northeast-1',
    'ap-northeast-2',
  ];
  stepStates: StepState[] = [
    StepState.Required,
    StepState.Required,
    StepState.Required,
    StepState.None,
  ];
  activeStep: boolean[] = [true, false, false, false];
  disableStep: boolean[] = [false, true, true, true];
  selections: any = {
    input_params: null,
    input_features: null,
    params: null,
    features: null,
    dbVersion: null,
    region: null,
    tier: null,
    dataMoverInstanceType: null,
    dataMoverInstanceCount: null,
    dataMoverAvailable: null,
    size: null,
    offering: null,
  };

  pageInput$: Observable<any>;
  createSite$: Observable<any>;
  @Input() customerId: string;

  constructor(
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly customerService: CustomerService,
    private readonly icawsOfferingService: ICAWSOfferingService
  ) {}

  ngOnInit(): void {
    // The use of first(), allows the individual Observables
    //  to complete and let this Observable emit.
    this.pageInput$ = forkJoin(
      this.getCustomerById(),
      this.getAllOffers()
    ).pipe(
      map(([customer, offers]: [Customer, any[]]) => ({
        customer,
        offers,
      })),
      catchError((error: any) => of({ error }))
    );
  }

  submitAWSForm($event: any, customer: Customer, offers: any[]): void {
    const includesMle: any = this.offerIncludes('mle', offers);
    if (!includesMle) {
      // $event are inputs from eco form
      this.selections.features = $event.features;
      this.selections.params = $event.params;
      this.selections.size = $event.size;
    }

    // This code and the associated forms that feed the data need to be refactored to clean up the hacks below
    // Use reactive forms instead of template driven forms when reimplementing
    // -------------------------------------------------------------------------------------------------------
    // TMCMC-3214 - the site_id should no longer be sent as part of the sqle_config section of the payload
    const supportSiteId: string = this.selections.params.site_id;
    delete this.selections.params.site_id;

    // TMCMC-3315 - when these form controls are modified, the bound variables carry strings instead of numbers
    // we therefore change them back to numbers in code. They stay numbers if the default values on the form are used.
    // This could be a defect in TdDynamicForms.
    if (
      this.selections.params.base_node_count &&
      typeof this.selections.params.base_node_count === 'string'
    ) {
      this.selections.params.base_node_count = parseInt(
        this.selections.params.base_node_count,
        10
      );
    }

    if (
      this.selections.params.node_storage_size &&
      typeof this.selections.params.node_storage_size === 'string'
    ) {
      this.selections.params.node_storage_size = parseInt(
        this.selections.params.node_storage_size,
        10
      );
    }

    if (
      this.selections.features.tim_percent &&
      typeof this.selections.features.tim_percent === 'string'
    ) {
      this.selections.features.tim_percent = parseInt(
        this.selections.features.tim_percent,
        10
      );
    }
    // --------------------------------------hacks end here----------------------------------------------------

    const combinedData: any = {
      ...this.selections.params,
      ...this.selections.features,
    };
    const data: any = {
      datamover_available: this.selections.dataMoverAvailable,
      datamover_config: {
        additional_agent_count: this.selections.dataMoverInstanceCount,
        instance_type: this.selections.dataMoverInstanceType,
      },
      sqle_config: {},
      viewpoint_config: {
        system_nickname: this.selections.params.system_nickname,
      },
    };

    data.sqle_config = {
      ...combinedData,
      node_tcore: `${this.selections.size.label} (${
        this.selections.size.tcore
      })`,
      pack: false,
      version: this.selections.dbVersion,
      tier: this.selections.tier,
    };

    let dataPayload: any = [];
    if (includesMle) {
      dataPayload = [
        {
          ecosystem: data,
          mle: {
            install_mle: $event.install_mle,
            mle_node_count: $event.install_mle ? $event.mle_node_count : 0,
            customer_name: customer.name,
          },
        },
      ];
    } else {
      dataPayload = [
        {
          ecosystem: data,
        },
      ];
    }

    const offeringRequest: any = {
      cloud_customer_id: customer.cloudId,
      region: this.selections.region,
      offering_name: this.selections.offering,
      data: dataPayload,
    };

    if (supportSiteId && supportSiteId.trim().length > 0) {
      offeringRequest.site_id = supportSiteId;
    }

    this.createSite$ = this.icawsOfferingService.create(offeringRequest).pipe(
      catchError((err: any) =>
        of({
          error: `An error occurred when requesting site creation: ${
            err.message
          }`,
        })
      ),
      map((response: any) => {
        // redirect
        this.snackBar.open('Site provisioning in progress.', 'OK');
        this.router.navigate([`/customer-dashboard/${customer.customerId}`]);
      })
    );
  }

  offerIncludes(svc: string, offers: any[]): boolean {
    if (
      this.selections.offering &&
      this.selections.region &&
      this.regionsWithEKS.indexOf(this.selections.region) !== -1
    ) {
      const services: string[] = offers.find(
        (o: any) => o.offering_name === this.selections.offering
      ).services;

      this.stepStates[this.stepStates.length - 1] = StepState.Required;

      return services.indexOf(svc) !== -1;
    }

    this.stepStates[this.stepStates.length - 1] = StepState.None;

    return false;
  }

  goToNextStep($event: any, step: number): void {
    if (step === 1) {
      this.selections.input_params = $event.params;
      this.selections.input_features = $event.features;
      this.selections.dbVersion = $event.dbVersion;
      this.selections.region = $event.region;
      this.selections.tier = $event.tier;
      this.selections.offering = $event.offering;
    }

    if (step === 2) {
      this.selections.dataMoverInstanceType = $event.dataMoverInstanceType;
      this.selections.dataMoverInstanceCount = $event.dataMoverInstanceCount;
      this.selections.dataMoverAvailable = $event.dataMoverAvailable;
    }

    // eco params/features
    if (step === 3) {
      this.selections.params = $event.params;
      this.selections.features = $event.features;
      this.selections.size = $event.size;
    }

    this.completeStep(step);
  }

  changeToActive(step: number): void {
    for (let stepNum: number = 0; stepNum < this.activeStep.length; stepNum++) {
      this.activeStep[stepNum] = stepNum === step - 1;
    }
  }

  private completeStep(step: number): void {
    this.stepStates[step - 1] = StepState.Complete;
    this.activeStep[step - 1] = false;
    const last: number =
      this.stepStates[this.stepStates.length - 1] === StepState.Required
        ? this.activeStep.length
        : this.activeStep.length - 1;
    for (let nextStep: number = 1; nextStep < last; nextStep++) {
      if (this.stepStates[nextStep] !== StepState.Complete) {
        this.activeStep[nextStep] = true;
        this.disableStep[nextStep] = false;
        break;
      }
    }
  }

  private getCustomerById(): Observable<Customer> {
    return this.customerService.getCustomerById(this.customerId).pipe(
      catchError((err: any) => {
        throw new Error(
          'No known customer found. Are you using a stale bookmark or did you lose network connectivity ?'
        );
      })
    );
  }

  private getAllOffers(): Observable<any[]> {
    return this.icawsOfferingService.getAllOffers().pipe(
      catchError((err: any) => {
        throw new Error(
          'No offers were received. Did you lose network connectivity ?'
        );
      })
    );
  }
}
