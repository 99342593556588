// tslint:disable:max-line-length imports
import { Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { StepState } from '@covalent/core';

import {
  DATA_MOVER_DEFAULT_VALUES,
  STANDARD_DISK,
} from '../../models/azure-provisioning';
import { Customer } from '../../models/customer.model';
import { EcosystemAppName } from '../../models/ecosystem-app-name.enum';
import {
  AvailableDBConfigs,
  EcosystemOption,
  FeatureAttribute,
  FeatureDetail,
  FeatureProperty,
  NodeLimit,
  OfferDetails,
  ProductOffer,
  ProductTier,
  Version,
  VMConfig,
} from '../../models/product-offering.model';
import { IncNodeOption } from '../../models/scale-out-in-request.model';
import { Subscription } from '../../models/subscription.model';
import { WorkOrderCreationRequest } from '../../models/work-order-create.model';
import { CustomerService } from '../../services/customer.service';
import { NetworkCidrValidationService } from '../../services/form/network-cidr-validation.service';
import { ProductOffersService } from '../../services/product-offers.service';
import { SysOpsService } from '../../services/sys-ops.service';
import { WorkOrderService } from '../../services/work-order.service';
import { DBNodeCount } from './create-sites-card.component.string';
import {
  CreateSiteComponentViewModel,
  getCreateSiteCardViewModel,
} from './create-sites-card.view-model';
// tslint:enable:max-line-length end imports

@Component({
  selector: 'imc-azure-create-sites-card',
  templateUrl: './create-sites-card.component.html',
  styleUrls: ['./create-sites-card.component.scss'],
})
export class AzureCreateSitesCardComponent implements OnInit, OnDestroy {
  private readonly scaleOutDefault: string = '1x';
  private readonly nodeLimits: NodeLimit = {
    min: 0,
    max: 0,
  };
  private previousTierSelected: string = '';
  private scaleOutOptions: string[] = [];

  @Input() customerId: string;
  incNodeOptions: number[];
  customer: Customer;
  dbVersions: Version[] = [];
  ecosystemOptions: EcosystemOption[] = [];
  readonly ecosystemConfigurationMap: any = {
    'Data Mover': {
      instanceType: 'dataMoverVmSize',
      instanceCount: 'dataMoverInstanceCount',
    },
  };
  disableCreation: boolean = false;
  readonly ecosystemColumnsToDisplay: string[] = [
    'Feature',
    'InstanceType',
    'InstanceCount',
    'EnableOption',
  ];
  vm: CreateSiteComponentViewModel;
  ONE_HSN_DB_NODE_COUNT_LOWER_BOUND: number = 2;
  ONE_HSN_DB_NODE_COUNT_UPPER_BOUND: number = 8;
  TWO_HSN_DB_NODE_COUNT_LOWER_BOUND: number = 9;
  TWO_HSN_DB_NODE_COUNT_UPPER_BOUND: number = 24;
  THREE_HSN_DB_NODE_COUNT_LOWER_BOUND: number = 25;
  THREE_HSN_DB_NODE_COUNT_UPPER_BOUND: number = 64;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly productOffersService: ProductOffersService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly customerService: CustomerService,
    private readonly sysOpsService: SysOpsService,
    private readonly validationService: NetworkCidrValidationService,
    private readonly workOrderService: WorkOrderService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    readonly location: Location
  ) {}

  ngOnInit(): void {
    this.vm = getCreateSiteCardViewModel();
    const groups: { [name: string]: FormGroup } = {};
    for (const formGroupName of this.vm.content.formGroupDependencyOrder) {
      const formGroup: FormGroup = this.buildFormGroup(formGroupName);
      this.vm.state.subscriptions.push(
        formGroup.valueChanges.subscribe(() => {
          this.formGroupUpdated(formGroupName);
          this.changeDetector.detectChanges();
        })
      );

      groups[formGroupName] = formGroup;
    }
    this.vm.state.form = this.formBuilder.group(groups);

    this.vm.state.subscriptions.push(
      this.customerService
        .getCustomerById(this.customerId)
        .subscribe((customer: Customer) => {
          this.customer = customer;
          this.getSubscriptionNameIds(this.customer.customerId);
        })
    );
    this.vm.state.subscriptions.push(
      this.productOffersService
        .getProductOffers()
        .subscribe((productOffers: ProductOffer[]) => {
          this.vm.data.productOffers = productOffers;
          this.updateSelectOptions();

          this.vm.state.isReady = true;
        })
    );

    this.setDiskType(STANDARD_DISK);
  }

  ngOnDestroy(): void {
    this.vm.state.subscriptions.forEach((subscription: any) => {
      subscription.unsubscribe();
    });
  }

  submitForm(): void {
    if (!this.vm.state.form.valid) {
      return;
    }
    const form: any = {};
    // Flatten form data
    for (const formGroupName of this.vm.content.formGroupDependencyOrder) {
      Object.assign(form, this.vm.state.form.get(formGroupName).value);
    }
    Object.assign(form, { incNodeOptions: this.incNodeOptions });

    if (!form.subscription.isAssigned) {
      this.vm.state.subscriptions.push(
        this.sysOpsService
          .updateSubscription({
            subscriptionId: form.subscription.id,
            name: `${this.customer.name}_${form.region}_${form.purpose}`,
            customerId: this.customer.customerId,
            customerName: this.customer.name,
          })
          .subscribe(
            () => {
              return;
            },
            (error: any) => {
              this.snackBar.open(
                'The azure subscription was not updated successfully.',
                'OK'
              );
            }
          )
      );
    }

    if (form.hsnEnabled) {
      form.actualHsnNodeCount = 0;
      form.expectedHsnNodeCount = this.getExpectedHsnNodeCount(
        form.dbNodeCount
      );
    }

    form.dbVmConfig = this.findDbVmConfig(
      form.dbVmConfig,
      form.availableDbConfigs
    );
    form.dbSize = form.dbVmConfig.size;

    this.makeWorkOrderRequest(form, this.customer.customerId);
  }

  hasCidrValidationError(): any {
    return (
      (this.vm.state.form.get([
        'dbNodeConfiguration',
        this.vm.content.formControls.networkCidr.name,
      ]).dirty ||
        this.vm.state.form.get([
          'dbNodeConfiguration',
          this.vm.content.formControls.dbNodeCount.name,
        ]).dirty ||
        this.vm.state.form.get([
          'dbNodeConfiguration',
          this.vm.content.formControls.currentScaleOutState.name,
        ]).dirty) &&
      (this.vm.state.form
        .get('dbNodeConfiguration')
        .hasError('invalidNetworkCidrRange', [
          this.vm.content.formControls.networkCidr.name,
        ]) ||
        this.vm.state.form
          .get('dbNodeConfiguration')
          .hasError('invalidNetworkCidrRange', [
            this.vm.content.formControls.dbNodeCount.name,
          ]) ||
        this.vm.state.form
          .get('dbNodeConfiguration')
          .hasError('invalidNetworkCidrRange', [
            this.vm.content.formControls.currentScaleOutState.name,
          ]))
    );
  }

  isLoading(): boolean {
    return !this.vm.state.isReady || !this.vm.state.getSubscriptionsReady;
  }

  // This method is used to set the default value when the toggle is changed.
  toggleChangeSet(event: any): any {
    if (event.checked) {
      this.enableScaleOutSelected();
    } else {
      this.setScaleOutSelected(undefined);
      this.disableScaleOutSelected();
    }
  }

  ecosystemAppsChangeEvent(element: EcosystemOption, event: any): any {
    if (!element) {
      this.ecosystemOptions.forEach((ecoSystemOption: EcosystemOption) => {
        ecoSystemOption.isEnabled = event.checked ? true : false;
      });
    } else {
      element.isEnabled = event.checked ? true : false;
    }
  }

  // This method is used to reset the selected scale out to be the default value when the db node count is increased
  updateScaleOutSelected(dbNodeCountValue: any): void {
    const scaleOutSelected: string = this.getScaleOutSelected();
    if (
      (scaleOutSelected === '4x' && dbNodeCountValue > 16) ||
      ((scaleOutSelected === '4x' || scaleOutSelected === '2x') &&
        dbNodeCountValue > 32)
    ) {
      this.setScaleOutSelected(this.scaleOutDefault);
    }
  }

  isUnassignedSubscriptionSelected(): boolean {
    const regionVersionItem: AbstractControl = this.vm.state.form.get(
      'regionVersion'
    );
    if (
      regionVersionItem &&
      regionVersionItem.value &&
      regionVersionItem.value.subscription
    ) {
      return !regionVersionItem.value.subscription.isAssigned;
    }

    return false;
  }

  showNodesText(): boolean {
    const dbNodeCount: any = this.vm.state.form.get([
      'dbNodeConfiguration',
      'dbNodeCount',
    ]).value;
    if (
      dbNodeCount >= DBNodeCount.MIN_NODE_COUNT &&
      dbNodeCount <= DBNodeCount.MAX_NODE_COUNT
    ) {
      return true;
    } else {
      return false;
    }
  }

  isStepDisabled(step: number): boolean {
    for (let i = 0; i < step; i++) {
      if (this.vm.state.stepStates[i] !== StepState.Complete) {
        return true;
      }
    }

    return false;
  }

  completeStep(step: number): void {
    this.vm.state.stepStates[step] = StepState.Complete;
    this.setScaleOutSelected(this.scaleOutDefault);
    this.vm.state.active[step + 1] = true;
  }

  getProductTierFromProduct(tier: any): ProductTier {
    const productOffer: ProductOffer = this.getProductOffersBySelectedFields()[0];
    const productTier: ProductTier = productOffer.offerDetails.tiers.find(
      (t: ProductTier) => t.name === tier
    );

    if (this.previousTierSelected !== productTier.name) {
      this.previousTierSelected = productTier.name;

      this.selectEcosystemOptions(productTier);

      const databaseFeatureAttributes: FeatureAttribute[] = productTier.features
        .find(
          (feature: any) =>
            feature.category.toLowerCase() === 'database features'
        )
        .attributes.filter(
          (feature: any) =>
            feature.featureProperties !== undefined ||
            feature.featureProperties !== null
        );

      if (!databaseFeatureAttributes) {
        return undefined;
      }

      this.selectNodeLimits(databaseFeatureAttributes);
      this.selectElasticityOptions(databaseFeatureAttributes);
    }

    return productTier;
  }

  private buildFormGroup(formGroupName: string): FormGroup {
    const controls: any = {};

    for (const controlName in this.vm.content.formControls) {
      if (this.vm.content.formControls.hasOwnProperty(controlName)) {
        const control: any = this.vm.content.formControls[controlName];
        if (control.group === formGroupName) {
          controls[control.name] = new FormControl(
            control.default,
            control.validators
          );
        }
      }
    }

    const productOfferValidator: ValidatorFn = (
      fg: FormGroup
    ): { [key: string]: any } => {
      const selectedFields: [string, string][] = [];
      for (const controlName in fg.controls) {
        if (!fg.controls.hasOwnProperty(controlName)) {
          continue;
        }
        const control: AbstractControl = fg.controls[controlName];
        const mapping: string = this.vm.content.formControls[controlName]
          .productOfferMapping;
        if (mapping && control.value) {
          selectedFields.push([mapping, control.value]);
        }
      }
      if (
        this.productOffersService.filterOffersBySelectedFields(
          (fg as any).availableProductOffers,
          selectedFields
        ).length === 0
      ) {
        return {
          invalidProductOffer: {
            selectedFields,
          },
        };
      }
    };

    return new FormGroup(controls, productOfferValidator);
  }

  private makeWorkOrderRequest(form, customerId: string): void {
    const workOrderCreationRequest: WorkOrderCreationRequest = {
      workOrderType: 'provision',
      cloudPlatform: 'azure',
      productOffer: form.productOffer ? form.productOffer.toLowerCase() : null,
      dbSize: form.dbSize ? form.dbSize : null,
      storageSize: form.storageSize,
      baseDbNodeCount: form.dbNodeCount,
      siteId: form.siteId,
      systemName: form.systemName,
      networkCidr: form.networkCidr,
      securityAccessCidr: form.accessCidr,
      area: form.region ? form.region.toLowerCase() : null,
      customerId,
      productTier: form.productTier ? form.productTier.toLowerCase() : null,
      dbVersion: form.databaseVersion,
      dataMoverVmSize: form.dataMoverVmSize,
      dataMoverInstanceCount: form.dataMoverInstanceCount,
      productOfferId:
        form.selectedProductOffers && form.selectedProductOffers.length > 0
          ? form.selectedProductOffers[0].productOfferId
          : null,
      viewpointVmSize: form.viewpointVmSize ? form.viewpointVmSize : null,
      viewpointInstanceCount: form.viewpointInstanceCount
        ? form.viewpointInstanceCount
        : null,
      restServicesVmSize: form.restServicesVmSize
        ? form.restServicesVmSize
        : null,
      restServicesInstanceCount: form.restServicesInstanceCount
        ? form.restServicesInstanceCount
        : null,
      dataStreamUtilityVmSize: form.dataStreamUtilityVmSize
        ? form.dataStreamUtilityVmSize
        : null,
      dataStreamUtilityInstanceCount: form.dataStreamUtilityInstanceCount
        ? form.dataStreamUtilityInstanceCount
        : null,
      serverManagementVmSize: form.serverManagementVmSize
        ? form.serverManagementVmSize
        : null,
      serverManagementInstanceCount: form.serverManagementInstanceCount
        ? form.serverManagementInstanceCount
        : null,
      availableDbConfigs:
        form.availableDbConfigs && form.availableDbConfigs.length > 0
          ? form.availableDbConfigs
          : null,
      availableDataMoverConfigs:
        form.availableDataMoverConfigs &&
        form.availableDataMoverConfigs.length > 0
          ? form.availableDataMoverConfigs
          : null,
      azureSubscriptionId: form.subscription.id,
      dbNodeCount:
        form.currentScaleOutState === undefined ? 0 : form.currentScaleOutState,
      incNodeOptions: form.incNodeOptions,
      hsnEnabled: form.hsnEnabled,
      purpose: form.purpose,
      timezone: form.timezone,
      temporal: form.temporal ? 'Yes' : 'No',
      tim: form.tim ? 'Yes' : 'No',
      storageType: form.storageType,
      deployDataMover: form.deployDataMover ? 'Yes' : 'No',
      deployDsu: form.deployDsu ? 'Yes' : 'No',
      deployRestServices: form.deployRestServices ? 'Yes' : 'No',
      deployViewpoint: form.deployViewpoint ? 'Yes' : 'No',
      deployServerManagement: form.deployServerManagement ? 'Yes' : 'No',
      actualHsnNodeCount: form.actualHsnNodeCount,
      expectedHsnNodeCount: form.expectedHsnNodeCount,
      dbVmConfig: form.dbVmConfig,
    };

    this.disableCreation = true;
    this.vm.state.subscriptions.push(
      this.workOrderService.postWorkOrder(workOrderCreationRequest).subscribe(
        (response) => {
          this.disableCreation = false;
          this.router.navigate(['customer-dashboard', this.customerId]);
        },
        (error) => {
          this.disableCreation = false;
          this.snackBar.open(
            error.json().message || 'System error occurred. Please try again.',
            'Ok'
          );
        }
      )
    );
  }

  private formGroupUpdated(formGroupName: string): void {
    const fgList: string[] = this.vm.content.formGroupDependencyOrder;
    const formGroupNumber: number = fgList.indexOf(formGroupName);

    this.vm.state.stepStates.fill(StepState.None, formGroupNumber + 1);
    this.vm.state.active.fill(false, formGroupNumber + 1);

    // The final FormGroup won't trigger changes elsewhere in the form.
    if (formGroupNumber < fgList.length) {
      this.updateSelectOptions();
    }

    // The final stepper step is set to complete if the form is valid.
    if (this.vm.state.form.get(fgList[fgList.length - 1]).valid) {
      this.vm.state.stepStates[this.vm.state.stepStates.length - 1] =
        StepState.Complete;
    }
  }

  // Each Product Offer driven control in the form group needs to be updated to reflect limitations imposed by
  // previously selected fields.
  private updateSelectOptions(): void {
    const productOffers: ProductOffer[] = this.getProductOffersBySelectedFields();

    const offerDetails: OfferDetails[] = productOffers.map(
      (offer: ProductOffer) => offer.offerDetails
    );
    this.vm.content.formControls.region.valuesList = this.getPropertyOptions(
      offerDetails,
      'regions'
    ).sort();
    this.vm.content.formControls.storageSize.valuesList = this.getPropertyOptions(
      offerDetails,
      'storageSizes'
    );

    this.dbVersions = [].concat.apply(
      [],
      offerDetails.map((details: OfferDetails) => details.dbVersions)
    );

    const tiers: any = [].concat.apply(
      [],
      offerDetails.map((details: OfferDetails) => details.tiers)
    );
    this.vm.content.formControls.productTier.valuesList = tiers.map(
      (tier: any) => tier.name
    );

    const vmConfigs: any = [].concat.apply(
      [],
      offerDetails.map((offerDetail: OfferDetails) => offerDetail.vmConfigs)
    );
    const dbSizes: any = [].concat.apply(
      vmConfigs.map((vmConfig: any) => Object.keys(vmConfig).toString())
    );
    this.vm.content.formControls.dbSize.valuesList = dbSizes;

    this.vm.content.formControls.availableDbConfigs.valuesList = vmConfigs;
    this.vm.content.formControls.dbVmConfig.valuesList = [];

    this.vm.content.formControls.purpose.valuesList = [].concat
      .apply(
        [],
        offerDetails.map(
          (offerDetail: OfferDetails) => offerDetail.purposeOptions
        )
      )
      .sort();
    this.vm.content.formControls.timezone.valuesList = [].concat
      .apply(
        [],
        offerDetails.map((offerDetail: OfferDetails) => offerDetail.timezones)
      )
      .sort();
    this.vm.content.formControls.storageType.valuesList = [].concat
      .apply(
        [],
        offerDetails.map(
          (offerDetail: OfferDetails) => offerDetail.storageTypes
        )
      )
      .sort();
    this.updateValidation();
  }

  private getProductOffersBySelectedFields(): ProductOffer[] {
    let orderedFormGroupsToUpdate: string[] = this.vm.content
      .formGroupDependencyOrder;
    let productOffers: ProductOffer[] = this.vm.data.productOffers;
    while (orderedFormGroupsToUpdate.length > 0) {
      // Add a custom property to the form group to track the allowed products at this stage for form validation
      (this.vm.state.form.get([
        orderedFormGroupsToUpdate[0],
      ]) as any).availableProductOffers = productOffers;

      const selectedFields: [string, string | number][] = [];

      for (const controlName in this.vm.content.formControls) {
        if (!this.vm.content.formControls.hasOwnProperty(controlName)) {
          continue;
        }
        const control: any = this.vm.content.formControls[controlName];
        if (
          control.group !== orderedFormGroupsToUpdate[0] ||
          !control.productOfferMapping
        ) {
          continue;
        }

        control.valuesList = this.productOffersService.getFieldOptions(
          productOffers,
          control.productOfferMapping
        );

        const formField: AbstractControl = this.vm.state.form.get([
          orderedFormGroupsToUpdate[0],
          control.name,
        ]);

        if (formField && formField.value) {
          selectedFields.push([control.productOfferMapping, formField.value]);
        }
      }
      productOffers = this.productOffersService.filterOffersBySelectedFields(
        productOffers,
        selectedFields
      );

      if (orderedFormGroupsToUpdate.length === 1) {
        // Update hidden control which keeps track of selected ProductOffers from customer selections
        const configurationControl: any = this.vm.state.form.get([
          orderedFormGroupsToUpdate[0],
        ]) as any;
        configurationControl.value.selectedProductOffers = productOffers;
        this.setEcosystemAppsHiddenFormFields();
        this.setAvailableDbConfigsHiddenFormFields();
      }
      orderedFormGroupsToUpdate = orderedFormGroupsToUpdate.slice(1);
    }

    return productOffers;
  }

  private setEcosystemAppsHiddenFormFields(): void {
    const ecosystemConfigurationControl: any = this.vm.state.form.get(
      'ecosystemConfiguration'
    ).value;
    const availableDataMoverConfigs: string[] = [];
    for (const option of this.ecosystemOptions) {
      switch (option.name) {
        case EcosystemAppName.Viewpoint: {
          ecosystemConfigurationControl.viewpointVmSize =
            option.instanceTypes[0].value;
          ecosystemConfigurationControl.viewpointInstanceCount =
            option.instanceCount[0].value;
          ecosystemConfigurationControl.deployViewpoint = option.isEnabled;
          break;
        }
        case EcosystemAppName.RestServices: {
          ecosystemConfigurationControl.restServicesVmSize =
            option.instanceTypes[0].value;
          ecosystemConfigurationControl.restServicesInstanceCount =
            option.instanceCount[0].value;
          ecosystemConfigurationControl.deployRestServices = option.isEnabled;
          break;
        }
        case EcosystemAppName.DataStreamUtility: {
          ecosystemConfigurationControl.dataStreamUtilityVmSize =
            option.instanceTypes[0].value;
          ecosystemConfigurationControl.dataStreamUtilityInstanceCount =
            option.instanceCount[0].value;
          ecosystemConfigurationControl.deployDsu = option.isEnabled;
          break;
        }
        case EcosystemAppName.DataMover: {
          for (const instanceType of option.instanceTypes) {
            availableDataMoverConfigs.push(instanceType.value);
          }
          ecosystemConfigurationControl.availableDataMoverConfigs = availableDataMoverConfigs;
          ecosystemConfigurationControl.deployDataMover = option.isEnabled;
          break;
        }
        case EcosystemAppName.ServerManagement: {
          ecosystemConfigurationControl.serverManagementVmSize =
            option.instanceTypes[0].value;
          ecosystemConfigurationControl.serverManagementInstanceCount =
            option.instanceCount[0].value;
          ecosystemConfigurationControl.deployServerManagement =
            option.isEnabled;
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  private setAvailableDbConfigsHiddenFormFields(): void {
    const dbNodeConfigurationControl: any = this.vm.state.form.get(
      'dbNodeConfiguration'
    ).value;
    const availableDbConfigs: VMConfig[] = [];
    if (
      dbNodeConfigurationControl.selectedProductOffers &&
      dbNodeConfigurationControl.selectedProductOffers.length > 0 &&
      dbNodeConfigurationControl.selectedProductOffers[0].offerDetails &&
      dbNodeConfigurationControl.selectedProductOffers[0].offerDetails.vmConfigs
    ) {
      for (const vmConfig of dbNodeConfigurationControl.selectedProductOffers[0]
        .offerDetails.vmConfigs) {
        availableDbConfigs.push(vmConfig);
      }
      dbNodeConfigurationControl.availableDbConfigs = availableDbConfigs;
    }
  }

  // refresh ecosystemOptions based on the selected productTier
  // Adding ecosystem included options for vmsize and instance counts
  private selectEcosystemOptions(productTier: ProductTier): void {
    this.setDefaultDataMoverInstance();
    this.ecosystemOptions = [];
    const ecosystemfeatures: FeatureAttribute[] = productTier.features
      .find(
        (featureCategory: FeatureDetail) =>
          featureCategory.category.toLowerCase() === 'ecosystem applications'
      )
      .attributes.filter(
        (feature: FeatureAttribute) => feature.featureProperties
      );
    this.productOffersService.AddEcosystemBaseAndUpgradeOptions(
      ecosystemfeatures,
      this.ecosystemOptions
    );
    // Adding ecosystem upgrade options for vmsize and instance counts
    const upgradeOptions: FeatureDetail = productTier.features.find(
      (featureCategory: FeatureDetail) =>
        featureCategory.category.toLowerCase() === 'upgrade options'
    );
    if (upgradeOptions) {
      const upgradeOptionsFeatures: FeatureAttribute[] = upgradeOptions.attributes.filter(
        (feature: FeatureAttribute) => feature.featureProperties
      );
      this.productOffersService.AddEcosystemBaseAndUpgradeOptions(
        upgradeOptionsFeatures,
        this.ecosystemOptions
      );
    }

    this.ecosystemOptions.forEach(
      (ecoOption: EcosystemOption) => (ecoOption.isEnabled = false)
    );
  }

  private selectNodeLimits(
    databaseFeatureAttributes: FeatureAttribute[]
  ): void {
    const nodeLimitProperty: FeatureProperty = databaseFeatureAttributes
      .find(
        (fa: FeatureAttribute) => fa.featureName.toLowerCase() === 'scalability'
      )
      .featureProperties.find(
        (fp: FeatureProperty) =>
          fp.featurePropertyName.toLowerCase() === 'node limit'
      );

    if (nodeLimitProperty && nodeLimitProperty.featurePropertyValues) {
      const nodeLimitArray: string[] = nodeLimitProperty.featurePropertyValues[0].value.split(
        '-'
      );
      if (nodeLimitArray && nodeLimitArray.length === 2) {
        this.nodeLimits.min = parseInt(nodeLimitArray[0], 10);
        this.nodeLimits.max = parseInt(nodeLimitArray[1], 10);
      }
    }
  }

  private selectElasticityOptions(
    databaseFeatureAttributes: FeatureAttribute[]
  ): void {
    const scaleOutProperty: FeatureProperty = databaseFeatureAttributes
      .find(
        (fa: FeatureAttribute) => fa.featureName.toLowerCase() === 'elasticity'
      )
      .featureProperties.find(
        (fp: FeatureProperty) =>
          fp.featurePropertyName.toLowerCase() === 'scale out/in'
      );

    if (!scaleOutProperty || !scaleOutProperty.featurePropertyValues) {
      return;
    }
    this.scaleOutOptions = [];
    scaleOutProperty.featurePropertyValues.forEach((val) =>
      this.scaleOutOptions.push(val.value)
    );
  }

  private getPropertyOptions(objects: any, property: string): string[] {
    const options: Set<string> = new Set<string>();
    for (const index in objects) {
      if (Array.isArray(objects[index][property])) {
        objects[index][property].forEach((arrayElement: any) => {
          options.add(arrayElement);
        });
      } else {
        options.add(objects[index][property]);
      }
    }

    const opts: string[] = [];
    options.forEach((option: string) => opts.push(option));

    return opts;
  }

  private getSubscriptionNameIds(customerId: string): void {
    this.vm.state.subscriptions.push(
      this.sysOpsService
        .getSubscriptions()
        .subscribe((subscriptions: Subscription[]) => {
          for (const subscription of subscriptions) {
            if (subscription.isActivated) {
              const valueObj: any = {
                name: subscription.name,
                id: subscription.subscriptionId,
                isAssigned: subscription.customerId ? true : false,
              };
              if (subscription.customerId === customerId) {
                this.vm.content.formControls.subscription.valuesList.unshift(
                  valueObj
                );
              } else if (!subscription.customerId) {
                this.vm.content.formControls.subscription.valuesList.push(
                  valueObj
                );
              }
            }
          }
          this.vm.state.getSubscriptionsReady = true;
        })
    );
  }

  private updateValidation(): void {
    this.vm.state.form
      .get(['dbNodeConfiguration', 'siteId'])
      .setValidators([
        ...this.vm.content.formControls.siteId.validators,
        Validators.required,
      ]);
    this.vm.state.form
      .get(['dbNodeConfiguration', 'dbNodeCount'])
      .setValidators([
        ...this.vm.content.formControls.dbNodeCount.validators,
        Validators.max(this.nodeLimits.max),
        Validators.min(this.nodeLimits.min),
      ]);
    this.vm.state.form
      .get(['dbNodeConfiguration', 'networkCidr'])
      .setValidators([
        ...this.vm.content.formControls.networkCidr.validators,
        this.validationService.networkCidrValidateRange(
          this.scaleOutStateList()
        ),
      ]);
    this.vm.state.form
      .get(['dbNodeConfiguration', 'currentScaleOutState'])
      .setValidators([
        ...this.vm.content.formControls.currentScaleOutState.validators,
        this.validationService.networkCidrValidateRange(
          this.scaleOutStateList()
        ),
      ]);
    this.vm.state.form
      .get(['dbNodeConfiguration', 'accessCidr'])
      .setValidators([]);
    this.vm.state.form
      .get(['regionVersion', 'subscription'])
      .setValidators([
        ...this.vm.content.formControls.subscription.validators,
        Validators.required,
      ]);
  }

  // This method will return the scaleOutOptions array based on the DB Node count.
  private scaleOutStateList(): number[] {
    const dbNodeCountValue: any = this.vm.state.form.get([
      'dbNodeConfiguration',
      'dbNodeCount',
    ]).value;

    let pools: number = DBNodeCount.MAX_SUBPOOLS_PER_NODE;
    const incScaleOptions: number[] = [];
    const totalPools: number = pools * dbNodeCountValue;
    let dbNodeCount: number = dbNodeCountValue;

    // if the initial db node count value > 64, dont perform any operation
    if (
      dbNodeCountValue !== null &&
      dbNodeCountValue >= DBNodeCount.MIN_NODE_COUNT &&
      dbNodeCountValue <= DBNodeCount.MAX_NODE_COUNT
    ) {
      incScaleOptions.push(dbNodeCount);
      while (pools > 1) {
        const option: IncNodeOption = this.findNextIncScaleOutOption(
          pools,
          totalPools
        );

        // new incremental scale out node options should be less than 4x of dbNodeCount and less than 64
        if (
          option !== null &&
          (option.nodes <=
            DBNodeCount.MAX_SUPPORTED_SCALE_FACTOR * dbNodeCountValue &&
            option.nodes <= DBNodeCount.MAX_NODE_COUNT)
        ) {
          incScaleOptions.push(option.nodes);
          dbNodeCount = option.nodes;
          pools = option.pools;
        } else {
          break;
        }
      }
    }

    this.incNodeOptions = incScaleOptions;

    return incScaleOptions;
  }

  // This method will calculate the next unfold option based on the base db node count
  //       Input: Current nodes & current pools to calculate the next unfold option
  //              totalPools argument is for unfolds after the first where the total pools in the system may be
  //              fewer than that indicated by the product of nodes and pools per node
  //       Output: Next unfold node and pool options
  private findNextIncScaleOutOption(
    pools: number,
    totalPools: number
  ): IncNodeOption {
    // start with one less pool per node
    let newPools: number = pools - 1;

    if (newPools < 1) {
      return null;
    }
    // find out how many nodes are required
    let newNodes: number = (totalPools + newPools - 1) / newPools;
    newNodes = Math.floor(newNodes);

    // find out fewest pools required
    newPools = (totalPools + newNodes - 1) / newNodes;

    return { nodes: newNodes, pools: Math.floor(newPools) };
  }

  private getExpectedHsnNodeCount(dbNodeCount: number): number {
    switch (true) {
      case dbNodeCount >= this.ONE_HSN_DB_NODE_COUNT_LOWER_BOUND &&
        dbNodeCount <= this.ONE_HSN_DB_NODE_COUNT_UPPER_BOUND:
        return 1;
      case dbNodeCount >= this.TWO_HSN_DB_NODE_COUNT_LOWER_BOUND &&
        dbNodeCount <= this.TWO_HSN_DB_NODE_COUNT_UPPER_BOUND:
        return 2;
      case dbNodeCount >= this.THREE_HSN_DB_NODE_COUNT_LOWER_BOUND &&
        dbNodeCount <= this.THREE_HSN_DB_NODE_COUNT_UPPER_BOUND:
        return 4;
      default:
        throw new Error(
          `dbNodeCount ${dbNodeCount} is out of range while calculating expected HSN node count`
        );
    }
  }

  private findDbVmConfig(
    dbVmConfig: string,
    availableDBConfigs: AvailableDBConfigs[]
  ): VMConfig {
    let vmConfig: VMConfig;
    if (dbVmConfig && availableDBConfigs) {
      availableDBConfigs.forEach((dbConfigs: AvailableDBConfigs) => {
        Object.keys(dbConfigs).forEach((key: string) => {
          dbConfigs[key].forEach((config: VMConfig) => {
            if (config.name === dbVmConfig) {
              vmConfig = {
                name: dbVmConfig,
                size: key,
                cpus: config.cpus,
                tcores: config.tcores,
                io: config.io,
                memory: config.memory,
                sku: config.sku,
              };
            }
          });
        });
      });
    }

    return vmConfig;
  }

  private setDiskType(value: string): void {
    this.getFormControl(
      'dbNodeConfiguration',
      this.vm.content.formControls.storageType.name
    ).setValue(value);
  }

  private enableScaleOutSelected(): void {
    const formControl: FormControl = this.getScaleOutSelectedControl();
    if (formControl.disabled) {
      formControl.enable();
    }
  }

  private disableScaleOutSelected(): void {
    const formControl: FormControl = this.getScaleOutSelectedControl();
    if (formControl.enabled) {
      formControl.disable();
    }
  }

  private setScaleOutSelected(value: string): void {
    this.getScaleOutSelectedControl().setValue(value);
  }

  private getScaleOutSelectedControl(): FormControl {
    return this.getFormControl(
      'dbNodeConfiguration',
      this.vm.content.formControls.currentScaleOutState.name
    );
  }

  private getScaleOutSelected(): string {
    return this.getFormControl(
      'dbNodeConfiguration',
      this.vm.content.formControls.currentScaleOutState.name
    ).value;
  }

  private setDefaultDataMoverInstance(): void {
    this.getFormControl(
      'ecosystemConfiguration',
      this.ecosystemConfigurationMap['Data Mover'].instanceCount
    ).setValue(DATA_MOVER_DEFAULT_VALUES.INSTANCE_COUNT);
    this.getFormControl(
      'ecosystemConfiguration',
      this.ecosystemConfigurationMap['Data Mover'].instanceType
    ).setValue(DATA_MOVER_DEFAULT_VALUES.INSTANCE_TYPE);
  }

  private getFormControl(
    formGroupName: string,
    formControlName: string
  ): FormControl {
    const formGroup: FormGroup = <FormGroup>(
      this.vm.state.form.get([formGroupName])
    );

    return <FormControl>formGroup.get(formControlName);
  }
}
