import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImcMaterialModule } from '@imc/core';

import { RouterModule } from '@angular/router';
import { CovalentJsonFormatterModule } from '@covalent/core';
import { TranslateModule } from '@ngx-translate/core';
import { SlaReportComponent } from './cards/sla-report/sla-report.component';
import { SlaReportsComponent } from './pages/sla-reports/sla-reports.component';
import { AvailabilityService } from './services/availability.service';
import { SharedModule } from '@imc/shared/shared.module';

@NgModule({
  declarations: [SlaReportComponent, SlaReportsComponent],
  imports: [
    CommonModule,
    ImcMaterialModule,
    CovalentJsonFormatterModule,
    RouterModule,
    SharedModule,
    TranslateModule,
  ],
  providers: [AvailabilityService],
})
export class AvailabilitiesModule {}
