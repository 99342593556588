import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TdMediaService } from '@covalent/core';
import { Subscription } from 'rxjs';

import { MatSort, MatTableDataSource } from '@angular/material';
import { Customer } from '@imc-features/dashboard/models/customer.model';
import { DashboardCustomerService } from '@imc-features/dashboard/services/dashboard-customer.service';

@Component({
  selector: 'imc-list-customers',
  templateUrl: './list-customers.component.html',
  styleUrls: ['./list-customers.component.scss'],
})
export class ListCustomersComponent implements OnInit, OnDestroy {
  private customersSubscription: Subscription;
  public isLoadingActive: boolean = true;
  public displayedColumns: string[] = ['name', 'cloudId'];
  public customers: Customer[] = [];
  dataSource: MatTableDataSource<Customer>;
  isError: boolean = false;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private readonly customersService: DashboardCustomerService,
    private readonly router: Router,
    public media: TdMediaService
  ) {}

  public ngOnInit(): void {
    this.customersSubscription = this.customersService
      .listCustomers()
      .subscribe(
        (customers: Customer[]) => {
          this.dataSource = new MatTableDataSource(customers);
          this.dataSource.sort = this.sort;
          this.customers = customers;
        },
        (error: any) => {
          this.isError = true;
        },
        () => {
          this.isLoadingActive = false;
        }
      );
  }

  public ngOnDestroy(): void {
    if (this.customersSubscription) {
      this.customersSubscription.unsubscribe();
    }
  }

  public viewCustomerDashboard(customer: Customer): void {
    this.router.navigate([`customer-dashboard/${customer.customerId}`]);
  }

  showCustomerDashboard(customerId: string): void {
    this.router.navigate([`customer-dashboard/${customerId}`]);
  }

  applyFilter(filterValue: string): void {
    if (filterValue.trim().length === 0) {
      return;
    }
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  removeFilters(): void {
    this.dataSource.filter = '';
  }
}
