import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { Site } from '@imc/core/models/sites.model';
import { LegacyCorrelationObjectService } from '@imc/shared/utilities/legacy-correlation-object-service';

import { SiteService } from '../../ecosystems-shared/services/site-service/ecosystems-site.service';
import { DbOperation } from '../constants/db-operations.constants';
import { HsnOperation } from '../constants/hsn-status.constants';
import { AzureInstanceInfo } from '../models/azure-instance-info.model';

@Injectable()
export class AzureService {
  constructor(
    private readonly apollo: Apollo,
    private readonly siteService: SiteService,
    private readonly correlationObjectService: LegacyCorrelationObjectService
  ) {}

  public postDatabaseSiteStart(site: Site): Observable<any> {
    const mutation: any = gql`
      mutation postDatabaseStart($workOrderBody: string) {
        databaseStart(input: $workOrderBody)
          @rest(
            type: "Boolean"
            path: "/management-console/workorders"
            method: "POST"
          ) {
          message
        }
      }
    `;

    const variables: any = {
      workOrderBody: {
        workOrderType: DbOperation.DbStart,
        siteId: site.supportSiteId,
      },
    };

    const context: any = { headers: {} };
    context.headers[
      'correlation-object'
    ] = this.correlationObjectService.createDeprecatedCorrelationObject();

    return this.apollo.mutate({ mutation, variables, context }).pipe(
      map((responseBody: any) => {
        if (responseBody.errorCode) {
          throw new Error(responseBody.message);
        }

        return (
          responseBody && responseBody.data && responseBody.data.databaseStart
        );
      }),
      first()
    );
  }

  public postDatabaseSiteStop(site: Site): Observable<any> {
    const mutation: any = gql`
      mutation postDatabaseStop($workOrderBody: string) {
        databaseStop(input: $workOrderBody)
          @rest(
            type: "Boolean"
            path: "/management-console/workorders"
            method: "POST"
          ) {
          message
        }
      }
    `;

    const variables: any = {
      workOrderBody: {
        workOrderType: DbOperation.DbStop,
        siteId: site.supportSiteId,
      },
    };

    const context: any = { headers: {} };
    context.headers[
      'correlation-object'
    ] = this.correlationObjectService.createDeprecatedCorrelationObject();

    return this.apollo.mutate({ mutation, variables, context }).pipe(
      map((responseBody: any) => {
        if (responseBody.errorCode) {
          throw new Error(responseBody.message);
        }

        return (
          responseBody && responseBody.data && responseBody.data.databaseStop
        );
      }),
      first()
    );
  }

  public postDatabaseSiteResize(
    site: Site,
    vmConfig: AzureInstanceInfo
  ): Observable<any> {
    const mutation: any = gql`
      mutation postDatabaseSiteResize($workOrderBody: string) {
        databaseSiteResize(input: $workOrderBody)
          @rest(
            type: "Boolean"
            path: "/management-console/workorders"
            method: "POST"
          ) {
          message
        }
      }
    `;

    const variables: any = {
      workOrderBody: {
        workOrderType: DbOperation.DbStop,
        siteId: site.supportSiteId,
        dbVmConfig: {
          size: vmConfig.size,
          tcores: vmConfig.tcores,
          cpus: vmConfig.cpus,
          io: vmConfig.io,
          sku: vmConfig.sku,
          name: vmConfig.name,
          memory: vmConfig.memory,
        },
      },
    };

    const context: any = { headers: {} };
    context.headers[
      'correlation-object'
    ] = this.correlationObjectService.createDeprecatedCorrelationObject();

    return this.apollo.mutate({ mutation, variables, context }).pipe(
      map((responseBody: any) => {
        if (responseBody.errorCode) {
          throw new Error(responseBody.message);
        }

        return (
          responseBody &&
          responseBody.data &&
          responseBody.data.databaseSiteResize
        );
      }),
      first()
    );
  }

  public postDatabaseSiteScale(
    site: Site,
    scaleTargetValue: number,
    scaleType: DbOperation
  ): Observable<any> {
    const mutation: any = gql`
      mutation postAzureDatabaseSiteScale($workOrderBody: string) {
        databaseSiteScale(input: $workOrderBody)
          @rest(
            type: "Boolean"
            path: "/management-console/workorders"
            method: "POST"
          ) {
          message
        }
      }
    `;

    const variables: any = {
      workOrderBody: {
        workOrderType: scaleType,
        siteId: site.supportSiteId,
        dbNodeCount: scaleTargetValue,
      },
    };

    const context: any = { headers: {} };
    context.headers[
      'correlation-object'
    ] = this.correlationObjectService.createDeprecatedCorrelationObject();

    return this.apollo.mutate({ mutation, variables, context }).pipe(
      map((responseBody: any) => {
        if (responseBody.errorCode) {
          throw new Error(responseBody.message);
        }

        return (
          responseBody &&
          responseBody.data &&
          responseBody.data.databaseSiteScale
        );
      }),
      first()
    );
  }

  public postEnableScaleOutIn(site: Site): Observable<any> {
    if (!site.sqleApp) {
      throw new Error('Site is missing SQLE App.');
    }
    if (
      site.sqleApp.nodeCount === null ||
      site.sqleApp.nodeCount === undefined
    ) {
      throw new Error('SQLE app is missing node count.');
    }
    const mutation: any = gql`
      mutation postEnableScaleOutIn($workOrderBody: string) {
        databaseEnableSiteScale(input: $workOrderBody)
          @rest(
            type: "Boolean"
            path: "/management-console/workorders"
            method: "POST"
          ) {
          message
        }
      }
    `;

    const variables: any = {
      workOrderBody: {
        workOrderType: DbOperation.EnableScaleOutIn,
        siteId: site.supportSiteId,
        dbNodeCount: site.sqleApp.nodeCount * 4,
      },
    };

    const context: any = { headers: {} };
    context.headers[
      'correlation-object'
    ] = this.correlationObjectService.createDeprecatedCorrelationObject();

    return this.apollo.mutate({ mutation, variables, context }).pipe(
      map((responseBody: any) => {
        if (responseBody.errorCode) {
          throw new Error(responseBody.message);
        }

        return (
          responseBody &&
          responseBody.data &&
          responseBody.data.databaseEnableSiteScale
        );
      }),
      first()
    );
  }

  public postHsnOperation(
    site: Site,
    hsnOperation: HsnOperation
  ): Observable<any> {
    if (!site.sqleApp) {
      throw new Error('Site is missing SQLE App.');
    }
    if (
      site.sqleApp.expectedHsnNodeCount === null ||
      site.sqleApp.expectedHsnNodeCount === undefined
    ) {
      throw new Error('SQLE app is missing expected HSN node count.');
    }
    const mutation: any = gql`
      mutation postHsnOperation($workOrderBody: string) {
        databaseHsnOperation(input: $workOrderBody)
          @rest(
            type: "Boolean"
            path: "/management-console/workorders"
            method: "POST"
          ) {
          message
        }
      }
    `;

    const variables: any = {
      workOrderBody: {
        workOrderType: hsnOperation,
        siteId: site.supportSiteId,
      },
    };

    if (
      hsnOperation === HsnOperation.Launch ||
      hsnOperation === HsnOperation.Delete
    ) {
      Object.assign(variables.workOrderBody, {
        hsnNodeCount: site.sqleApp.expectedHsnNodeCount,
      });
    }

    const context: any = { headers: {} };
    context.headers[
      'correlation-object'
    ] = this.correlationObjectService.createDeprecatedCorrelationObject();

    return this.apollo.mutate({ mutation, variables, context }).pipe(
      map((responseBody: any) => {
        if (responseBody.errorCode) {
          throw new Error(responseBody.message);
        }

        return (
          responseBody &&
          responseBody.data &&
          responseBody.data.databaseHsnOperation
        );
      }),
      first()
    );
  }

  public getDbVmConfig(site: Site, configName: string): AzureInstanceInfo {
    if (!site.sqleApp) {
      throw new Error('Site is missing SQLE App.');
    }
    let vmConfig: AzureInstanceInfo;
    const availableDbConfigs: any = site.sqleApp.availableDbConfigs;
    if (availableDbConfigs) {
      availableDbConfigs.forEach((dbConfigs: any) => {
        Object.keys(dbConfigs).forEach((key: string) => {
          dbConfigs[key].forEach((config: any) => {
            if (config.name === configName) {
              vmConfig = {
                name: configName,
                size: key,
                cpus: config.cpus,
                tcores: config.tcores,
                io: config.io,
                memory: config.memory,
                sku: config.sku,
              };
            }
          });
        });
      });
    }

    return vmConfig;
  }
}
