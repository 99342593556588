import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(
    duration: number,
    formatString?: 'full' | 'short',
    unitFormat?: 'shortUnits' | 'longUnits',
    fallback?: string
  ): string {
    const timeParts = this.getTotalTimeParts(duration, unitFormat);

    // Remove leading zeros
    let timePartsCleaned = [];
    timeParts.forEach((part, index) => {
      if (Math.round(part.value)) {
        timePartsCleaned.push(part);
      }
    });

    if (formatString === 'short') {
      timePartsCleaned = timePartsCleaned.slice(0, 2);
    }

    // Add suffix
    const result = [];
    timePartsCleaned.forEach((part, index) => {
      if (index === timePartsCleaned.length - 1) {
        const value = Math.round(part.value);
        let suffix = part.suffix;

        if (unitFormat === 'longUnits') {
          suffix = this.pluralizeIfGreaterThanOne(value, suffix);
        }

        result.push(value + suffix);
      } else {
        const value = Math.floor(part.value);

        if (value) {
          let suffix = part.suffix;

          if (unitFormat === 'longUnits') {
            suffix = this.pluralizeIfGreaterThanOne(value, suffix);
          }

          result.push(value + suffix);
        }
      }
    });

    if (result.length) {
      return result.join(' ');
    }
    return fallback;
  }

  getTotalTimeParts(duration: number, unitFormat: string) {
    const totalDays = Math.floor(duration / (24 * 60 * 60 * 1000));
    const days = duration % (24 * 60 * 60 * 1000);
    const totalHours = Math.floor(days / (60 * 60 * 1000));
    const hours = duration % (60 * 60 * 1000);
    const totalMinutes = Math.floor(hours / (60 * 1000));
    let daySuffix = 'd';
    let hourSuffix = 'h';
    let minuteSuffix = 'm';

    if (unitFormat === 'longUnits') {
      daySuffix = ' day';
      hourSuffix = ' hour';
      minuteSuffix = ' minute';
    }

    return [
      { value: totalDays, suffix: daySuffix },
      { value: totalHours, suffix: hourSuffix },
      { value: totalMinutes, suffix: minuteSuffix },
    ];
  }

  private pluralizeIfGreaterThanOne(value: number, output: string): string {
    if (value > 1) {
      return (output += 's');
    }
    return output;
  }
}
