import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';

import { AppConfigService } from '@imc/core/services';
import { PolicyService } from '@imc/core/services/policy-service/policy.service';
import { AvailabilityService } from '../../services/availability.service';

@Component({
  selector: 'imc-sla-report-component',
  templateUrl: './sla-report.component.html',
  styleUrls: ['./sla-report.component.scss'],
})
export class SlaReportComponent implements OnChanges, OnDestroy {
  private availabilitySubscription: Subscription;
  private policySubscription: Subscription;
  isCustomer: boolean = false;
  displayedColumns: string[] = [
    'snIncidentUrls',
    'outageDurationMS',
    'startTime',
    'endTime',
    'summary',
  ];
  outages: any = [];
  @Input() report: any;
  @Output() readonly published: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private readonly policyService: PolicyService,
    private readonly availabilityService: AvailabilityService,
    private readonly appConfig: AppConfigService
  ) {
    this.policySubscription = policyService.isCustomer$.subscribe(
      (isCustomer: boolean) => (this.isCustomer = isCustomer)
    );
  }

  ngOnChanges(): void {
    this.refresh();
  }

  ngOnDestroy(): void {
    if (this.availabilitySubscription) {
      this.availabilitySubscription.unsubscribe();
    }

    if (this.policySubscription) {
      this.policySubscription.unsubscribe();
    }
  }

  public publish(): void {
    this.availabilitySubscription = this.availabilityService
      .publishReport(this.report)
      .subscribe((report: any) => {
        this.report.published = report.published;
        this.report.publishedDate = report.publishedDate;
        this.report.publisherUserId = report.publisherUserId;
        this.published.emit(report);
        this.refresh();
      });
  }

  public millisecondsToHMmSs(timeMilliseconds: number): string {
    if (
      timeMilliseconds === null ||
      timeMilliseconds === undefined ||
      timeMilliseconds <= 0
    ) {
      return '0:00:00';
    }
    const timeInSec: number = Math.floor(timeMilliseconds / 1000) % 60;
    const timeInMin: number = Math.floor(timeMilliseconds / 60 / 1000) % 60;
    const timeInHr: number = Math.floor(timeMilliseconds / 3600 / 1000);

    return `${timeInHr}:${`0${timeInMin}`.slice(-2)}:${`0${timeInSec}`.slice(
      -2
    )}`;
  }

  private refresh(): void {
    this.outages = [];
    if (!this.report) {
      return;
    }

    this.report.publishedDate = new Date(
      this.report.publishedDate
    ).toUTCString();
    this.report.startDate = new Date(this.report.startDate).toUTCString();
    this.report.endDate = new Date(this.report.endDate).toUTCString();
    if (this.report.outages && this.report.outages.length > 0) {
      const processedOutages: any[] = [];
      this.report.outages.forEach((outage: any) => {
        processedOutages.push(this.processOutage(outage));
      });
      this.outages = new MatTableDataSource(processedOutages);
    }
  }

  private processOutage(outage: any): any {
    outage.outageDurationMS =
      new Date(outage.endTime).getTime() - new Date(outage.startTime).getTime();
    outage.startTime = new Date(outage.startTime).toUTCString();
    outage.endTime = new Date(outage.endTime).toUTCString();
    outage.summary = outage.incident.synopsis;

    const snIncidentNumbers: any[] = this.isCustomer
      ? outage.incident.filteredSnIncidentNumbers
      : outage.incident.snIncidentNumbers;
    outage.snIncidentUrls = [];
    snIncidentNumbers.forEach((snIncidentNumber: any) => {
      const incidentNumber: any = this.isCustomer
        ? snIncidentNumber
        : snIncidentNumber.incidentNumber;
      outage.snIncidentUrls.push({
        name: incidentNumber,
        link: `${
          this.appConfig.getConfig().endpoints.serviceNow
        }/incident.do?sysparm_query=number%3D${incidentNumber}`,
      });
    });

    return outage;
  }
}
