import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PolicyIdentifierInfo } from '../../models/policyIdentifierInfo';
import { PolicyUserRole } from '../../models/roles.enum';
import { UserRoleInfo } from '../../models/userRoleInfo.model';
import { PolicyService } from '../../services/policy-service/policy.service';
import { PolicyPermissionsUtility } from '../../utilities/policy-util/policyPermissionsUtility';

@Injectable()
export class PolicyCheckerService {
  constructor(private readonly policyService: PolicyService) {}

  isEnabled(policyIdentifierInfo: PolicyIdentifierInfo): Observable<boolean> {
    return new Observable((observer) => {
      this.policyService.userRoleInfo$.subscribe(
        (userRoleInfo: UserRoleInfo) => {
          const currentRole: PolicyUserRole = userRoleInfo.currentRole;
          if (
            currentRole &&
            PolicyPermissionsUtility.isPolicyPermissionAvailable(
              currentRole,
              policyIdentifierInfo.operation,
              policyIdentifierInfo.resource
            )
          ) {
            observer.next(true);
          } else {
            observer.next(false);
          }
        }
      );
    });
  }
}
