import { Component, OnInit } from '@angular/core';
import { DynamicLayoutComponent } from '@imc-features/ecosystems/_modules/ecosystems-shared/components/dynamic-layout/dynamic-layout.component';
import { isSiteFeatureDisabled } from '@imc-features/ecosystems/_modules/ecosystems-shared/utilities/site-feature-utility/site-feature-utility';
import {
  availableOps,
  elasticityOps,
} from '../../constants/db-elasticity-operations.constants';
import { dbFeatureStrings } from '../../constants/db-features.constants';

@Component({
  selector: 'imc-aws-site-elasticity',
  templateUrl: './aws-site-elasticity.component.html',
  styleUrls: ['./aws-site-elasticity.component.scss'],
})
export class AwsSiteElasticityComponent extends DynamicLayoutComponent
  implements OnInit {
  operationSelected: any;

  public elasticityOperations: any;
  public availableOps: any = availableOps;

  ngOnInit(): void {
    this.elasticityOperations = elasticityOps.filter((op: any) => {
      if (
        op.feature !== undefined &&
        dbFeatureStrings[op.feature] !== undefined
      ) {
        if (
          isSiteFeatureDisabled(
            this.siteInfo,
            dbFeatureStrings.DB_COMPONENT_NAME,
            dbFeatureStrings[op.feature]
          )
        ) {
          return false;
        }
      } else {
        // operation is not restricted by a site feature flag
        return true;
      }

      return true;
    });
  }

  setOperation(event: string): void {
    this.operationSelected = event;
  }
}
