import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TdMediaService } from '@covalent/core';
import { Subscription } from 'rxjs';

import { CcuMigrationModel } from '@imc-features/ecosystems/_modules/tmc/models/ccu-migrations-list.model';
import { CcuMigrationService } from '@imc-features/ecosystems/_modules/tmc/services/ccu-migrations-service/ccu-migrations.service';

@Component({
  selector: 'imc-tmc-ccu-migration-details',
  templateUrl: './tmc-ccu-migration-details.component.html',
  styleUrls: ['./tmc-ccu-migration-details.component.scss'],
})
export class TmcCcuMigrationDetailsComponent implements OnDestroy {
  ccuMigrationDetails: CcuMigrationModel;
  serviceSubscription: Subscription;
  migrationId: string;
  migrationFetchError: boolean = false;

  constructor(
    private readonly ccuMigrationService: CcuMigrationService,
    private readonly activatedRoute: ActivatedRoute,
    public media: TdMediaService
  ) {
    this.activatedRoute.params.subscribe((params: any) => {
      this.migrationId = params.migrationId;
    });
    this.getMigrationDetails(this.migrationId);
  }

  getMigrationDetails(migrationId: string): void {
    this.serviceSubscription = this.ccuMigrationService
      .getCcuMigrationById(migrationId)
      .subscribe(
        (migrationResponse: CcuMigrationModel) => {
          this.ccuMigrationDetails = migrationResponse;
        },
        (error: any) => {
          this.migrationFetchError = true;
        }
      );
  }

  ngOnDestroy(): void {
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }
  }
}
