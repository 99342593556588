import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { InstanceInfo } from '@imc-features/ecosystems/_modules/ecosystems-shared/models/instance-info.model';
import { Site } from '@imc/core/models/sites.model';
import { TmcInstanceInfoService } from '../../services/tmc-instance-info-service/tmc-instance-info.service';

@Component({
  selector: 'imc-tmc-instance-info',
  templateUrl: './tmc-instance-info.component.html',
  styleUrls: ['./tmc-instance-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmcInstanceInfoComponent implements OnChanges {
  @Input() site: Site;
  @Input() loading: boolean = true;
  @Input() error: any;

  loaderKey: string = 'tmcInstanceInfoLoader';

  instanceInfo: InstanceInfo;
  subtitle: string = '';

  constructor(
    private readonly _tmcInstanceInfoService: TmcInstanceInfoService
  ) {}

  ngOnChanges(): void {
    if (this.site !== undefined && this.site.siteId) {
      this.subtitle = `Instance info on ${this.site.displayName}`;
      this.instanceInfo = this._tmcInstanceInfoService.getInstanceInfo(
        this.site
      );
    }
  }
}
