import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerUserRoles } from '../models/roles.enum';
import { PolicyService } from '../services/policy-service/policy.service';

@Injectable()
export class SysOpsGuard implements CanActivate {
  constructor(private router: Router, private policyService: PolicyService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.policyService.userRoleInfo$.pipe(
      map((data: any) => {
        const isCustomer: any = CustomerUserRoles[data.currentRole]
          ? true
          : false;

        if (isCustomer) {
          this.router.navigate(['/']);

          return false;
        }

        return true;
      })
    );
  }
}
