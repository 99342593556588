import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../models/user.model';

@Injectable()
export class DashboardUserService {
  constructor(private readonly apollo: Apollo) {}

  listUsersForCustomer(customerId: string): Observable<User[]> {
    const userListQuery: any = gql`
      query userList {
        user(id: $customerId)
          @rest(
            type: "User"
            path: "/management-console/customers/{args.id}/users?excludeDisabled=true"
          ) {
          firstName
          lastName
          emailAddress
          userId
        }
      }
    `;

    return this.apollo
      .query<any>({ query: userListQuery, variables: { customerId } })
      .pipe(
        map((responseBody: any) => {
          if (responseBody.errors) {
            throw new Error('There was an error fetching list of users!');
          }

          return responseBody.data.user;
        })
      );
  }
}
