import {
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { TimeFilterViewModel } from './timeFilter.viewModel';
import { TimeFilterTabComponent } from './timeFilterTab/timeFilterTab.component';
import { TimeFilterTabViewModel } from './timeFilterTab/timeFilterTab.viewModel';

@Component({
  selector: 'time-filter',
  templateUrl: './timeFilter.html',
  styleUrls: ['./timeFilter.scss'],
})
export class TimeFilterComponent {
  @ViewChildren(TimeFilterTabComponent) private tabQueryList: QueryList<
    TimeFilterTabComponent
  >;
  @Input() _vm: TimeFilterViewModel;
  @Output() tabSelected: EventEmitter<number> = new EventEmitter<number>();

  highlightStyles: any;

  ngOnInit() {
    if (!this._vm) {
      this._vm = new TimeFilterViewModel();
    }

    this.moveHighlight(this._vm.selectedIndex);
  }

  selectTab(event: any, tabViewModel: TimeFilterTabViewModel) {
    if (
      event.type === 'keypress' &&
      !(event.key === 'Enter' || event.key === ' ')
    ) {
      return;
    }

    const previouslySelectedIndex = this._vm.selectedIndex;
    this.tabQueryList.forEach((tab: TimeFilterTabComponent) => {
      if (previouslySelectedIndex === tab.getIndex()) {
        tab.onTabDeselected();
      }
      if (tabViewModel.index === tab.getIndex()) {
        const index: number = tab.getIndex();
        this._vm.selectedIndex = index;
        this.moveHighlight(index);
        this.tabSelected.emit(tabViewModel.index);
      }
    });
  }

  moveHighlight(index: number) {
    const widthPercentage: number =
      100 / this._vm.timeFilterTabViewModels.length;
    this.highlightStyles = {
      width: widthPercentage + '%',
      left: widthPercentage * index + '%',
    };
  }
}
