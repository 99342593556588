import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { ImcMaterialModule } from '../../imc-material.module';
import { LeftNavComponent } from './components/left-nav/left-nav.component';

@NgModule({
  declarations: [LeftNavComponent],
  imports: [CommonModule, ImcMaterialModule, RouterModule],
  exports: [LeftNavComponent],
})
export class NavModule {}
