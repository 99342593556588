import { Site } from '@imc/core/models/sites.model';
import { isNullOrUndefined } from 'util';

// tslint:disable-next-line:only-arrow-functions
export function isSiteFeatureEnabled(
  site: Site,
  featureSetName: string,
  featureName: string
): boolean {
  return (
    !isNullOrUndefined(site) &&
    !isNullOrUndefined(site.features) &&
    !isNullOrUndefined(site.features[featureSetName]) &&
    !isNullOrUndefined(site.features[featureSetName][featureName]) &&
    site.features[featureSetName][featureName]
  );
}

// tslint:disable-next-line:only-arrow-functions
export function isSiteFeatureDisabled(
  site: Site,
  featureSetName: string,
  featureName: string
): boolean {
  return (
    !isNullOrUndefined(site) &&
    !isNullOrUndefined(site.features) &&
    !isNullOrUndefined(site.features[featureSetName]) &&
    !isNullOrUndefined(site.features[featureSetName][featureName]) &&
    !site.features[featureSetName][featureName]
  );
}
