import { PolicyOperations } from '../../models/policyOperations.enum';
import { PolicyResources } from '../../models/policyResources.enum';
import { POLICY_ROLE_MAPPING } from '../../models/policyRoleMapping.model';
import { PolicyUserRole } from '../../models/roles.enum';

export class PolicyPermissionsUtility {
  static isPolicyPermissionAvailable(
    currentRole: PolicyUserRole,
    operation: PolicyOperations,
    resource: PolicyResources
  ): boolean {
    // get the policy for the current role
    const policy: any = POLICY_ROLE_MAPPING[currentRole];

    // check for the operation in the fetched policy - including wild card
    if (policy) {
      const resourcesWithPermission: string[] = policy[operation];
      if (resourcesWithPermission && resourcesWithPermission.length > 0) {
        // wild card
        if (resourcesWithPermission.find((x) => x === '*')) {
          return true;
        }
        // normal case
        if (resourcesWithPermission.find((x) => x === resource.toString())) {
          return true;
        }
      }
    }

    return false;
  }
}
