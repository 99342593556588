import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { CcuMigrationsListModel } from '@imc-features/ecosystems/_modules/tmc/models/ccu-migrations-list.model';
import { CcuMigrationService } from '@imc-features/ecosystems/_modules/tmc/services/ccu-migrations-service/ccu-migrations.service';

@Component({
  selector: 'imc-tmc-ccu-migration-onboarding',
  templateUrl: './tmc-ccu-migration-onboarding.component.html',
  styleUrls: ['./tmc-ccu-migration-onboarding.component.scss'],
})
export class TmcCcuMigrationOnboardingComponent implements OnInit, OnDestroy {
  private serviceSubscription: Subscription;
  private customerSubscription: Subscription;
  hasData: boolean = false;
  loading: boolean = true;
  loaderKey: string = 'ccuMigrationsLoader';
  error: boolean = false;
  columns: string[] = [
    'customerName',
    'siteIdSource',
    'siteIdTarget',
    'createdAt',
    'status',
    'ticketNumber',
    'expectedETA',
  ];
  dataSource: MatTableDataSource<CcuMigrationsListModel>;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private readonly ccuMigrationService: CcuMigrationService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.customerSubscription = this.ccuMigrationService
      .getCustomersIdNameMap()
      .subscribe((custMap: Map<string, string>) => {
        this.getMigrationList(custMap);
      });
  }

  getMigrationList(custIdNameMap: Map<string, string>): void {
    this.serviceSubscription = this.ccuMigrationService
      .getCcuMigrationList()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        (ccuMigrations: any) => {
          if (ccuMigrations && ccuMigrations.length > 0) {
            ccuMigrations.forEach((migration: any) => {
              const custName: string = custIdNameMap[migration.customerId];
              if (custName) {
                migration.customerName = custName;
              }
              if (migration.ticket && migration.ticket.number !== '') {
                migration.ticketNumber = migration.ticket.number;
              } else {
                migration.ticketNumber = 'N/A';
              }
              if (!migration.expectedETA || migration.expectedETA === '') {
                migration.expectedETA = 'N/A';
              }
            });
          }
          this.dataSource = new MatTableDataSource(ccuMigrations);
          this.dataSource.sort = this.sort;
          this.hasData = true;
        },
        (error: any) => {
          this.error = true;
        }
      );
  }

  ngOnDestroy(): void {
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }
    if (this.customerSubscription) {
      this.customerSubscription.unsubscribe();
    }
  }
}
