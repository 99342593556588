import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  DbState,
  DbStatusStrings,
} from '@imc-features/ecosystems/_modules/ecosystems-shared/constants/db-state.constants';
import { ElasticityService } from '@imc-features/ecosystems/_modules/ecosystems-shared/services/elasticity-service/elasticity.service';
import { Site } from '@imc/core/models/sites.model';
import { AppConfigService } from '@imc/core/services';
import { Subscription } from 'rxjs';
import { IcawsService } from '../../services/icaws-service/icaws.service';
import { GoogleAnalyticsService } from '@imc/core/services/google-analytics/google-analytics.service';

@Component({
  selector: 'imc-aws-stop-database',
  templateUrl: './stop-database.component.html',
  styleUrls: ['./stop-database.component.scss'],
})
export class StopDatabaseComponent implements OnInit, OnDestroy {
  private dbStatusStrings: any = DbStatusStrings;
  private icawsSubscription: Subscription;
  private useLegacyApis: boolean = true;
  @Input() siteInfo: Site;
  dbStateOptions: any = DbState;
  failedOperation: boolean = false;
  operationInProgress: boolean = false;
  displayStatus: boolean = false;
  disableStop: boolean = false;
  dbState: number;
  dbMessage: string = '';
  errorMessage: string = '';

  analyticsEvent: any = {
    awsDbStop: {
      eventKey: 'aws_db_stop',
    },
  };

  constructor(
    private readonly icawsService: IcawsService,
    private readonly appConfig: AppConfigService,
    private readonly elasticityService: ElasticityService,
    private readonly analyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {
    this.useLegacyApis = this.appConfig.getConfig().legacy.sqle;
    if (this.siteInfo.sqleApp) {
      this.getDbInfo();
    }
  }

  ngOnDestroy(): void {
    if (this.icawsSubscription) {
      this.icawsSubscription.unsubscribe();
    }
  }

  getDbInfo(): void {
    // Incase the db does not have db status
    if (!this.siteInfo.sqleApp.status) {
      this.dbState = this.dbStatusStrings.started.dbState;
      this.dbMessage = this.dbStatusStrings.started.dbMessage;
    } else {
      // Incase siteInfo.dbStatus is not in the mapping, setting default to started
      const status: any = this.dbStatusStrings[this.siteInfo.sqleApp.status];
      this.dbState = status
        ? status.dbState
        : this.dbStatusStrings.started.dbState;
      this.dbMessage = status
        ? status.dbMessage
        : this.dbStatusStrings.started.dbMessage;
    }
  }

  confirmStop(): void {
    // Immediate update on UI
    this.operationInProgress = true;

    this.disableStop = true;
    this.siteInfo.sqleApp.status = 'stopping';
    this.siteInfo.operationalStatus = 'not operational';
    this.getDbInfo();
    this.analyticsService.sendAnalyticsEvent(this.analyticsEvent.awsDbStop);

    if (this.useLegacyApis) {
      // call old apis
      this.icawsSubscription = this.icawsService
        .postDatabaseSiteStop(this.siteInfo)
        .subscribe(
          () => {
            // add any other operations on a success call
            this.operationInProgress = false;
            this.elasticityService.siteDbStatusChanged(this.dbMessage);
            this.elasticityService.refreshSiteInfo();
          },
          (error: any) => {
            this.errorMessage = error.networkError.result.message;
            this.failedOperation = true;
            this.siteInfo.sqleApp.status = 'started';
            this.siteInfo.sqleApp.operationalStatus = 'not operational';
            this.operationInProgress = false;
          }
        )
        .add(() => {
          this.displayStatus = true;
          this.getDbInfo();
        });
    } else {
      // call new sqle apis
      this.icawsSubscription = this.icawsService
        .postDatabaseSqleAppStop(this.siteInfo)
        .subscribe(
          () => {
            // add any other operations on a success call
            this.operationInProgress = false;
            this.elasticityService.siteDbStatusChanged(this.dbMessage);
            this.elasticityService.refreshSiteInfo();
          },
          (error: any) => {
            this.errorMessage = error.networkError.result.message;
            this.failedOperation = true;
            this.siteInfo.sqleApp.status = 'started';
            this.siteInfo.sqleApp.operationalStatus = 'not operational';
            this.operationInProgress = false;
          }
        )
        .add(() => {
          this.displayStatus = true;
          this.getDbInfo();
        });
    }
  }
}
