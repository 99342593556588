import { Component, Input } from '@angular/core';

import { Site } from '@imc/core/models/sites.model';

@Component({
  selector: 'imc-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent {
  @Input() site: Site;
}
