import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable, of, Subscription } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { PolicyService } from '@imc/core';
import { LegacyCorrelationObjectService } from '@imc/shared/utilities/legacy-correlation-object-service';

@Injectable()
export class AzureSecurityRulesService implements OnInit, OnDestroy {
  isCustomer$: Observable<boolean>;
  isCustomer: boolean;
  userSubscription: Subscription;

  constructor(
    private readonly apollo: Apollo,
    private readonly policyService: PolicyService,
    private readonly correlationObjectService: LegacyCorrelationObjectService
  ) {}

  // tslint:disable-next-line:contextual-life-cycle
  ngOnInit(): void {
    this.isCustomer$ = this.policyService.isCustomer$;
    this.userSubscription = this.isCustomer$.subscribe(
      (isCustomer: boolean) => {
        this.isCustomer = isCustomer;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  public initializeAzureSiteSecurityRules(): Observable<any> {
    const getSecurityRulesQuery: any = gql`
      query getSecurityRules {
        securityRules(securityRulesUrl: $securityRulesUrl)
          @rest(
            type: "AzureSecurityRules"
            path: "/security-rules"
            endpoint: "securityRules"
          ) {
          result
        }
      }
    `;

    const requestContext: any = { headers: {} };
    requestContext.headers[
      'correlation-object'
    ] = this.correlationObjectService.createDeprecatedCorrelationObject();

    return this.apollo
      .query<any>({
        query: getSecurityRulesQuery,
        fetchPolicy: 'no-cache',
        context: requestContext,
      })
      .pipe(
        switchMap((responseBody: any) => {
          if (responseBody.errors) {
            throw new Error(JSON.stringify(responseBody.errors));
          }

          return of(
            responseBody.data &&
              responseBody.data.securityRules &&
              responseBody.data.securityRules.result
          );
        }),
        first()
      );
  }
}
