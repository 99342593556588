import { FormGroup, Validators } from '@angular/forms';
import { StepState } from '@covalent/core';
import { Subscription } from 'rxjs';
// import { Customer } from '../../models/customer.model';

const lengths: any = {
  supportSiteID: 15,
  maxSystemName: 8,
  minSystemName: 3,
  shortDescription: 250,
  longDescription: 4000,
  longDescriptionRows: 5,
  longDescriptionCols: 50,
};

const regex: any = {
  cidr_all: /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2]))$/,
  alphaNumericAlphaFirst: /^[a-zA-Z][a-zA-Z0-9]*$/,
  alphaNumericCaps: /^[A-Z0-9]*$/,
};

export interface CreateSiteComponentViewModel {
  content: {
    title: string;
    subtitle: string;
    formGroupDependencyOrder: string[];
    formControls: any;
  };
  data: {
    // customer: Customer;
    productOffers: any[];
  };
  state: {
    isReady: boolean;
    getSubscriptionsReady: boolean;
    form: FormGroup;
    subscriptions: Subscription[];
    stepStates: StepState[];
    active: boolean[];
  };
}

const createSiteComponentViewModel: CreateSiteComponentViewModel = {
  content: {
    title: 'Provision a New Site for ',
    subtitle: 'Please define the database site per Customer request',
    formGroupDependencyOrder: [
      'productOffer',
      'regionVersion',
      'productTier',
      'ecosystemConfiguration',
      'dbNodeConfiguration',
    ],
    formControls: {
      productOffer: {
        name: 'productOffer',
        group: 'productOffer',
        productOfferMapping: 'productOffer',
        placeholder: 'Product Offer',
        default: null,
        valuesList: [],
        validators: [Validators.required],
        errorMessages: {
          required: 'Product Offer is required',
        },
      },
      region: {
        name: 'region',
        group: 'regionVersion',
        placeholder: 'Region',
        default: null,
        valuesList: [],
        validators: [Validators.required],
        errorMessages: {
          required: 'Region is required',
        },
      },
      databaseVersion: {
        name: 'databaseVersion',
        group: 'regionVersion',
        placeholder: 'Database Version',
        default: null,
        valuesList: [],
        validators: [Validators.required],
        errorMessages: {
          required: 'Database version is required',
        },
      },
      subscription: {
        name: 'subscription',
        group: 'regionVersion',
        placeholder: 'Subscription',
        default: null,
        valuesList: [],
        validators: [],
        errorMessages: {
          required: 'Subscription is required',
        },
      },
      productTier: {
        name: 'productTier',
        group: 'productTier',
        placeholder: 'Product Tier',
        default: null,
        valuesList: [],
        validators: [Validators.required],
        errorMessages: {
          required: 'Product tier is required',
        },
      },
      dataMoverVmSize: {
        name: 'dataMoverVmSize',
        group: 'ecosystemConfiguration',
        placeholder: 'Data Mover VM Size',
        default: null,
        validators: [Validators.required],
        errorMessages: {
          required: 'Data Mover VM Size is required',
        },
      },
      dataMoverInstanceCount: {
        name: 'dataMoverInstanceCount',
        group: 'ecosystemConfiguration',
        placeholder: 'Data Mover Instance count',
        default: null,
        validators: [Validators.required],
        errorMessages: {
          required: 'Data Mover instance count is required',
        },
      },
      deployDataMover: {
        name: 'deployDataMover',
        group: 'ecosystemConfiguration',
        placeholder: 'To deploy Data Mover',
        default: false,
        validators: null,
        errorMessages: null,
      },
      availableDataMoverConfigs: {
        name: 'availableDataMoverConfigs',
        group: 'ecosystemConfiguration',
        placeholder:
          'All available Data Mover VM Sizes. This is a hidden field.',
        default: null,
        validators: null,
        errorMessages: null,
      },
      viewpointVmSize: {
        name: 'viewpointVmSize',
        group: 'ecosystemConfiguration',
        placeholder:
          'Viewpoint (Multi-System) VM Size. This is a hidden field.',
        default: null,
        validators: null,
        errorMessages: null,
      },
      viewpointInstanceCount: {
        name: 'viewpointInstanceCount',
        group: 'ecosystemConfiguration',
        placeholder:
          'Viewpoint (Multi-System) Instance count. This is a hidden field.',
        default: null,
        validators: null,
        errorMessages: null,
      },
      deployViewpoint: {
        name: 'deployViewpoint',
        group: 'ecosystemConfiguration',
        placeholder: 'To deploy Viewpoint',
        default: false,
        validators: null,
        errorMessages: null,
      },
      restServicesVmSize: {
        name: 'restServicesVmSize',
        group: 'ecosystemConfiguration',
        placeholder: 'REST Services VM Size. This is a hidden field.',
        default: null,
        validators: null,
        errorMessages: null,
      },
      restServicesInstanceCount: {
        name: 'restServicesInstanceCount',
        group: 'ecosystemConfiguration',
        placeholder: 'REST Services Instance count. This is a hidden field.',
        default: null,
        validators: null,
        errorMessages: null,
      },
      deployRestServices: {
        name: 'deployRestServices',
        group: 'ecosystemConfiguration',
        placeholder: 'To deploy REST Services',
        default: false,
        validators: null,
        errorMessages: null,
      },
      dataStreamUtilityVmSize: {
        name: 'dataStreamUtilityVmSize',
        group: 'ecosystemConfiguration',
        placeholder: 'Data Stream Utility VM Size. This is a hidden field.',
        default: null,
        validators: null,
        errorMessages: null,
      },
      dataStreamUtilityInstanceCount: {
        name: 'dataStreamUtilityInstanceCount',
        group: 'ecosystemConfiguration',
        placeholder:
          'Data Stream Utility Instance count. This is a hidden field.',
        default: null,
        validators: null,
        errorMessages: null,
      },
      deployDsu: {
        name: 'deployDsu',
        group: 'ecosystemConfiguration',
        placeholder: 'To deploy Data Stream Utility',
        default: false,
        validators: null,
        errorMessages: null,
      },
      serverManagementVmSize: {
        name: 'serverManagementVmSize',
        group: 'ecosystemConfiguration',
        placeholder: 'Server Management VM Size. This is a hidden field.',
        default: null,
        validators: null,
        errorMessages: null,
      },
      serverManagementInstanceCount: {
        name: 'serverManagementInstanceCount',
        group: 'ecosystemConfiguration',
        placeholder:
          'Server Management Instance count. This is a hidden field.',
        default: null,
        validators: null,
        errorMessages: null,
      },
      deployServerManagement: {
        name: 'deployServerManagement',
        group: 'ecosystemConfiguration',
        placeholder: 'To deploy Server Management',
        default: false,
        validators: null,
        errorMessages: null,
      },
      dbSize: {
        name: 'dbSize',
        group: 'dbNodeConfiguration',
        placeholder: 'Installation Size',
        default: null,
        valuesList: [],
        validators: null,
        errorMessages: {
          required: 'Installation Size is required',
        },
      },
      dbNodeCount: {
        name: 'dbNodeCount',
        group: 'dbNodeConfiguration',
        placeholder: 'Database Node Count',
        default: null,
        validators: [Validators.required],
        errorMessages: {
          required: 'Database Node Count is required',
          min: 'Database Node Count is lower than mininum value',
          max: 'Database Node Count exceeds maximum value',
        },
      },
      hsnEnabled: {
        name: 'hsnEnabled',
        group: 'dbNodeConfiguration',
        placeholder: 'launch HSN',
        default: true,
        validators: null,
        errorMessages: null,
      },
      storageSize: {
        name: 'storageSize',
        group: 'dbNodeConfiguration',
        placeholder: 'Raw Storage per Instance',
        default: null,
        validators: [Validators.required],
        errorMessages: {
          required: 'Raw Storage per Instance is required',
          pattern:
            'Raw Storage per Instance must be one of the available options.',
        },
      },
      storageType: {
        name: 'storageType',
        group: 'dbNodeConfiguration',
        placeholder: 'Storage Disk Type',
        default: null,
        validators: [Validators.required],
        errorMessages: null,
      },
      siteId: {
        name: 'siteId',
        group: 'dbNodeConfiguration',
        placeholder: 'Support Site ID (example: ACMEPROD1)',
        maxLength: lengths.supportSiteID,
        default: null,
        validators: [
          Validators.pattern(regex.alphaNumericCaps),
          Validators.maxLength(lengths.supportSiteID),
        ],
        errorMessages: {
          required: 'Support Site ID is required',
          pattern: 'May only contain English capital letters and numbers',
          maxLength: `Cannot exceed ${lengths.supportSiteID} characters`,
        },
      },
      systemName: {
        name: 'systemName',
        group: 'dbNodeConfiguration',
        placeholder: 'System Name (example: acmeProd)',
        maxLength: lengths.maxSystemName,
        minLength: lengths.minSystemName,
        default: null,
        validators: [
          Validators.required,
          Validators.pattern(regex.alphaNumericAlphaFirst),
          Validators.maxLength(lengths.maxSystemName),
          Validators.minLength(lengths.minSystemName),
        ],
        errorMessages: {
          required: 'System Name is required',
          pattern:
            'Must start with a letter and may only contain English letters and numbers',
          maxLength: `Cannot exceed ${lengths.maxSystemName} characters`,
          minLength: `Must be at least ${lengths.minSystemName} characters`,
        },
      },
      networkCidr: {
        name: 'networkCidr',
        group: 'dbNodeConfiguration',
        placeholder: 'Network CIDR IPs (example: 10.10.0.0/18)',
        default: null,
        validators: [Validators.required],
        errorMessages: {
          required: 'Network CIDR IPs is required',
          pattern: 'Valid CIDR format: [0-255].[0-255].[0-255].[0-255]/[16-32]',
          range: "CIDR range doesn't support the site",
        },
      },
      accessCidr: {
        name: 'accessCidr',
        group: 'dbNodeConfiguration',
        placeholder: 'Access CIDR IPs (example: 255.255.0.0/32)',
        default: null,
        validators: [Validators.required, Validators.pattern(regex.cidr_all)],
        errorMessages: {
          required: 'Access CIDR IPs is required',
          pattern:
            'Must be a valid IP in the format: [0-255].[0-255].[0-255].[0-255]/32',
        },
      },
      currentScaleOutState: {
        name: 'currentScaleOutState',
        group: 'dbNodeConfiguration',
        placeholder: 'Initial Deployment Scale Size',
        default: null,
        validators: [Validators.required],
        errorMessages: {
          required: 'CurrentScaleOutState is required',
          pattern: 'CurrentScaleOutState must be one of the available options',
        },
      },
      purpose: {
        name: 'purpose',
        group: 'dbNodeConfiguration',
        placeholder: 'Purpose of the site',
        default: 'Production',
        validators: [Validators.required],
        errorMessages: {
          required: 'Purpose is required',
        },
      },
      timezone: {
        name: 'timezone',
        group: 'dbNodeConfiguration',
        placeholder: 'Timezone of the site',
        default: 'UTC',
        validators: [Validators.required],
        errorMessages: {
          required: 'Timezone is required',
        },
      },
      temporal: {
        name: 'temporal',
        group: 'dbNodeConfiguration',
        placeholder: 'temporal',
        default: false,
        validators: null,
        errorMessages: null,
      },
      tim: {
        name: 'tim',
        group: 'dbNodeConfiguration',
        placeholder: 'Teradata Intelligent Memory',
        default: false,
        validators: null,
        errorMessages: null,
      },
      selectedProductOffers: {
        name: 'selectedProductOffers',
        group: 'dbNodeConfiguration',
        placeholder:
          'List of productOffers from customer selections. This is a hidden field',
        default: null,
        validators: null,
        errorMessages: null,
      },
      availableDbConfigs: {
        name: 'availableDbConfigs',
        group: 'dbNodeConfiguration',
        placeholder: 'All available DB VM Sizes. This is a hidden field.',
        default: null,
        validators: null,
        errorMessages: null,
      },
      dbVmConfig: {
        name: 'dbVmConfig',
        group: 'dbNodeConfiguration',
        placeholder: 'Selected DB VM Size. This is a hidden field.',
        default: null,
        validators: [Validators.required],
        errorMessages: null,
      },
    },
  },
  data: {
    productOffers: [],
  },
  state: {
    isReady: false,
    getSubscriptionsReady: false,
    form: null,
    stepStates: [
      StepState.Required,
      StepState.Required,
      StepState.Required,
      StepState.Required,
      StepState.Required,
    ],
    active: [true, false, false, false, false],
    subscriptions: [],
  },
};

export const getCreateSiteCardViewModel: () => CreateSiteComponentViewModel = function() {
  return createSiteComponentViewModel;
};
