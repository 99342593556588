import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Subscription } from '../models/subscription.model';

import { LegacyCorrelationObjectService } from '@imc/shared/utilities/legacy-correlation-object-service';

@Injectable()
export class SysOpsService {
  constructor(
    private readonly apollo: Apollo,
    private readonly correlationObjectService: LegacyCorrelationObjectService
  ) {}

  public getSubscriptions(): Observable<Subscription[]> {
    const query: any = gql`
      query subscriptions {
        subscriptions
          @rest(
            type: "AzureSubscription"
            path: "/management-console/subscriptions"
          ) {
          subscriptionId
          name
          customerId
          customerName
          owner
          isActivated
          comments
          createdTime
          updatedTime
          requestedBy
          status
        }
      }
    `;

    const context: any = { headers: {} };
    context.headers[
      'correlation-object'
    ] = this.correlationObjectService.createDeprecatedCorrelationObject();

    // Use no-cache, so that updates to subscriptions always get reflected as
    // soon as they are available. Per the azure team, updates can take as
    // long as 30 seconds
    return this.apollo
      .query<any>({ query, context, fetchPolicy: 'no-cache' })
      .pipe(
        map((responseBody: any) => {
          if (!responseBody || responseBody.errors) {
            throw new Error('Unable to fetch subscriptions');
          }

          return responseBody.data && responseBody.data.subscriptions;
        })
      );
  }

  public updateSubscription(subscription: Subscription): Observable<boolean> {
    const mutation: any = gql`
      mutation updateSubscription(
        $subscriptionId: String!
        $subscription: Any!
      ) {
        subscription(input: $subscription, subscriptionId: $subscriptionId)
          @rest(
            type: "AzureSubscription"
            method: "PUT"
            path: "/management-console/subscriptions/{args.subscriptionId}"
          ) {
          ignore
        }
      }
    `;

    // TODO: setup refetch queries to update the cache
    const subscriptionId: string = subscription.subscriptionId;
    delete subscription.subscriptionId;
    const variables: any = { subscriptionId, subscription };
    const context: any = { headers: {} };
    context.headers[
      'correlation-object'
    ] = this.correlationObjectService.createDeprecatedCorrelationObject();

    return this.apollo
      .mutate({ mutation, context, variables, fetchPolicy: 'no-cache' })
      .pipe(
        map((responseBody: any) => {
          if (!responseBody || responseBody.errors) {
            throw new Error('Unable to update subscription');
          }

          return responseBody.data;
        })
      );
  }
}
