import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';
import { OAuthenticationService } from '@imc/core/services';
import { UrlHelperService } from 'angular-oauth2-oidc';

@Component({
  selector: 'imc-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  private _queryParams: Params;
  private _data: Data;

  // Default values for the error page
  public title: string = 'Unknown Error';
  public subtitle: string = 'Oops! Something bad happened!';
  public content: string =
    'We have encounted an unknown error, please go back, and try again later!';
  public enableLoginButton: boolean = false;
  public enableGoBackButton: boolean = true;
  public enableAssumeRoleButton: boolean = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly location: Location,
    private readonly authService: OAuthenticationService,
    private readonly urlHelper: UrlHelperService
  ) {}

  public ngOnInit(): void {
    this._data = this.activatedRoute.snapshot.data;
    this._queryParams = this.activatedRoute.snapshot.queryParams;

    const hashParts: any = this.urlHelper.getHashFragmentParams();

    // Precedence: data > queryParams > hash fragement
    this.title = this._data.error || this._queryParams.error || this.title;
    this.subtitle =
      this._data.error_description ||
      this._queryParams.error_description ||
      this.subtitle;
    this.content =
      this._data.content || this._queryParams.content || this.content;

    if (hashParts.constructor === Object && Object.keys(hashParts).length > 0) {
      this.title = hashParts.error || this.title;
      this.subtitle = hashParts.error_description || this.subtitle;
    }

    if (this.title === 'unauthorized') {
      this.content = this.content
        ? this.content
        : 'Encounted an error during login, please try login again!';
      this.enableGoBackButton = false;
      this.enableLoginButton = true;
    }

    if (this.title === 'permission-denied') {
      this.enableGoBackButton = false;
      this.enableAssumeRoleButton = true;
    }

    if (this.title === '404') {
      this.content =
        'The resource you requested is not found, please go back and try again!';
    }
  }

  public back($event: any): void {
    $event.preventDefault();
    this.location.back();
  }

  public login($event: any): void {
    $event.preventDefault();
    this.authService.navigateToLoginPage();
  }

  public navigateToAssumeRole($event: any): void {
    this.router.navigate(['/assume-role']);
  }
}
