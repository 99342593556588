import { Pipe, PipeTransform } from '@angular/core';

// tslint:disable-next-line:comment-type
/*
Some values may be stored as a string with a numerical value and a unit of measurment, and they need to be
split for display purposes but we don't want to expose an entire property just for it in the view model.
Add units as necessary.
}
*/

@Pipe({
  name: 'splitUnitValue',
})
export class SplitUnitValuePipe implements PipeTransform {
  units: string[] = ['MB', 'GB', 'TB'];

  transform(valueInput: string, option: UnitOrValue): string {
    for (const unit of this.units) {
      if (valueInput.indexOf(unit) > -1) {
        if (option === 'value') {
          const results: any = valueInput.split(unit);

          return results[0];
        } else if (option === 'unit') {
          return unit;
        }

        return "Invalid option. Must be \"unit\" or \"value\".";
      }
    }

    return valueInput;
  }
}

export type UnitOrValue = 'unit' | 'value';
