import { AbstractControl } from '@angular/forms';
import { ITdDynamicElementValidator } from '@covalent/dynamic-forms';
import { TIMEZONES } from './timezones';

export const TYPES = {
  string: 'string',
  number: 'number',
  integer: 'number',
  boolean: 'boolean',
  select: 'select',
  percent: 'percent',
};

const timezones = TIMEZONES;

export class OfferingParameter {
  id: string;
  label: string;
  type: string;
  required: boolean;
  defaultValue: any;
  choices: any[];
  placeholder: string;
  selectedValue: any;
  minValue: number;
  maxValue: number;
  minLength: number;
  maxLength: number;
  validators: ITdDynamicElementValidator[];

  constructor(
    id: string,
    label: string,
    type: string,
    required: boolean = false,
    defaultValue: any = null,
    choices: any[] = null,
    min: number = null,
    max: number = null,
    validators?: ITdDynamicElementValidator[]
  ) {
    this.id = id;
    this.label = label;
    this.placeholder = label;
    this.type = TYPES[type];
    this.required = required;
    this.defaultValue = defaultValue;
    this.choices = choices;
    if (this.type) {
      if (this.type === 'string') {
        this.minLength = min;
        this.maxLength = max;
      } else {
        this.minValue = min;
        this.maxValue = max;
      }
    }
    this.validators = validators;
  }

  reset() {
    this.selectedValue = null;
  }
}

export const alphaNumericValidator = (control: AbstractControl) => {
  const isValid: boolean =
    control.value &&
    control.value.length &&
    /^[a-zA-Z0-9]*$/.test(control.value);
  return isValid ? null : { isValid };
};

const DATABASE_NODE_COUNT = new OfferingParameter(
  'base_node_count',
  'Node Count',
  TYPES.number,
  true,
  4,
  null,
  2,
  64
);
const ENABLE_SCALE_IN = new OfferingParameter(
  'scale_in',
  'Enable Scale In/out',
  TYPES.boolean,
  false,
  false
);
const INITIAL_DEP_SIZE = new OfferingParameter(
  'scale_size',
  'Initial Deployment Scale Size',
  TYPES.string,
  false,
  null,
  ['1x', '2x', '4x']
);

export const RAW_STORAGE = new OfferingParameter(
  'node_storage_size',
  'RAW Storage per Node (in TB)',
  TYPES.number,
  true,
  null,
  null,
  5,
  72
);
const SITE_ID = new OfferingParameter(
  'site_id',
  'Support Site ID (example: ACMEPROD01)',
  TYPES.string,
  false
);
const DB_SYS_NAME = new OfferingParameter(
  'system_name',
  'Vantage SQL Engine System Name (example: acmeProd)',
  TYPES.string,
  true,
  null,
  null,
  1,
  15,
  [{ validator: alphaNumericValidator }]
);
const VP_NAME = new OfferingParameter(
  'system_nickname',
  'Viewpoint System Name',
  TYPES.string,
  true,
  null,
  null,
  1,
  8,
  [{ validator: alphaNumericValidator }]
);
const PURPOSE = new OfferingParameter(
  'purpose',
  'Purpose of the Site',
  TYPES.string,
  true,
  null,
  ['Dev', 'POC', 'Prod', 'Test']
);
const TIMEZONE = new OfferingParameter(
  'timezone',
  'Timezone',
  TYPES.string,
  false,
  null,
  timezones
);
const MAINFRAME_SUPPORT = new OfferingParameter(
  'mainframe_support',
  'Need Mainframe Support ?',
  TYPES.boolean
);
const MAINFRAME_PE_COUNT = new OfferingParameter(
  'mainframe_pe_count',
  'Mainframe PE Count',
  TYPES.string,
  false,
  null,
  [0, 1, 2]
);
const JAPANESE_SUPPORT = new OfferingParameter(
  'kanji_support',
  'Japanese Support Required',
  TYPES.boolean,
  false,
  false
);
const TEMPORAL = new OfferingParameter(
  'temporal',
  'Temporal',
  TYPES.boolean,
  false,
  false
);
const SECURE_ZONES = new OfferingParameter(
  'secure_zones',
  'Secure Zones',
  TYPES.boolean,
  false,
  false
);
const ROW_LEVEL_SECURITY = new OfferingParameter(
  'row_level_security',
  'Row Level Security',
  TYPES.boolean,
  false,
  false
);
const TIM_PERCENT = new OfferingParameter(
  'tim_percent',
  'Teradata Intelligent Memory Percentage (example: 50%, upto 80%)',
  TYPES.number,
  false,
  0,
  null,
  0,
  80
);

export const ECOSYSTEM_OFFER = [
  DATABASE_NODE_COUNT,
  RAW_STORAGE,
  SITE_ID,
  MAINFRAME_PE_COUNT,
  DB_SYS_NAME,
  VP_NAME,
  TIMEZONE,
  JAPANESE_SUPPORT,
];
export const ECOSYSTEM_OFFER_FEATURES = [
  TEMPORAL,
  SECURE_ZONES,
  ROW_LEVEL_SECURITY,
  TIM_PERCENT,
];

export const ANALYSIS_OFFER = [
  DATABASE_NODE_COUNT,
  ENABLE_SCALE_IN,
  INITIAL_DEP_SIZE,
  RAW_STORAGE,
  SITE_ID,
  DB_SYS_NAME,
  VP_NAME,
  PURPOSE,
  TIMEZONE,
  JAPANESE_SUPPORT,
];
export const ANALYSIS_OFFER_FEATURES = [
  TEMPORAL,
  SECURE_ZONES,
  ROW_LEVEL_SECURITY,
  TIM_PERCENT,
];
