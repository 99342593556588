export const DATA_MOVER_DEFAULT_VALUES = {
  INSTANCE_COUNT: 1,
  INSTANCE_TYPE: 'Standard_DS3_v2',
};

export const PREMIUM_DISK = 'Premium_LRS';
export const STANDARD_DISK = 'Standard_LRS';

export const SCALING_DISABLED = 'Scaling-Disabled';

export const AZURE_ICONS = {
  Small: {
    svgIcon: 'size-small', // "mc" is a namespace prefix of the svg asset name
    svgSelectedIcon: 'size-small-white',
  },
  Medium: {
    svgIcon: 'size-medium',
    svgSelectedIcon: 'size-medium-white',
  },
  Large: {
    svgIcon: 'size-large',
    svgSelectedIcon: 'size-large-white',
  },
};
