import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { OnboardingModel } from '@imc-features/dashboard/models/onboarding.model';

@Component({
  selector: 'imc-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit {
  onboardingRequests: OnboardingModel[] = [];

  constructor(private readonly router: Router) {}

  ngOnInit(): void {
    this.onboardingRequests = this.getOnboardingRequests();
  }

  getOnboardingRequests(): OnboardingModel[] {
    // TODO hard coded for now until apollo integration is done

    return [
      {
        workOrderId: 'WO123',
        customerName: 'Walmart',
        siteId: 'Site123',
        status: 'Pending',
        updatedBy: 'Aruna Veerappan',
        updatedAt: '1/2/2019 4:26:21 PM',
        platform: 'AWS',
      },
      {
        workOrderId: 'WO234',
        customerName: 'Best Buy',
        siteId: 'Site1234',
        status: 'Pending',
        updatedBy: 'Aruna Veerappan',
        updatedAt: '1/2/2019 4:26:21 PM',
        platform: 'AWS',
      },
    ];
  }

  viewOnboardingDetails(): void {
    this.router.navigate(['onboarding-details']);
  }
}
