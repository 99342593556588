import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TdDialogService } from '@covalent/core';
import { CustomerModule } from '@imc-features/customer';
import { DashboardModule } from '@imc-features/dashboard';
import { ReportsModule } from '@imc-features/reports/reports.module';
import { SiteHomepageModule } from '@imc-features/site-homepage';
import { CoreModule, GraphQLModule, ImcMaterialModule } from '@imc/core';
import { AppConfigService } from '@imc/core/services';
import {
  createTranslateLoader,
  setupTranslateService,
} from '@imc/core/translate-utils';
import { SharedModule } from '@imc/shared/shared.module';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DocConfigService } from '@imc/core/services/doc-config/doc-config.service';

const appInitializerFactory: (appConfig: AppConfigService) => Function = (
  appConfig: AppConfigService
) => () => appConfig.loadAppConfig();

const docInitializerFactory: (docConfig: DocConfigService) => Function = (
  docConfig: DocConfigService
) => () => docConfig.loadDocConfig();
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ImcMaterialModule,
    GraphQLModule,
    DashboardModule,
    SharedModule,
    SiteHomepageModule,
    ReportsModule,
    CustomerModule,
    CoreModule.forRoot(),
    CustomerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: 'localStorage', useValue: localStorage },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    CookieService,
    TdDialogService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      multi: true,
      deps: [AppConfigService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: docInitializerFactory,
      multi: true,
      deps: [DocConfigService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(translateService: TranslateService) {
    setupTranslateService(translateService);
  }
}
