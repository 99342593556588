import { Component, Input } from '@angular/core';

import { Site } from '@imc/core/models/sites.model';

@Component({
  selector: 'imc-engines',
  templateUrl: './engines.component.html',
  styleUrls: ['./engines.component.scss'],
})
export class EnginesComponent {
  @Input() site: Site;
}
