import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SlaReportsComponent } from './_modules/availabilities/pages/sla-reports/sla-reports.component';
import { ReportsDashboardComponent } from './pages/reports-dashboard/reports-dashboard.component';
const routes: Routes = [
  {
    path: '',
    component: ReportsDashboardComponent,
  },
  {
    path: 'sla',
    component: SlaReportsComponent,
  },
  {
    path: 'metering',
    loadChildren: '../metering/metering.module#MeteringModule',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportsRoutingModule {}
