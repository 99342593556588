import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CovalentStepsModule } from '@covalent/core/steps';
import { CovalentDynamicFormsModule } from '@covalent/dynamic-forms';

import { ImcMaterialModule } from '@imc/core';

import { AzureCreateSitesCardComponent } from './cards/create-sites-card/create-sites-card.component';
import { AzureInstancesComponent } from './components/azureInstances/azure-instances.component';
import { ProductFeatureComponent } from './components/productFeature/product-feature.component';
import { ProductFeaturesComponent } from './components/productFeatures/product-features.component';
import { AzureCreateSitesComponent } from './pages/create-sites-page/create-sites.component';
import { CustomerService } from './services/customer.service';
import { NetworkCidrValidationService } from './services/form/network-cidr-validation.service';
import { ProductOffersService } from './services/product-offers.service';
import { SysOpsService } from './services/sys-ops.service';
import { WorkOrderService } from './services/work-order.service';

@NgModule({
  declarations: [
    ProductFeatureComponent,
    ProductFeaturesComponent,
    AzureInstancesComponent,
    AzureCreateSitesCardComponent,
    AzureCreateSitesComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CovalentDynamicFormsModule,
    CovalentStepsModule,
    ImcMaterialModule,
  ],
  providers: [
    CustomerService,
    ProductOffersService,
    SysOpsService,
    WorkOrderService,
    NetworkCidrValidationService,
  ],
})
export class AzureProvisioningModule {}
