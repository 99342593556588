// tslint:disable
export const awsPortRange = /^\d+(-\d+)?$/;
export const azurePortRange = /^(\d+(-\d+)?)|\*$/;
export const cidr_network = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/(1[6-9]|2[0-9]|3[0-2]))$/;
export const cidr_all = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2]))$/;
export const portRange = /^\d+(-\d+)?$/;
export const azureResourceNameFirstChar = /^[a-zA-Z0-9]/;
export const azureResourceNameValidChars = /^[a-zA-Z0-9_\-.]*$/;
export const azureResourceNameMaxLength = /^.{1,80}$/;
export const azureResourceNameLastChar = /\w$/;
export const alphaNumeric = /^[a-zA-Z0-9]*$/;
export const alphaNumericAlphaFirst = /^[a-zA-Z][a-zA-Z0-9]*$/;
export const alphaNumericDashes = /^[a-zA-Z0-9\-]*$/;
export const alphaNumericCaps = /^[A-Z0-9]*$/;
// This is the W3 standard for valid emails
export const email = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
// tslint:enable
