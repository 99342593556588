import { Injectable } from '@angular/core';
import { Site } from '@imc/core/models/sites.model';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class IcawsService {
  constructor(private readonly apollo: Apollo) {}

  postDatabaseSiteStart(site: Site): Observable<any> {
    const mutation: any = gql`
      mutation postDatabaseStart($supportSiteId: string, $input: any) {
        databaseStart(supportSiteId: $supportSiteId, input: $input)
          @rest(
            type: "Boolean"
            path: "/{args.supportSiteId}/start"
            method: "POST"
            endpoint: "icaws"
          ) {
          message
        }
      }
    `;

    const variables: any = {
      supportSiteId: site.supportSiteId || site.siteId,
      input: {},
    };

    return this.apollo.mutate({ mutation, variables }).pipe(
      map((responseBody: any) => {
        if (responseBody.errorCode) {
          throw new Error(responseBody.message);
        }

        return (
          responseBody && responseBody.data && responseBody.data.databaseStart
        );
      })
    );
  }

  postDatabaseSiteStop(site: Site): Observable<any> {
    const mutation: any = gql`
      mutation postDatabaseStop($supportSiteId: string, $input: any) {
        databaseStop(supportSiteId: $supportSiteId, input: $input)
          @rest(
            type: "Boolean"
            path: "/{args.supportSiteId}/stop"
            method: "POST"
            endpoint: "icaws"
          ) {
          message
        }
      }
    `;

    const variables: any = {
      supportSiteId: site.supportSiteId || site.siteId,
      input: {},
    };

    return this.apollo.mutate({ mutation, variables }).pipe(
      map((responseBody: any) => {
        if (responseBody.errorCode) {
          throw new Error(responseBody.message);
        }

        return (
          responseBody && responseBody.data && responseBody.data.databaseStop
        );
      })
    );
  }

  postDatabaseSiteResize(site: Site, selectedSize: string): Observable<any> {
    const mutation: any = gql`
      mutation postDatabaseSiteResize($payload: any, $input: any) {
        databaseResize(payload: $payload, input: $input)
          @rest(
            type: "Boolean"
            path: "/{args.payload.supportSiteId}/resize?instanceType={args.payload.instanceType}"
            method: "POST"
            endpoint: "icaws"
          ) {
          message
        }
      }
    `;

    const variables: any = {
      payload: {
        supportSiteId: site.supportSiteId || site.siteId,
        instanceType: selectedSize,
      },
      input: {},
    };

    return this.apollo.mutate({ mutation, variables }).pipe(
      map((responseBody: any) => {
        if (responseBody.errorCode) {
          throw new Error(responseBody.message);
        }

        return (
          responseBody && responseBody.data && responseBody.data.databaseResize
        );
      })
    );
  }

  putDatabaseSiteScale(site: Site, requestBody: any): Observable<any> {
    const mutation: any = gql`
      mutation putDatabaseSiteScale(
        $supportSiteId: string!
        $requestBody: any!
      ) {
        databaseSiteScale(supportSiteId: $supportSiteId, input: $requestBody)
          @rest(
            type: "Boolean"
            path: "/{args.supportSiteId}/scale"
            method: "PUT"
            endpoint: "icaws"
          ) {
          message
        }
      }
    `;

    const variables: any = {
      supportSiteId: site.supportSiteId || site.siteId,
      requestBody,
    };

    return this.apollo.mutate({ mutation, variables }).pipe(
      map((responseBody: any) => {
        if (responseBody.errorCode) {
          throw new Error(responseBody.message);
        }

        return (
          responseBody &&
          responseBody.data &&
          responseBody.data.databaseSiteScale
        );
      })
    );
  }

  putDatabaseSiteEbsScaling(site: Site, requestBody: any): Observable<any> {
    const mutation: any = gql`
      mutation putDatabaseSiteEbsScale(
        $supportSiteId: string!
        $requestBody: any!
      ) {
        databaseSiteEbsScale(
          supportSiteId: $supportSiteId
          input: $requestBody
        )
          @rest(
            type: "Boolean"
            path: "/{args.supportSiteId}/ebs"
            method: "PUT"
            endpoint: "icaws"
          ) {
          message
        }
      }
    `;

    const variables: any = {
      supportSiteId: site.supportSiteId || site.siteId,
      requestBody,
    };

    return this.apollo.mutate({ mutation, variables }).pipe(
      map((responseBody: any) => {
        if (responseBody.errorCode) {
          throw new Error(responseBody.message);
        }

        return (
          responseBody &&
          responseBody.data &&
          responseBody.data.databaseSiteEbsScale
        );
      })
    );
  }

  postDatabaseSqleAppStart(site: Site): Observable<any> {
    const mutation: any = gql`
      mutation postDatabaseSqleAppStart($supportSiteId: string, $input: any) {
        databaseSqleAppStart(supportSiteId: $supportSiteId, input: $input)
          @rest(
            type: "Boolean"
            path: "/{args.supportSiteId}/sqlengines/{args.supportSiteId}/jobs"
            method: "POST"
            endpoint: "icawsSqle"
          ) {
          message
        }
      }
    `;

    const variables: any = {
      supportSiteId: site.supportSiteId || site.siteId,
      input: {
        action_name: 'start',
        params: {},
      },
    };

    return this.apollo.mutate({ mutation, variables }).pipe(
      map((responseBody: any) => {
        if (responseBody.errorCode) {
          throw new Error(responseBody.message);
        }

        return (
          responseBody &&
          responseBody.data &&
          responseBody.data.databaseSqleAppStart
        );
      })
    );
  }

  postDatabaseSqleAppStop(site: Site): Observable<any> {
    const mutation: any = gql`
      mutation postDatabaseSqleAppStop($supportSiteId: string, $input: any) {
        databaseSqleAppStop(supportSiteId: $supportSiteId, input: $input)
          @rest(
            type: "Boolean"
            path: "/{args.supportSiteId}/sqlengines/{args.supportSiteId}/jobs"
            method: "POST"
            endpoint: "icawsSqle"
          ) {
          message
        }
      }
    `;

    const variables: any = {
      supportSiteId: site.supportSiteId || site.siteId,
      input: {
        action_name: 'stop',
        params: {
          message: 'Stopping the sqle engine on user request from IMC',
        },
      },
    };

    return this.apollo.mutate({ mutation, variables }).pipe(
      map((responseBody: any) => {
        if (responseBody.errorCode) {
          throw new Error(responseBody.message);
        }

        return (
          responseBody &&
          responseBody.data &&
          responseBody.data.databaseSqleAppStop
        );
      })
    );
  }

  postDatabaseSqleAppResize(site: Site, selectedSize: string): Observable<any> {
    const mutation: any = gql`
      mutation postDatabaseSqleAppResize($supportSiteId: string, $input: any) {
        databaseSqleAppResize(supportSiteId: $supportSiteId, input: $input)
          @rest(
            type: "Boolean"
            path: "/{args.supportSiteId}/sqlengines/{args.supportSiteId}/jobs"
            method: "POST"
            endpoint: "icawsSqle"
          ) {
          message
        }
      }
    `;

    const variables: any = {
      supportSiteId: site.supportSiteId || site.siteId,
      input: {
        action_name: 'resize',
        params: {
          aws_type: selectedSize,
          inplace: true,
        },
      },
    };

    return this.apollo.mutate({ mutation, variables }).pipe(
      map((responseBody: any) => {
        if (responseBody.errorCode) {
          throw new Error(responseBody.message);
        }

        return (
          responseBody &&
          responseBody.data &&
          responseBody.data.databaseSqleAppResize
        );
      })
    );
  }

  postDatabaseSqleAppScale(site: Site, targetScale: any): Observable<any> {
    const mutation: any = gql`
      mutation postDatabaseSqleAppScale($supportSiteId: string, $input: any) {
        databaseSqleAppScale(supportSiteId: $supportSiteId, input: $input)
          @rest(
            type: "Boolean"
            path: "/{args.supportSiteId}/sqlengines/{args.supportSiteId}/jobs"
            method: "POST"
            endpoint: "icawsSqle"
          ) {
          message
        }
      }
    `;

    const variables: any = {
      supportSiteId: site.supportSiteId || site.siteId,
      input: {
        action_name: 'scale_factor',
        params: {
          target_scale: targetScale,
        },
      },
    };

    return this.apollo.mutate({ mutation, variables }).pipe(
      map((responseBody: any) => {
        if (responseBody.errorCode) {
          throw new Error(responseBody.message);
        }

        return (
          responseBody &&
          responseBody.data &&
          responseBody.data.databaseSqleAppScale
        );
      })
    );
  }

  postDatabaseSqleAppEbsScaling(
    site: Site,
    enteredNewVolume: number
  ): Observable<any> {
    const mutation: any = gql`
      mutation postDatabaseSqleAppEbsScaling(
        $supportSiteId: string
        $input: any
      ) {
        databaseSqleAppEbsScaling(supportSiteId: $supportSiteId, input: $input)
          @rest(
            type: "Boolean"
            path: "/{args.supportSiteId}/sqlengines/{args.supportSiteId}/jobs"
            method: "POST"
            endpoint: "icawsSqle"
          ) {
          message
        }
      }
    `;

    const variables: any = {
      supportSiteId: site.supportSiteId || site.siteId,
      input: {
        action_name: 'expand_volume',
        params: {
          target_total: enteredNewVolume,
        },
      },
    };

    return this.apollo.mutate({ mutation, variables }).pipe(
      map((responseBody: any) => {
        if (responseBody.errorCode) {
          throw new Error(responseBody.message);
        }

        return (
          responseBody &&
          responseBody.data &&
          responseBody.data.databaseSqleAppEbsScaling
        );
      })
    );
  }
}
