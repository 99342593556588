export enum HsnStatus {
  NOT_LAUNCHED = 'not launched',
  STOPPED = 'stopped',
  STARTED = 'started',
  LAUNCH_IN_PROGRESS = 'launch in progress',
  LAUNCH_FAILED = 'launch failed',
  UPDATE_IN_PROGRESS = 'update in progress',
  UPDATE_FAILED = 'update failed',
  DELETE_IN_PROGRESS = 'delete in progress',
  DELETE_FAILED = 'delete failed',
  RESIZE_IN_PROGRESS = 'resize in progress',
  RESIZE_FAILED = 'resize failed',
  STOP_IN_PROGRESS = 'stop in progress',
  STOP_FAILED = 'stop failed',
  START_IN_PROGRESS = 'start in progress',
  START_FAILED = 'start failed',
}

export enum HsnState {
  START,
  STOP,
  CHANGE,
  FAIL,
}

export enum HsnOperation {
  Launch = 'hsnlaunch',
  Update = 'hsnupdate',
  Delete = 'hsndelete',
}

export const hsnStatusStrings: any = {
  started: {
    dbState: HsnState.START,
    dbMessage: 'Hot Standbys Running',
  },
  stopped: {
    dbState: HsnState.STOP,
    dbMessage: 'Hot Standbys Stopped',
  },
  'not launched': {
    dbState: HsnState.STOP,
    dbMessage: 'Hot Standbys Not Launched',
  },
  'launch in progress': {
    dbState: HsnState.CHANGE,
    dbMessage: 'Launch Hot Standbys In-Progress',
  },
  'delete in progress': {
    dbState: HsnState.CHANGE,
    dbMessage: 'Delete Hot Standbys In-Progress',
  },
  'start in progress': {
    dbState: HsnState.CHANGE,
    dbMessage: 'Start Hot Standbys In-Progress',
  },
  'stop in progress': {
    dbState: HsnState.CHANGE,
    dbMessage: 'Stop Hot Standbys In-Progress',
  },
  'resize in progress': {
    dbState: HsnState.CHANGE,
    dbMessage: 'Resize Hot Standbys In-Progress',
  },
  'update in progress': {
    dbState: HsnState.CHANGE,
    dbMessage: 'Update Hot Standbys In-Progress',
  },
  'launch failed': {
    dbState: HsnState.FAIL,
    dbMessage: 'Launch Hot Standbys Failed',
  },
  'delete failed': {
    dbState: HsnState.FAIL,
    dbMessage: 'Delete Hot Standbys Failed',
  },
  'start failed': {
    dbState: HsnState.FAIL,
    dbMessage: 'Start Hot Standbys Failed',
  },
  'stop failed': {
    dbState: HsnState.FAIL,
    dbMessage: 'Stop Hot Standbys Failed',
  },
  'resize failed': {
    dbState: HsnState.FAIL,
    dbMessage: 'Resize Hot Standbys Failed',
  },
};
