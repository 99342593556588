export interface IndexedBackupJobs {
  [key: string]: BackupJob[];
}

export interface BackupJob {
  jobDetails: BackupJobDetails;
  jobBackupSets: BackupSet[];
}

export interface BackupJobDetails {
  jobId: string;
  customerId: string;
  siteId: string;
  jobSchedule: JobSchedule;
  jobType: string;
  retryPolicy: {
    retryDelay: number;
    numberOfAttempts: number;
  };
  status: string;
  enabled: boolean;
  name: string;
  description: string;
}

export interface JobSchedule {
  scheduleTimeZone: string;
  repeatTimes: string;
  startTime: string;
  runInterval: string; // ISO Duration
  owner: string;
}

export interface BackupSet {
  jobId: string;
  backupsetId: string;
  customerId: string;
  siteId: string;
  size: number; // TB?? TIB?? Cats??
  timeTaken: number; // Seconds
  startTime: number;
  endTime: number;
  status: BackupSetStatus;
  backupType: string;
}

export type BackupSetStatus = 'successful' | 'failed';

export const BackupSetStatuses = {
  Successful: 'successful' as BackupSetStatus,
  Failed: 'failed' as BackupSetStatus,
};

export interface Job {
  jobId: number;
  customerId?: string;
  name: string;
  siteId?: string;
  systemName?: string;
  isActive?: boolean;
  backupType?: string;
  jobType?: string;
  jobTargetType?: string;
  status?: string;
  description?: string;
  noOfRetentionCopies?: number;
  nextRunStartTime?: string;
  runInterval?: number;
  runUnit?: string;
  backupJobSchedule?: BackupJobSchedule;
  isAutoAbortActive?: boolean;
  autoAbortInMin?: number;
}

export interface JobRun {
  runId: number;
  backupSetId: number;
  backupSetSize: string;
  status: string;
  status_details: string;
  dsaJobStatus: string;
  startTime: string;
  endTime: string;
  skippedObjects: any;
  isAbortReady: boolean;
  isAbortRequested: boolean;
  isRetained?: boolean;
}

export interface BaasActivityResponse {
  eventId: string;
  eventType: string;
  eventInfo: any;
  createdAt: string;
  jobId: number;
}

export enum BaasActivityType {
  UPDATE_DSA_JOB_DEFINITION_ENQUEUED = 'UPDATE_DSA_JOB_DEFINITION_ENQUEUED',
  UPDATE_DSA_JOB_DEFINITION_STATUS = 'UPDATE_DSA_JOB_DEFINITION_STATUS',
}

export enum BackupType {
  FULL = 'FULL',
  DELTA = 'DELTA',
  CUMULATIVE = 'CUMULATIVE',
}

export enum JobType {
  BACKUP = 'BACKUP',
  RESTORE = 'RESTORE',
}

export enum JobCreationStatus {
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
  NEW = 'NEW',
  PENDING = 'PENDING',
  JOB_CREATED_SCHEDULE_NOT_UPDATED = 'JOB_CREATED_SCHEDULE_NOT_UPDATED',
}

export enum JobStatus {
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
  NEW = 'NEW',
  JOB_RESTARTED = 'JOB_RESTARTED',
  MAX_RETRY_LIMIT_REACHED = 'MAX_RETRY_LIMIT_REACHED',
  RUNNING = 'RUNNING',
  QUEUED = 'QUEUED',
  PAUSED = 'PAUSED',
  INVALID = 'INVALID',
  MIGRATED = 'MIGRATED',
  ABORTED = 'ABORTED',
}

export interface BackupJobSchedule {
  scheduleTimeZone: string;
  repeatTimes: string;
  retainedCopies: number;
  startDate: string;
  startByTime: string;
  runUnit: string;
  runInterval: number; // ISO Duration
  owner: string;
}

export interface SkippedObject {
  objectName: string;
  objectType: string;
  parentName: string;
  parentType?: string;
}

export interface Credentials {
  username: string;
  password: string;
}

export interface SkippedObjectsPatch {
  jobId: any;
  objectList: SkippedObject[];
  credentials?: Credentials;
}

export class BackupObject {
  objectName: string;
  byteCount: number;
  status: string;
  rowCount: number;
  errorCode: string;
  warningCode: string;
  objectType: string;
  parentName: string;
  endTime: number;
  errorText: string;
  warningText: string;
  phase: string;
  ancestorType?: string; // -- Supposed to come from API, but since TDRest isnt in place, taking from user
  ancestorName?: string; // -- Supposed to come from API, but since TDRest isnt in place, taking from user
  parentType?: string; // -- Supposed to come from API, but since TDRest isnt in place, taking from user
}

export interface SkippedObjectUIMetadata {
  selected: boolean;
}

export const DefaultSiteFlags: {
  enabled: boolean;
  skippedObjectsEnabled: boolean;
  backupRetentionEnabled: boolean;
} = {
  skippedObjectsEnabled: false,
  enabled: true,
  backupRetentionEnabled: false,
};
