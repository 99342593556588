import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Availability } from '../models/availability.model';

@Injectable()
export class AvailabilityService {
  constructor(private readonly _apollo: Apollo) {}

  getSiteAvailability(
    months: number,
    customerId?: string
  ): Observable<Availability[]> {
    const customerIdInfo: string = customerId ? `/${customerId}` : '';
    const availabilityQuery: any = gql`
      query availability {
        availability(months: $months, customerId: $customerId)
          @rest(
            type: "Availability"
            path: "/service-health/availabilityReports{args.customerId}?monthsAgo={args.months}"
          ) {
          siteId
          availabilityPercentage
          reportId
          targetSLA
        }
      }
    `;

    return this._apollo
      .query<any>({
        query: availabilityQuery,
        variables: { months, customerId: customerIdInfo },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((responseBody: any) => {
          if (responseBody.errors) {
            throw new Error('Fetching availability information failed');
          }

          return responseBody.data.availability;
        })
      );
  }
}
