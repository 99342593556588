import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Ticket } from '../models/ticket.model';

@Injectable()
export class TicketsService {
  constructor(private readonly _apollo: Apollo) {}

  getTickets(
    limit: number,
    customerId?: string,
    priority?: string,
    status?: string
  ): Observable<Ticket[]> {
    let path: string = `/management-console/tickets?limit=${limit}`;

    if (customerId !== null && customerId !== undefined) {
      path = `${path}&customerId=${customerId}`;
    }
    if (priority !== null && priority !== undefined) {
      path = `${path}&priority=${priority}`;
    }
    if (status !== null && status !== undefined) {
      path = `${path}&status=${status}`;
    }

    const ticketsQuery: any = gql`
      query tickets {
        tickets(path: $path) @rest(type: "Ticket", path: $path) {
          updatedOn
          priority
          state
          status
          impact
          shortDescription
          number
          ticketId
          ticketType
          customerName
        }
      }
    `;

    return this._apollo
      .query<any>({
        query: ticketsQuery,
        variables: { path },
      })
      .pipe(
        map((responseBody: any) => {
          if (responseBody.errors) {
            throw new Error('There was an error in fetching support tickets');
          }

          return responseBody.data.tickets;
        })
      );
  }
}
