import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TdChartComponent } from '@covalent/echarts/base';
import { SystemUsage } from '@imc-features/dashboard/models/usage.model';
import { SystemUsageService } from '@imc-features/dashboard/services/system-usage.service';
import { ColorPaletteService } from '@imc/core/services/color-palette/color-palette.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Customer } from '../../models/customer.model';

@Component({
  selector: 'imc-system-usage',
  templateUrl: './system-usage.component.html',
  styleUrls: ['./system-usage.component.scss'],
})
export class SystemUsageComponent implements OnInit, OnDestroy {
  private getSystemUsageSubscription: Subscription;
  @ViewChild('systemUsageChart') systemUsageChart: TdChartComponent;
  azure: any[] = [];
  aws: any[] = [];
  tmc: any[] = [];
  config: object = {};

  loaderKey: string = 'systemUsageDataLoader';
  @Input() loading: boolean = true;
  @Input() error: boolean;

  awsColorCode: string;
  azureColorCode: string;
  tmcColorCode: string;

  customerMap: Map<string, Customer> = new Map();

  constructor(
    private systemUsageService: SystemUsageService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _colorPaletteService: ColorPaletteService
  ) {}

  @Input()
  set inputCustomers(customers: Customer[]) {
    this.customerMap.clear();

    if (customers.length === 0) {
      return;
    }

    customers.forEach((customer: Customer) => {
      this.customerMap.set(customer.customerId, customer);
    });

    this.getSystemUsage();
  }

  symbolSize(data: number[]): number {
    return (data[0] + data[1]) / 4; // this can be customized based on the size of the circle
  }

  ngOnInit(): void {
    this.awsColorCode = this._colorPaletteService.getAwsColor();
    this.azureColorCode = this._colorPaletteService.getAzureColor();
    this.tmcColorCode = this._colorPaletteService.getTMCColor();
  }

  ngOnDestroy(): void {
    if (this.getSystemUsageSubscription) {
      this.getSystemUsageSubscription.unsubscribe();
    }
  }

  getSystemUsage(): void {
    this.loading = true;
    const today: Date = new Date();
    today.setMinutes(0, 0, 0);

    const oneMonthAgo: Date = new Date(today);
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    this.systemUsageService
      .getSystemUsage(oneMonthAgo.toISOString(), today.toISOString())
      .pipe(
        finalize(() => {
          this.loading = false;
          this.refresh();
        })
      )
      .subscribe(
        (usages: SystemUsage[]) => {
          // validate if the system usage data is for a valid customer. Usage service might have customers who are not valid anymore.
          // TODO  to refactor system usage service to return data only for valid customers

          usages
            .filter((usage: SystemUsage) =>
              this.customerMap.has(usage.site.customerId)
            )
            .forEach((usage: SystemUsage) => {
              const statistic: any[] = usage.statistics
                ? [
                    usage.statistics.disk
                      ? parseFloat(usage.statistics.disk.utilization.toFixed(2))
                      : 0,
                    usage.statistics.cpu
                      ? parseFloat(usage.statistics.cpu.utilization.toFixed(2))
                      : 0,
                    usage.site.siteId,
                    this.customerMap.get(usage.site.customerId).name,
                  ]
                : [];
              const platform: string = usage.site
                ? usage.site.cloudPlatform
                : undefined;

              if (platform !== null && platform !== undefined) {
                switch (platform.toLowerCase()) {
                  case 'aws':
                    this.aws.push(statistic);
                    break;
                  case 'azure':
                    this.azure.push(statistic);
                    break;
                  case 'tmc':
                    this.tmc.push(statistic);
                    break;
                  default:
                  // do nothing for any other case
                }
              }
            });
        },
        () => {
          this.error = true;
        }
      );
  }

  refresh(): void {
    // tslint:disable-next-line:no-string-literal
    if (!this._changeDetectorRef['destroyed']) {
      this._changeDetectorRef.markForCheck();
      this.systemUsageChart.render();
    }
  }
}
