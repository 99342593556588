import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { forkJoin, from, Observable } from 'rxjs';
import { first, map, mergeMap, retry, switchMap, tap } from 'rxjs/operators';

import { listSites, Site } from '@imc/core';

@Injectable()
export class AvailabilityService {
  constructor(private readonly apollo: Apollo) {}

  public getSlasForCustomer(months: number): Observable<any> {
    return forkJoin(
      this.getSites(),
      this.getSitesSlaReportsForCustomer(months)
    ).pipe(
      map(([sitesMap, slas]: [Map<string, any>, any[]]) =>
        createReport(slas, sitesMap, true)
      )
    );
  }

  public getSLAs(months: number): Observable<any> {
    const customers$: Observable<any[]> = this.getCustomers({
      fields: 'customerId,name,customerOnboard,u_teradata_customer_number',
    });
    const sites$: Observable<Map<string, any>> = this.getSites();
    let sitesMap: Map<string, any>;

    const slaReport$: Observable<any> = forkJoin(customers$, sites$).pipe(
      tap((customersSites: any) => {
        sitesMap = customersSites[1];
      }),
      // tslint:disable-next-line:no-unnecessary-callback-wrapper
      switchMap((customersSites: any) => from(customersSites[0])),
      mergeMap((customer: any) =>
        this.getSitesSlaReports(customer.customerId, customer.name, months)
      ),
      map((slas: any[]) => createReport(slas, sitesMap, false))
    );

    return slaReport$;
  }

  public publishReport(report: any): Observable<any> {
    const mutation = gql`
      mutation publish($customerId: String!, $input: String!) {
        report(customerId: $customerId, input: $input)
          @rest(
            type: "slaReport"
            method: "PUT"
            path: "/service-health/availabilityReports/publish/{args.customerId}"
          ) {
          availabilityPercentage
          cloudPlatform
          completed
          customerId
          endDate
          outageTime
          outages
          published
          reportId
          siteId
          startDate
          publishedDate
          publisherUserId
        }
      }
    `;

    return this.apollo
      .mutate({
        mutation,
        variables: {
          customerId: report.customerId,
          input: {
            reportId: `${report.siteId}:${report.reportId}`,
          },
        },
      })
      .pipe(
        map((responseBody) => {
          if (!responseBody || responseBody.errorCode) {
            throw new Error('Unable to publish availability report');
          }

          const report: any =
            responseBody && responseBody.data && responseBody.data.report;
          report.availability = truncate(report.availabilityPercentage, 2);

          return report;
        })
      );
  }

  private getSites(): Observable<Map<string, Site>> {
    return listSites(this.apollo).pipe(
      map((sites: Site[]) => {
        const sitesMap: Map<string, Site> = new Map<string, Site>();
        sites.forEach((item: any) => sitesMap.set(item.siteId, item));

        return sitesMap;
      }),
      first()
    );
  }

  private getCustomers(request: any): Observable<any[]> {
    const listCustomersQuery: any = gql`
      query listCustomers {
        listCustomersResponse(fields: $fields)
          @rest(type: "Customer", path: "/sites-service/v2/customers?{args}") {
          customerId
          name
          cloudId
          customerOnboard
          number: u_teradata_customer_number
        }
      }
    `;

    return this.apollo
      .query<any>({
        query: listCustomersQuery,
        variables: {
          fields: request.fields,
        },
      })
      .pipe(
        map((responseBody: any) => {
          if (responseBody.errors) {
            throw new Error(JSON.stringify(responseBody.errors));
          }

          return (
            (responseBody.data && responseBody.data.listCustomersResponse) || []
          );
        }),
        map((customers: any[]) =>
          customers
            .filter((customer: any) => customer.customerOnboard === 'true')
            .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
        ),
        first()
      );
  }

  private getSitesSlaReports(
    customerId: string,
    customerName: string,
    months: number
  ): Observable<any> {
    const _query: string = gql`
      {
        slaReports(customerId: $customerId, months: $months)
          @rest(
            type: "slaReport"
            path: "/service-health/availabilityReports/{args.customerId}?monthsAgo={args.months}"
          ) {
          availabilityPercentage
          cloudPlatform
          completed
          customerId
          endDate
          outageTime
          outages
          published
          reportId
          siteId
          startDate
          publishedDate
          publisherUserId
        }
      }
    `;

    return this.apollo
      .query<any>({
        query: _query,
        variables: { customerId, months },
      })
      .pipe(
        map((responseBody: ApolloQueryResult<any>) => {
          if (responseBody.errors) {
            throw new Error(JSON.stringify(responseBody.errors));
          }

          return (responseBody.data && responseBody.data.slaReports) || [];
        }),
        map((slaReports: any[]) =>
          slaReports.map((slaReport: any) => ({ ...slaReport, customerName }))
        ),
        retry(2)
      );
  }

  private getSitesSlaReportsForCustomer(months: number): Observable<any> {
    const _query: string = gql`
      {
        slaReports(months: $months)
          @rest(
            type: "slaReport"
            path: "/service-health/availabilityReports?monthsAgo={args.months}"
          ) {
          availabilityPercentage
          cloudPlatform
          completed
          endDate
          outageTime
          outages
          published
          reportId
          siteId
          startDate
          publishedDate
          publisherUserId
        }
      }
    `;

    return this.apollo
      .query<any>({
        query: _query,
        variables: { months },
      })
      .pipe(
        map((responseBody: ApolloQueryResult<any>) => {
          if (responseBody.errors) {
            throw new Error(JSON.stringify(responseBody.errors));
          }

          return (responseBody.data && responseBody.data.slaReports) || [];
        }),
        retry(2),
        first()
      );
  }
}

const truncate: Function = (
  originalNumber: number,
  decimalAccuracy: number
): string => {
  const truncated: string[] = originalNumber
    .toString()
    .match(new RegExp(`(\\d+\\.\\d{${decimalAccuracy}})(\\d)`));

  return truncated
    ? parseFloat(truncated[1]).toFixed(decimalAccuracy)
    : originalNumber.valueOf().toFixed(decimalAccuracy);
};

const reduceSysopsReport: Function = (
  agg: any,
  report: any,
  sitesMap: Map<string, Site>,
  customerInfo: any
): any => {
  report.availability = truncate(report.availabilityPercentage, 2);
  const site: any = sitesMap.get(report.siteId);
  const currentSiteTargetSla: number = ['aws', 'azure', 'tmc'].includes(
    site.cloudPlatform
  )
    ? site.targetSLA
      ? site.targetSLA
      : 99.9
    : undefined;
  report.siteDisplayName = site.displayName;
  report.sitePurpose = site.purpose;
  report.targetSLA = report.targetSLA ? report.targetSLA : currentSiteTargetSla;
  if (!agg[report.siteId]) {
    agg[report.siteId] = {
      ...customerInfo,
      siteDisplayName: report.siteDisplayName,
      sitePurpose: report.sitePurpose,
      siteId: report.siteId,
      cloudPlatform: site.cloudPlatform,
      currentSiteTargetSla,
      averageAvailability: 0,
      lowestAvailability: 100,
      totalAvailability: 0,
      totalReports: 0,
      reports: {},
    };
  }

  const r: any = agg[report.siteId];
  r.totalReports++;
  r.lowestAvailability = Math.min(r.lowestAvailability, report.availability);
  r.totalAvailability += report.availabilityPercentage;
  r.averageAvailability = truncate(r.totalAvailability / r.totalReports, 2);
  const reportMonth: string = report.startDate.substr(0, 7);
  report.month = reportMonth;
  r.reports[reportMonth] = report;

  return agg;
};

const reduceCustomerReport: Function = (
  agg: any,
  report: any,
  sitesMap: Map<string, Site>
): any => {
  report.availability = truncate(report.availabilityPercentage, 2);
  const site: any = sitesMap.get(report.siteId);
  const currentSiteTargetSla: number = site.targetSLA
    ? site.targetSLA
    : undefined;
  report.siteDisplayName = site.displayName;
  report.sitePurpose = site.purpose;
  report.targetSLA = report.targetSLA ? report.targetSLA : currentSiteTargetSla;
  if (!agg[report.siteId]) {
    agg[report.siteId] = {
      siteDisplayName: report.siteDisplayName,
      siteId: report.siteId,
      cloudPlatform: site.cloudPlatform,
      currentSiteTargetSla,
      reports: {},
    };
  }

  const r: any = agg[report.siteId];
  const reportMonth: string = report.startDate.substr(0, 7);
  report.month = reportMonth;
  r.reports[reportMonth] = report;

  return agg;
};

const createReport: Function = (
  slas: any[],
  sitesMap: Map<string, Site>,
  isCustomer: boolean
): any[] => {
  const pivot: any = slas
    .filter(
      (report: any) =>
        sitesMap.has(report.siteId) && sitesMap.get(report.siteId).cloudPlatform
    )
    .reduce(
      (agg: any, report: any) =>
        isCustomer
          ? reduceCustomerReport(agg, report, sitesMap)
          : reduceSysopsReport(agg, report, sitesMap, {
              customerId: report.customerId,
              customerName: report.customerName,
            }),
      {}
    );

  return Object.values(pivot);
};
