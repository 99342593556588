import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
} from '@angular/core';

import { TdLoadingService } from '@covalent/core/loading';

import { UserService } from '@imc-features/monitoring/services/user.service';
import { User } from '@imc-features/users/models/user.model';
import { Site } from '@imc/core/models/sites.model';
import { AppConfigService } from '@imc/core/services/app-config/app-config.service';
import { OAuthenticationService } from '@imc/core/services/authentication/authentication.service';
import { ErrorService } from '@imc/core/services/error-handling/error.service';
import { PolicyService } from '@imc/core/services/policy-service/policy.service';
import { Subscription } from 'rxjs';
import { BackupJob } from '../../models/backup-job.model';
import { BackupsService } from '../../services/backups.service';

const numBackupJobs: number = 5;

@Component({
  selector: 'imc-recent-backups',
  templateUrl: './recent-backups.component.html',
  styleUrls: ['./recent-backups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecentBackupsComponent implements OnChanges, OnDestroy {
  private customerSubscription: Subscription;
  @Input() site: Site;
  siteId: string;
  customerId: string;
  backupJobs: BackupJob[];

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _backupsService: BackupsService,
    private readonly _loadingService: TdLoadingService,
    private readonly _errorService: ErrorService,
    private readonly _appConfig: AppConfigService,
    private readonly _authService: OAuthenticationService,
    private readonly _policyService: PolicyService,
    private readonly _userService: UserService
  ) {}

  async ngOnChanges(): Promise<void> {
    if (this.site) {
      this.customerSubscription = this._policyService.isCustomer$.subscribe(
        async (isCustomer: boolean) => {
          this._loadingService.register('recent-backups');
          this.customerId = isCustomer
            ? await this._userService.getCustomerId()
            : this.site.customerId;
          this.siteId = this.site.siteId;
          await this.getLastNumBackupJobs();
          this._changeDetectorRef.markForCheck();
          this._loadingService.resolve('recent-backups');
        }
      );
    }
  }

  async getCustomerId(): Promise<void> {
    try {
      const customerId: string = `${
        this._appConfig.getConfig().auth.namespace
      }customerId`;
      const userIdentity: User = await this._authService
        .getIdentityClaims()
        .toPromise();
      this.customerId = userIdentity[customerId];
    } catch (error) {
      setTimeout(() => {
        this._errorService.open(error);
      });
    }
  }

  async getLastNumBackupJobs(): Promise<void> {
    try {
      const backupJobs: BackupJob[] = await this._backupsService
        .getBackupJobsForSite(this.siteId, this.customerId)
        .toPromise();
      this.backupJobs = backupJobs.slice(0, numBackupJobs);
    } catch (error) {
      setTimeout(() => {
        this._errorService.open(error);
      });
    }
  }

  ngOnDestroy(): void {
    if (this.customerSubscription) {
      this.customerSubscription.unsubscribe();
    }
  }
}
