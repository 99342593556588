import { Component, Inject, OnDestroy } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Event, NavigationEnd, Router } from '@angular/router';
import { AssumeRoleUri } from '@imc/core';
import { UserRole } from '@imc/core/models/role.model';
import { PolicyUserRolesDetails } from '@imc/core/models/roles.enum';
import { UserRoleInfo } from '@imc/core/models/userRoleInfo.model';
import {
  AppConfigService,
  OAuthenticationService,
  PolicyService,
} from '@imc/core/services';
import { Apollo } from 'apollo-angular';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { CachePersistor } from 'apollo-cache-persist';
import gql from 'graphql-tag';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { flatMap, map, take } from 'rxjs/operators';

@Component({
  selector: 'imc-app',
  templateUrl: './app.component.html',
  styles: [],
})
export class AppComponent implements OnDestroy {
  private get userId(): string | undefined {
    return this.extractCustomClaim('userId');
  }

  private get customerId(): string | undefined {
    return this.extractCustomClaim('customerId');
  }

  private get serviceNowUserId(): string | undefined {
    return this.extractCustomClaim('serviceNowUserId');
  }

  private get sub(): string | undefined {
    if (!AppComponent.isEmptyObject(this.identityClaims)) {
      return this.identityClaims.sub;
    }

    return undefined;
  }

  private static readonly blacklistTokenUrl: string =
    '/management-console/auth/blacklist-token';

  private gaTrackerCreated: boolean = false;
  private identityClaims: any = {};

  private readonly isAuthenticatedSubscription: Subscription;
  private gaSubscription: Subscription;

  private readonly usernameSubject$: BehaviorSubject<
    string
  > = new BehaviorSubject<string>(undefined);
  private readonly emailSubject$: BehaviorSubject<string> = new BehaviorSubject<
    string
  >(undefined);

  public username$: Observable<string> = this.usernameSubject$.asObservable();
  public email$: Observable<string> = this.emailSubject$.asObservable();

  public isAuthenticated: Observable<boolean>;
  public currentRole: UserRole;

  hideBetaToggle: boolean = false;

  tryNewVersion: boolean = true;

  constructor(
    private readonly _iconRegistry: MatIconRegistry,
    private readonly _domSanitizer: DomSanitizer,
    private readonly oauthService: OAuthenticationService,
    private readonly policyService: PolicyService,
    private readonly cookieService: CookieService,
    private readonly appConfig: AppConfigService,
    public router: Router,
    private readonly apollo: Apollo,
    @Inject('ApolloPersistor')
    private readonly persistor: CachePersistor<NormalizedCacheObject>,
    @Inject('localStorage') private readonly localStorage: Storage
  ) {
    this.registerIcons();
    this.isAuthenticated = this.oauthService.isAuthenticated$;
    this.policyService.userRoleInfo$.subscribe((userRoleInfo: UserRoleInfo) => {
      this.currentRole = PolicyUserRolesDetails.find(
        (role: any) => role.policyUserRole === userRoleInfo.currentRole
      );
    });

    this.isAuthenticatedSubscription = this.isAuthenticated
      .pipe(
        flatMap((isAuthenticated: boolean) => {
          if (isAuthenticated) {
            return this.oauthService.getIdentityClaims();
          }

          return of(undefined);
        })
      )
      .subscribe((identityClaims: any) => {
        if (identityClaims) {
          this.identityClaims = identityClaims;
          this.usernameSubject$.next(
            `${identityClaims.given_name} ${identityClaims.family_name}`
          );
          this.emailSubject$.next(identityClaims.email);

          this.disableBetaSwitch();

          // "gaClientId" in local storage is used for Google Analytics. It identifies a client.
          // It is stored in local storage so it will be persisted across sessions if user uses
          // the same browser and visit the same website again.
          if (!localStorage.getItem('gaClientId')) {
            localStorage.setItem('gaClientId', `${this.userId}-${Date.now()}`);
          }

          // Add google analytics tracking
          if (!this.gaTrackerCreated) {
            (<any>window).ga(
              'create',
              this.appConfig.getConfig().instrumentation.gaSiteCode,
              {
                storage: 'none',
                clientId: localStorage.getItem('gaClientId'),
                siteSpeedSampleRate: 100,
              }
            );
            this.gaTrackerCreated = true;

            // Subscribe google analytics pageview event to router only after user is authenticated
            this.gaSubscription = this.router.events.subscribe(
              (event: Event) => {
                if (event instanceof NavigationEnd) {
                  (<any>window).ga('set', 'page', event.urlAfterRedirects);
                  (<any>window).ga('send', 'pageview', {
                    dimension1: this.userId,
                    dimension2: this.customerId,
                    dimension3: this.serviceNowUserId,
                    dimension4: this.sub,
                  });
                }
              }
            );
          }
        } else {
          // stops the google analytics tracking if it has subscribed
          if (this.gaSubscription) {
            this.gaSubscription.unsubscribe();
          }
        }
      });

    this.oauthService.runInitialLoginSequence();

    // policy call at login
    this.policyService.setupPolicyRole();
  }

  private static isEmptyObject(item: any): boolean {
    return item.constructor === Object && Object.keys(item).length === 0;
  }

  public ngOnDestroy(): void {
    if (this.isAuthenticatedSubscription) {
      this.isAuthenticatedSubscription.unsubscribe();
    }
    if (this.gaSubscription) {
      this.gaSubscription.unsubscribe();
    }
  }

  public tryNewVersionOptionChanged(event: any): void {
    this.tryNewVersion = event.checked;
    this.cookieService.set(
      'tryNewVersion',
      this.tryNewVersion.toString(),
      undefined,
      '/'
    );
    this.prepareLegacyProfile();
    window.location.href = `${
      this.appConfig.getConfig().endpoints.domain
    }/switch?timestamp=${new Date().getTime()}`;
  }

  public logout(): void {
    this.persistor.pause(); // Pause automatic persistence.
    Promise.all([
      this.persistor.purge(), // Purge the current data in persistor (i.e. session storage).
      this.apollo.getClient().clearStore(), // clear apollo in memory cache without refetch.
    ]).catch((error: any) => {
      return; // swallow any error intentionally to allow log out continue.
    });
    this.isAuthenticated
      // oauthService.logout() will emit a new value in isAuthenticate,
      // causing an infinite loop, so we should only take 1 value.
      .pipe(take(1))
      .subscribe(
        (isAuthenticated: boolean) => {
          if (isAuthenticated) {
            this.blacklistToken().subscribe(
              () => this.oauthService.logout(),
              // if blacklist token request fails (e.g. token expired or already black listed),
              // continue logout anyways.
              (error: any) => this.oauthService.logout()
            );
          } else {
            // User is not logged in, no need to call blacklistToken,
            // but will need to clear any state stored in oauth service from the lib,
            // also clear the local storage, and redirect to /login
            this.oauthService.logout();
          }
        },
        (error: any) => {
          this.oauthService.logout();
        }
      );
  }

  public navigateToChangeRolePage(): void {
    this.router.navigate([AssumeRoleUri]);
  }

  private disableBetaSwitch(): void {
    let customersWithBetaSiteOnly: string[] = [];
    if (
      this.appConfig.getConfig().customers &&
      this.appConfig.getConfig().customers.vcm
    ) {
      customersWithBetaSiteOnly = this.appConfig.getConfig().customers.vcm;
    }

    if (customersWithBetaSiteOnly.includes(this.customerId)) {
      this.hideBetaToggle = true;
    }
  }

  private registerIcons(): void {
    // Covalent Icons
    this._iconRegistry.registerFontClassAlias('covalent', 'covalent-icons');

    // SVG Icons
    this._iconRegistry.addSvgIcon(
      'teradata',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/teradata.svg'
      )
    );
    this._iconRegistry.addSvgIcon(
      'teradata-dark',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/teradata-dark.svg'
      )
    );
    this._iconRegistry.addSvgIcon(
      'teradata-solid',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/teradata-solid.svg'
      )
    );
    this._iconRegistry.addSvgIcon(
      'teradata-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/teradata-icon.svg'
      )
    );
    this._iconRegistry.addSvgIcon(
      'size-large',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/machines/size-large.svg'
      )
    );
    this._iconRegistry.addSvgIcon(
      'size-large-white',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/machines/size-large-white.svg'
      )
    );
    this._iconRegistry.addSvgIcon(
      'size-medium',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/machines/size-medium.svg'
      )
    );
    this._iconRegistry.addSvgIcon(
      'size-medium-white',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/machines/size-medium-white.svg'
      )
    );
    this._iconRegistry.addSvgIcon(
      'size-small',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/machines/size-small.svg'
      )
    );
    this._iconRegistry.addSvgIcon(
      'size-small-white',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/machines/size-small-white.svg'
      )
    );
  }

  private extractCustomClaim(name: string): string | undefined {
    if (!AppComponent.isEmptyObject(this.identityClaims)) {
      const claimKey: string = Object.keys(this.identityClaims).find(
        (key: string) => key.includes(name)
      );
      if (claimKey) {
        return this.identityClaims[claimKey];
      }

      return undefined; // name key not found
    }

    return undefined; // identityClaims is an empty object, i.e. not logged in yet.
  }

  private blacklistToken(): Observable<any> {
    const mutation: string = gql`
      mutation blacklistToken($input: any!){
        blacklistToken(input: {}) @rest(
            type: "BlacklistToken"
            path: "${AppComponent.blacklistTokenUrl}"
            method: "POST"
          ) {
            # issuer of the JWT token
            iss
            # id of the JWT token
            jti
            # token expiry time
            ttl
        }
      }`;

    return this.apollo.mutate<any>({ mutation }).pipe(
      map((responseBody: any) => {
        if (responseBody.errors) {
          throw new Error('There was an error blacklisting the token!');
        }

        return responseBody.data.blacklistToken;
      })
    );
  }

  private prepareLegacyProfile(): void {
    const profile: any = {};
    const namespace: string = this.appConfig.getConfig().auth.namespace;
    const regex: RegExp = new RegExp(`^(?:${namespace})(\\w+)`);
    if (!AppComponent.isEmptyObject(this.identityClaims)) {
      Object.keys(this.identityClaims).forEach((key: string) => {
        const matched: RegExpExecArray = regex.exec(key);
        if (matched) {
          profile[matched[1]] = this.identityClaims[key];
        } else {
          profile[key] = this.identityClaims[key];
        }
      });
      localStorage.setItem('profile', JSON.stringify(profile));
    }
  }
}
