import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  DbState,
  DbStatusStrings,
} from '@imc-features/ecosystems/_modules/ecosystems-shared/constants/db-state.constants';
import { ElasticityService } from '@imc-features/ecosystems/_modules/ecosystems-shared/services/elasticity-service/elasticity.service';
import { Site } from '@imc/core/models/sites.model';
import { AppConfigService } from '@imc/core/services';
import { Subscription } from 'rxjs';
import { AwsInstanceInfoService } from '../../services/aws-instance-info-service/aws-instance-info.service';
import { IcawsService } from '../../services/icaws-service/icaws.service';
import { GoogleAnalyticsService } from '@imc/core/services/google-analytics/google-analytics.service';

@Component({
  selector: 'imc-ebs-volume-expansion-database',
  templateUrl: './ebs-volume-expansion-database.component.html',
  styleUrls: ['./ebs-volume-expansion-database.component.scss'],
})
export class EbsVolumeExpansionDatabaseComponent implements OnInit, OnDestroy {
  private dbStatusStrings: any = DbStatusStrings;
  private icawsSubscription: Subscription;
  private useLegacyApis: boolean = true;
  @Input() siteInfo: Site;
  dbStateOptions: any = DbState;
  operationInProgress: boolean = false;

  failedOperation: boolean = false;
  displayStatus: boolean = false;
  dbState: number;
  dbMessage: string = '';
  disableButton: boolean = false;
  isEbsScalingPossible: boolean = true;
  currentStorageValue: number = 0;
  checkBoxSelected: boolean = false;
  newEbsVolume: number;

  analyticsEvent: any = {
    awsExpandVolume: {
      eventKey: 'aws_expand_volume',
    },
  };

  constructor(
    private readonly icawsService: IcawsService,
    private readonly awsInstanceInfoService: AwsInstanceInfoService,
    private readonly appConfig: AppConfigService,
    private readonly elasticityService: ElasticityService,
    private readonly analyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {
    this.useLegacyApis = this.appConfig.getConfig().legacy.sqle;
    if (this.siteInfo.sqleApp) {
      this.getDbInfo();
      const storageSizePerNodeTB: string = this.siteInfo.sqleApp
        .storageSizePerNodeTB;
      if (storageSizePerNodeTB) {
        this.isEbsScalingPossible = this.awsInstanceInfoService.isEbsScalingPossible(
          storageSizePerNodeTB
        );
        if (this.isEbsScalingPossible) {
          this.currentStorageValue = +parseInt(
            storageSizePerNodeTB.split('T')[0],
            10
          );
          this.newEbsVolume = this.currentStorageValue + 1;
        }
      }
    }
    this.newEbsVolume = this.currentStorageValue + 1;
  }

  ngOnDestroy(): void {
    if (this.icawsSubscription) {
      this.icawsSubscription.unsubscribe();
    }
  }

  getDbInfo(): void {
    const dbStatus: string = this.siteInfo.sqleApp.status;
    // Incase the db does not have db status
    if (!dbStatus) {
      this.dbState = this.dbStatusStrings.started.dbState;
      this.dbMessage = this.dbStatusStrings.started.dbMessage;
    } else {
      // Incase siteInfo.dbStatus is not in the mapping, setting default to started
      const status: any = this.dbStatusStrings[dbStatus];
      this.dbState = status
        ? status.dbState
        : this.dbStatusStrings.started.dbState;
      this.dbMessage = status
        ? status.dbMessage
        : this.dbStatusStrings.started.dbMessage;
    }
  }

  onSubmit(formGroup: any): void {
    this.operationInProgress = true;
    if (this.siteInfo.sqleApp) {
      const currDbStatus: string = this.siteInfo.sqleApp.status;
      const currOprStatus: string = this.siteInfo.sqleApp.operationalStatus;
      // Immediate update on UI
      this.disableButton = true;
      this.siteInfo.sqleApp.status = 'ebs-expanding';
      this.siteInfo.sqleApp.operationalStatus = 'not operational';
      this.getDbInfo();
      this.analyticsService.sendAnalyticsEvent(
        this.analyticsEvent.awsExpandVolume
      );
      if (this.useLegacyApis) {
        // call old apis
        this.icawsSubscription = this.icawsService
          .putDatabaseSiteEbsScaling(
            this.siteInfo,
            this.generateRequestBody(this.newEbsVolume)
          )
          .subscribe(
            () => {
              // add any other operations on a success call
              this.operationInProgress = false;
              this.elasticityService.siteDbStatusChanged(this.dbMessage);
              this.elasticityService.refreshSiteInfo();
            },
            () => {
              this.failedOperation = true;
              this.siteInfo.sqleApp.status = currDbStatus;
              this.siteInfo.sqleApp.operationalStatus = currOprStatus;
              this.checkBoxSelected = false;
              this.operationInProgress = false;
            }
          )
          .add(() => {
            this.displayStatus = true;
            this.getDbInfo();
          });
      } else {
        // call new sqle apis
        this.icawsSubscription = this.icawsService
          .postDatabaseSqleAppEbsScaling(this.siteInfo, this.newEbsVolume)
          .subscribe(
            () => {
              // add any other operations on a success call
              this.operationInProgress = false;
              this.elasticityService.siteDbStatusChanged(this.dbMessage);
              this.elasticityService.refreshSiteInfo();
            },
            () => {
              this.failedOperation = true;
              this.siteInfo.sqleApp.status = currDbStatus;
              this.siteInfo.sqleApp.operationalStatus = currOprStatus;
              this.checkBoxSelected = false;
              this.operationInProgress = false;
            }
          )
          .add(() => {
            this.displayStatus = true;
            this.getDbInfo();
          });
      }
    }
  }

  // TODO: Below function is to support v1site
  // can be deprecated once IMC 1.5 is totally integrated with new apis (v2 site)
  private generateRequestBody(selectionMade: number): any {
    const request: any = {
      current_size_tb: this.currentStorageValue,
      desired_size_tb: selectionMade,
    };

    return request;
  }

  private isNaN(input: any): boolean {
    return isNaN(input);
  }

  private checkIfFormValid(input: any): boolean {
    return (
      input !== null &&
      input !== '' &&
      input % 1 === 0 &&
      !isNaN(input) &&
      input > this.currentStorageValue &&
      input <= 60
    );
  }

  private onCheckboxSelected($event: any): void {
    this.checkBoxSelected = $event.checked;
  }
}
