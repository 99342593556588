import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicLayoutComponent } from '@imc-features/ecosystems/_modules/ecosystems-shared/components/dynamic-layout/dynamic-layout.component';
import { Subscription } from 'rxjs';
import { AzureSecurityRulesService } from '../../services/azure-security-rules/azure-security-rules.service';

@Component({
  selector: 'imc-azure-site-overview',
  templateUrl: './azure-site-overview.component.html',
  styleUrls: ['./azure-site-overview.component.scss'],
})
export class AzureSiteOverviewComponent extends DynamicLayoutComponent
  implements OnInit, OnDestroy {
  securityRules: any;
  securityRulesLoading: boolean = false;
  securityRulesError: boolean = false;

  securityRulesSubscription: Subscription;
  constructor(
    private readonly azureSecurityRulesService: AzureSecurityRulesService
  ) {
    super();
  }

  ngOnInit(): void {
    this.securityRulesLoading = true;
    this.securityRulesSubscription = this.azureSecurityRulesService
      .initializeAzureSiteSecurityRules()
      .subscribe(
        (securityRules: any) => {
          this.securityRules = securityRules;
          this.securityRulesLoading = false;
        },
        () => {
          this.securityRulesLoading = false;
          this.securityRulesError = true;
        }
      );
  }

  ngOnDestroy(): void {
    if (this.securityRulesSubscription) {
      this.securityRulesSubscription.unsubscribe();
    }
  }
}
