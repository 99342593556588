import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { PolicyCheckerService } from '../services/policy-checker-service/policy-checker.service';
import { PolicyIdentifierInfo } from '../models/policyIdentifierInfo';
import { ErrorUri } from '../constants/url';

@Injectable()
export class PolicyGuard implements CanActivate {
  constructor(
    private router: Router,
    private policyCheckerService: PolicyCheckerService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return new Observable((observer) => {
      const policyIdentifierInfo: PolicyIdentifierInfo = {
        operation: next.data.operation,
        resource: next.data.resource,
      };
      this.policyCheckerService
        .isEnabled(policyIdentifierInfo)
        .subscribe((canActivate: boolean) => {
          if (canActivate) {
            observer.next(true);
          } else {
            this.router.navigate([ErrorUri], {
              queryParams: {
                error: 'permission-denied',
                error_description:
                  "You don't have permissions to view this page",
                content:
                  'Your current role does not have permissions to view this page. ' +
                  'Try elevating your role or please contact an administrator for assistance.',
              },
            });
            observer.next(false);
          }
        });
    });
  }
}
