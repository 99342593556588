// Metrics Agent Proxy - Start

export enum ProxyFunctionName {
  'site_registration' = 'site_registration',
  'site_un_registration' = 'site_un_registration',
  'network_tester' = 'network_tester',
  'get_site_registration_details' = 'get_site_registration_details',
  'get_viewpoint_system_names' = 'get_viewpoint_system_names',
  'send_metrics_support_mail' = 'send_metrics_support_mail',
}

export enum LambdaInvocationType {
  'RequestResponse' = 'RequestResponse',
  'Event' = 'Event',
}

export interface MetricsAgentProxyFunction {
  name: ProxyFunctionName;
  invocation_type: LambdaInvocationType;
}

export interface MetricsAgentProxyRequest {
  function: MetricsAgentProxyFunction;
  payload?: object;
}

export interface ProxyFunctionResponseData {
  function_return_status: boolean;
  function_data: object;
  function_message: string;
  errorType?: string; // Specal error handling cases
  errorMessage?: string; // Specal error handling cases
}

export interface MetricsAgentProxyResponse {
  status: boolean;
  message: string;
  data: ProxyFunctionResponseData;
}

export interface SiteRegistrationDetails {
  siteId: string;
  vpIpAddress: string;
  systemName: string;
  cloudPlatform: string;
  systemId: string;
  regStatus: string;
  regDetails: string;
  regDate: string;
  lastSuccessCall: string;
  portScanResult: string;
  current_failure: string;
  portUsed: string;
}

export interface ViewpointSystemInfo {
  systemId: number;
  systemName: string;
}

export interface ViewpointSystemList {
  system: ViewpointSystemInfo[];
}

// Metrics Agent Proxy - End
