import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CreateAzureSubscriptionComponent } from '@imc-features/ecosystems/_modules/azure/components/create-azure-subscription/create-azure-subscription.component';
import { EditAzureSubscriptionComponent } from '@imc-features/ecosystems/_modules/azure/components/edit-azure-subscription/edit-azure-subscription.component';
import { SysOpsGuard } from '@imc/core/guards';

import { AwsCreateSitesComponent } from './_modules/aws-provisioning/pages/create-sites-page/create-sites.component';
import { AwsDashboardComponent } from './_modules/aws/pages/aws-dashboard/aws-dashboard.component';
import { AzureCreateSitesComponent } from './_modules/azure-provisioning/pages/create-sites-page/create-sites.component';
import { AzureDashboardComponent } from './_modules/azure/pages/azure-dashboard/azure-dashboard.component';
import { MetricsToolComponent } from './_modules/ecosystems-shared/cards/metrics-tool/metrics-tool.component';
import { SkippedObjectsComponent } from './_modules/legacy-baas/components/backupsTable/skippedObjects/skippedObjects.component';
import { LegacyBaasComponent } from './_modules/legacy-baas/pages/legacy-baas.component';
import { TmcCcuMigrationDetailsComponent } from './_modules/tmc/pages/tmc-ccu-migration-details/tmc-ccu-migration-details.component';
import { TmcDashboardComponent } from './_modules/tmc/pages/tmc-dashboard/tmc-dashboard.component';
import { ListSitesComponent } from './pages/list-sites/list-sites.component';
import { SiteDetailsComponent } from './pages/site-details/site-details.component';
import { SiteElasticityComponent } from './pages/site-elasticity/site-elasticity.component';

const routes: Routes = [
  {
    path: '',
    component: ListSitesComponent,
    canActivate: [SysOpsGuard],
  },
  {
    path: 'platform/tmc/migrations/:migrationId',
    component: TmcCcuMigrationDetailsComponent,
    canActivate: [SysOpsGuard],
  },
  {
    path: 'create/aws/:customerId',
    component: AwsCreateSitesComponent,
    canActivate: [SysOpsGuard],
  },
  {
    path: 'create/azure/:customerId',
    component: AzureCreateSitesComponent,
    canActivate: [SysOpsGuard],
  },
  {
    path: 'platform/tmc',
    component: TmcDashboardComponent,
    canActivate: [SysOpsGuard],
  },
  {
    path: 'platform/aws',
    component: AwsDashboardComponent,
    canActivate: [SysOpsGuard],
  },
  {
    path: 'platform/azure',
    component: AzureDashboardComponent,
    canActivate: [SysOpsGuard],
    children: [
      {
        path: 'edit-subscription/:id',
        component: EditAzureSubscriptionComponent,
        outlet: 'azureRightDrawer',
      },
      {
        path: 'create-subscription',
        component: CreateAzureSubscriptionComponent,
        outlet: 'azureRightDrawer',
      },
    ],
  },
  {
    path: ':siteId/legacy-baas',
    component: LegacyBaasComponent,
  },
  {
    path: ':siteId/legacy-baas/skipped-objects',
    component: SkippedObjectsComponent,
  },
  {
    path: ':siteId/backups',
    loadChildren: './_modules/backups/backups.module#BackupsModule',
  },
  {
    path: 'list-sites/:customerId',
    component: ListSitesComponent,
  },
  {
    path: ':siteId',
    component: SiteDetailsComponent,
    children: [
      {
        path: 'elasticity',
        component: SiteElasticityComponent,
        outlet: 'side',
      },
      {
        path: 'config',
        component: MetricsToolComponent,
        outlet: 'side',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EcosystemsRoutingModule {}
