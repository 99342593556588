import { Component, OnDestroy, OnInit } from '@angular/core';
import { TdMediaService } from '@covalent/core';

import { Subscription } from 'rxjs';
import { Customer } from '../../models/customer.model';
import { DashboardCustomerService } from '../../services/dashboard-customer.service';
@Component({
  selector: 'imc-customer-dashboard',
  templateUrl: './system-dashboard.component.html',
  styleUrls: ['./system-dashboard.component.scss'],
})
export class SystemDashboardComponent implements OnInit, OnDestroy {
  private listCustomersSubscription: Subscription;
  customers: Customer[] = [];
  loading: boolean = true;
  error: boolean;

  constructor(
    public media: TdMediaService,
    private readonly dashboardService: DashboardCustomerService
  ) {}

  ngOnInit(): void {
    this.listCustomersSubscription = this.dashboardService
      .listCustomers()
      .subscribe(
        (customers: Customer[]) => {
          this.customers = customers;
        },
        (error: any) => {
          this.error = true;
        },
        () => {
          this.loading = false;
        }
      );
  }

  ngOnDestroy(): void {
    if (this.listCustomersSubscription) {
      this.listCustomersSubscription.unsubscribe();
    }
  }
}
