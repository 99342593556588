import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetSiteRequest } from '../../graphql/models/get-site-request.model';
import { ListSitesRequest } from '../../graphql/models/list-sites-request.model';
import { getSiteById, listSites } from '../../graphql/sites-service';
import { Customer, Site } from '../../models/sites.model';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  constructor(private apollo: Apollo) {}

  getSiteInfo(request: GetSiteRequest): Observable<Site> {
    return getSiteById(this.apollo, request);
  }

  listSites(request?: ListSitesRequest): Observable<Site[]> {
    return listSites(this.apollo, request);
  }

  listCustomers(): Observable<Map<string, Customer>> {
    const listCustomersQuery: any = gql`
      query listCustomers {
        listCustomersResponse(fields: $fields)
          @rest(type: "Customer", path: "/sites-service/v2/customers?{args}") {
          customerId
          name
        }
      }
    `;

    const fields: string = 'customerId,name';
    const variables: any = { fields };

    return this.apollo
      .query<any>({
        query: listCustomersQuery,
        variables,
      })
      .pipe(
        map((responseBody: any) => {
          if (!responseBody || responseBody.errors) {
            throw new Error(`There was an error in list customers!`);
          }

          return (
            (responseBody.data && responseBody.data.listCustomersResponse) || []
          );
        }),
        map((customers: Customer[]) => {
          const customersMap: Map<string, Customer> = new Map<
            string,
            Customer
          >();
          customers.forEach((item: any) =>
            customersMap.set(item.customerId, item)
          );

          return customersMap;
        })
      );
  }
}
