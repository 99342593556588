import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { TdLoadingService } from '@covalent/core/loading';
import { TdChartComponent } from '@covalent/echarts/base';
import { ErrorService } from '@imc/core/services/error-handling/error.service';

import { MetricDatapoint } from '@imc-features/site-homepage/models/metrics-graph.model';

import { MetricsGraphService } from '@imc-features/site-homepage/services/metrics-graph.service';
import { TranslateService } from '@ngx-translate/core';

export enum TimeRange {
  Month = <any>'month',
  Week = <any>'week',
  Day = <any>'day',
}

@Component({
  selector: 'imc-cpu-utilization',
  templateUrl: './cpu-utilization.component.html',
  styleUrls: ['./cpu-utilization.component.scss'],
})
export class CpuUtilizationComponent implements OnChanges {
  private readonly _errorService: ErrorService;
  @Input() siteId: string;
  @ViewChild('cpuUtilizationChart') cpuUtilizationChart: TdChartComponent;
  fetchDataComplete: boolean = false;
  emptyResponseData: boolean = false;
  cpuPercentAverage: string = '0';
  utilizationData: any = [];
  timeline: string;
  timeFilter: TimeRange = TimeRange.Month;
  hasCPUData: boolean;

  cpuConfig: any = {
    color: ['#f3753f'],
    legend: {},
    title: {},
    tooltip: {
      trigger: 'axis',
    },
    grid: { bottom: 30, right: 50, left: 50, top: 20 },
    xAxis: [
      {
        show: true,
        type: 'time',
        boundaryGap: false,
        axisLabel: {
          formatter: (d: any) => this.formatLabels(d),
        },
      },
    ],
    yAxis: [
      {
        show: true,
        type: 'value',
        min: 0,
      },
    ],
    series: [
      {
        type: 'line',
        itemStyle: {
          opacity: 0.95,
          color: '#007373',
        },
        data: [],
        symbolSize: 0,
      },
    ],
  };

  yAxisMax: number = 100;

  get zoomIcon(): string {
    return this.yAxisMax === 100 ? 'zoom_in' : 'zoom_out';
  }

  get zoomTooltipText(): string {
    return this._translate.instant(
      this.yAxisMax === 100 ? 'zoom_in' : 'zoom_out'
    );
  }

  constructor(
    private readonly _metricsGraphService: MetricsGraphService,
    private readonly _loadingService: TdLoadingService,
    private readonly _datePipe: DatePipe,
    private readonly _translate: TranslateService
  ) {}

  async ngOnChanges(): Promise<void> {
    this.cpuConfig.tooltip.formatter = (data: any) =>
      this._datePipe.transform(data[0].value[0], 'short');
    await this.fetchDataByOneMonth();
  }

  async toggleZoom(): Promise<void> {
    this.yAxisMax = this.yAxisMax === 100 ? undefined : 100;
    await this.triggerChartCreation();
  }

  formatLabels(item: Date): string {
    if (this.timeFilter === TimeRange.Day) {
      return this._datePipe.transform(item, 'MMM dd, h:mm a');
    } else {
      return this._datePipe.transform(item, 'MMM dd');
    }
  }

  setTimeLine(end: Date, start: Date): void {
    this.timeline = `${this._datePipe.transform(
      start,
      'MMM dd'
    )} - ${this._datePipe.transform(end, 'MMM dd')}`;
  }

  async fetchDataByOneDay(): Promise<void> {
    this.timeFilter = TimeRange.Day;
    const now: Date = new Date();
    const dayAgo: Date = new Date();
    dayAgo.setDate(dayAgo.getDate() - 1);
    this.setTimeLine(now, dayAgo);
    await this.fetchData(this.siteId, dayAgo, now);
    await this.triggerChartCreation();
  }

  async fetchDataByOneWeek(): Promise<void> {
    this.timeFilter = TimeRange.Week;
    const now: Date = new Date();
    const dayAgo: Date = new Date();
    dayAgo.setDate(dayAgo.getDate() - 7);
    this.setTimeLine(now, dayAgo);
    await this.fetchData(this.siteId, dayAgo, now);
    await this.triggerChartCreation();
  }

  async fetchDataByOneMonth(): Promise<void> {
    this.timeFilter = TimeRange.Month;
    const now: Date = new Date();
    const monthAgo: Date = new Date();
    monthAgo.setMonth(monthAgo.getMonth() - 1);
    this.setTimeLine(now, monthAgo);
    await this.fetchData(this.siteId, monthAgo, now);
    await this.triggerChartCreation();
  }

  async fetchData(
    siteId: string,
    startDate: Date,
    endDate: Date
  ): Promise<void> {
    try {
      this.hasCPUData = false;
      this._loadingService.register('loadCPUChart');
      const data: any = await this._metricsGraphService
        .fetchData(siteId, startDate, endDate, 350)
        .toPromise();
      if (data) {
        this.hasCPUData = await this.checkCPUDataAvailibility(data);
        this.utilizationData = data;
      }
    } catch (error) {
      setTimeout(() => {
        this._errorService.open(error);
      });
    } finally {
      this._loadingService.resolve('loadCPUChart');
    }
  }

  async triggerChartCreation(): Promise<void> {
    if (this.utilizationData) {
      await this.prepareChartData();
      this.drawChart();
    }
  }

  async checkCPUDataAvailibility(data: any): Promise<boolean> {
    return (
      data.graphData &&
      data.graphData.cpu_percentage_utilization &&
      data.graphData.cpu_percentage_utilization.datapoints &&
      data.graphData.cpu_percentage_utilization.datapoints.length > 0
    );
  }

  async prepareChartData(): Promise<void> {
    if (
      this.utilizationData.graphData &&
      this.utilizationData.graphData.cpu_percentage_utilization &&
      this.utilizationData.graphData.cpu_percentage_utilization.datapoints
    ) {
      const cpuList: MetricDatapoint[] = this.utilizationData.graphData
        .cpu_percentage_utilization.datapoints;

      let cpuTotal: number = 0;
      const tempArry: any = [];
      cpuList.map((element: MetricDatapoint) => {
        const d: Date = new Date(element.Timestamp.slice(0, -1));
        const v: string = element.Average.toFixed(3);
        cpuTotal += element.Average;
        const item: any = {
          name: d.toISOString(),
          value: [d.toISOString(), v],
        };
        tempArry.push(item);
      });
      this.cpuPercentAverage = (cpuTotal / cpuList.length).toFixed(3);

      // sort by name. This is needed to make sure the graph is plotted in chronological order.
      tempArry.sort((a: any, b: any) => {
        const nameA: string = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB: string = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      this.cpuConfig.series[0].data = [];
      tempArry.forEach((item: any) => {
        this.cpuConfig.series[0].data.push(item);
      });

      this.cpuConfig.yAxis[0].max = this.yAxisMax;
    }
  }

  drawChart(): void {
    if (
      this.cpuUtilizationChart &&
      this.cpuConfig.series &&
      this.cpuConfig.series[0].data.length > 0
    ) {
      this.cpuUtilizationChart.render();
    }
  }
}
