import { Directive, ElementRef, Input } from '@angular/core';
import { AnalyticsEventViewModel } from './analyticsEvent.viewModel';
import { trackedEvents } from './analyticsEventTracking.map';

declare let ga: Function;

@Directive({
  selector: '[imcAnalyticsEventTracking]',
})
export class AnalyticsEventTrackingDirective {
  @Input('imcAnalyticsEventTracking') analyticsEvent: AnalyticsEventViewModel;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    if (this.analyticsEvent && this.analyticsEvent.eventKey) {
      const elem = this.el.nativeElement;

      const gaEventObject = Object.assign(
        {},
        trackedEvents[this.analyticsEvent.eventKey]
      );

      gaEventObject.hitType = 'event';

      if (
        gaEventObject.eventAction &&
        gaEventObject.eventAction.startsWith('focus')
      ) {
        elem.addEventListener('focus', () => {
          ga('send', gaEventObject);
        });
      } else {
        elem.addEventListener('click', () => {
          ga('send', gaEventObject);
        });
      }
    }
  }
}
