export enum PolicyUserRole {
  ReadOnlySiteAdmin = 'ReadOnlySiteAdmin',
  SiteAdmin = 'SiteAdmin',
  SiteAccessManager = 'SiteAccessManager',
  SiteSuperAdmin = 'SiteSuperAdmin',
  LimitedBaseUser = 'LimitedBaseUser',
  ReadOnlyLimitedSysops = 'ReadOnlyLimitedSysops',
  ReadOnlySysops = 'ReadOnlySysops',
  SiteOnboardingUser = 'SiteOnboardingUser',
  FullSysops = 'FullSysops',
}

export enum CustomerUserRoles {
  ReadOnlySiteAdmin = 'ReadOnlySiteAdmin',
  SiteAdmin = 'SiteAdmin',
  SiteAccessManager = 'SiteAccessManager',
  SiteSuperAdmin = 'SiteSuperAdmin',
  LimitedBaseUser = 'LimitedBaseUser',
}

// PolicyUserRolesDetails in future will come from Service.
export const PolicyUserRolesDetails = [
  {
    policyUserRole: PolicyUserRole.SiteSuperAdmin,
    display: 'Site Super admin',
    description: 'Full access to IMC,  restricted to customer site only.',
  },
  {
    policyUserRole: PolicyUserRole.SiteAdmin,
    display: 'Site Administrator',
    description:
      'Full access to IMC but with no user/role management permissions, ' +
      ' restricted to customer site only.',
  },
  {
    policyUserRole: PolicyUserRole.ReadOnlySiteAdmin,
    display: 'Read-Only Site Admin',
    description:
      'Read only access to IMC for customer site. All new users created by customer automatically ' +
      'assigned this role by default.',
  },
  {
    policyUserRole: PolicyUserRole.SiteAccessManager,
    display: 'Site Access Manager',
    description:
      'Read Only access but can assign user roles as well as create and delete users.',
  },
  {
    policyUserRole: PolicyUserRole.LimitedBaseUser,
    display: 'Limited base user',
    description:
      'The minimal user role.  This role can login to IMC and can ' +
      'only change its own password and user info ' +
      '(role assignment, name, etc) but no write or even read access to any other resource. ' +
      'A registered user will always have the base user role available.',
  },
  {
    policyUserRole: PolicyUserRole.FullSysops,
    display: 'Full Sysops',
    description:
      'Full access to all parts of IMC and all customers sites. ' +
      'Full Sysops users can create, update and delete all users and assign roles. ' +
      'Intended for Teradata employees only.  Most likely Teradata users working in DevOps.',
  },
  {
    policyUserRole: PolicyUserRole.ReadOnlySysops,
    display: 'Read-Only Sysops',
    description:
      'Full read only access to all parts of IMC and all customers. ' +
      'Intended for Teradata employees only who need to see all customers but not change things.',
  },
  {
    policyUserRole: PolicyUserRole.ReadOnlyLimitedSysops,
    display: 'Read-Only Limited Sysops',
    description:
      'Full read only access to all parts of IMC and only some customers. ' +
      'Intended for Teradata Managed Services users.',
  },
  {
    policyUserRole: PolicyUserRole.SiteOnboardingUser,
    display: 'Site Onboarding User',
    description:
      'Full read only access to all parts of IMC and all customers. ' +
      'Also has write permissions to Onboarding widget. ' +
      'Intended for Teradata Solutions Architects.',
  },
];
