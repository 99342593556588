import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';

@Component({
  selector: 'skipped-objects-dialog',
  templateUrl: './skippedObjectsDialog.component.html',
  styleUrls: ['./skippedObjectsDialog.component.scss'],
})
export class SkippedObjectsDialogComponent {
  skippedObjects: any;
  errorCodeGuides: any;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  close() {
    this.dialog.closeAll();
  }

  ngOnInit() {
    this.skippedObjects = this.data;
    this.errorCodeGuides = require('./errorCodeGuides.json');
  }
}
