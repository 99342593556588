import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ConsumptionService {
  constructor(private readonly _apollo: Apollo) {}

  getVantageUnits(
    interval: string,
    activityType: string,
    siteId: string,
    customerId: string,
    rangeBegin: string
  ): Observable<any> {
    const vantageUnitsQuery: any = gql`
      query VantageUnits {
        vantageUnits(
          interval_type: $interval
          activity_type: $activityType
          site_id: $siteId
          customer_id: $customerId
          range_begin: $rangeBegin
        )
          @rest(
            type: $activityType
            path: "/aggregates?{args}"
            endpoint: "consumption"
          ) {
          interval_val
          interval_ts
        }
      }
    `;

    return this._apollo
      .query<any>({
        query: vantageUnitsQuery,
        variables: {
          interval,
          activityType,
          customerId,
          siteId,
          rangeBegin,
        },
      })
      .pipe(
        map((responseBody: any) => {
          if (responseBody.errors) {
            throw new Error(
              'There was an error fetching aggregation of vantage units!'
            );
          }

          return responseBody.data.vantageUnits;
        })
      );
  }
}
