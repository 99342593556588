import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  DbState,
  DbStatusStrings,
} from '@imc-features/ecosystems/_modules/ecosystems-shared/constants/db-state.constants';
import { Site } from '@imc/core/models/sites.model';
import { Subscription } from 'rxjs';
import { AzureService } from '../../services/azure.service';
import { GoogleAnalyticsService } from '@imc/core/services/google-analytics/google-analytics.service';
@Component({
  selector: 'imc-azure-start-database',
  templateUrl: './start-database.component.html',
  styleUrls: ['./start-database.component.scss'],
})
export class StartDatabaseComponent implements OnInit, OnDestroy {
  private readonly dbStatusStrings: any = DbStatusStrings;
  private azureSubscription: Subscription;
  @Input() siteInfo: Site;
  dbStateOptions: any = DbState;
  failedOperation: boolean = false;
  displayStatus: boolean = false;
  disableStart: boolean = false;
  dbState: number;
  dbMessage: string = '';

  analyticsEvent: any = {
    azureDbStart: {
      eventKey: 'azure_db_start',
    },
  };

  constructor(
    private readonly azureService: AzureService,
    private readonly analyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {
    if (this.siteInfo.sqleApp) {
      this.getDbInfo();
    }
  }

  ngOnDestroy(): void {
    if (this.azureSubscription) {
      this.azureSubscription.unsubscribe();
    }
  }

  getDbInfo(): void {
    // Incase the site does not have 'dbStatus'
    if (!this.siteInfo.sqleApp.status) {
      this.dbState = this.dbStatusStrings.started.dbState;
      this.dbMessage = this.dbStatusStrings.started.dbMessage;
    } else {
      // Incase siteInfo.dbStatus is not in the mapping, setting default to started
      const status: any = this.dbStatusStrings[this.siteInfo.sqleApp.status];
      this.dbState = status
        ? status.dbState
        : this.dbStatusStrings.started.dbState;
      this.dbMessage = status
        ? status.dbMessage
        : this.dbStatusStrings.started.dbMessage;
    }
  }

  confirmStart(): void {
    // Immediate update on UI
    this.disableStart = true;
    this.siteInfo.sqleApp.status = 'starting';
    this.siteInfo.sqleApp.operationalStatus = 'not operational';
    this.getDbInfo();
    this.analyticsService.sendAnalyticsEvent(this.analyticsEvent.azureDbStart);
    this.azureSubscription = this.azureService
      .postDatabaseSiteStart(this.siteInfo)
      .subscribe(
        (success: any) => {
          // add any other operations on a success call
        },
        (error: any) => {
          this.failedOperation = true;
          this.siteInfo.sqleApp.status = 'stopped';
          this.siteInfo.sqleApp.operationalStatus = 'not operational';
        }
      )
      .add(() => {
        this.displayStatus = true;
        this.getDbInfo();
      });
  }
}
