import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';

import { PolicyUserRole } from '@imc/core/models/roles.enum';
import { UserRoleInfo } from '@imc/core/models/userRoleInfo.model';
import { PolicyService } from '@imc/core/services/policy-service/policy.service';

import { BackupService } from '../../../services/backups.service';

@Component({
  selector: 'backup-cancel-dialog',
  templateUrl: './backupJobCancelDialog.component.html',
  styleUrls: ['./backupJobCancelDialog.component.scss'],
})
export class BackupJobCancelDialogComponent implements OnDestroy {
  public static isConfirmButtonClicked = false;
  private isSysOpsUser: boolean;
  private readonly userRoleInfoSubscription: Subscription;
  private abortJobSubscription: Subscription;
  constructor(
    private readonly dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly backupService: BackupService,
    private readonly policyService: PolicyService,
    private readonly snackBar: MatSnackBar
  ) {
    this.userRoleInfoSubscription = this.policyService.userRoleInfo$.subscribe(
      (role: UserRoleInfo) => {
        if (
          role.currentRole === PolicyUserRole.FullSysops ||
          role.currentRole === PolicyUserRole.ReadOnlySysops ||
          role.currentRole === PolicyUserRole.ReadOnlyLimitedSysops
        ) {
          this.isSysOpsUser = true;
        } else {
          this.isSysOpsUser = false;
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.userRoleInfoSubscription) {
      this.userRoleInfoSubscription.unsubscribe();
    }
    if (this.abortJobSubscription) {
      this.abortJobSubscription.unsubscribe();
    }
  }

  close() {
    this.dialog.closeAll();
  }

  confirm() {
    this.cancelJob();
    this.dialog.closeAll();
    BackupJobCancelDialogComponent.isConfirmButtonClicked = true;
  }

  cancelJob() {
    if (this.isSysOpsUser) {
      this.abortJobSubscription = this.backupService
        .abortJobForCustomer(this.data.customerId, this.data.jobId)
        .subscribe(
          () => {
            this.snackBar.open(
              'Abort Requested Successfully.' +
                ' Refresh the page to check the job status.',
              'Ok'
            );
          },
          () => {
            this.snackBar.open('Failure to issue Abort request.', 'Ok');
          }
        );
    } else {
      this.abortJobSubscription = this.backupService
        .abortJob(this.data.jobId)
        .subscribe(
          () => {
            this.snackBar.open(
              'Abort Requested Successfully.' +
                ' Refresh the page to check the job status.',
              'Ok'
            );
          },
          () => {
            this.snackBar.open('Failure to issue Abort request.', 'Ok');
          }
        );
    }
  }
}
