import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { TdLoadingService } from '@covalent/core/loading';
import { Availability } from '@imc-features/dashboard/models/availability.model';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { SystemDashboardService } from '../../services/system-dashboard.service';

@Component({
  selector: 'imc-lowest-availability',
  templateUrl: './lowest-availability.component.html',
  styleUrls: ['./lowest-availability.component.scss'],
})
export class LowestAvailabilityComponent implements OnInit, OnDestroy {
  private getAvailabilitySubscription: Subscription;
  loading: boolean = true;
  error: boolean = false;
  subTitle: string = `For Last Month`;
  dataSource: MatTableDataSource<any>;
  columns: string[] = [
    'customerName',
    'displayName',
    'targetSLA',
    'shortDescription',
    'cloudPlatform',
  ];

  constructor(
    private readonly dataService: SystemDashboardService,
    private readonly loadingService: TdLoadingService
  ) {}

  ngOnInit(): void {
    this.loadingService.register('loading');
    const now: Date = new Date();
    const reportStartDate: Date = new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      1
    );
    reportStartDate.setUTCMonth(reportStartDate.getUTCMonth() - 1);
    const reportMonth: string = reportStartDate.toISOString().substr(0, 7);
    this.subTitle = `${this.subTitle}: ${reportMonth}`;
    const availabilities: any[] = [];
    this.getAvailabilitySubscription = this.dataService
      .getLowestAvailability(reportMonth)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        (slaReport: Availability) => {
          slaReport.availability = truncate(slaReport.availability, 2);
          availabilities.push(slaReport);
        },
        (error: any) => {
          this.error = true;
        },
        () => {
          availabilities.sort((a: any, b: any) => {
            const diffA: number = a.targetSla - a.availability;
            const diffB: number = b.targetSla - b.availability;

            return diffB - diffA;
          });
          this.dataSource = new MatTableDataSource(availabilities);
          this.loading = false;
        }
      );
  }

  ngOnDestroy(): void {
    if (this.getAvailabilitySubscription) {
      this.getAvailabilitySubscription.unsubscribe();
    }
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}

const truncate: Function = (
  originalNumber: number,
  decimalAccuracy: number
): string => {
  const truncated: string[] = originalNumber
    .toString()
    .match(new RegExp(`(\\d+\\.\\d{${decimalAccuracy}})(\\d)`));

  return truncated
    ? parseFloat(truncated[1]).toFixed(decimalAccuracy)
    : originalNumber.valueOf().toFixed(decimalAccuracy);
};
