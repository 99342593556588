import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { NewSiteDialogComponent } from '@imc-features/customer/dialogs/new-site-dialog/new-site-dialog.component';

@Component({
  selector: 'imc-new-site-card',
  templateUrl: './new-site-card.component.html',
  styleUrls: ['./new-site-card.component.scss'],
})

export class NewSiteCardComponent {
  constructor(public dialog: MatDialog) {}

  openNewSiteDialog(): void {
    this.dialog.open(NewSiteDialogComponent, {
      width: '380px',
    });
  }
}
