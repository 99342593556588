import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { LeftNavService } from '../../services/left-nav.service';

@Component({
  selector: 'imc-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.scss'],
})
export class LeftNavComponent implements OnInit, OnDestroy {
  private leftNavSubscription: Subscription;

  @Output() readonly closeNav: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  routes: any;

  constructor(private readonly leftNavService: LeftNavService) {}

  ngOnInit(): void {
    this.leftNavSubscription = this.leftNavService
      .getLeftNavConfig()
      .subscribe((config: any) => {
        this.routes = config;
      });
  }

  ngOnDestroy(): void {
    this.leftNavSubscription.unsubscribe();
  }

  closeLayout(): void {
    this.closeNav.emit(true);
  }
}
