import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TdChartComponent } from '@covalent/echarts/base';
import { MeteringUri, ReportsUri } from '@imc/core';
import { ColorPaletteService } from '@imc/core/services/color-palette/color-palette.service';
import { from, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { DbInstanceHours, PeriodUsagePair } from '../../models/metering.model';
import { DashboardMeteringService } from '../../services/dashboard-metering.service';

@Component({
  selector: 'imc-db-instance-hours',
  templateUrl: './db-instance-hours.component.html',
  styleUrls: ['./db-instance-hours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DbInstanceHoursComponent implements OnInit, OnDestroy {
  private instanceHoursSubscription: Subscription;
  private currentDate: Date = new Date();
  private startDate: Date;
  private endDate: Date;

  private xAxisDates: string[] = [];
  private xAxisEpochs: number[] = [];

  @ViewChild('dbInstanceHoursChart') dbInstanceHoursChart: TdChartComponent;
  @Input() customerId: string;

  // Below fields are hard-coded on purpose as this chart always wants to display such data.
  serviceId: string = 'ICAWS';
  siteId: string = 'All';
  granularity: string = 'daily';

  loading: boolean = true;
  loaderKey: string = 'vantageInstanceHoursLoader';
  error: boolean = false;
  viewAllLink: string = '';

  dbInstanceHours: DbInstanceHours[] = [];

  config: any = {
    toolbox: {
      showTitle: true,
      top: 0,
      right: '15px',
      show: true,
      feature: {
        dataView: {
          title: 'View Data',
          lang: ['Data View', 'Turn Off', 'Refresh'],
        },
      },
    },
    xAxis: [
      {
        data: this.xAxisDates,
        name: 'Day',
        nameLocation: 'middle',
        nameGap: '18',
      },
      {
        show: true,
        type: 'time',
        boundaryGap: false,
      },
    ],
    series: [],
    yAxis: [
      {
        show: true,
        type: 'value',
        axisLabel: { inside: false },
        name: 'Instance Hours',
        nameLocation: 'middle',
        nameGap: '30',
      },
    ],
    tooltip: {
      show: true,
      trigger: 'item',
      showContent: true,
    },
  };

  constructor(
    private _dashboardMetringService: DashboardMeteringService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _colorPaletteService: ColorPaletteService
  ) {}

  ngOnInit(): void {
    this.viewAllLink = `/${ReportsUri}/${MeteringUri}/${this.customerId}`;
    // setting the start date to one month ago
    this.startDate = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth(),
      this.currentDate.getDate()
    );
    this.startDate = this.convertDateToUTC(this.startDate);
    this.startDate.setUTCMonth(this.startDate.getUTCMonth() - 1);

    this.endDate = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth(),
      this.currentDate.getDate()
    );
    this.endDate = this.convertDateToUTC(this.endDate);

    [this.xAxisDates, this.xAxisEpochs] = this.getXAxisCoordinates(
      this.startDate,
      this.endDate
    );

    this.config.xAxis[0].data = this.xAxisDates;
    if (this.customerId !== undefined) {
      this.getDbInstanceHours();
    }
  }

  ngOnDestroy(): void {
    if (this.instanceHoursSubscription) {
      this.instanceHoursSubscription.unsubscribe();
    }
  }

  getDbInstanceHours(): void {
    this.instanceHoursSubscription = this._dashboardMetringService
      .getDbInstanceHours(
        this.serviceId,
        this.siteId,
        this.granularity,
        this.startDate.toISOString(),
        this.endDate.toISOString(),
        this.customerId
      )
      .pipe(
        // tslint:disable-next-line:no-unnecessary-callback-wrapper
        switchMap((records: DbInstanceHours[]) => from(records)),
        filter(
          (record: DbInstanceHours) => record.meterName === 'AWS Instance Count'
        )
      )
      .subscribe(
        (record: DbInstanceHours) => {
          this.dbInstanceHours.push(record);
        },
        (error: any) => {
          this.error = true;
        },
        () => {
          this.loading = false;
          this.updateChartConfig();
        }
      );
  }

  updateChartConfig(): void {
    this.dbInstanceHours.forEach(
      (dbInstanceHour: DbInstanceHours, index: number) => {
        this.config.series.push({
          type: 'bar',
          color: this._colorPaletteService.getColor(index),
          stack: 'stack',
          cursor: 'default',
          itemStyle: {
            opacity: 0.95,
          },
          name: `${dbInstanceHour.siteId}-${dbInstanceHour.resourceType}`,
          data: this.getUsageData(dbInstanceHour.periodUsageList),
        });
      }
    );

    this.refresh();
  }

  refresh(): void {
    // tslint:disable-next-line:no-string-literal
    if (!this._changeDetectorRef['destroyed']) {
      this._changeDetectorRef.markForCheck();
      this.dbInstanceHoursChart.render();
    }
  }

  // TODO need to find better way to generate date range
  getXAxisCoordinates(
    startDateSelected: Date,
    endDateSelected: Date
  ): [string[], number[]] {
    const dates: string[] = [];
    const epochs: number[] = [];
    const startDate: Date = new Date(startDateSelected.valueOf());

    while (startDate < endDateSelected) {
      dates.push(`${startDate.getUTCMonth() + 1}/${startDate.getUTCDate()}`);
      epochs.push(startDate.getTime() / 1000);
      startDate.setUTCDate(startDate.getUTCDate() + 1);
    }

    return [dates, epochs];
  }

  private getUsageData(periodUsageList: PeriodUsagePair[]): Number[] {
    const usageData: Number[] = [];
    if (periodUsageList) {
      if (periodUsageList.length <= this.xAxisEpochs.length) {
        let xAxisEpochsIndex: number = 0;
        let periodUsageListIndex: number = 0;
        while (
          xAxisEpochsIndex < this.xAxisEpochs.length &&
          periodUsageListIndex < periodUsageList.length
        ) {
          if (
            this.xAxisEpochs[xAxisEpochsIndex] ===
            periodUsageList[periodUsageListIndex].periodEpoch
          ) {
            usageData.push(periodUsageList[periodUsageListIndex].usageValue);
            xAxisEpochsIndex++;
            periodUsageListIndex++;
          } else {
            usageData.push(0);
            xAxisEpochsIndex++;
          }
        }

        if (
          xAxisEpochsIndex === this.xAxisEpochs.length &&
          periodUsageListIndex < periodUsageList.length
        ) {
          return [];
        }

        while (xAxisEpochsIndex < this.xAxisEpochs.length) {
          usageData.push(0);
          xAxisEpochsIndex++;
        }
      }
    }

    return usageData;
  }

  private convertDateToUTC(dateLocal: Date): Date {
    const utcDate: Date = new Date(
      Date.UTC(
        dateLocal.getFullYear(),
        dateLocal.getMonth(),
        dateLocal.getDate(),
        dateLocal.getHours(),
        dateLocal.getMinutes(),
        dateLocal.getSeconds(),
        dateLocal.getMilliseconds()
      )
    );
    utcDate.setDate(utcDate.getDate());

    return utcDate;
  }
}
