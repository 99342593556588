import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  DbState,
  DbStatusStrings,
} from '@imc-features/ecosystems/_modules/ecosystems-shared/constants/db-state.constants';
import { Site } from '@imc/core/models/sites.model';
import { Subscription } from 'rxjs';
import { DbOperation } from '../../constants/db-operations.constants';
import { AzureInstanceInfoService } from '../../services/azure-instance-info-service/azure-instance-info.service';
import { AzureService } from '../../services/azure.service';
import { GoogleAnalyticsService } from '@imc/core/services/google-analytics/google-analytics.service';

@Component({
  selector: 'imc-azure-scale-in-out-database',
  templateUrl: './scale-in-out-database.component.html',
  styleUrls: ['./scale-in-out-database.component.scss'],
})
export class ScaleInOutDatabaseComponent implements OnInit, OnDestroy {
  private dbStatusStrings: any = DbStatusStrings;
  private azureSubscriptions: Subscription[] = [];
  @Input() siteInfo: Site;
  dbStateOptions: any = DbState;
  failedOperation: boolean = false;
  displayStatus: boolean = false;
  dbState: number;
  dbMessage: string = '';
  toBeDisabled: number;
  disableButton: boolean = false;
  isScalingPossible: boolean = true;
  supportedIncNodeCount: number[] = [];
  selectedScaleOption: number;

  analyticsEvent: any = {
    azureScaleInOut: {},
  };

  constructor(
    private readonly analyticsService: GoogleAnalyticsService,
    private readonly azureService: AzureService,
    private readonly azureInstanceInfoService: AzureInstanceInfoService
  ) {}

  ngOnInit(): void {
    if (this.siteInfo.sqleApp) {
      this.getDbInfo();
      this.isScalingPossible = this.azureInstanceInfoService.isNodeScalingPossible(
        this.siteInfo.sqleApp && this.siteInfo.sqleApp.baseNodeCount,
        this.siteInfo.sqleApp && this.siteInfo.sqleApp.nodeCount
      );
      if (this.isScalingPossible) {
        this.supportedIncNodeCount = this.siteInfo.sqleApp.incNodeOptions
          ? this.siteInfo.sqleApp.incNodeOptions
          : [];
        this.toBeDisabled = this.siteInfo.sqleApp.nodeCount;
        this.selectedScaleOption = this.toBeDisabled;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.azureSubscriptions && this.azureSubscriptions.length > 0) {
      this.azureSubscriptions.forEach((azureSubscription: Subscription) => {
        azureSubscription.unsubscribe();
      });
    }
  }

  getDbInfo(): void {
    // Incase the db does not have db status
    if (!this.siteInfo.sqleApp.status) {
      this.dbState = this.dbStatusStrings.started.dbState;
      this.dbMessage = this.dbStatusStrings.started.dbMessage;
    } else {
      // Incase siteInfo.dbStatus is not in the mapping, setting default to started
      const status: any = this.dbStatusStrings[
        this.siteInfo.sqleApp && this.siteInfo.sqleApp.status
      ];
      this.dbState = status
        ? status.dbState
        : this.dbStatusStrings.started.dbState;
      this.dbMessage = status
        ? status.dbMessage
        : this.dbStatusStrings.started.dbMessage;
    }
  }

  onSubmit(formGroup: any): void {
    const currDbStatus: string =
      this.siteInfo.sqleApp && this.siteInfo.sqleApp.status;
    const currOprStatus: string =
      this.siteInfo.sqleApp && this.siteInfo.sqleApp.operationalStatus;
    // Immediate update on UI
    this.disableButton = true;
    const scaleTargetValue: number = this.selectedScaleOption;
    const scaleType: DbOperation = this.getScaleType(scaleTargetValue);
    if (scaleType === DbOperation.DbScaleIn) {
      this.analyticsEvent.azureScaleInOut.eventKey = 'azure_scale_in';
    } else {
      this.analyticsEvent.azureScaleInOut.eventKey = 'azure_scale_out';
    }
    this.siteInfo.operationalStatus = 'not operational';
    this.getDbInfo();

    this.analyticsService.sendAnalyticsEvent(
      this.analyticsEvent.azureScaleInOut
    );

    this.azureSubscriptions.push(
      this.azureService
        .postDatabaseSiteScale(this.siteInfo, scaleTargetValue, scaleType)
        .subscribe(
          () => {
            // add any other operations on a success call
          },
          () => {
            this.failedOperation = true;
            this.siteInfo.sqleApp.status = currDbStatus;
            this.siteInfo.sqleApp.operationalStatus = currOprStatus;
          }
        )
        .add(() => {
          this.displayStatus = true;
          this.getDbInfo();
        })
    );
  }

  private getScaleType(scaleTargetValue: number): DbOperation {
    let scaleType: DbOperation;

    if (this.siteInfo.sqleApp.nodeCount > scaleTargetValue) {
      this.siteInfo.sqleApp.status = 'scaling_in';
      scaleType = DbOperation.DbScaleIn;
    } else {
      this.siteInfo.sqleApp.status = 'scaling_out';
      scaleType = DbOperation.DbScaleOut;
    }

    return scaleType;
  }
}
