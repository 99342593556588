import { Component, Input } from '@angular/core';

@Component({
  selector: 'imc-aws-mle-card',
  templateUrl: './aws-machine-learning-engine.component.html',
  styleUrls: ['./aws-machine-learning-engine.component.scss'],
})
export class AwsMachineLearningEngineComponent {
  @Input() state: string;
  @Input() nodes: number;

  totalTCore(): number {
    return this.nodes * MLE_INSTANCE_MULTIPLIER;
  }
}
const MLE_INSTANCE_MULTIPLIER: number = 4.8;
