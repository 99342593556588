import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@imc-features/dashboard/models/user.model';
import { DashboardUserService } from '@imc-features/dashboard/services/dashboard-user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'imc-dashboard-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy, OnChanges {
  private static rowCount: number = 5;
  private usersSubscription: Subscription;

  @Input() customerId: string;

  users: User[] = [];

  loading: boolean = true;
  loaderKey: string = 'userListLoader';
  error: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly userService: DashboardUserService
  ) {}

  ngOnInit(): void {
    this.getUserDetails();
  }

  ngOnDestroy(): void {
    if (this.usersSubscription) {
      this.usersSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getUserDetails();
  }

  getUserDetails(): void {
    if (this.usersSubscription) {
      this.usersSubscription.unsubscribe();
    }
    this.usersSubscription = this.userService
      .listUsersForCustomer(this.customerId)
      .subscribe(
        (users: User[]) => {
          this.users = users;
          // TODO: Temporary trimming until we have pagination on the users api to return 5 records
          if (this.users.length > UsersComponent.rowCount) {
            this.users.length = UsersComponent.rowCount;
          }
        },
        (error: any) => {
          this.error = true;
        },
        () => {
          this.loading = false;
        }
      );
  }

  viewAllUsers(): void {
    this.router.navigate(['users/list'], { relativeTo: this.route });
  }
}
