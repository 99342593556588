import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CovalentLoadingModule, CovalentMediaModule } from '@covalent/core';
import { ImcMaterialModule } from '@imc/core';

import { SiteService } from '@imc/core/services/site-service/site.service';

import { NewSiteCardComponent } from './cards/new-site-card/new-site-card.component';
import { SiteCardComponent } from './cards/site-card/site-card.component';

import { UserService } from '@imc-features/monitoring/services/user.service';
import { TranslateModule } from '@ngx-translate/core';
import { NewSiteDialogComponent } from './dialogs/new-site-dialog/new-site-dialog.component';
import { SiteSelectionComponent } from './pages/site-selection/site-selection.component';
import { TicketService } from './services/ticket.service';

@NgModule({
  declarations: [
    NewSiteCardComponent,
    SiteCardComponent,
    SiteSelectionComponent,
    NewSiteDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ImcMaterialModule,
    CovalentMediaModule,
    CovalentLoadingModule,
    TranslateModule,
  ],
  exports: [],
  providers: [SiteService, TicketService, UserService],
  entryComponents: [NewSiteDialogComponent],
})
export class CustomerModule {}
