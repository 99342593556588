import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { AzureWorkOrder } from '../../models/azure-work-order.model';
import { AzureWorkOrdersService } from '../../services/azure-work-orders.service';

@Component({
  selector: 'imc-azure-workorders',
  templateUrl: './azure-work-orders.component.html',
  styleUrls: ['./azure-work-orders.component.scss'],
})
export class AzureWorkOrdersComponent implements OnInit, OnDestroy {
  private serviceSubscription: Subscription;
  hasData: boolean = false;
  loading: boolean = true;
  loaderKey: string = 'azureWorkOrdersLoader';
  error: boolean = false;
  columns: string[] = ['status', 'name', 'lastUpdated', 'customer', 'site'];
  dataSource: MatTableDataSource<AzureWorkOrder>;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private readonly workOrdersService: AzureWorkOrdersService) {}

  ngOnInit(): void {
    this.serviceSubscription = this.workOrdersService
      .getWorkOrders()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        (workOrders: AzureWorkOrder[]) => {
          this.dataSource = new MatTableDataSource(workOrders);
          this.dataSource.sortingDataAccessor = (
            item: AzureWorkOrder,
            sortHeaderId: string
          ) => this.sorter(item, sortHeaderId);
          this.dataSource.sort = this.sort;
          this.hasData = true;
        },
        (error: any) => {
          this.error = true;
        }
      );
  }

  ngOnDestroy(): void {
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private sorter(item: AzureWorkOrder, property: string): string | number {
    switch (property) {
      case 'customer': {
        return item.customerName ? item.customerName : '';
      }
      case 'name': {
        return item.name ? item.name : '';
      }
      case 'status': {
        return item.status;
      }
      case 'site': {
        return item.siteDisplayName ? item.siteDisplayName : '';
      }
      case 'lastUpdated': {
        return item.lastUpdated.valueOf();
      }
      default: {
        throw new Error('sorting this column is not supported');
      }
    }
  }
}
