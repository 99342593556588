import { Component, Input, OnChanges, TemplateRef } from '@angular/core';
import { TdLoadingService } from '@covalent/core/loading';

@Component({
  selector: 'imc-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss'],
})
export class DashboardCardComponent implements OnChanges {
  @Input() cardTitle: string = '';
  @Input() cardSubTitle: string = '';
  @Input() loaderKey: string = '';
  @Input() viewAllLink: string = '';

  @Input() noData: boolean = false;
  @Input() dataLoading: boolean = true;
  @Input() errorMessage: string = 'Error fetching info';
  @Input() noDataMessage: string = 'No info found';
  @Input() error: boolean = false;
  @Input() template: TemplateRef<any>;

  constructor(private _loadingService: TdLoadingService) {}

  ngOnChanges(): void {
    this.stopSpinner(); // In cases where spinner is never stopped when view is detroyed but spinner instance is still alive, we stop first and then start the spinner if needed
    if (this.dataLoading) {
      this.showSpinner();
    }
  }

  showSpinner(): void {
    this._loadingService.register(this.loaderKey);
  }

  stopSpinner(): void {
    this._loadingService.resolve(this.loaderKey);
  }
}
