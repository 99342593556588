import { Component, Input, OnInit } from '@angular/core';
import { Site } from '@imc/core/models/sites.model';

const V2STATUSES: string[] = [
  'Operational',
  'Provisioning',
  'Retired',
  'Unallocated',
];
const UNKNOWN: string = 'UNKNOWN';

@Component({
  selector: 'imc-site-card',
  templateUrl: './site-card.component.html',
  styleUrls: ['./site-card.component.scss'],
})
export class SiteCardComponent implements OnInit {
  status: string;
  hasOperationalStatus: boolean;
  hasStatus: boolean;
  viewSiteUrl: string;

  statusIcons: any = [
    {
      condition: 'OPERATIONAL',
      icon: 'check_circle',
      tooltip: 'site_status_operational',
      classes: 'tc-green-600',
    },
    {
      condition: 'DEGRADED',
      icon: 'warning',
      tooltip: 'site_status_degraded',
      classes: 'tc-yellow-600',
    },
    {
      condition: 'NOT OPERATIONAL',
      icon: 'error',
      tooltip: 'site_status_not_operational',
      classes: 'tc-red-600',
    },
    {
      condition: 'RETIRED',
      icon: 'error',
      tooltip: 'site_status_retired',
      classes: 'tc-red-600',
    },
    {
      condition: UNKNOWN,
      icon: 'remove_circle',
      tooltip: 'site_status_not_found',
      classes: 'tc-grey-500',
    },
    {
      condition: 'PROVISIONING',
      icon: 'add_circle',
      tooltip: 'site_status_provisioning',
      classes: 'tc-orange-600',
    },
    {
      condition: 'STARTED',
      icon: 'add_circle',
      tooltip: 'site_status_started',
      classes: 'tc-orange-600',
    },
    {
      condition: 'UNALLOCATED',
      icon: 'remove_circle',
      tooltip: 'site_status_unallocated',
      classes: 'tc-orange-600',
    },
  ];

  @Input() site: Site;

  ngOnInit(): void {
    this.hasOperationalStatus = !!this.site.operationalStatus;
    this.hasStatus = !!(this.site.sqleApp && this.site.sqleApp.status);
    this.getStatus();
  }

  getStatus(): void {
    if (this.hasOperationalStatus && this.hasStatus) {
      this.getMultipleStatus();
    } else {
      this.getSingleStatus();
    }
  }

  private getSingleStatus(): void {
    if (this.hasOperationalStatus) {
      if (V2STATUSES.includes(this.site.operationalStatus)) {
        this.status = this.site.operationalStatus.toUpperCase();
      } else {
        this.status = UNKNOWN;
      }
    } else if (this.hasStatus) {
      this.status = this.site.sqleApp.status.toUpperCase();
    } else {
      this.status = UNKNOWN;
    }
  }

  private getMultipleStatus(): void {
    const operationalStatus: any = this.site.operationalStatus.toUpperCase();
    const status: any = this.site.sqleApp.status.toUpperCase();
    const isRetired: boolean = status === 'RETIRED';

    if (isRetired) {
      this.status = status;
    } else if (
      operationalStatus === 'NOT OPERATIONAL' &&
      operationalStatus !== status
    ) {
      this.status = 'DEGRADED';
    } else {
      this.getSingleStatus();
    }
  }
}
