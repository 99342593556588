import { Component, Input } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, first, map, mergeMap } from 'rxjs/operators';

import { Site } from '@imc/core/models/sites.model';

import {
  BackupJob,
  BackupJobDetails,
  BackupJobSchedule,
} from '../../models/backup-job.model';
import { BackupsService } from '../../services/backups-service/backups.service';

@Component({
  selector: 'imc-ecosystems-dashboard-backups',
  templateUrl: './ecosystems-backups-card.component.html',
  styleUrls: ['./ecosystems-backups-card.component.scss'],
})
export class EcosystemsBackupsCardComponent {
  jobs$: Observable<any>;
  siteDisplayName: string;

  @Input() set siteInfo(site: Site) {
    if (site && site.siteId && site.customerId) {
      this.siteDisplayName = site.displayName ? site.displayName : site.siteId;
      this.jobs$ = this.getBackupJobs(site);
    } else {
      this.siteDisplayName = !site
        ? 'unknown site'
        : site.displayName
        ? site.displayName
        : site.siteId
        ? site.siteId
        : 'unknown site';
      const errorMessage: string = !site
        ? 'The site is invalid'
        : !site.siteId
        ? 'The site does not have a valid id'
        : 'The site is not associated with a customer';
      this.jobs$ = of({
        site,
        jobs: [],
        noData: true,
        error: `An error was encountered. ${errorMessage}.`,
      });
    }
  }

  intervalMap: any = {
    DAY: {
      '=0': 'Day',
      '=1': 'Day',
      other: '# Days',
    },
    WEEK: {
      '=0': 'Week',
      '=1': 'Week',
      other: '# Weeks',
    },
    MONTH: {
      '=0': 'Month',
      '=1': 'Month',
      other: '# Months',
    },
  };

  constructor(
    private readonly ecosystemsDashboardBackupsService: BackupsService
  ) {}

  private getBackupJobs(site: Site): Observable<any> {
    return this.ecosystemsDashboardBackupsService
      .getBackupJobsForSite(site.siteId, site.customerId)
      .pipe(
        mergeMap((jobs: BackupJob[]) => this.getSiteJobs(site, jobs)),
        catchError((error: any) =>
          of({
            site,
            jobs: [],
            noData: true,
            error:
              'An error was encountered while fetching backup job information',
          })
        )
      );
  }

  private getSiteJobs(site: Site, backupJobs: BackupJob[]): Observable<any> {
    const noData: boolean = backupJobs.length === 0;

    return noData
      ? of({ site, jobs: [], noData, error: undefined })
      : this.siteJobs(
          site,
          backupJobs.length > 5 ? backupJobs.slice(0, 5) : backupJobs
        );
  }

  private siteJobs(site: Site, backupJobs: BackupJob[]): Observable<any> {
    const jobObs: Observable<BackupJob>[] = backupJobs.map<
      Observable<BackupJob>
    >((job: BackupJob) =>
      this.ecosystemsDashboardBackupsService
        .getBackupJobDetails(job.jobId, site.customerId)
        .pipe(
          map((details: BackupJobDetails) => this.transformJob(job, details)),
          first(),
          catchError((error: any) =>
            of(this.backupJobWithSchedule(job, undefined))
          )
        )
    );

    return forkJoin(jobObs).pipe(
      map((jobs: BackupJob[]) => ({
        site,
        jobs,
        noData: false,
        error: undefined,
      }))
    );
  }

  private transformJob(job: BackupJob, details: BackupJobDetails): BackupJob {
    const schedule: BackupJobSchedule =
      details.jobSchedule && details.jobSchedule.length > 0
        ? details.jobSchedule[0]
        : undefined;

    return this.backupJobWithSchedule(job, schedule);
  }

  private backupJobWithSchedule(
    job: BackupJob,
    schedule: BackupJobSchedule
  ): BackupJob {
    return {
      name: job.name,
      jobId: job.jobId,
      backupJobSchedule: schedule,
    };
  }
}
