import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TdMediaService } from '@covalent/core';

import { ImcMaterialModule } from '@imc/core';
import { SharedModule } from '@imc/shared/shared.module';

import { EcosystemsSharedModule } from '../ecosystems-shared/ecosystems-shared.module';
import { AwsCustomerTicketsComponent } from './cards/aws-customer-tickets/aws-customer-tickets.component';
import { AwsCustomersComponent } from './cards/aws-customers/aws-customers.component';
import { AwsDevopsReportsComponent } from './cards/aws-devops-reports/aws-devops-reports.component';
import { AwsInstanceInfoComponent } from './cards/aws-instance-info/aws-instance-info.component';
import { AwsMachineLearningEngineComponent } from './cards/aws-machine-learning-engine/aws-machine-learning-engine.component';
import { AwsNetworkComponent } from './cards/aws-network/aws-network.component';
import { AwsPrivateLinkComponent } from './cards/aws-private-link/aws-private-link.component';
import { AwsProvisioningMetricsComponent } from './cards/aws-provisioning-metrics/aws-provisioning-metrics.component';
import { AwsSitesComponent } from './cards/aws-sites/aws-sites.component';
import { EbsVolumeExpansionDatabaseComponent } from './dialogs/ebs-volume-expansion-database/ebs-volume-expansion-database.component';
import { ScaleInOutDatabaseComponent } from './dialogs/scale-in-out-database/scale-in-out-database.component';
import { ScaleUpDownDatabaseComponent } from './dialogs/scale-up-down-database/scale-up-down-database.component';
import { StartDatabaseComponent } from './dialogs/start-database/start-database.component';
import { StopDatabaseComponent } from './dialogs/stop-database/stop-database.component';
import { AwsSiteElasticityComponent } from './layouts/aws-site-elasticity/aws-site-elasticity.component';
import { AwsSiteOverviewComponent } from './layouts/aws-site-overview/aws-site-overview.component';
import { AwsDashboardComponent } from './pages/aws-dashboard/aws-dashboard.component';
import { SplitUnitValuePipe } from './pipes/split-unit-value/split-unit-value.pipe';
import { AwsInstanceInfoService } from './services/aws-instance-info-service/aws-instance-info.service';
import { AwsPrivateLinkService } from './services/aws-private-link-service/aws-private-link.service';
import { IcawsService } from './services/icaws-service/icaws.service';

@NgModule({
  declarations: [
    AwsDashboardComponent,
    AwsCustomerTicketsComponent,
    AwsCustomersComponent,
    AwsNetworkComponent,
    AwsSitesComponent,
    AwsDevopsReportsComponent,
    AwsInstanceInfoComponent,
    AwsMachineLearningEngineComponent,
    AwsPrivateLinkComponent,
    AwsSiteOverviewComponent,
    AwsSiteElasticityComponent,
    AwsProvisioningMetricsComponent,
    StartDatabaseComponent,
    StopDatabaseComponent,
    ScaleUpDownDatabaseComponent,
    ScaleInOutDatabaseComponent,
    EbsVolumeExpansionDatabaseComponent,
    SplitUnitValuePipe,
  ],
  entryComponents: [AwsSiteOverviewComponent, AwsSiteElasticityComponent],
  imports: [
    CommonModule,
    ImcMaterialModule,
    SharedModule,
    EcosystemsSharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    TdMediaService,
    AwsInstanceInfoService,
    IcawsService,
    AwsPrivateLinkService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AwsModule {}
