import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';

import { TdLoadingService } from '@covalent/core/loading';
import { vuBarConfig } from '@imc/core/constants/echart';
import 'echarts/lib/component/markLine';
import moment from 'moment';

import { UserService } from '@imc-features/monitoring/services/user.service';
import { ErrorService } from '@imc/core/services/error-handling/error.service';

import { IConsumption } from '../../../monitoring/models/consumption.model';

import { Site } from '@imc/core/models/sites.model';
import { PolicyService } from '@imc/core/services/policy-service/policy.service';
import { Subscription } from 'rxjs';
import { ConsumptionService } from '../../services/consumption.service';
import { VUThresholdService } from '../../services/vu-threshold.service';

@Component({
  selector: 'imc-vantage-units',
  templateUrl: './vantage-units.component.html',
  styleUrls: ['./vantage-units.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VantageUnitsComponent implements OnChanges, OnDestroy {
  private customerSubscription: Subscription;
  @Input() site: Site;
  siteId: string;
  userId: string;
  customerId: string;

  rangeBegin: string;
  monthOffset: number = 3;

  threshold: string;
  vantageUnitsData: IConsumption[];
  formattedDataForChart: any[] = []; // [string, number][]
  vantageConsumptionValues: number[];
  config: any;
  noDataReturned: boolean;
  markLineObj: any;

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _loadingService: TdLoadingService,
    private readonly _userService: UserService,
    private readonly _errorService: ErrorService,
    private readonly _consumptionService: ConsumptionService,
    private readonly _decimalPipe: DecimalPipe,
    private readonly _vuThresholdService: VUThresholdService,
    private readonly _policyService: PolicyService
  ) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.site) {
      this.customerSubscription = this._policyService.isCustomer$.subscribe(
        async (isCustomer: boolean) => {
          this.customerId = isCustomer
            ? await this._userService.getCustomerId()
            : this.site.customerId;

          this.userId = await this._userService.getUserId();
          this.siteId = this.site.siteId;
          this.config = { ...vuBarConfig() };
          this._setStartDate();
          await this.handleOnChanges();
        }
      );
    }
  }

  async handleOnChanges(): Promise<void> {
    this._loadingService.register('vantage-units');
    await Promise.all([this.getThreshold(), this.getVantageUnits()]);
    this.formatData();
    this._loadingService.resolve('vantage-units');

    if (!this.noDataReturned) {
      this.renderChart();
    }
  }

  async getThreshold(): Promise<void> {
    try {
      this.threshold = await this._vuThresholdService
        .getThreshold(this.siteId, this.userId, this.customerId)
        .toPromise();
    } catch (error) {
      // do nothing, just don't display threshold if there's a problem
    }
  }

  async getVantageUnits(): Promise<void> {
    try {
      const intervalType: string = 'month';
      const activityType: string = 'vantage_units';
      this.noDataReturned = true;
      const data: IConsumption[] = await this._consumptionService
        .getVantageUnits(
          intervalType,
          activityType,
          this.siteId,
          this.customerId,
          this.rangeBegin
        )
        .toPromise();

      if (data) {
        this.vantageUnitsData = data;
        this.noDataReturned = false;
      }
    } catch (error) {
      setTimeout(() => {
        this._errorService.open(error);
      });
    }
  }

  formatData(): void {
    if (this.vantageUnitsData && this.vantageUnitsData.length) {
      this.vantageConsumptionValues = this.vantageUnitsData.map(
        (item: any) => item.interval_val
      );

      const monthYearSeries: string[] = this._monthYearListAsc();

      const monthYearVantageUnitMap: Map<string, number> = new Map();
      this.vantageUnitsData.forEach(({ interval_ts, interval_val }: any) =>
        monthYearVantageUnitMap.set(
          moment(interval_ts.split('T')[0]).format('MMM YYYY'),
          interval_val
        )
      );

      this.formattedDataForChart = monthYearSeries.map((monthYear: string) => [
        monthYear,
        monthYearVantageUnitMap.get(monthYear),
      ]);
    }
  }

  renderChart(): void {
    this.config.xAxis.data = this.formattedDataForChart.map(
      ([a, b]: [string, number]) => a
    );

    const dataForPloting: any = this.formattedDataForChart.map(
      ([a, b]: [string, number]) => b
    );

    this.config.series = [
      {
        tooltip: {
          formatter: (params: any) => {
            const value: string = this._decimalPipe.transform(
              params.value,
              '1.0-2'
            );

            return `${params.name} : ${value}`;
          },
        },
        type: 'bar',
        barMaxWidth: 200,
        markLine: this.threshold
          ? {
              data: [
                {
                  name: 'Threshold',
                  yAxis: this.threshold,
                },
              ],
              label: { show: false },
              lineStyle: { color: '#d84315' },
            }
          : undefined,
        label: {
          normal: {
            show: true,
            position: 'inside',
            formatter: (params: any) =>
              this._decimalPipe.transform(params.value, '1.0-2'),
          },
        },
        data: dataForPloting,
      },
    ];

    this._changeDetectorRef.markForCheck();
  }

  ngOnDestroy(): void {
    if (this.customerSubscription) {
      this.customerSubscription.unsubscribe();
    }
  }

  private _monthYearListAsc(): string[] {
    const range: number[] = Array.from(Array(this.monthOffset + 1).keys());

    return range
      .map((i: number) =>
        moment()
          .subtract(i, 'months')
          .format('MMM YYYY')
      )
      .reverse();
  }

  private _setStartDate(): void {
    this.rangeBegin = moment()
      .subtract(this.monthOffset, 'month')
      .startOf('month')
      .format('YYYY-MM-DD');
  }
}
