export const CLOUD_PLATFORM_INSTANCE_INFO: any = {
  instanceSizeRanking: {
    Standard_E16s_v3: 0,
    'Standard_E32-8s_v3': 1,
    'Standard_E32-16s_v3': 2,
    Standard_E32s_v3: 3,
    'Standard_E64-16s_v3': 4,
    Standard_E64s_v3: 5,
    'Standard_E64-32s_v3': 6,
  },
};
