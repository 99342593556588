import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';

import { TdLoadingService } from '@covalent/core/loading';
import moment from 'moment';

import { UserService } from '@imc-features/monitoring/services/user.service';
import { Site } from '@imc/core/models/sites.model';
import { ErrorService } from '@imc/core/services/error-handling/error.service';

import { IConsumption } from '../../../monitoring/models/consumption.model';
import { ConsumptionService } from '../../services/consumption.service';

@Component({
  selector: 'imc-consumption',
  templateUrl: './consumption.component.html',
  styleUrls: ['./consumption.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsumptionComponent implements OnChanges, OnInit {
  @Input() site: Site;
  customerId: string;
  rangeBegin: string;

  vantageUnits: IConsumption[];
  cdsUsed: IConsumption[];

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _loadingService: TdLoadingService,
    private readonly _userService: UserService,
    private readonly _errorService: ErrorService,
    private readonly _consumptionService: ConsumptionService
  ) {}

  async ngOnInit(): Promise<void> {
    this.setStartDate();
  }

  async ngOnChanges(): Promise<void> {
    this._loadingService.register('consumption');
    if (this.site) {
      await Promise.all([
        this.getVantageUnits(),
        this.getConsumerDiskSpaceUsed(),
      ]);
    }
    this._changeDetectorRef.markForCheck();
    this._loadingService.resolve('consumption');
  }

  async getVantageUnits(): Promise<void> {
    try {
      const intervalType: string = 'month';
      const activityType: string = 'vantage_units';
      this.vantageUnits = await this._consumptionService
        .getVantageUnits(
          intervalType,
          activityType,
          this.site.siteId,
          this.site.customerId,
          this.rangeBegin
        )
        .toPromise();
    } catch (error) {
      this._errorService.open(error);
    }
  }

  async getConsumerDiskSpaceUsed(): Promise<void> {
    try {
      const intervalType: string = 'month';
      const activityType: string = 'cds';
      this.cdsUsed = await this._consumptionService
        .getVantageUnits(
          intervalType,
          activityType,
          this.site.siteId,
          this.site.customerId,
          this.rangeBegin
        )
        .toPromise();

      if (this.cdsUsed[0] && this.cdsUsed[0].interval_val > 0) {
        this.cdsUsed[0].interval_val = Number(
          this.cdsUsed[0].interval_val.toString()
        );
      }
    } catch (error) {
      this._errorService.open(error);
    }
  }

  private setStartDate(): void {
    this.rangeBegin = moment()
      .startOf('month')
      .format('YYYY-MM-DD');
  }
}
