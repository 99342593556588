import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TdTruncatePipe } from '@covalent/core';
import { TdChartComponent } from '@covalent/echarts/base';
import {
  ListTicketsRequest,
  Ticket,
  TicketsResponse,
} from '@imc-features/dashboard/models/ticket.model';
import { DashboardTicketsService } from '@imc-features/dashboard/services/dashboard-tickets.service';
import { Customer } from '@imc-features/users/models/customer.model';
import { SupportUri } from '@imc/core';
import { ColorPaletteService } from '@imc/core/services/color-palette/color-palette.service';
import moment from 'moment';

const DEFAULT_BLACLIST_CUSTOMER_IDS: any = {
  f12b950bc4c41100c00bb25363ba47fd: 'Terdata Cloud',
};
const MAX_NUMBER_QUERIES: number = 5;

@Component({
  selector: 'imc-most-tickets',
  templateUrl: './most-tickets.component.html',
  styleUrls: ['./most-tickets.component.scss'],
})
export class MostTicketsComponent implements OnInit {
  @ViewChild('mostTicketsChart') mostTicketsChart: TdChartComponent;
  lastNumberOfDays: number = 14;
  customers: any[] = [];
  customerIdNameMap: Map<string, string> = new Map();
  tickets: any[] = [];
  mostTicketsDataLoading: boolean = true;
  barColor: string;
  loaderKey: string = 'mostTicketsLoader';
  loading: boolean = true;
  error: any;
  subTitle: string = `Customers with most tickets (last ${
    this.lastNumberOfDays
  } days)`;

  constructor(
    private dashboardTickets: DashboardTicketsService,
    private readonly router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    private _colorPaletteService: ColorPaletteService,
    private readonly truncatePipe: TdTruncatePipe
  ) {}

  @Input()
  set inputCustomers(customers: Customer[]) {
    customers.forEach((customer: Customer) => {
      this.customerIdNameMap[customer.customerId] = customer.name;
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.barColor = this._colorPaletteService.getTMCColor();
    this.getCustomersWithMostTickets();
  }

  async getCustomersWithMostTickets(): Promise<void> {
    let allTickets: Ticket[] = [];
    const listTicketRequest: ListTicketsRequest = {
      fields: 'ticketId,customerId',
      limit: 100,
      blacklistCustomerIds: Object.keys(DEFAULT_BLACLIST_CUSTOMER_IDS).join(
        ','
      ),
      updatedAfterUTC: moment()
        .subtract(this.lastNumberOfDays, 'days')
        .toISOString(),
      customerTickets: 'true',
    };
    let numberQueries: number = 0;
    let startKey: any;

    while (numberQueries < MAX_NUMBER_QUERIES) {
      numberQueries += 1;
      const ticketsResponse: TicketsResponse = await this.dashboardTickets.getPaginatedTickets(
        listTicketRequest,
        startKey
      );
      if (
        !ticketsResponse ||
        !ticketsResponse.tickets ||
        ticketsResponse.tickets.length === 0
      ) {
        break;
      }
      startKey = ticketsResponse.lastEvaluatedKey;
      allTickets = allTickets.concat(ticketsResponse.tickets);
    }

    // setup map of customer name and ticket count
    const ticketCountMap: any = {};
    allTickets.forEach((ticket: Ticket) => {
      const custName: string = this.customerIdNameMap[ticket.customerId];
      if (!custName) {
        return;
      }

      if (
        ticketCountMap[custName] === undefined ||
        ticketCountMap[custName] === null
      ) {
        ticketCountMap[custName] = 1;
      } else {
        ticketCountMap[custName] = ticketCountMap[custName] + 1;
      }
    });

    // Create items array with customer name and ticket count as entries
    const items: any = Object.keys(ticketCountMap).map((key: string) => [
      key,
      ticketCountMap[key],
    ]);

    // Sort the array based on the second element(ticket count)
    items.sort((first: any, second: any) => first[1] - second[1]);

    // pick the top 5 and push into to-be-outputted arrays
    items.slice(items.length - 5, items.length).forEach((item: any) => {
      // truncate customer name so that y-axis names are not really long
      this.customers.push(this.truncatePipe.transform(item[0], 20));
      this.tickets.push(parseInt(item[1], 10));
    });
    this.loading = false;
    this.refresh();
  }

  viewAllTickets(): void {
    this.router.navigateByUrl(`${SupportUri}`);
  }

  refresh(): void {
    // tslint:disable-next-line:no-string-literal
    if (!this._changeDetectorRef['destroyed']) {
      this._changeDetectorRef.markForCheck();
      this.mostTicketsChart.render();
    }
  }
}
