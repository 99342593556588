import { Component, OnDestroy, OnInit } from '@angular/core';
import { TdMediaService } from '@covalent/core';
import { Customer, Site } from '@imc/core';
import { SiteService } from '@imc/core/services/site-service/site.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'imc-intellicloud-system-details',
  templateUrl: './intellicloud-system-details.component.html',
  styleUrls: ['./intellicloud-system-details.component.scss'],
})
export class IntellicloudSystemDetailsComponent implements OnInit, OnDestroy {
  customerDatabaseSites: Site[] = [];
  sites$: Observable<Site[]>;
  chartData: object[] = [];
  aws: Map<string, object[]> = new Map();
  azure: Map<string, object[]> = new Map();
  tmc: Map<string, object[]> = new Map();
  sitesSubscription: Subscription;
  isLoading: boolean = true;

  constructor(
    private readonly siteService: SiteService,
    public media: TdMediaService
  ) {}

  ngOnInit(): void {
    this.getCustomerDatabaseSites();
  }

  ngOnDestroy(): void {
    if (this.sitesSubscription) {
      this.sitesSubscription.unsubscribe();
    }
  }

  getCustomerDatabaseSites(): void {
    const customers$: Observable<
      Map<string, Customer>
    > = this.siteService.listCustomers();
    const sites$: Observable<any[]> = this.siteService.listSites();
    this.sites$ = forkJoin(customers$, sites$).pipe(
      map((customersSites: any[]) => {
        const customersMap: Map<string, Customer> = customersSites[0];
        const sites: Site[] = customersSites[1];

        return sites
          .filter((site: Site) => customersMap.has(site.customerId))
          .map((site: Site) => ({
            ...site,
            customerName: customersMap.get(site.customerId).name,
          }))
          .sort((a: Site, b: Site) =>
            a.customerName > b.customerName ? 1 : -1
          );
      })
    );

    this.sitesSubscription = this.sites$.subscribe((siteList: Site[]) => {
      this.customerDatabaseSites = siteList;
      this.prepareChartData();
      this.isLoading = false;
    });
  }

  prepareChartData(): void {
    const chartDataChildren: object[] = [];
    this.customerDatabaseSites.forEach((site: Site) => {
      if (site && site.customerName) {
        const siteValue: object = {
          name: `${site.displayName} (${site.purpose})`,
          value: site.supportSiteId,
        };
        switch (site.cloudPlatform.toLowerCase()) {
          case 'aws':
            this.updateMap(this.aws, site.customerName, siteValue);
            break;

          case 'azure':
            this.updateMap(this.azure, site.customerName, siteValue);
            break;

          case 'tmc':
            this.updateMap(this.tmc, site.customerName, siteValue);
            break;

          default:
        }
      }
    });

    chartDataChildren.push(this.getSubTrees('AWS', this.aws));
    chartDataChildren.push(this.getSubTrees('AZURE', this.azure));
    chartDataChildren.push(this.getSubTrees('TMC', this.tmc));
    this.chartData.push({ name: 'Platform', children: chartDataChildren });
  }

  updateMap(
    siteMap: Map<string, object[]>,
    customerName: string,
    siteValue: object
  ): void {
    if (siteMap.has(customerName)) {
      siteMap.get(customerName).push(siteValue);
    } else {
      siteMap.set(customerName, [siteValue]);
    }
  }

  getSubTrees(
    platformName: string,
    platformMap: Map<string, object[]>
  ): object {
    const platformChildren: Object[] = [];

    platformMap.forEach((value: object[], key: string) => {
      platformChildren.push({
        name: `${key} (Sites: ${value.length})`,
        children: value,
      });
    });

    return {
      name: `${platformName} (Customers: ${platformChildren.length})`,
      children: platformChildren,
    };
  }
}
