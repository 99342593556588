import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Threshold } from '../models/threshold.model';

@Injectable()
export class VUThresholdService {
  constructor(private readonly _apollo: Apollo) {}

  getThreshold(
    siteId: string,
    userId: string,
    customerId: string
  ): Observable<string> {
    const vuThresholdQuery: any = gql`
      query vuThreshold {
        threshold(siteId: $siteId, userId: $userId, customerId: $customerId)
          @rest(
            type: "Threshold"
            path: "/notification/settings?siteId={args.siteId}&userId={args.userId}&customerId={args.customerId}"
            endpoint: "threshold"
          ) {
          site_id
          user_set_vu_capacity
          opt_in_email
        }
      }
    `;

    return this._apollo
      .query<any>({
        query: vuThresholdQuery,
        variables: { siteId, userId, customerId },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((responseBody: any) => {
          if (responseBody.errors) {
            throw new Error(
              'There was an error fetching vantage unit threshold!'
            );
          }
          const threshold: any = responseBody.data.threshold;

          return threshold.length > 0 &&
            threshold[0].user_set_vu_capacity &&
            threshold[0].user_set_vu_capacity !== '0'
            ? threshold[0].user_set_vu_capacity
            : undefined;
        })
      );
  }
}
