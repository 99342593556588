import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material';
import {
  CovalentJsonFormatterModule,
  TdDataTableService,
  TdMediaService,
} from '@covalent/core';
import { CoreModule, ImcMaterialModule } from '@imc/core';
import { SharedModule } from '@imc/shared/shared.module';

import { BackupEditorFormComponent } from './components/backupEditorForm/backupEditorForm.component';
import { BackupRetentionConfirmationComponent } from './components/backupRetentionConfirmation/backupRetentionConfirmation.component';
import { ActivityLogComponent } from './components/backupsTable/activityLog/activityLog.component';
import { BaasSiteLevelFeaturesComponent } from './components/backupsTable/baasSiteLevelFeatures/baasSiteLevelFeatures.component';
import { BackupJobCancelDialogComponent } from './components/backupsTable/backupJobCancelDialog/backupJobCancelDialog.component';
import { BackupsTableComponent } from './components/backupsTable/backupsTable.component';
import { ConfirmationDialogComponent } from './components/backupsTable/confirmationDialog/confirmationDialog.component';
import { DataTableCardComponent } from './components/backupsTable/dataTableCard/dataTableCard.component';
import { ReplicationDetailsDialogComponent } from './components/backupsTable/replicationDetailsDialog/replicationDetailsDialog.component';
import { SearchableListComponent } from './components/backupsTable/searchableList/searchableList.component';
import { SkippedObjectsData } from './components/backupsTable/skippedObjects.service';
import { SkippedObjectsComponent } from './components/backupsTable/skippedObjects/skippedObjects.component';
import { SkippedObjectsDialogComponent } from './components/backupsTable/skippedObjectsDialog/skippedObjectsDialog.component';
import { SkippedObjectsErrorPanelComponent } from './components/backupsTable/skippedObjectsErrorPanel/skippedObjectsErrorPanel.component';
import { SkippedObjectsInfoDialogComponent } from './components/backupsTable/skippedObjectsInfoDialog/skippedObjectsInfoDialog.component';
import { SkippedSummaryDialog } from './components/backupsTable/skippedSummaryDialog/skippedSummaryDialog.component';
import { SimpleDialogComponent } from './components/simpleDialog/simpleDialog.component';
import { TimeFilterComponent } from './components/timeFilter/timeFilter.component';
import { TimeFilterTabComponent } from './components/timeFilter/timeFilterTab/timeFilterTab.component';
import { LegacyBaasComponent } from './pages/legacy-baas.component';
import { DurationPipe } from './pipes/duration.pipe';
import { KeysPipe } from './pipes/keys/keys.pipe';
import { UTCPipe } from './pipes/utc/utc.pipe';
import { BackupService } from './services/backups.service';
import { SiteService } from './services/site.service';
import { DateUtilService } from './utils/dateUtil/dateUtil.service';

@NgModule({
  declarations: [
    TimeFilterTabComponent,
    TimeFilterComponent,
    SimpleDialogComponent,
    BackupEditorFormComponent,
    BackupRetentionConfirmationComponent,
    BaasSiteLevelFeaturesComponent,
    BackupJobCancelDialogComponent,
    ReplicationDetailsDialogComponent,
    SkippedObjectsDialogComponent,
    SkippedObjectsInfoDialogComponent,
    BackupsTableComponent,
    ActivityLogComponent,
    SkippedSummaryDialog,
    SkippedObjectsComponent,
    SkippedObjectsErrorPanelComponent,
    LegacyBaasComponent,
    DataTableCardComponent,
    DurationPipe,
    SearchableListComponent,
    ConfirmationDialogComponent,
    UTCPipe,
    KeysPipe,
  ],
  imports: [
    CovalentJsonFormatterModule,
    CommonModule,
    CoreModule,
    SharedModule,
    ImcMaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatNativeDateModule,
  ],
  providers: [
    TdMediaService,
    SiteService,
    BackupService,
    SkippedObjectsData,
    DurationPipe,
    TdDataTableService,
    DateUtilService,
  ],
  entryComponents: [
    LegacyBaasComponent,
    BaasSiteLevelFeaturesComponent,
    BackupJobCancelDialogComponent,
    ReplicationDetailsDialogComponent,
    SkippedObjectsDialogComponent,
    SkippedObjectsInfoDialogComponent,
    SkippedSummaryDialog,
    ConfirmationDialogComponent,
  ],
})
export class LegacyBaasModule {}
