import { Component, Input, OnChanges, OnDestroy } from '@angular/core';

import moment from 'moment';

import { UserService } from '@imc-features/monitoring/services/user.service';
import { ErrorService } from '@imc/core/services/error-handling/error.service';

import { IConsumption } from '../../../monitoring/models/consumption.model';
import { Threshold } from '../../models/threshold.model';

import { Site } from '@imc/core/models/sites.model';
import { PolicyService } from '@imc/core/services/policy-service/policy.service';
import { Subscription } from 'rxjs';
import { ConsumptionService } from '../../services/consumption.service';
import { VUThresholdService } from '../../services/vu-threshold.service';

@Component({
  selector: 'imc-threshold-alert',
  templateUrl: './threshold-alert.component.html',
  styleUrls: ['./threshold-alert.component.scss'],
})
export class ThresholdAlertComponent implements OnChanges, OnDestroy {
  private customerSubscription: Subscription;
  @Input() site: Site;
  siteId: string;

  userId: string;
  customerId: string;

  rangeBegin: string;

  threshold: string;
  isOverThreshold: boolean;

  constructor(
    private readonly _userService: UserService,
    private readonly _consumptionService: ConsumptionService,
    private readonly _errorService: ErrorService,
    private readonly _vuThresholdService: VUThresholdService,
    private readonly _policyService: PolicyService
  ) {}

  async ngOnChanges(): Promise<void> {
    if (this.site) {
      this.customerSubscription = this._policyService.isCustomer$.subscribe(
        async (isCustomer: boolean) => {
          this.customerId = isCustomer
            ? await this._userService.getCustomerId()
            : this.site.customerId;
          this.userId = await this._userService.getUserId();
          this.siteId = this.site.siteId;
          this.setStartDate();
          await this.getThreshold();
          await this.getVantageUnits();
        }
      );
    }
  }

  async getThreshold(): Promise<void> {
    try {
      this.threshold = await this._vuThresholdService
        .getThreshold(this.siteId, this.userId, this.customerId)
        .toPromise();
    } catch (error) {
      // do nothing, just don't display threshold if there's a problem
    }
  }

  async getVantageUnits(): Promise<void> {
    try {
      const intervalType: string = 'month';
      const activityType: string = 'vantage_units';
      const currentVantageUnits: IConsumption[] = await this._consumptionService
        .getVantageUnits(
          intervalType,
          activityType,
          this.siteId,
          this.customerId,
          this.rangeBegin
        )
        .toPromise();

      if (currentVantageUnits.length > 0) {
        const vantageUnits: number = currentVantageUnits[0].interval_val;
        if (this.threshold) {
          this.isOverThreshold = vantageUnits >= Number(this.threshold);
        }
      }
    } catch (error) {
      setTimeout(() => {
        this._errorService.open(error);
      });
    }
  }

  ngOnDestroy(): void {
    if (this.customerSubscription) {
      this.customerSubscription.unsubscribe();
    }
  }

  private setStartDate(): void {
    const startDate: moment.Moment = moment().startOf('month');
    this.rangeBegin = startDate.format('YYYY-MM-DD');
  }
}
