import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Site } from '../models/sites.model';
import {
  siteListFields,
  siteRequiredFields,
} from './constants/sites.constants';
import {
  genericAppV2Fragment,
  mleAppV2Fragment,
  nodeV1Fragment,
  nodeV2Fragment,
  siteDetailsFragment,
  sqleAppV2Fragment,
} from './fragments/site-details-fragments';
import { sitesListFragment } from './fragments/sites-list-fragments';
import { GetSiteRequest } from './models/get-site-request.model';
import { ListSitesRequest } from './models/list-sites-request.model';
import { transformSite } from './transforms/site.transforms';

const containsRequiredFields: Function = (site: Site): boolean =>
  siteRequiredFields.every(
    (k: string) => site[k] !== undefined && site[k] !== null
  );

export const getSiteById: Function = (
  apollo: Apollo,
  request: GetSiteRequest
): Observable<Site> => {
  const getSiteByIdQuery: any = gql`
    query getSiteById {
      site(siteId: $siteId)
        @rest(type: "Site", path: "/sites-service/v2/sites/{args.siteId}") {
        ...SiteDetailsFragment
      }
    }
    ${siteDetailsFragment}
    ${genericAppV2Fragment}
    ${sqleAppV2Fragment}
    ${mleAppV2Fragment}
    ${nodeV1Fragment}
    ${nodeV2Fragment}
  `;

  return apollo
    .query<any>({
      query: getSiteByIdQuery,
      variables: {
        siteId: request.siteId,
      },
    })
    .pipe(
      map((responseBody: any) => {
        if (!responseBody || responseBody.errors) {
          throw new Error(`There was an error get info for ${request.siteId}!`);
        }

        const site: Site = transformSite(responseBody.data.site);

        return site;
      })
    );
};

export const listSites: Function = (
  apollo: Apollo,
  request?: ListSitesRequest
): Observable<Site[]> => {
  const requestOptions: ListSitesRequest = request || {};
  const fields: string = siteListFields;
  const listSitesQuery: any = gql`
    query listSites {
      listSitesResponse(
        fields: $fields
        customerId: $customerId
        supportSiteId: $supportSiteId
        isDeleted: $isDeleted
        cacheMiss: $cacheMiss
        filters: $filters
      ) @rest(type: "Site", path: "/sites-service/v2/sites?{args}") {
        ...SitesListFragment
      }
    }
    ${sitesListFragment}
  `;

  return apollo
    .query<any>({
      query: listSitesQuery,
      variables: {
        fields,
        customerId: requestOptions.customerId,
        supportSiteId: requestOptions.supportSiteId,
        isDeleted: requestOptions.isDeleted,
        cacheMiss: requestOptions.cacheMiss,
        filters: requestOptions.filters,
      },
    })
    .pipe(
      map((responseBody: any) => {
        if (!responseBody || responseBody.errors) {
          throw new Error('There was an error fetching list of sites!');
        }

        return (responseBody.data && responseBody.data.listSitesResponse)
          .map(transformSite)
          .filter(containsRequiredFields);
      })
    );
};
