import { Injectable } from '@angular/core';
import { AppConfigService, OAuthenticationService } from '@imc/core/services';
import { ErrorService } from '@imc/core/services/error-handling/error.service';

@Injectable()
export class UserService {
  constructor(
    private readonly _authService: OAuthenticationService,
    private readonly _appConfig: AppConfigService,
    private readonly _errorService: ErrorService
  ) {}

  async getUserId(): Promise<string> {
    try {
      const userId: string = `${
        this._appConfig.getConfig().auth.namespace
      }userId`;
      const userIdentity: any = await this._authService
        .getIdentityClaims()
        .toPromise();

      return userIdentity[userId];
    } catch (error) {
      this._errorService.open(error);
    }
  }

  async getCustomerId(): Promise<string> {
    try {
      const customerId: string = `${
        this._appConfig.getConfig().auth.namespace
      }customerId`;
      const userIdentity: any = await this._authService
        .getIdentityClaims()
        .toPromise();

      return userIdentity[customerId];
    } catch (error) {
      this._errorService.open(error);
    }
  }
}
