import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

const SUPPORTED_LANGS: string[] = ['en'];

const _getSelectedLanguage: (translateService: TranslateService) => string = (
  translateService: TranslateService
): string => {
  const registeredLangs: string[] = translateService.getLangs();
  const browserCultureLang: string = translateService.getBrowserCultureLang();
  const browserLang: string = translateService.getBrowserLang();
  const defaultLang: string = translateService.getDefaultLang();

  if (registeredLangs.indexOf(browserCultureLang) > -1) {
    return browserCultureLang;
  } else if (registeredLangs.indexOf(browserLang) > -1) {
    return browserLang;
  }

  return defaultLang;
};

export const createTranslateLoader: (
  httpClient: HttpClient
) => TranslateLoader = (httpClient: HttpClient): TranslateLoader =>
  new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json');

export const setupTranslateService: (
  translateService: TranslateService
) => void = (translateService: TranslateService): void => {
  translateService.setDefaultLang('en');
  translateService.addLangs(SUPPORTED_LANGS);
  const selectedLanguage: string = _getSelectedLanguage(translateService);
  // Using require to avoid making an http request to get the language file.
  // Prevents untranslated strings from flashing on the screen
  // tslint:disable-next-line
  const data: any = require('../../assets/i18n/' + selectedLanguage + '.json');
  translateService.setTranslation(selectedLanguage, data, false);
  translateService.use(selectedLanguage);
  translateService.reloadLang(selectedLanguage);
};
