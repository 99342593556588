import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TdMediaService } from '@covalent/core';
import { CovalentBaseEchartsModule } from '@covalent/echarts/base';
import { CovalentLineEchartsModule } from '@covalent/echarts/line';
import { CovalentToolboxEchartsModule } from '@covalent/echarts/toolbox';
import { CovalentTooltipEchartsModule } from '@covalent/echarts/tooltip';
import { CoreModule, ImcMaterialModule } from '@imc/core';
import { SharedModule } from '@imc/shared/shared.module';
import { AwsProvisioningModule } from './_modules/aws-provisioning/aws-provisioning.module';
import { AwsModule } from './_modules/aws/aws.module';
import { AzureProvisioningModule } from './_modules/azure-provisioning/azure-provisioning.module';
import { AzureModule } from './_modules/azure/azure.module';
import { EcosystemsSharedModule } from './_modules/ecosystems-shared/ecosystems-shared.module';
import { LegacyBaasModule } from './_modules/legacy-baas/legacy-baas.module';
import { TmcModule } from './_modules/tmc/tmc.module';
import { ImcSiteElasticityDirective } from './directives/site-elasticity.directive';
import { ImcSiteHostDirective } from './directives/site-host.directive';
import { EcosystemsRoutingModule } from './ecosystems-routing.module';
import { DefaultSiteOverviewComponent } from './layouts/default-site-overview/default-site-overview.component';
import { ListSitesComponent } from './pages/list-sites/list-sites.component';
import { SiteDetailsComponent } from './pages/site-details/site-details.component';
import { SiteElasticityComponent } from './pages/site-elasticity/site-elasticity.component';
@NgModule({
  declarations: [
    SiteDetailsComponent,
    SiteElasticityComponent,
    ListSitesComponent,
    DefaultSiteOverviewComponent,
    ImcSiteHostDirective,
    ImcSiteElasticityDirective,
  ],
  exports: [SiteDetailsComponent, ListSitesComponent, SiteElasticityComponent],
  entryComponents: [DefaultSiteOverviewComponent],
  imports: [
    CommonModule,
    RouterModule,
    EcosystemsRoutingModule,
    ImcMaterialModule,
    SharedModule,
    CovalentBaseEchartsModule,
    CovalentTooltipEchartsModule,
    CovalentToolboxEchartsModule,
    CovalentLineEchartsModule,
    AwsModule,
    AwsProvisioningModule,
    AzureModule,
    AzureProvisioningModule,
    TmcModule,
    LegacyBaasModule,
    EcosystemsSharedModule,
    CoreModule,
  ],
  providers: [TdMediaService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EcosystemsModule {}
