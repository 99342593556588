import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { TdMediaService } from '@covalent/core';

import { Site } from '@imc/core/models/sites.model';
import { SiteService } from '@imc/core/services/site-service/site.service';

import { UserService } from '@imc-features/monitoring/services/user.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'imc-site-selection',
  templateUrl: './site-selection.component.html',
  styleUrls: ['./site-selection.component.scss'],
})
export class SiteSelectionComponent implements OnInit {
  sites: Site[];
  sitesLoading: boolean;
  customerId: string;

  multiSiteRoutes: any[] = [
    {
      icon: {
        fontSet: '',
        name: 'insert_chart',
      },
      route: 'reports',
      title: 'monitoring',
    },
    {
      icon: {
        fontSet: '',
        name: 'live_help',
      },
      route: 'support',
      title: 'support_tickets',
    },
    {
      icon: {
        fontSet: '',
        name: 'people',
      },
      title: 'manage_users',
    },
  ];

  constructor(
    public media: TdMediaService,
    private readonly _siteService: SiteService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _userService: UserService
  ) {}

  ngOnInit(): void {
    this.media.broadcast();
    this.setCustomerRouterLinks();
    this.getSites();
  }

  async getSites(): Promise<void> {
    try {
      this.sitesLoading = true;
      this.sites = await this._siteService.listSites().toPromise();
      this.sitesLoading = false;
    } catch (e) {
      throw new Error(e);
    } finally {
      this._cdr.markForCheck();
    }
  }

  async setCustomerRouterLinks(): Promise<void> {
    try {
      this.customerId = await this._userService.getCustomerId();
      this.multiSiteRoutes[2].route = `/users/list`;
    } catch (e) {
      throw new Error(e);
    } finally {
      this._cdr.markForCheck();
    }
  }
}
