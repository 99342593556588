import { Component, Inject, Input, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { TdLoadingService } from '@covalent/core';
import { Subscription } from 'rxjs';

import { SiteService } from '../../../services/site.service';

import { Site } from '@imc/core';
import { isNullOrUndefined } from 'util';

interface BaasFeaturesType {
  SkippedObjects: string;
}

interface BaasDialogData {
  siteId: string;
}

/**
 * In future will add auto-abort, etc ...
 */
const BaaSFeatures: BaasFeaturesType = {
  SkippedObjects: 'skippedObjectsEnabled',
};

const BaasFeaturesDisplayInformation: object = {
  SkippedObjects: {
    text: 'Skipped Objects',
    icon: 'warning',
  },
};

@Component({
  selector: 'mc-baas-site-level-features',
  templateUrl: './baasSiteLevelFeatures.component.html',
  styleUrls: ['./baasSiteLevelFeatures.component.scss'],
})
export class BaasSiteLevelFeaturesComponent implements OnDestroy {
  private getSiteSubscription: Subscription;
  private updateSiteSubscription: Subscription;
  @Input() siteId: string;
  site: Site;
  baasFeaturesRef: BaasFeaturesType = BaaSFeatures;
  ObjectRef: any = Object;
  existingFeatureStates: object = {};
  currentUIStates: object = {};
  FEATURE_COMPONENT: string = 'baas';
  enableSave: boolean = false;
  loaderString: string = 'siteLevelLoader';

  checked = {
    isSkippedObjectsEnabled: true,
  };

  constructor(
    public dialogRef: MatDialogRef<BaasSiteLevelFeaturesComponent>,
    public siteService: SiteService,
    private loaderService: TdLoadingService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private dialogData: BaasDialogData
  ) {
    this.siteId = this.dialogData.siteId;

    this.loaderService.register(this.loaderString);
    this.getSiteSubscription = this.siteService.getSite(this.siteId).subscribe(
      (site: Site) => {
        this.site = site;
        if (
          this.site &&
          this.site.features &&
          this.site.features[this.FEATURE_COMPONENT]
        ) {
          this.existingFeatureStates = this.site.features[
            this.FEATURE_COMPONENT
          ];
          this.currentUIStates = { ...this.existingFeatureStates };
        }
        this.loaderService.resolve(this.loaderString);
      },
      (error) => {
        this.loaderService.resolve(this.loaderString);
        this.snackBar.open('Error loading the site information', 'Ok');
      }
    );
  }

  private static getDisplayName(baasFeature: string) {
    let displayString = '';
    if (baasFeature in BaasFeaturesDisplayInformation) {
      displayString = BaasFeaturesDisplayInformation[baasFeature].text;
    }

    return displayString;
  }

  private static getDisplayIcon(baasFeature: string) {
    let displayIcon = '';
    if (baasFeature in BaasFeaturesDisplayInformation) {
      displayIcon = BaasFeaturesDisplayInformation[baasFeature].icon;
    }

    return displayIcon;
  }

  ngOnDestroy(): void {
    if (this.getSiteSubscription) {
      this.getSiteSubscription.unsubscribe();
    }
    if (this.updateSiteSubscription) {
      this.updateSiteSubscription.unsubscribe();
    }
  }

  displayNameHandler(feature) {
    return BaasSiteLevelFeaturesComponent.getDisplayName(feature);
  }

  displayIconHandler(feature) {
    return BaasSiteLevelFeaturesComponent.getDisplayIcon(feature);
  }

  onToggled($event, feature) {
    this.currentUIStates[BaaSFeatures[feature]] = $event.checked;

    const features: string[] = Object.keys(this.existingFeatureStates);
    for (const feat of features) {
      if (
        isNullOrUndefined(this.existingFeatureStates[feat]) ||
        this.existingFeatureStates[feat] !== this.currentUIStates[feat]
      ) {
        this.enableSave = true;
        return;
      }
    }

    this.enableSave = false;
  }

  enableFeatures() {
    this.loaderService.register(this.loaderString);
    // -- Generate the Features Object --
    const features = { ...this.site.features };
    features[this.FEATURE_COMPONENT] = { ...this.currentUIStates };
    this.updateSiteSubscription = this.siteService
      .updateSiteFeatures(this.site.siteId, { features })
      .subscribe(
        (response) => {
          this.loaderService.resolve(this.loaderString);
          this.dialogRef.close();
        },
        (error) => {
          this.snackBar.open('Error enabling the Site level Flag', 'Ok');
          this.currentUIStates = { ...this.existingFeatureStates };
          this.loaderService.resolve(this.loaderString);
        }
      );
  }
}
