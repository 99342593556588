import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { convertToCSV, downloadCSV } from '@imc/core/utilities/csv/csv';

@Component({
  selector: 'imc-download-csv-button',
  templateUrl: './download-csv-button.component.html',
  styleUrls: ['./download-csv-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CSVDownloadButtonComponent {
  @Input() title: string;
  @Input() data: any;
  @Input() disabled: boolean;

  download(): void {
    const parsedData: string = convertToCSV(this.data);
    downloadCSV(this.title, parsedData);
  }
}
