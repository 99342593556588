import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Output,
  Renderer,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import {
  IPageChangeEvent,
  ITdDataTableColumn,
  ITdDataTableSortChangeEvent,
  TdDataTableService,
  TdDataTableSortingOrder,
  TdLoadingService,
  TdPagingBarComponent,
} from '@covalent/core';

import { PolicyOperations } from '@imc/core/models/policyOperations.enum';
import { PolicyResources } from '@imc/core/models/policyResources.enum';

import { Site } from '@imc/core';
import { Subscription } from 'rxjs';
import { DefaultSiteFlags } from '../../../models/backups.model';
import { BackupJobCancelDialogComponent } from '../backupJobCancelDialog/backupJobCancelDialog.component';
import { ReplicationDetailsDialogComponent } from '../replicationDetailsDialog/replicationDetailsDialog.component';
import { SkippedObjectsDialogComponent } from '../skippedObjectsDialog/skippedObjectsDialog.component';
import { SkippedObjectsInfoDialogComponent } from '../skippedObjectsInfoDialog/skippedObjectsInfoDialog.component';

export interface DataCardTableConfig {
  columns: ITdDataTableColumn[];
  title: string;
  subTitle?: string;
  emptyContent: string;
  pageSizes?: number[];
  pageSize?: number;
  fromRow?: number;
  currentPage?: number;
  firstLast?: boolean;
  sortable: boolean;
  downloadCSVFileName?: string;
  downloadCSVQuery?: string;
  sortBy?: string;
  sortOrder?: TdDataTableSortingOrder;
  selectable?: boolean;
  infoComponent?: any;
  jobName?: string;
  backupJobCancelDialogComponent?: any;
  skippedObjectsDialogComponent?: typeof SkippedObjectsDialogComponent;
  skippedObjectsV2DialogComponent?: typeof SkippedObjectsInfoDialogComponent;
  replicationDetailsDialogComponent?: typeof ReplicationDetailsDialogComponent;
  customDialogComponent?: any;
  rowClickOpenWindow?: boolean;
  addRouterLink?: any[];
  addRouterTooltip?: string;
  analyticsEvent?: any;
  verticalHeight?: number;
  prefix?: string;
  postfix?: string[];
}

@Component({
  selector: 'mc-data-table-card',
  templateUrl: './dataTableCard.component.html',
  styleUrls: ['./dataTableCard.component.scss'],
})
export class DataTableCardComponent implements AfterViewInit, OnDestroy {
  private routerEventsSubscription: Subscription;
  @Input() set rows(value) {
    this.data = value;
    if (!this.data) {
      this.loadingService.register(this.uniqueId);
    } else {
      this.filter();
    }
  }

  @Input() set config(settings: DataCardTableConfig) {
    if (this.settings) {
      // Save any cached/bound settings that should not be reset
      settings.pageSize = this.pageSize = this.settings.pageSize;
      settings.sortBy = this.sortBy = this.settings.sortBy;
      settings.sortOrder = this.sortOrder = this.settings.sortOrder;
    }
    this.settings = settings;
    if (settings.pageSizes || settings.pageSize) {
      // Use setting if provided or the first page size
      settings.pageSize = settings.pageSize || settings.pageSizes[0];
    } else {
      // Fallback max value (could use a very large number if needed)
      settings.pageSize = 100;
    }

    settings.fromRow = settings.fromRow || 1;
    settings.currentPage = settings.currentPage || 1;

    if (this.firstTimeFilter && this.data) {
      this.filter();
    }
  }

  private dialogHasFocus: boolean;
  data: any[];
  uniqueId: string;
  filteredData: any[] = [];
  filteredTotal: number;
  firstTimeFilter: boolean = true;
  pageSize: number;
  sortBy: string;
  sortOrder: any;
  settings: DataCardTableConfig;
  openDialog: any;
  objectReference: any = Object;
  PolicyOperations = PolicyOperations;
  PolicyResources = PolicyResources;

  @ViewChild(TdPagingBarComponent) pagingBar: TdPagingBarComponent;

  @Output() tabSelected = new EventEmitter();
  @Input('overrideTitle') title: string;
  @Input('overrideSubTitle') subTitle: string;
  @Input() site: Site;

  BAAS_WIDGET_NAME: string = 'baas';
  BAAS_WIDGET_SKIPPED_ENABLED: string = 'skippedObjectsEnabled';

  constructor(
    private dataTableService: TdDataTableService,
    private loadingService: TdLoadingService,
    private dialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private renderer: Renderer
  ) {
    this.uniqueId = 'dataTableCard.indeterminate' + Math.random();
    this.dialogHasFocus = false;
  }

  ngOnInit() {
    this.router.events.subscribe(() => {
      // If the route changes, close any open dialogs
      if (this.openDialog) {
        this.openDialog.close();
        this.openDialog = void 0;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    if (!this.data) {
      this.changeDetector.detectChanges();
    }

    if (
      this.site &&
      this.isSiteFeatureDefined(
        this.site,
        this.BAAS_WIDGET_NAME,
        this.BAAS_WIDGET_SKIPPED_ENABLED
      )
    ) {
      if (!this.site.features) {
        this.site.features = {};
      }

      // to set true -> published if the site or isCustomerVisible is not present
      if (!this.site.features.baas) {
        this.site.features.baas = DefaultSiteFlags;
      } else {
        if (!this.site.features.baas.hasOwnProperty('skippedObjectsEnabled')) {
          this.site.features.baas.skippedObjectsEnabled =
            DefaultSiteFlags.skippedObjectsEnabled;
        }
      }
    }
  }

  getVerticalHeight() {
    if (this.settings.verticalHeight) {
      return this.settings.verticalHeight;
    } else {
      return null;
    }
  }

  sort(sortEvent: ITdDataTableSortChangeEvent): void {
    this.settings.sortBy = sortEvent.name;
    this.settings.sortOrder = sortEvent.order;
    this.filter();
    if (this.pagingBar) {
      this.pagingBar.navigateToPage(1);
    }
  }

  page(pagingEvent: IPageChangeEvent): void {
    this.settings.fromRow = pagingEvent.fromRow;
    this.settings.currentPage = pagingEvent.page;
    this.settings.pageSize = pagingEvent.pageSize;
    this.filter();
  }

  filter(): void {
    if (!this.data || !this.settings) {
      return;
    }
    this.loadingService.resolveAll(this.uniqueId);
    this.firstTimeFilter = false;

    let newData: any[] = this.data;
    this.filteredTotal = newData.length;
    newData = this.dataTableService.sortData(
      newData,
      this.settings.sortBy,
      this.settings.sortOrder
    );
    newData = this.dataTableService.pageData(
      newData,
      this.settings.fromRow,
      this.settings.currentPage * this.settings.pageSize
    );
    newData.forEach((x) => {
      if (x.platform && x.platform.toUpperCase() === 'TMC') {
        x.platform = 'Teradata Cloud';
      }
    });
    this.filteredData = newData;
  }

  tabSelectedChanged(value) {
    this.tabSelected.emit(value);
  }

  showDetails(details) {
    if (this.settings.infoComponent) {
      this.closeOpenedDialog();
      this.openDialog = this.dialog.open(this.settings.infoComponent);
    }
  }

  isPublished(details) {
    return this.settings.postfix &&
      details.row[details.columnDetails.name + this.settings.postfix[0]]
      ? true
      : false;
  }

  isSLAMet(details) {
    if (
      !this.settings.postfix ||
      !details.row[details.columnDetails.name + this.settings.postfix[1]] ||
      !details.row[details.columnDetails.name + this.settings.postfix[1]].slaMet
    ) {
      return true;
    }
    return (
      details.row[details.columnDetails.name + this.settings.postfix[1]] &&
      details.row[details.columnDetails.name + this.settings.postfix[1]].slaMet
    );
  }

  isFeatureEnabled(component: string, feature: string) {
    if (
      !this.site ||
      !this.isSiteFeatureDefined(this.site, component, feature)
    ) {
      return DefaultSiteFlags[feature];
    }

    return this.site.features[component][feature] || false;
  }

  showDialog(details) {
    if (this.settings.customDialogComponent) {
      this.openDialog = this.dialog.open(this.settings.customDialogComponent);
      this.openDialog.componentInstance.data = details;
    }
  }

  showCancelJobDialog(jobInfo) {
    if (this.settings.backupJobCancelDialogComponent) {
      this.closeOpenedDialog();
      this.openDialog = this.dialog.open(
        this.settings.backupJobCancelDialogComponent
      );
      this.openDialog.componentInstance.data = jobInfo;
    }
  }

  isCancelJobConfirmed() {
    return BackupJobCancelDialogComponent.isConfirmButtonClicked;
  }

  showReplicationDetailsDialog(replicationDetails) {
    if (this.settings.replicationDetailsDialogComponent) {
      this.closeOpenedDialog();
      this.openDialog = this.dialog.open(
        this.settings.replicationDetailsDialogComponent
      );
      this.openDialog.componentInstance.data = replicationDetails;
    }
  }

  showSkippedObjectsDialog(jobInfo) {
    if (this.settings.skippedObjectsDialogComponent) {
      this.closeOpenedDialog();
      this.openDialog = this.dialog.open(
        this.settings.skippedObjectsDialogComponent
      );
      this.openDialog.componentInstance.data = jobInfo;
    }
  }

  showSkippedObjectsDialogV2(jobInfo) {
    if (this.settings.skippedObjectsV2DialogComponent) {
      this.closeOpenedDialog();
      this.openDialog = this.dialog.open(
        this.settings.skippedObjectsV2DialogComponent,
        {
          data: jobInfo,
        }
      );
    }
  }

  navigate(paths: string[]) {
    this.router.navigate(paths);
  }

  @HostListener('keydown.esc') closeOpenedDialog() {
    if (this.openDialog) {
      this.dialogHasFocus = false;
      this.openDialog.close();
      this.openDialog = void 0;
    }
  }

  @HostListener('document:keydown.tab') onTabDown() {
    if (this.openDialog && !this.dialogHasFocus) {
      event.preventDefault();

      const componentInDialog = this.openDialog.componentInstance.elementRef;

      if (componentInDialog) {
        const firstActionableElement = componentInDialog.nativeElement.querySelector(
          '[tabIndex], a'
        );

        if (firstActionableElement) {
          firstActionableElement.focus();
          this.dialogHasFocus = true;
        }
      }
    }
  }

  downloadCSV() {
    if (
      !this.data ||
      this.data.length === 0 ||
      !this.settings ||
      !this.settings.columns ||
      this.settings.columns.length === 0
    ) {
      return;
    }
    const foundFields: Set<string> = new Set<string>();
    const fields: string[] = [];
    const filteredData: any = [];

    // extract table data with required fields
    for (const item of this.data) {
      const newItem = {};
      for (const col of this.settings.columns) {
        if (item && item[col.name]) {
          newItem[col.label] = item[col.name];
          foundFields.add(col.label);
          if (typeof newItem[col.label] === 'string') {
            let str = newItem[col.label];

            if (str.length > 0) {
              if (
                str[0] === '=' ||
                str[0] === '+' ||
                str[0] === '-' ||
                str[0] === '@'
              ) {
                // Prefix with single quote to avoid macro evaluation in the downloaded CSV.
                str = "'" + str;
              }
            }

            newItem[col.label] = str;
          }
        }
      }
      if (Object.keys(newItem).length !== 0) {
        filteredData.push(newItem);
      }
    }

    if (filteredData.length === 0 || foundFields.size === 0) {
      // no data to download
      return;
    }

    // capture fields
    foundFields.forEach((col: string) => fields.push(col));

    if (this.settings.downloadCSVQuery) {
      // capture query string
      fields.push(this.settings.downloadCSVQuery);
    }

    const csv = json2csv(filteredData, fields);
    const fileName: string =
      'IMC ' +
      (this.settings.downloadCSVFileName || 'download') +
      ' ' +
      this.formattedCurrentDate() +
      '.csv';
    this.buildDownloader(csv, fileName);
  }

  private formattedCurrentDate(): string {
    const date: Date = new Date();
    const monthMm: string = date
      .getMonth()
      .toString()
      .padStart(2, '0');
    const dateDd: string = date
      .getDate()
      .toString()
      .padStart(2, '0');
    const yearYyyy: number = date.getFullYear();
    const hoursHh: string = date
      .getHours()
      .toString()
      .padStart(2, '0');
    const minutesMm: string = date
      .getMinutes()
      .toString()
      .padStart(2, '0');
    const secondsSs: string = date
      .getSeconds()
      .toString()
      .padStart(2, '0');

    return `${yearYyyy}-${monthMm}-${dateDd} ${hoursHh}${minutesMm}${secondsSs}`;
  }

  private buildDownloader(data, filename) {
    const isSafari = /^((?!chrome|android|crios|fxios).)*safari/i.test(
      navigator.userAgent
    );
    const element = this.renderer.createElement(document.body, 'a');
    const blob = new Blob([data], {
      type: 'data:text/csv;charset=utf-8',
    });

    this.renderer.setElementStyle(element, 'visibility', 'hidden');
    this.renderer.setElementAttribute(element, 'target', '_blank');
    this.renderer.setElementAttribute(element, 'download', filename);

    setTimeout(() => {
      this.renderer.invokeElementMethod(element, 'click');
      this.renderer.invokeElementMethod(element, 'remove');
    }, 5);
  }

  private isSiteFeatureDefined(
    site: Site,
    featureSetName: string,
    featureName: string
  ) {
    return (
      site.features !== undefined &&
      site.features !== null &&
      site.features[featureSetName] !== undefined &&
      site.features[featureSetName] !== null &&
      site.features[featureSetName][featureName] !== undefined &&
      site.features[featureSetName][featureName] !== null
    );
  }
}

function json2csv(data, fields) {
  const array = JSON.parse(data);
  let str = '';

  for (let k = 0; k < fields.length; k++) {
    str += `${fields[k].label}`;
    if (k < fields.length - 1) {
      str += ',';
    }
  }

  str += '\r\n';
  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (let j = 0; j < fields.length; j++) {
      if (line !== '') {
        line += ',';
      }

      line += array[i][fields[j].label];
    }

    str += line + '\r\n';
  }

  return str;
}
