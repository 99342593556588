import { Component, Input } from '@angular/core';

@Component({
  selector: 'imc-azure-product-feature',
  templateUrl: './product-feature.component.html',
  styleUrls: ['./product-feature.component.scss'],
})
export class ProductFeatureComponent {
  @Input() feature: any;

  shouldDisplayAttributeValue(attributeValue: string): boolean {
    return !(
      attributeValue.toLowerCase() === 'included' ||
      attributeValue.toLowerCase() === 'upgrade'
    );
  }

  shouldDisplayAttribute(attribute: any): boolean {
    return attribute.value.toLowerCase() !== 'not offered';
  }

  shouldDisplayFeaturePropertyValue(value: any): boolean {
    return !(value === '0' || value.indexOf('Disabled') >= 0);
  }
}
