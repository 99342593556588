import { Component, Input, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Site } from '@imc/core';
import { AzureSecurityRule } from '../../models/azure-security-rule.model';

@Component({
  selector: 'imc-azure-outbound-rules',
  templateUrl: './azure-outbound-rules.component.html',
  styleUrls: ['./azure-outbound-rules.component.scss'],
})
export class AzureOutboundRulesComponent implements OnChanges {
  @Input() site: Site;
  @Input() loading: boolean = true;
  @Input() error: boolean = false;
  @Input() securityRules: any;

  displayedColumns: string[] = [
    'name',
    'priority',
    'portRange',
    'protocol',
    'addressCidr',
  ];

  loaderKey: string = 'azure-site-outbound-rules';
  subtitle: string = '';

  dataSource: MatTableDataSource<AzureSecurityRule[]>;

  ngOnChanges(): void {
    if (this.site && this.site.siteId) {
      this.subtitle = `List of outbound rules for ${this.site.siteId}`;
    }

    if (this.securityRules) {
      this.dataSource = new MatTableDataSource(
        this.securityRules[this.site.siteId].outbound
      );
    }
  }
}
