import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ErrorUri } from '@imc/core/constants/url';
import { PolicyUserRole } from '@imc/core/models/roles.enum';
import { UserRoleInfo } from '@imc/core/models/userRoleInfo.model';
import { PolicyService } from '@imc/core/services/policy-service/policy.service';

@Component({
  selector: 'imc-aws-create-sites-page',
  templateUrl: './create-sites.component.html',
  styleUrls: ['./create-sites.component.scss'],
})
export class AwsCreateSitesComponent implements OnInit {
  customerId$: Observable<string>;

  constructor(
    private readonly router: Router,
    private readonly policyService: PolicyService,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.customerId$ = this.policyService.userRoleInfo$.pipe(
      switchMap((role: UserRoleInfo) => {
        const allowed: boolean = role.currentRole === PolicyUserRole.FullSysops;
        if (!allowed) {
          this.redirectPermissionDenied(role);

          return of<string>();
        }

        return this.route.paramMap.pipe(
          map((parameters: ParamMap) => parameters.get('customerId'))
        );
      })
    );
  }

  private redirectPermissionDenied(role: UserRoleInfo): void {
    let error: string = 'permission-denied';
    let content: string =
      'You need the full sysops role to use this page. ' +
      'Try clicking the link below to assume the full sysops role or please contact an administrator for assistance.';
    if (role.allowedRoles.indexOf(PolicyUserRole.FullSysops) === -1) {
      error = 'unauthorized';
      content =
        'You do not have the full sysops role required to use this page. Please check with your organization if you are expected to have this role, and contact an administrator of you are.';
    }

    this.router.navigate([ErrorUri], {
      queryParams: {
        error,
        error_description: 'You do not have permissions to use this page',
        content,
      },
    });
  }
}
