import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { PolicyIdentifierInfo } from '../../models/policyIdentifierInfo';
import { PolicyCheckerService } from '../../services/policy-checker-service/policy-checker.service';

@Directive({
  selector: '[mcPolicyChecker]',
})
export class PolicyCheckerDirective {
  @Input('mcPolicyChecker') set policyCheck(
    policyIdentifierInfo: PolicyIdentifierInfo
  ) {
    // Feature flag control for policy
    this.policyCheckerService
      .isEnabled(policyIdentifierInfo)
      .subscribe((canShow: boolean) => {
        if (canShow) {
          this.viewContainer.createEmbeddedView(this.template);
        } else {
          this.viewContainer.clear();
        }
      });
  }

  constructor(
    private readonly viewContainer: ViewContainerRef,
    private readonly template: TemplateRef<any>,
    private readonly policyCheckerService: PolicyCheckerService
  ) {}
}
