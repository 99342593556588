import {
  ComponentFactoryResolver,
  Directive,
  Input,
  ViewContainerRef,
} from '@angular/core';

import { AwsSiteElasticityComponent } from '../_modules/aws/layouts/aws-site-elasticity/aws-site-elasticity.component';
import { AzureSiteElasticityComponent } from '../_modules/azure/layouts/azure-site-elasticity/azure-site-elasticity.component';
import { DynamicLayoutComponent } from '../_modules/ecosystems-shared/components/dynamic-layout/dynamic-layout.component';

@Directive({
  selector: '[imcSiteElasticity]',
})
export class ImcSiteElasticityDirective {
  // TODO: need to map these layouts accordingly
  private readonly layoutMappings: any = {
    aws: AwsSiteElasticityComponent,
    azure: AzureSiteElasticityComponent,
    default: AwsSiteElasticityComponent,
  };

  @Input('imcSiteElasticity') set siteInfo(siteInfo: any) {
    if (!siteInfo.siteId) {
      return;
    }

    const currentLayout: any = this.getCurrentLayout(siteInfo);
    const factory: any = this.componentFactoryResolver.resolveComponentFactory(
      currentLayout
    );
    this.viewContainerRef.clear();
    const componentRef: any = this.viewContainerRef.createComponent(factory);

    // set component context
    const instance: any = <DynamicLayoutComponent>componentRef.instance;
    instance.siteInfo = siteInfo;
    instance.loading = false;
  }
  constructor(
    public viewContainerRef: ViewContainerRef,
    private readonly componentFactoryResolver: ComponentFactoryResolver
  ) {}

  private getCurrentLayout(siteInfo: any): DynamicLayoutComponent {
    let selectedLayout: DynamicLayoutComponent;

    if (siteInfo && siteInfo.cloudPlatform) {
      selectedLayout = this.layoutMappings[
        siteInfo.cloudPlatform.toLowerCase()
      ];
    }

    return selectedLayout || this.layoutMappings.default;
  }
}
