import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TdMediaService } from '@covalent/core';

import { Site } from '@imc/core/models/sites.model';
import { ErrorService } from '@imc/core/services/error-handling/error.service';
import { SiteService } from '@imc/core/services/site-service/site.service';

@Component({
  selector: 'imc-site-homepage',
  templateUrl: './site-homepage.component.html',
  styleUrls: ['./site-homepage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteHomepageComponent implements OnInit {
  siteId: string;
  siteInfo: Site;

  constructor(
    public media: TdMediaService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _errorService: ErrorService,
    private readonly _siteService: SiteService
  ) {}

  async ngOnInit(): Promise<void> {
    this._activatedRoute.params.subscribe(async (params: any) => {
      this.siteId = params.siteId;
      await this.getSiteInfo();
      this._changeDetectorRef.markForCheck();
    });
  }

  async getSiteInfo(): Promise<void> {
    try {
      this.siteInfo = await this._siteService
        .getSiteInfo({ siteId: this.siteId })
        .toPromise();
    } catch (error) {
      setTimeout(() => {
        this._errorService.open(error);
      });
    }
  }
}
