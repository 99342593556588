import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'imc-aws-mle-configuration',
  templateUrl: './mleConfiguration.component.html',
  styleUrls: ['./mleConfiguration.component.scss'],
})
export class MleConfigurationComponent {
  @Input() set mleAvailable(v: boolean) {
    this._mleAvailable = this.mleEnabled = v;
  }

  @Output() submitForm: EventEmitter<any> = new EventEmitter();

  _mleAvailable = true;
  mleEnabled = true;
  mleInstances = 2;

  constructor(public location: Location) {}

  onSubmit() {
    this.submitForm.emit({
      install_mle: this.mleEnabled,
      mle_node_count: this.mleInstances,
    });
  }
}
