import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CovalentBaseEchartsModule } from '@covalent/echarts/base';
import { CovalentLineEchartsModule } from '@covalent/echarts/line';
import { CovalentToolboxEchartsModule } from '@covalent/echarts/toolbox';
import { CovalentTooltipEchartsModule } from '@covalent/echarts/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { ImcMaterialModule } from '../core/imc-material.module';
import { CpuUtilizationComponent } from './components/cpu-utilization/cpu-utilization.component';
import { DashboardCardComponent } from './components/dashboard-card/dashboard-card.component';
import { CSVDownloadButtonComponent } from './components/download-csv-button/download-csv-button.component';
import { FeatureDirective } from './directives/feature/feature.directive';
import { ErrorComponent } from './pages/error/error.component';
import { DisplayAsLabelPipe } from './pipes/display-as-label/display-as-label.pipe';
import { LegacyCorrelationObjectService } from './utilities/legacy-correlation-object-service';
import { ImcDateFormatPipe } from './pipes/imc-date-format/imc-date-format.pipe';

@NgModule({
  declarations: [
    FeatureDirective,
    DisplayAsLabelPipe,
    ImcDateFormatPipe,
    CpuUtilizationComponent,
    DashboardCardComponent,
    ErrorComponent,
    CSVDownloadButtonComponent,
  ],
  imports: [
    CommonModule,
    ImcMaterialModule,
    RouterModule,
    CovalentBaseEchartsModule,
    CovalentTooltipEchartsModule,
    CovalentToolboxEchartsModule,
    CovalentLineEchartsModule,
    TranslateModule,
  ],
  providers: [
    LegacyCorrelationObjectService,
    DisplayAsLabelPipe,
    ImcDateFormatPipe,
  ],
  exports: [
    FeatureDirective,
    DisplayAsLabelPipe,
    ImcDateFormatPipe,
    DashboardCardComponent,
    CpuUtilizationComponent,
    CSVDownloadButtonComponent,
    TranslateModule,
  ],
})
export class SharedModule {}
