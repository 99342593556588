import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  IPageChangeEvent,
  ITdDataTableColumn,
  ITdDataTableSortChangeEvent,
  TdDataTableService,
  TdDataTableSortingOrder,
} from '@covalent/core';
import { DateUtilService } from '../../../utils/dateUtil/dateUtil.service';

@Component({
  selector: 'imc-searchable-list',
  templateUrl: './searchableList.component.html',
  styleUrls: ['./searchableList.component.scss'],
})
export class SearchableListComponent implements OnInit, OnChanges {
  @Input('placeholder') placeholder;
  @Input('items') items;
  @Input('nonSearchableProperties') nonSearchableProperties = [];
  @Input('columns') columns: ITdDataTableColumn[];
  @Input('selectable') selectable: boolean;
  @Input('multiple') multiple: boolean;
  @Input('pageSize') pageSize: number;
  @Input('clickable') clickable = 'false';
  @Input('sortBy') sortBy = 'modifiedOn';
  @Input('searchable') searchable = false;
  @Input('showPagination') showPagination = false;
  @Input('sortOrder') sortOrder: TdDataTableSortingOrder =
    TdDataTableSortingOrder.Descending;
  @Output() onSelection: EventEmitter<any> = new EventEmitter();

  selection = [];
  currentPageData = [];
  searchTerm = '';
  fromRow = 1;
  currentPage = 1;
  searchResultCount: number;
  dateTypeColumns = [
    'modifiedOn',
    'lastUpdatedUtc',
    'lastUpdated',
    'created',
    'createdOn',
    'reallyLongDate',
  ];

  constructor(
    private readonly dataTableService: TdDataTableService,
    private readonly dateUtils: DateUtilService
  ) {}

  ngOnInit() {
    this.search('');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      this.items = changes.items.currentValue;
      this.search('');
    }
  }

  search(searchTerm: string): void {
    this.fromRow = 1;
    this.currentPage = 1;
    this.searchTerm = searchTerm;
    this.filter();
  }

  page(pagingEvent: IPageChangeEvent): void {
    this.fromRow = pagingEvent.fromRow;
    this.currentPage = pagingEvent.page;
    // To handle ExpressionChangedAfterItHasBeenCheckedError, calling filter method inside Promise.
    Promise.resolve(null).then(() => this.filter());
  }

  sort(sortEvent: ITdDataTableSortChangeEvent): void {
    this.sortBy = sortEvent.name;
    this.sortOrder = sortEvent.order;
    this.filter();
  }

  filter(): void {
    if (!this.items) {
      return;
    }
    const searchResult = this.dataTableService.filterData(
      this.items,
      this.searchTerm,
      true,
      this.nonSearchableProperties
    );
    this.searchResultCount = searchResult.length;

    let sortedSearchResult = [];
    if (this.dateTypeColumns.indexOf(this.sortBy) > -1) {
      sortedSearchResult = this.dateUtils.sortOnDateTypeColumn(
        searchResult,
        this.sortBy,
        this.sortOrder === TdDataTableSortingOrder.Ascending ? 1 : -1
      );
    } else {
      sortedSearchResult = this.dataTableService.sortData(
        searchResult,
        this.sortBy,
        this.sortOrder
      );
    }
    const pageSize = this.pageSize || sortedSearchResult.length;
    this.currentPageData = this.dataTableService.pageData(
      sortedSearchResult,
      this.fromRow,
      this.currentPage * pageSize
    );
  }

  selectRow() {
    this.onSelection.emit({ selection: this.selection });
  }

  clickRow(data) {
    this.onSelection.emit({ selection: data });
  }
}
