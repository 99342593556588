import { Injectable } from '@angular/core';
import {
  getSiteById,
  GetSiteRequest,
  listSites,
  ListSitesRequest,
} from '@imc/core';
import { Site } from '@imc/core/models/sites.model';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { Customer } from '../../models/customer.model';

@Injectable()
export class SiteService {
  constructor(private readonly apollo: Apollo) {}

  getSiteInfo(request: GetSiteRequest): Observable<Site> {
    return getSiteById(this.apollo, request);
  }

  listSites(request?: ListSitesRequest): Observable<Site[]> {
    return listSites(this.apollo, request);
  }

  listSitesByPlatform(platform: string): Observable<Site[]> {
    const filters: any = {
      cloudPlatform: [
        { operator: '=', value: platform.toLowerCase() },
        { operator: '=', value: platform.toUpperCase() },
      ],
    };

    return listSites(this.apollo, { filters: JSON.stringify(filters) });
  }

  listCustomers(): Observable<Map<string, Customer>> {
    const listCustomersQuery: any = gql`
      query listCustomers {
        listCustomersResponse(fields: $fields)
          @rest(type: "Customer", path: "/sites-service/v2/customers?{args}") {
          customerId
          name
        }
      }
    `;

    const fields: string = 'customerId,name';
    const variables: any = { fields };

    return this.apollo
      .query<any>({
        query: listCustomersQuery,
        variables,
      })
      .pipe(
        map((responseBody: any) => {
          if (!responseBody || responseBody.errors) {
            throw new Error(`There was an error in list customers!`);
          }

          return (
            (responseBody.data && responseBody.data.listCustomersResponse) || []
          );
        }),
        map((customers: Customer[]) => {
          const customersMap: Map<string, Customer> = new Map<
            string,
            Customer
          >();
          customers.forEach((item: any) =>
            customersMap.set(item.customerId, item)
          );

          return customersMap;
        }),
        first()
      );
  }

  listCustomersByPlatform(platform: string): Observable<Customer[]> {
    return this.listSitesByPlatform(platform).pipe(
      switchMap((sites: Site[]) => {
        const customerIds: Set<string> = new Set<string>();
        sites.forEach((site: Site) => customerIds.add(site.customerId));
        const customerObservables: Observable<Customer>[] = [];
        customerIds.forEach((customerId: string) => {
          if (customerId && customerId !== 'sentestmga') {
            customerObservables.push(this.getCustomerById(customerId));
          }
        });

        return forkJoin(customerObservables).pipe(
          map((customers: Customer[]) =>
            customers.filter((customer: Customer) => customer)
          )
        );
      })
    );
  }

  getCustomerById(customerId: string): Observable<Customer> {
    const query: any = gql`
      query customerById {
        customer(id: $customerId)
          @rest(
            type: "Customer"
            path: "/sites-service/v2/customers/{args.id}"
            endpoint: "default"
          ) {
          customerId
          name
          cloudId
          customerOnboard
          number: u_teradata_customer_number
        }
      }
    `;

    return this.apollo.query<any>({ query, variables: { customerId } }).pipe(
      map((responseBody: any) => {
        if (!responseBody || responseBody.errors) {
          throw new Error(
            `There was an error in get customer by ID ${customerId}!`
          );
        }

        const receivedCustomer: any =
          responseBody.data && responseBody.data.customer;

        return {
          customerId,
          name: receivedCustomer.name,
          cloudId: receivedCustomer.cloudId,
          customerOnboard:
            receivedCustomer.customerOnboard === 'true' ? 'Yes' : 'No',
        };
      }),
      first(),
      catchError((err: Error) => of(undefined))
    );
  }
}
