import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { map, mergeMap, switchMap, take } from 'rxjs/operators';

import { NotifyModel, NotifyResponse } from '../models/notify.model';
import { NotifyService } from './notify.service';

export interface NotificationsAlert {
  ackTimestamp: string;
  fetchedTimestamp: string;
  notifications: NotifyModel[];
}

@Injectable({
  providedIn: 'root',
})
export class NotificationPollerService {
  private readonly _newNotifications: BehaviorSubject<NotificationsAlert>;
  private subscription: Subscription;

  constructor(private readonly notifyService: NotifyService) {
    this._newNotifications = new BehaviorSubject({
      ackTimestamp: undefined,
      fetchedTimestamp: undefined,
      notifications: [],
    });

    this.startPolling(60000);
  }

  /**
   * Gets unread notifications for the logged in user. Server uses JWT for getting the user specific notifications
   */
  public pollNotifications(interval: number): Observable<any> {
    return this.notifyService
      .getUserInfoForNotifications()
      .pipe(
        map((ts: any) => {
          this.setLastSeenTimeStamp(ts.lastSeenNotificationTimestamp);
        }),
        switchMap(() => timer(0, interval))
      )
      .pipe(
        mergeMap(() =>
          this.notifyService.getNotifications(
            `>${this._newNotifications.value.ackTimestamp}`
          )
        )
      );
  }

  /**
   * Notify the most recent timestamp of the notifications
   */
  public updateLastSeenTimeStamp(): void {
    const notifications: NotifyModel[] = this._newNotifications.value
      .notifications;
    const ts: string = notifications.reduce(
      (acc: string, value: NotifyModel) =>
        value.receivedTimestamp < acc ? acc : value.receivedTimestamp,
      ''
    );
    if (ts) {
      this.setLastSeenTimeStamp(ts);
      this.notifyService
        .postUserInfoForNotifications({ lastSeenNotificationTimestamp: ts })
        .pipe(take(1))
        .subscribe();
    }
  }

  public stopPolling(): void {
    this.subscription.unsubscribe();
  }

  get newNotifications(): BehaviorSubject<NotificationsAlert> {
    return this._newNotifications;
  }

  private setLastSeenTimeStamp(timestamp: string): void {
    const alert: NotificationsAlert = this._newNotifications.value;

    // Set timestamp to a while back if null
    alert.ackTimestamp = timestamp ? timestamp : '2010-10-10T00:00:00.000Z';
  }

  private startPolling(interval: number): void {
    this.subscription = this.pollNotifications(interval).subscribe(
      (notifyResponse: NotifyResponse) => {
        const alert: NotificationsAlert = this._newNotifications.value;
        let lastTime: Date = new Date(alert.ackTimestamp);

        alert.notifications = notifyResponse.items ? notifyResponse.items : [];

        if (notifyResponse.items) {
          lastTime = new Date(
            notifyResponse.items[
              notifyResponse.items.length - 1
            ].receivedTimestamp
          );
        } else {
          lastTime.setMilliseconds(lastTime.getMilliseconds() + 1);
        }
        alert.fetchedTimestamp = lastTime.toISOString();

        this._newNotifications.next(alert);
      }
    );
  }
}
