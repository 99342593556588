import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  BackupJob,
  BackupJobDetails,
  BackupJobSchedule,
} from '../../models/backup-job.model';

@Injectable()
export class BackupsService {
  constructor(private readonly apollo: Apollo) {}

  getBackupJobsForSite(
    siteId: string,
    customerId: string
  ): Observable<BackupJob[]> {
    const backupJobsListQuery = gql`
      query backupJobsList {
        backupJobs(siteId: $siteId, customerId: $customerId)
          @rest(
            type: "BackupJob"
            path: "/jobs?siteId={args.siteId}&customerId={args.customerId}&jobType=BACKUP,REPLICATION,RESTORE"
            endpoint: "baas"
          ) {
          jobId
          name
        }
      }
    `;

    return this.apollo
      .query<any>({
        query: backupJobsListQuery,
        variables: { siteId, customerId },
      })
      .pipe(
        map((responseBody) => {
          if (responseBody.errors) {
            throw new Error('Unable to get backup jobs for a site!');
          }
          return responseBody.data.backupJobs;
        })
      );
  }

  getBackupJobDetails(
    jobId: string,
    customerId: string
  ): Observable<BackupJobDetails> {
    const backupJobDetailsQuery = gql`
      query backupJobsDetails {
        backupJobDetails(id: $jobId, customerId: $customerId)
          @rest(
            type: "BackupJobDetails"
            path: "/jobs/{args.id}?customerId={args.customerId}"
            endpoint: "baas"
          ) {
          jobDetails
        }
      }
    `;

    return this.apollo
      .query<any>({
        query: backupJobDetailsQuery,
        variables: { jobId, customerId },
      })
      .pipe(
        map((responseBody) => {
          if (responseBody.errors) {
            throw new Error('Unable to get backup job schedule!');
          }

          return responseBody.data.backupJobDetails.jobDetails;
        })
      );
  }
}
