import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BackupJob } from '../models/backup-job.model';

@Injectable()
export class BackupsService {
  constructor(private readonly _apollo: Apollo) {}

  getBackupJobsForSite(
    siteId: string,
    customerId: string
  ): Observable<BackupJob[]> {
    const backupJobsListQuery: any = gql`
      query backupJobsList {
        backupJobs(siteId: $siteId, customerId: $customerId)
          @rest(
            type: "BackupJob"
            path: "/jobs?siteId={args.siteId}&customerId={args.customerId}&jobType=BACKUP,REPLICATION,RESTORE"
            endpoint: "baas"
          ) {
          jobId
          name
          status
          siteId
          backupType
          jobType
        }
      }
    `;

    return this._apollo
      .query<any>({
        query: backupJobsListQuery,
        variables: { siteId, customerId },
      })
      .pipe(
        map((responseBody: any) => {
          if (responseBody.errors) {
            throw new Error('Unable to get backup jobs for a site!');
          }

          return responseBody.data.backupJobs;
        })
      );
  }
}
