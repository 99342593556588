import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  TemplateRef,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { PolicyIdentifierInfo } from '@imc/core/models/policyIdentifierInfo';
import { PolicyCheckerService } from '@imc/core/services/policy-checker-service/policy-checker.service';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { AssumeRoleUri } from '@imc/core/constants/url';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'imc-policy-button',
  templateUrl: './policy-button.component.html',
  styleUrls: ['./policy-button.component.scss'],
})
export class PolicyButtonComponent implements OnInit, OnDestroy {
  private checkSubscription: Subscription;
  @Input() policyIdentifierInfo: PolicyIdentifierInfo;
  @Input() buttonName: string;
  @Input() click: Function;
  @Input() isButtonDisabled: boolean = false;
  @Input() isOperationInProgress: boolean = false;
  @Output() clickOperation = new EventEmitter();
  @ViewChild('noticeDialog') publishDialogTemplate: TemplateRef<any>;

  openDialog: MatDialogRef<any>;
  isPolicyDisabled: boolean = false;

  constructor(
    private readonly dialog: MatDialog,
    private readonly policyCheckerService: PolicyCheckerService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.checkSubscription = this.policyCheckerService
      .isEnabled(this.policyIdentifierInfo)
      .subscribe((enabled: boolean) => {
        this.isPolicyDisabled = !enabled;
      });
  }

  ngOnDestroy(): void {
    if (this.checkSubscription) {
      this.checkSubscription.unsubscribe();
    }
  }

  onClick(): void {
    this.clickOperation.emit();
  }

  openNoticeDialog(): void {
    this.openDialog = this.dialog.open(this.publishDialogTemplate);
  }

  closeNoticeDialog(): void {
    this.openDialog.close();
  }

  navigateToAssumeRole(): void {
    this.closeNoticeDialog();
    this.router.navigate([AssumeRoleUri]);
  }
}
