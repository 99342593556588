import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticatedUserGuard } from '../../guards';
import { NotifyDetailsComponent } from './components/notify-details/notify-details.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthenticatedUserGuard],
    component: NotifyDetailsComponent,
  },
  {
    path: ':id',
    canActivate: [AuthenticatedUserGuard],
    component: NotifyDetailsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NotifyRoutingModule {}
