import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SystemUsage } from '../models/usage.model';

@Injectable()
export class SystemUsageService {
  constructor(private readonly apollo: Apollo) {}

  /**
   * Get Site Usage
   */
  getSystemUsage(
    startTime: string,
    endTime: string
  ): Observable<SystemUsage[]> {
    let namespace: string = 'intellicloud';
    let dataPoints: number = 1;

    const usageQuery = gql`
      query usage {
        Usage(
          startTime: $startTime
          endTime: $endTime
          namespace: $namespace
          dataPoints: $dataPoints
        )
          @rest(
            type: "Usage"
            path: "/service-health/site-usages?startTime={args.startTime}&endTime={args.endTime}&namespace={args.namespace}&dataPoints={args.dataPoints}"
          ) {
          site
          statistics
        }
      }
    `;

    return this.apollo
      .query<any>({
        query: usageQuery,
        variables: { startTime, endTime, namespace, dataPoints },
      })
      .pipe(
        map((responseBody) => {
          if (responseBody.errors) {
            throw new Error('Fetching system usage information failed');
          }
          return responseBody.data.Usage;
        })
      );
  }
}
