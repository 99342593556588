import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  LambdaInvocationType,
  MetricsAgentProxyFunction,
  MetricsAgentProxyRequest,
  MetricsAgentProxyResponse,
  ProxyFunctionName,
  ProxyFunctionResponseData,
  SiteRegistrationDetails,
  ViewpointSystemInfo,
  ViewpointSystemList,
} from '../../models/metrics-agent-proxy.model';

@Injectable()
export class MetricsAgentProxy {
  constructor(private readonly apollo: Apollo) {}

  /**
   * Posts notification for Metrics Proxy Agent service
   * @MetricsAgentProxyRequest user settings related to notification
   */
  public invokeFunction(
    proxyRequest: MetricsAgentProxyRequest
  ): Observable<any> {
    const mutation: any = gql`
      mutation postUserSettings($payload: any!) {
        postSettings(input: $payload)
          @rest(
            type: "AgentResponse"
            path: "/metrics-agent-service/agent-proxy"
            method: "POST"
            endpoint: "metricsAgentProxy"
          ) {
          data
          message
          status
        }
      }
    `;

    const variables: any = {
      payload: {
        function: proxyRequest.function,
        payload: proxyRequest.payload,
      },
    };

    // tslint:disable-next-line: await-promise
    return this.apollo.mutate({ mutation, variables }).pipe(
      map((responseBody: any) => {
        if (responseBody.errorCode) {
          throw new Error(responseBody.message);
        }

        return (
          responseBody && responseBody.data && responseBody.data.postSettings
        );
      })
    );

    // const retVal: any = x.toPromise();

    // return (
    //   retVal
    //     // tslint:disable-next-line: only-arrow-functions
    //     .then(function(responseData: any): any {
    //       // Success
    //       return responseData.data.postSettings;
    //     })
    //     // tslint:disable-next-line:only-arrow-functions
    //     .catch(function(err: any): any {
    //       // Failure
    //       return {
    //         status: false,
    //         message: 'Error: Agent Proxy call failed',
    //         data: err,
    //       };
    //     })
    // );
  }
}
