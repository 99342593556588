import { Component, OnInit } from '@angular/core';
import { TdLoadingService } from '@covalent/core/loading';
import { ErrorService } from '@imc/core/services/error-handling/error.service';
import { Ticket } from '../../models/ticket.model';
import { TicketsService } from '../../services/tickets.service';

const ticketLimit: number = 5;

@Component({
  selector: 'imc-support-tickets',
  templateUrl: './support-tickets.component.html',
  styleUrls: ['./support-tickets.component.scss'],
})
export class SupportTicketsComponent implements OnInit {
  tickets: Ticket[];
  constructor(
    private readonly _ticketsService: TicketsService,
    private readonly _loadingService: TdLoadingService,
    private readonly _errorService: ErrorService
  ) {}

  async ngOnInit(): Promise<void> {
    this._loadingService.register('support-tickets');
    await this.getRecentSupportTickets();
    this._loadingService.resolve('support-tickets');
  }

  async getRecentSupportTickets(): Promise<void> {
    try {
      this.tickets = await this._ticketsService
        .getTickets(ticketLimit)
        .toPromise();
    } catch (error) {
      setTimeout(() => {
        this._errorService.open(error);
      });
    }
  }

  getStatusColor(status: string): string {
    if (status === 'open') {
      return 'tc-green-600';
    } else {
      return 'tc-orange-600';
    }
  }
}
