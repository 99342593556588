import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StepState, TdStepComponent } from '@covalent/core';
import { PolicyUserRole } from '@imc/core/models/roles.enum';
import { GenericApp, Node, Site } from '@imc/core/models/sites.model';
import { UserRoleInfo } from '@imc/core/models/userRoleInfo.model';
import { PolicyService } from '@imc/core/services/policy-service/policy.service';
import { Subscription } from 'rxjs';
import {
  LambdaInvocationType,
  MetricsAgentProxyRequest,
  ProxyFunctionName,
  ProxyFunctionResponseData,
  SiteRegistrationDetails,
  ViewpointSystemInfo,
  ViewpointSystemList,
} from '../../models/metrics-agent-proxy.model';
import { MetricsAgentProxy } from '../../services/metrics-agent-proxy/metrics-agent-proxy.service';
import { SiteService } from '../../services/site-service/ecosystems-site.service';

@Component({
  selector: 'imc-metrics-tool-registration',
  templateUrl: './metrics-tool.registration.component.html',
  styleUrls: ['./metrics-tool.registration.component.scss'],
})
// tslint:disable-next-line: component-class-suffix
export class MetricsToolRegistration implements OnChanges, OnInit, OnDestroy {
  private site: Site;
  @Input() siteId: string = undefined;
  @Output() readonly notify: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  currentSiteId: string;
  metricsRegData: SiteRegistrationDetails;
  subscriptionObjects: Subscription[] = [];

  // Manage site registration
  siteRegistrationForm: FormGroup = undefined;
  siteRegistrationWaiting: boolean = false;
  siteRegistrationResult: string = undefined;
  siteRegistrationStatus: boolean = false;
  siteRegistrationBackendError: boolean = false;
  siteRegistrationCallError: string = undefined;

  // Stepper function variables
  @ViewChildren(TdStepComponent) steps: QueryList<TdStepComponent>;

  step1Network: any = {
    form: undefined,
    ipAddress: undefined,
    port80: false,
    port80Waiting: false,
    port80Result: undefined,
    port80BackendError: false,
    port443: false,
    port443Waiting: false,
    port443Result: undefined,
    port443BackendError: false,
    portScanComplete: false,
    stepState: StepState.None,
    active: true,
    callError: undefined,
    disabled: true,
  };

  step2ServiceAccount: any = {
    form: undefined,
    disabled: true,
    active: false,
    stepState: StepState.None,
    result: undefined,
    status: false,
    data: undefined,
    waiting: false,
    backendError: false,
    svcPassword: undefined,
    callError: undefined,
  };

  viewpointSystemList: ViewpointSystemList = undefined;

  step3SystemName: any = {
    event: undefined,
    disabled: true,
    active: false,
    stepState: StepState.None,
    result: undefined,
    status: false,
    data: undefined,
    waiting: false,
    backendError: false,
    selectedSystemName: undefined,
  };

  step4SiteRegistration: any = {
    disabled: true,
    active: false,
    stepState: StepState.None,
    result: undefined,
    status: false,
    data: undefined,
    waiting: false,
    backendError: false,
    callError: undefined,
  };

  siteViewpoint: any = {
    viewpointIp: undefined,
    errorMessage: undefined,
    error: false,
  };

  // Generic backend error message
  backendErrorMessage: string = 'Error: [Backend] Something went wrong.';

  isSysOpsUser: boolean = false;
  userRoleInfo: UserRoleInfo;

  constructor(
    private readonly metricsAgentProxy: MetricsAgentProxy,
    private readonly siteService: SiteService,
    private readonly policyService: PolicyService
  ) {}

  ngOnInit(): void {
    this.currentSiteId = this.siteId;
  }

  ngOnChanges(): void {
    this.currentSiteId = this.siteId;
    if (this.currentSiteId) {
      this.getSiteInfo();

      if (this.siteRegistrationForm) {
        this.siteRegistrationForm.reset();
      }

      this.step1Network.port443 = false;
      this.step1Network.port443Waiting = false;
      this.step1Network.port443Result = undefined;
      this.step1Network.port80 = false;
      this.step1Network.port80Waiting = false;
      this.step1Network.port80Result = undefined;
      this.step1Network.stepState = StepState.None;
      this.step1Network.active = true;
      this.step1Network.callError = undefined;

      if (this.step1Network.form) {
        this.step1Network.form.reset();
      }

      this.stepServiceAccount_reset();

      if (this.steps) {
        const stepArray: TdStepComponent[] = this.steps.toArray();
        if (stepArray[0]) {
          const networkStep: TdStepComponent = stepArray[0];
          networkStep.disabled = false;
          networkStep.open();
        }
      }

      // Resolve if the current user is SysOps or not
      this.subscriptionObjects.push(
        this.policyService.userRoleInfo$.subscribe((role: UserRoleInfo) => {
          this.userRoleInfo = role;
          if (
            role.currentRole === PolicyUserRole.FullSysops ||
            role.currentRole === PolicyUserRole.ReadOnlySysops ||
            role.currentRole === PolicyUserRole.ReadOnlyLimitedSysops
          ) {
            this.isSysOpsUser = true;
          } else {
            this.isSysOpsUser = false;
          }
        })
      );
    }
  }

  getSiteInfo(): void {
    this.subscriptionObjects.push(
      this.siteService
        .getSiteInfo({ siteId: this.siteId })
        .subscribe((val: Site) => {
          this.site = val;
          this.get_viewpoint_ip();
        })
    );
  }

  ngOnDestroy(): void {
    if (this.subscriptionObjects) {
      for (const entry of this.subscriptionObjects) {
        if (entry) {
          entry.unsubscribe();
        }
      }
    }
  }

  editViewpointIP(): void {
    this.step1Network.disabled = !this.step1Network.disabled;
  }

  getSiteRegistrationDetails(): void {
    const getParam: any = {
      function: {
        name: 'get_site_registration_details',
        invocation_type: 'RequestResponse',
      },
      payload: {
        site_id: this.siteId,
      },
    };
    this.metricsRegData = undefined;

    const siteRegistrationSubscription: Subscription = this.metricsAgentProxy
      .invokeFunction(getParam)
      .subscribe((getData: any) => {
        if (getData.status) {
          const res: ProxyFunctionResponseData = getData.data;
          if (res.function_return_status && res.function_data) {
            const x: any = res.function_data;
            this.metricsRegData = x;
          }
        }
      });

    this.subscriptionObjects.push(siteRegistrationSubscription);
  }

  reset_site_registration(): void {
    if (this.siteRegistrationForm) {
      this.siteRegistrationForm.reset();
    }

    this.step1Network.port443 = false;
    this.step1Network.port443Waiting = false;
    this.step1Network.port443Result = undefined;
    this.step1Network.port80 = false;
    this.step1Network.port80Waiting = false;
    this.step1Network.port80Result = undefined;
    this.step1Network.stepState = StepState.None;
    this.step1Network.active = true;

    if (this.step1Network.form) {
      this.step1Network.form.reset();
    }

    this.stepServiceAccount_reset();

    if (this.steps) {
      const stepArray: TdStepComponent[] = this.steps.toArray();
      if (stepArray[0]) {
        const networkStep: TdStepComponent = stepArray[0];
        networkStep.disabled = false;
        networkStep.open();
      }
    }
  }

  submit_register_site(form?: FormGroup): void {
    this.siteRegistrationForm = form;
    this.siteRegistrationResult = undefined;
    this.siteRegistrationWaiting = true;

    if (form) {
      if (form.valid) {
        const siteId: string = this.currentSiteId;
        const systemName: string = form.value.systemName;
        const vpIpAddress: string = form.value.vpIpAddress;
        const vpServicePassword: string = form.value.vpServicePassword;

        this.registerSite(siteId, systemName, vpIpAddress, vpServicePassword);
      }
    } else {
      const siteId: string = this.currentSiteId;
      const vpIpAddress: string = this.step1Network.ipAddress;
      const vpServicePassword: string = this.step2ServiceAccount.svcPassword;
      const systemName: string = this.step3SystemName.selectedSystemName;

      this.registerSite(siteId, systemName, vpIpAddress, vpServicePassword);
    }
  }

  registerSite(
    siteId: string,
    systemName: string,
    vpIpAddress: string,
    vpServicePassword: string
  ): void {
    this.siteRegistrationResult = undefined;
    this.siteRegistrationWaiting = true;
    this.siteRegistrationBackendError = false;
    this.siteRegistrationCallError = undefined;

    const regEvent: MetricsAgentProxyRequest = {
      function: {
        name: ProxyFunctionName.site_registration,
        invocation_type: LambdaInvocationType.RequestResponse,
      },
      payload: {
        site_id: siteId,
        vp_ipaddress: vpIpAddress,
        system_name: systemName,
        service_account_password: vpServicePassword,
      },
    };

    const registerSiteSubscription: Subscription = this.metricsAgentProxy
      .invokeFunction(regEvent)
      .subscribe((getData: any) => {
        if (getData.status) {
          const siteRegResponse: ProxyFunctionResponseData = getData.data;
          if (siteRegResponse.function_return_status) {
            this.siteRegistrationWaiting = false;

            this.siteRegistrationStatus =
              siteRegResponse.function_return_status;
            this.siteRegistrationResult = siteRegResponse.function_message;

            this.notify.emit(this.siteRegistrationStatus);

            if (typeof siteRegResponse.function_return_status === 'undefined') {
              this.siteRegistrationStatus = false;
              this.siteRegistrationBackendError = true;
              this.siteRegistrationResult = undefined;
            }

            if (
              siteRegResponse &&
              typeof siteRegResponse.function_return_status === 'undefined'
            ) {
              this.siteRegistrationStatus = false;
              this.siteRegistrationCallError = JSON.stringify(siteRegResponse);
            }
          } else {
            this.siteRegistrationResult = JSON.stringify(
              siteRegResponse.function_message
            );
            this.siteRegistrationWaiting = false;
            this.siteRegistrationStatus = false;

            // tslint:disable-next-line:no-console
            console.error(
              'Metrics-Agent-Proxy:Error- Failed for <site_registration>'
            );
          }
        }
      });
    this.subscriptionObjects.push(registerSiteSubscription);
  }

  // Stepper site registration related functions - start

  // Step1: Network test

  submit_step_network_tester(form?: FormGroup): void {
    this.step1Network.form = form;
    this.step1Network.port80 = false;
    this.step1Network.port80Waiting = true;
    this.step1Network.port80Result = undefined;
    this.step1Network.port443Waiting = true;
    this.step1Network.port443Result = undefined;
    this.step1Network.port443 = false;
    this.step1Network.stepState = StepState.None;
    this.step1Network.callError = undefined;

    // Disable step 2
    this.stepServiceAccount_reset();
    // Disable step3.
    this.stepSystemName_reset();

    if (!form) {
      if (!this.siteViewpoint.viewpointIp) {
        this.step1Network.callError = this.siteViewpoint.errorMessage;

        return;
      }

      if (this.siteViewpoint.viewpointIp) {
        this.step1Network.ipAddress = this.siteViewpoint.viewpointIp;
      }
    } else if (form.valid) {
      this.step1Network.ipAddress = form.value.stepVpIpAddress;
    }

    const vpIpAddress: string = this.step1Network.ipAddress;

    // We probe two ports 80 first and 443 next.
    // And depending on what is open we set [this.step1Network.port80] and [this.step1Network.port80] to true
    // For site registration and data collection we use port 443 if both 80 and 443 are open. If only port 80
    // is available then we just use that.

    this.stepNetworkTest(vpIpAddress, '80');
    this.stepNetworkTest(vpIpAddress, '443');
    this.stepNetworkTestComplete();
  }

  stepNetworkTest(vpIpAddress: string, portNumber: string): void {
    const regEvent: MetricsAgentProxyRequest = {
      function: {
        name: ProxyFunctionName.network_tester,
        invocation_type: LambdaInvocationType.RequestResponse,
      },
      payload: {
        site_id: this.currentSiteId,
        host: vpIpAddress,
        port: portNumber,
      },
    };

    const networkTesterSubscription: Subscription = this.metricsAgentProxy
      .invokeFunction(regEvent)
      .subscribe((getData: any) => {
        if (getData.status) {
          const networkTesterResponse: ProxyFunctionResponseData = getData.data;
          if (networkTesterResponse) {
            if (networkTesterResponse) {
              if (portNumber === '80') {
                this.step1Network.port80Waiting = false;
                this.step1Network.port80 =
                  networkTesterResponse.function_return_status;
                this.step1Network.port80Result =
                  networkTesterResponse.function_message;
              }

              if (portNumber === '443') {
                this.step1Network.port443Waiting = false;
                this.step1Network.port443 =
                  networkTesterResponse.function_return_status;
                this.step1Network.port443Result =
                  networkTesterResponse.function_message;
              }
            }

            if (
              typeof networkTesterResponse.function_return_status ===
              'undefined'
            ) {
              if (portNumber === '80') {
                this.step1Network.port80Waiting = false;
                this.step1Network.port80 = false;
                this.step1Network.port80BackendError = true;
                this.step1Network.port80Result = 'Bakend error';
              }

              if (portNumber === '443') {
                this.step1Network.port443Waiting = false;
                this.step1Network.port443 = false;
                this.step1Network.port443BackendError = true;
                this.step1Network.port443Result = 'Bakend error';
              }

              this.step1Network.callError = JSON.stringify(
                networkTesterResponse
              );
            }

            this.stepNetworkTestComplete();
          } else {
            if (portNumber === '80') {
              this.step1Network.port80Waiting = false;
              this.step1Network.port80 = false;
              this.step1Network.port80Result = JSON.stringify(
                networkTesterResponse.function_message
              );
            }

            if (portNumber === '443') {
              this.step1Network.port443Waiting = false;
              this.step1Network.port443 = false;
              this.step1Network.port443Result = JSON.stringify(
                networkTesterResponse.function_message
              );
            }
            this.stepNetworkTestComplete();
          }
        } else {
          this.step1Network.port80Waiting = false;
          this.step1Network.port443Waiting = false;
          this.step1Network.callError = JSON.stringify(getData.message);
          this.stepNetworkTestComplete();
        }
      });
    this.subscriptionObjects.push(networkTesterSubscription);
  }

  stepNetworkTestComplete(): void {
    if (
      (this.step1Network.port443 === true ||
        this.step1Network.port80 === true) &&
      (this.step1Network.port443Result && this.step1Network.port80Result)
    ) {
      this.step1Network.stepState = StepState.Complete;

      // If things looks good in step1. i.e. the target viewpoint IP address is reachable
      // at either port 80 or 443 then we can proceed to step2, which is to get the
      // Viewpoint service account password and so we enable that step.
      this.step2ServiceAccount.disabled = false;
      this.step2ServiceAccount.active = true;

      const stepArray: TdStepComponent[] = this.steps.toArray();

      if (stepArray[1]) {
        const nextStep: TdStepComponent = stepArray[1];
        nextStep.disabled = false;
        nextStep.open();
      }
    } else if (
      this.step1Network.port443 === false &&
      this.step1Network.port80 === false &&
      !this.step1Network.port443Waiting &&
      !this.step1Network.port80Waiting
    ) {
      this.step1Network.stepState = StepState.Required;

      const stepArray: TdStepComponent[] = this.steps.toArray();
      if (stepArray[0]) {
        const networkStep: TdStepComponent = stepArray[0];
        networkStep.disabled = false;
        networkStep.open();
      }

      this.step2ServiceAccount.disabled = true;
      this.step2ServiceAccount.active = false;
    } else {
      this.step1Network.stepState = StepState.None;
    }
  }

  // Step2: Try to login to Viewpoint

  stepServiceAccount_reset(): void {
    if (this.step2ServiceAccount.form) {
      this.step2ServiceAccount.form.reset();
    }
    this.step2ServiceAccount.disabled = true;
    this.step2ServiceAccount.active = false;
    this.step2ServiceAccount.stepState = StepState.None;
    this.step2ServiceAccount.result = undefined;
    this.step2ServiceAccount.status = false;
    this.step2ServiceAccount.data = undefined;
    this.step2ServiceAccount.waiting = false;
    this.step2ServiceAccount.callError = undefined;

    this.stepSystemName_reset();
  }

  submit_step_2_password(form: FormGroup): void {
    this.step2ServiceAccount.result = undefined;
    this.step2ServiceAccount.status = false;
    this.step2ServiceAccount.data = undefined;
    this.step2ServiceAccount.form = form;
    this.step2ServiceAccount.waiting = true;
    this.step2ServiceAccount.callError = undefined;

    // Disable step3.
    this.stepSystemName_reset();

    const vpIpAddress: string = this.step1Network.ipAddress;
    let httpsProtocol: boolean = false;

    // If both port 80 and 443 is available we use port 443 to connect
    if (this.step1Network.port443) {
      httpsProtocol = true;
    }

    if (form.valid) {
      const svcPassword: string = form.value.stepServiceAccountPasssword;
      this.step2ServiceAccount.svcPassword = svcPassword;
      this.getViewpointSystemNames(vpIpAddress, httpsProtocol, svcPassword);
    }
  }

  escapeRegExp(str: string): string {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
  }

  getViewpointSystemNames(
    vpIpAddress: string,
    httpsProtocol: boolean,
    svcPassword: string
  ): void {
    const regEvent: MetricsAgentProxyRequest = {
      function: {
        name: ProxyFunctionName.get_viewpoint_system_names,
        invocation_type: LambdaInvocationType.RequestResponse,
      },
      payload: {
        site_id: this.currentSiteId,
        viewpoint_ip: vpIpAddress,
        service_account_password: svcPassword,
        https_protocol: httpsProtocol,
      },
    };

    const getViewpointSystemsSubscription: Subscription = this.metricsAgentProxy
      .invokeFunction(regEvent)
      .subscribe((getData: any) => {
        if (getData.status) {
          const systemNamesResponse: ProxyFunctionResponseData = getData.data;

          let error401: string = this.escapeRegExp(
            'Return status code = [401]'
          );
          error401 = error401.toLowerCase();
          let error404: string = this.escapeRegExp(
            'Return status code = [404]'
          );
          error404 = error404.toLowerCase();
          let errorConnection: string = this.escapeRegExp('ConnectionError');
          errorConnection = errorConnection.toLowerCase();

          if (systemNamesResponse) {
            this.step2ServiceAccount.status =
              systemNamesResponse.function_return_status;
            this.step2ServiceAccount.result =
              systemNamesResponse.function_message;
            this.step2ServiceAccount.data = systemNamesResponse.function_data;
            this.viewpointSystemList = this.step2ServiceAccount.data;
          }

          if (this.step2ServiceAccount.result) {
            if (this.step2ServiceAccount.result.toLowerCase().match(error401)) {
              this.step2ServiceAccount.result =
                'Error: Login Failed. \
                                                         Please check if [ taasmetrics ] \
                                                         service account exist. And \
                                                         check if the password is right.';
            } else if (
              this.step2ServiceAccount.result.toLowerCase().match(error404)
            ) {
              this.step2ServiceAccount.result =
                'Error: Viewpoint likely not running. [404 Not Found]';
            }
          }

          if (
            typeof systemNamesResponse.function_return_status === 'undefined' &&
            systemNamesResponse
          ) {
            this.step2ServiceAccount.status = false;
            this.step2ServiceAccount.waiting = false;
            this.step2ServiceAccount.backendError = true;
          }

          if (systemNamesResponse && systemNamesResponse.errorType) {
            // This can happen in some cases. For example www.yahoo.com results in
            // timeout whereas www.google.com results in 404 non found case handled
            // above.
            this.step2ServiceAccount.status = false;
            this.step2ServiceAccount.waiting = false;

            if (systemNamesResponse.errorType.match(errorConnection)) {
              this.step2ServiceAccount.result =
                'Error: Login Failed. Viewpoint likely not running. \
                                                         [Connection Error]';
            } else {
              this.step2ServiceAccount.result = systemNamesResponse.errorType;
            }
          }

          if (
            typeof systemNamesResponse.function_return_status === 'undefined' &&
            systemNamesResponse &&
            !systemNamesResponse.errorType
          ) {
            this.step2ServiceAccount.status = false;
            this.step2ServiceAccount.waiting = false;
            this.step2ServiceAccount.callError = JSON.stringify(
              systemNamesResponse
            );
          }

          this.step2ServiceAccount.waiting = false;
          this.stepServiceAccount_complete();
        } else {
          const systemNamesResponse: ProxyFunctionResponseData = getData.data;
          this.step2ServiceAccount.status = false;
          this.step2ServiceAccount.waiting = false;
          this.step2ServiceAccount.result = JSON.stringify(
            systemNamesResponse.function_message
          );

          this.stepServiceAccount_complete();
        }
      });
    this.subscriptionObjects.push(getViewpointSystemsSubscription);
  }

  stepServiceAccount_complete(): void {
    if (this.step2ServiceAccount.status === true) {
      this.step2ServiceAccount.stepState = StepState.Complete;
      this.step2ServiceAccount.active = false;

      this.step3SystemName.disabled = false;
      this.step3SystemName.active = true;

      const stepArray: TdStepComponent[] = this.steps.toArray();
      if (stepArray[2]) {
        const nextStep: TdStepComponent = stepArray[2];
        nextStep.disabled = false;
        nextStep.open();
      }
    } else if (
      !this.step2ServiceAccount.status &&
      !this.step2ServiceAccount.waiting
    ) {
      this.step2ServiceAccount.stepState = StepState.Required;

      const stepArray: TdStepComponent[] = this.steps.toArray();
      if (stepArray[1]) {
        const step2: TdStepComponent = stepArray[1];
        step2.disabled = false;
        step2.open();
      }

      this.step3SystemName.disabled = true;
      this.step3SystemName.active = false;
    } else {
      this.step2ServiceAccount.stepState = StepState.None;
    }
  }

  // Step3: Choose the System Name

  stepSystemName_reset(): void {
    this.step3SystemName.disabled = true;
    this.step3SystemName.active = false;
    this.step3SystemName.stepState = StepState.None;
    this.step3SystemName.result = undefined;
    this.step3SystemName.status = false;
    this.step3SystemName.data = undefined;
    this.step3SystemName.waiting = true;
    this.step3SystemName.selectedSystemName = undefined;

    this.stepSiteRegistration_reset();
  }

  step_3_select_system_name(system: ViewpointSystemInfo): void {
    this.step3SystemName.result = undefined;
    this.step3SystemName.status = false;
    this.step3SystemName.data = undefined;
    this.step3SystemName.waiting = true;

    this.stepSiteRegistration_reset(); // Reset site registration - step 4.

    // Set the value selected.
    this.step3SystemName.selectedSystemName = system.systemName;

    if (this.step3SystemName.selectedSystemName) {
      this.step3SystemName.waiting = false;
    }
  }

  submit_step_3_select_system_name(): void {
    if (this.step3SystemName.selectedSystemName) {
      this.stepSystemName_complete();
    }
  }

  stepSystemName_complete(): void {
    if (this.step3SystemName.selectedSystemName) {
      this.step3SystemName.waiting = false;
      this.step3SystemName.stepState = StepState.Complete;

      this.step4SiteRegistration.disabled = false;
      this.step4SiteRegistration.active = true;

      const stepArray: TdStepComponent[] = this.steps.toArray();
      if (stepArray[3]) {
        const nextStep: TdStepComponent = stepArray[3];
        nextStep.disabled = false;
        nextStep.open();
      }
    }
  }

  // Step4: Register the site

  stepSiteRegistration_reset(): void {
    this.step4SiteRegistration.disabled = true;
    this.step4SiteRegistration.active = false;
    this.step4SiteRegistration.stepState = StepState.None;
    this.step4SiteRegistration.result = undefined;
    this.step4SiteRegistration.status = false;
    this.step4SiteRegistration.data = undefined;
    this.step4SiteRegistration.waiting = true;
    this.step4SiteRegistration.callError = undefined;
  }

  submit_step_4_register_site(): void {
    this.submit_register_site();
  }

  // Stepper site registration related functions - end

  get_viewpoint_ip(): void {
    const genericApps: GenericApp[] = this.site.genericApps;
    this.siteViewpoint.viewpointIp = undefined;
    this.siteViewpoint.error = false;
    this.siteViewpoint.errorMessage = undefined;

    if (!genericApps) {
      this.siteViewpoint.error = true;
      this.siteViewpoint.errorMessage = 'Error: Viewpoint not found';
      this.siteViewpoint.viewpointIp = undefined;
    } else {
      let vpCount: number = 0;
      const viewPointNodes: Node[][] = genericApps
        .filter(
          (genericApp: GenericApp) =>
            genericApp.type === 'viewpoint' && genericApp.nodes
        )
        .map((genericApp: GenericApp) => genericApp.nodes);

      const viewPointIpAddresses: string[] = [].concat
        .apply([], viewPointNodes)
        .filter((node: Node) => node.ipAddress)
        .map((node: Node) => node.ipAddress);

      vpCount = viewPointIpAddresses.length;
      if (viewPointIpAddresses.length === 1) {
        this.siteViewpoint.error = false;
        this.siteViewpoint.viewpointIp = viewPointIpAddresses[0];
      }

      if (vpCount === 0) {
        this.siteViewpoint.viewpointIp = undefined;
        this.siteViewpoint.error = true;
        this.siteViewpoint.errorMessage = 'Error: Viewpoint Not Found.';
      }

      if (vpCount > 1) {
        this.siteViewpoint.viewpointIp = undefined;
        this.siteViewpoint.error = true;
        this.siteViewpoint.errorMessage =
          'Error: More than 1 Viewpoint is mapped for the site.';
      }
    }

    if (!this.siteViewpoint.viewpointIp) {
      this.step1Network.disabled = false;
    }
  }
}
