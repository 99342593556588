import { PolicyUserRole } from './roles.enum';

export class UserRoleInfo {
  public allowedRoles?: PolicyUserRole[] = [];
  public currentRole?: PolicyUserRole;
  public managedCustomers?: string[];
  public rawRolesData?: string[] = [];
  public separator?: string = ':R:';

  public setAllowedRolesWithRawData?(rawRolesData: string[]) {
    this.rawRolesData = rawRolesData;
    for (const item of rawRolesData) {
      // parse the string to get the role info delimited by ':R:'
      // example data 0:R:ReadOnlySysops
      if (item.split(':R:').length >= 2) {
        try {
          this.allowedRoles.push(this.getPolicyRole(item.split(':R:')[1]));
        } catch (error) {
          // log and skip this unrecognized role
          console.error(
            `Encounted an unrecognized policy service role: ${
              item.split(':R:')[1]
            }!`
          );
        }
      }
    }
  }

  public setCurrentRoleWithRawData?(rawRoleData: string) {
    // parse the string to get the role info delimited by ':R:'
    // example data 0:R:ReadOnlySysops
    if (rawRoleData && rawRoleData.split(':R:').length >= 2) {
      this.currentRole = this.getPolicyRole(rawRoleData.split(':R:')[1]);
    }
  }
  public setCurrentRole?(roleData: string) {
    this.currentRole = this.getPolicyRole(roleData);
  }

  private getPolicyRole?(data: string): PolicyUserRole {
    switch (data) {
      case 'ReadOnlySiteAdmin':
        return PolicyUserRole.ReadOnlySiteAdmin;
      case 'SiteAdmin':
        return PolicyUserRole.SiteAdmin;
      case 'SiteAccessManager':
        return PolicyUserRole.SiteAccessManager;
      case 'SiteSuperAdmin':
        return PolicyUserRole.SiteSuperAdmin;
      case 'LimitedBaseUser':
        return PolicyUserRole.LimitedBaseUser;
      case 'ReadOnlyLimitedSysops':
        return PolicyUserRole.ReadOnlyLimitedSysops;
      case 'ReadOnlySysops':
        return PolicyUserRole.ReadOnlySysops;
      case 'FullSysops':
        return PolicyUserRole.FullSysops;
      case 'SiteOnboardingUser':
        return PolicyUserRole.SiteOnboardingUser;
      default:
        throw new Error('Invalid Role');
    }
  }
}
