import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TicketService {
  constructor(private apollo: Apollo) {}

  public requestNewSite(customerId: string): Observable<void> {
    const mutation: any = gql`
      mutation requestNewSite($ticketInfo: any!) {
        requestNewSite(input: $ticketInfo)
          @rest(
            path: "/management-console/tickets"
            type: "Ticket"
            method: "POST"
          ) {
          ticketId
        }
      }
    `;

    const variables: any = {
      ticketInfo: {
        company: customerId,
        shortDescription: 'New Site Request',
        type: 'change',
      },
    };

    return this.apollo.mutate({ mutation, variables }).pipe(
      map((responseBody: any) => {
        if (responseBody.errorCode) {
          throw new Error(responseBody.message);
        }
      })
    );
  }
}
