import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { Site } from '@imc/core/models/sites.model';
import { AzureInstanceInfo } from '../../models/azure-instance-info.model';
import { AzureInstanceInfoService } from '../../services/azure-instance-info-service/azure-instance-info.service';

@Component({
  selector: 'imc-azure-instance-info',
  templateUrl: './azure-instance-info.component.html',
  styleUrls: ['./azure-instance-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AzureInstanceInfoComponent implements OnChanges {
  @Input() site: Site;
  @Input() loading: boolean = true;
  @Input() error: any;

  loaderKey: string = 'azureInstanceInfoLoader';

  instanceInfo: AzureInstanceInfo;
  subtitle: string = '';
  constructor(
    private readonly _azureInstanceInfoService: AzureInstanceInfoService
  ) {}

  ngOnChanges(): void {
    if (this.site !== undefined) {
      this.subtitle = `Instance info on ${this.site.displayName}`;
      this.instanceInfo = this._azureInstanceInfoService.getInstanceInfo(
        this.site
      );
    }
  }
}
