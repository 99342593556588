import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Site } from '@imc/core';
import { ReportsUri, SupportUri } from '@imc/core/constants/url';
import { PolicyService } from '@imc/core/services/policy-service/policy.service';
import { SiteService } from '@imc/core/services/site-service/site.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LeftNavService implements OnDestroy {
  private routerSubscription: Subscription;
  private customerSubscription: Subscription;
  private siteId: string = '';
  private isCustomer: boolean;
  public leftNav$: BehaviorSubject<any> = new BehaviorSubject({});

  sysOpsRoutes: any[] = [
    {
      navTitle: 'Intellicloud View',
      children: [
        {
          icon: 'dashboard',
          route: 'home',
          title: 'System Dashboard',
        },
        {
          icon: 'navigation',
          route: 'intellicloud-system-details',
          title: 'Intellicloud System Details',
        },
        {
          icon: 'people',
          route: 'customers',
          title: 'Customers',
        },
      ],
    },
    {
      navTitle: 'DevOps',
      children: [
        {
          icon: 'cloud',
          route: 'sites/platform/aws',
          title: 'IC AWS DevOps Dashboard',
        },
        {
          icon: 'cloud',
          route: 'sites/platform/azure',
          title: 'IC Azure DevOps Dashboard',
        },
        {
          icon: 'cloud',
          route: 'sites/platform/tmc',
          title: 'TD Cloud Devops Dashboard',
        },
      ],
    },
  ];

  constructor(
    private readonly policyService: PolicyService,
    private readonly router: Router,
    private readonly location: Location,
    private readonly siteService: SiteService
  ) {
    this.getRoutes();
    this.routerSubscription = this.router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd) {
        this.getRoutes();
      }
    });
  }

  getLeftNavConfig(): Observable<boolean> {
    return this.leftNav$.asObservable();
  }

  getRoutes(): any {
    let config: any[];

    this.customerSubscription = this.policyService.isCustomer$.subscribe(
      (isCustomer: boolean) => {
        this.isCustomer = isCustomer;
        const allSites: any[] = this.getAllSitesRoutes(this.isCustomer);
        if (isCustomer) {
          config = allSites;
        } else {
          config = this.sysOpsRoutes.concat(allSites);
        }

        this.siteId = this.getCurrentSiteId();
        if (this.siteId !== undefined && this.siteId.length > 0) {
          this.siteService
            .getSiteInfo({ siteId: this.siteId })
            .subscribe((site: Site) => {
              config = this.getSingleSiteRoutes(site.isVCM).concat(config);
              this.leftNav$.next(config);
            });
        } else {
          this.leftNav$.next(config);
        }
      }
    );
  }

  getAllSitesRoutes(isCustomer: boolean): any[] {
    return [
      {
        navTitle: 'All Sites',
        children: [
          {
            icon: 'list',
            route: isCustomer ? '/' : 'sites',
            title: 'Sites',
          },
          {
            icon: 'live_help',
            route: SupportUri,
            title: 'Support Tickets',
          },
          {
            icon: 'person',
            route: 'users/list',
            title: 'Users',
          },
          {
            icon: 'access_time',
            route: ReportsUri,
            title: 'Monitoring',
          },
        ],
      },
      // Enable if you need see Example Feature module. Should be only local
      // {
      //   icon: 'camera',
      //   route: 'features',
      //   title: 'Example Features',
      // }
    ];
  }

  // TODO: This code needs to be eliminated and
  // we need a better way to fix the issues of
  // applying the right context to the left nav
  getCurrentSiteId(): string {
    let currentSiteId: string = '';
    this.location
      .path()
      .split('/')
      .forEach((str: string, index: number, arr: any[]) => {
        if (
          str === 'sites' &&
          arr.length > index + 1 &&
          arr[index + 1] !== 'platform' &&
          arr[index + 1] !== 'create'
        ) {
          currentSiteId = arr[index + 1];
        }
      });

    return currentSiteId;
  }

  getSingleSiteRoutes(isVCMSite: boolean): any[] {
    if (isVCMSite) {
      return [
        {
          navTitle: this.siteId,
          children: [
            {
              icon: 'dashboard',
              route: `/sites/${this.siteId}`,
              title: 'Site Dashboard',
            },
            {
              icon: 'access_time',
              route: `/sites/${this.siteId}/monitoring`,
              title: 'Site Monitoring',
            },
            {
              icon: 'live_help',
              route: `/sites/${this.siteId}/engine/new-sql/overview`,
              title: 'Engines',
            },
          ],
        },
      ];
    }

    return [
      {
        navTitle: this.siteId,
        children: [
          {
            icon: 'dashboard',
            route: `/sites/${this.siteId}`,
            title: 'Site Dashboard',
          },
          {
            icon: 'backup',
            route: `/sites/${this.siteId}/legacy-baas`,
            title: 'Backups',
          },
        ],
      },
    ];
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.customerSubscription.unsubscribe();
  }
}
