import { Injectable } from '@angular/core';
import { TdDynamicElement, TdDynamicType } from '@covalent/dynamic-forms';
import { TypeUtilService } from './typeUtil.service';

export const TYPEMAP = {
  string: TdDynamicElement.Input,
  input: TdDynamicElement.Input,
  number: TdDynamicType.Number,
  integer: TdDynamicType.Number,
  boolean: TdDynamicElement.SlideToggle,
  select: TdDynamicElement.Select,
  hiddenstring: TdDynamicElement.Password,
  date: TdDynamicElement.Datepicker,
  array: TdDynamicElement.Input,
  object: TdDynamicElement.Input,
  percent: TdDynamicElement.Slider,
};

@Injectable()
export class FormBuilderService {
  constructor(private typeUtil: TypeUtilService) {}

  buildFormParameters(parameters, flex?) {
    return parameters.map((param) => this.buildFormParameter(param, flex));
  }

  buildFormParameter(value, flex?) {
    const dynamicObj: any = {
      label: value.label,
      name: value.id,
      type: TYPEMAP[value.type.toLowerCase()],
      flex: flex || 25,
      min: value.minValue,
      max: value.maxValue,
      required: this.typeUtil.toBoolean(value.required),
      default: this.getTypedValue(value),
    };

    if (value.choices && value.choices.length > 0) {
      dynamicObj.type = TYPEMAP.select;
      dynamicObj.selections = value.choices;
      if (value.default && value.choices.indexOf(value.default) >= 0) {
        dynamicObj.default = value.default;
      } else {
        dynamicObj.default = value.choices[0].value || value.choices[0];
      }
    }

    const extraFields = ['maxLength', 'hidden', 'validators'];
    extraFields.forEach((item) => {
      if (value[item]) {
        dynamicObj[item] = value[item];
      }
    });

    return dynamicObj;
  }

  getDefaultValue(type) {
    if (type.toLowerCase() === 'array') {
      return [];
    } else if (type.toLowerCase() === 'object') {
      return {};
    }
  }

  private getTypedValue(value) {
    return value.type.toLowerCase() === 'boolean'
      ? this.typeUtil.toBoolean(value.defaultValue)
      : value.defaultValue;
  }
}
