import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { GenericApp, Node, Site } from '@imc/core/models/sites.model';

const addAppDetails: Function = (
  cloudPlatform: string,
  type: string,
  nodes: Node[]
): { type: string; ips: any } => {
  if (cloudPlatform.toLowerCase() === 'aws') {
    if (type === 'sqle' || type === 'viewpoint') {
      return {
        type,
        ips:
          nodes &&
          nodes
            .filter(
              (node: Node) =>
                node.publicIpAddress && node.publicIpAddress.trim() !== ''
            )
            .map((n: Node) => n.publicIpAddress)
            .join(', '),
      };
    } else {
      return {
        type: undefined,
        ips: undefined,
      };
    }
  } else {
    return {
      type,
      ips:
        nodes &&
        nodes
          .filter(
            (node: Node) => node.ipAddress && node.ipAddress.trim() !== ''
          )
          .map((n: Node) => n.ipAddress)
          .join(', '),
    };
  }
};

@Component({
  selector: 'imc-application-overview',
  templateUrl: './application-overview.component.html',
  styleUrls: ['./application-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationOverviewComponent implements OnChanges {
  @Input() site: Site;
  @Input() loaderKey: string = 'applicationOverviewLoader';
  @Input() loading: boolean = true;
  @Input() error: any;
  subtitle: string = '';
  apps: any[];

  ngOnChanges(): void {
    if (this.site !== undefined) {
      this.subtitle = `Applications Overview for ${this.site.displayName}`;
      this.apps = [];
      if (this.site.cloudPlatform && this.site.sqleApp) {
        this.apps.push(
          addAppDetails(
            this.site.cloudPlatform,
            'sqle',
            this.site.sqleApp.nodes
          )
        );
      }
      if (this.site.cloudPlatform && this.site.genericApps) {
        this.site.genericApps.forEach((genericApp: GenericApp) => {
          this.apps.push(
            addAppDetails(
              this.site.cloudPlatform,
              genericApp.type,
              genericApp.nodes
            )
          );
        });
      }

      this.apps = this.apps.filter(
        (app: any) =>
          app.type && app.type !== undefined && app.ips !== undefined
      );
    }
  }
}
