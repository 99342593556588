import { Component, Input } from '@angular/core';

import { ProductTier } from '../../models/product-offering.model';

@Component({
  selector: 'imc-azure-product-features',
  templateUrl: './product-features.component.html',
  styleUrls: ['./product-features.component.scss'],
})
export class ProductFeaturesComponent {
  public _productTier: ProductTier;
  public firstColumnFeatures: any[];
  public secondColumnFeatures: any[];

  @Input() set productTier(productTier: ProductTier) {
    this._productTier = productTier;
    this.firstColumnFeatures = [];
    this.secondColumnFeatures = [];
    for (const feature of productTier.features) {
      if (
        feature.category.toLowerCase() === 'ecosystem applications' ||
        feature.category.toLowerCase() === 'upgrade options'
      ) {
        this.secondColumnFeatures.push(feature);
      } else if (feature.category.toLowerCase() !== 'upsell features') {
        this.firstColumnFeatures.push(feature);
      }
    }
  }
}
