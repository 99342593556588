import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'imc-confirm-dialog',
  templateUrl: './confirmationDialog.component.html',
})
export class ConfirmationDialogComponent {
  header: string;
  confirmMessage: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly translate: TranslateService
  ) {
    this.confirmMessage =
      data.confirmMessage || this.translate.instant('proceed_with_action');
    this.header = data.header || this.translate.instant('confirm');
  }

  onCloseConfirm() {
    this.dialogRef.close(true);
  }

  onCloseCancel() {
    this.dialogRef.close(false);
  }
}
