import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'utc',
})
export class UTCPipe implements PipeTransform {
  transform(date: string, formatString: string): string {
    let momentDate = moment(date);
    if (!momentDate.isValid()) {
      momentDate = moment(date, 'MM-DD-YYYY HH:mm');
      if (!momentDate.isValid()) {
        return 'Invalid date';
      }
    }
    return momentDate.utc().format(formatString);
  }
}
