import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardTicketsService } from '@imc-features/dashboard/services/dashboard-tickets.service';
import { SupportUri, TicketsUri, CustomerDashboardUri } from '@imc/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Ticket } from '../../models/ticket.model';
import { MatTableDataSource } from '@angular/material';

const PRIORITY: string = '1';
const STATUS: string = 'open';

@Component({
  selector: 'imc-recent-tickets',
  templateUrl: './recent-tickets.component.html',
  styleUrls: ['./recent-tickets.component.scss'],
})
export class RecentTicketsComponent implements OnInit, OnDestroy, OnChanges {
  private getTicketsSubscription: Subscription;
  @Input() customerId: string;
  @Input() customerSpecific: boolean = false;
  loaderKey: string = 'recentTicketsLoader';
  loading: boolean = true;
  error: boolean = false;
  routerLink: string = '';
  dataSource: MatTableDataSource<Ticket> = new MatTableDataSource<Ticket>();

  LIMIT: number = 5;
  displayedColumns: string[] = [
    'number',
    'customerName',
    'state',
    'updatedOn',
    'shortDescription',
  ];

  tickets: Ticket[];

  constructor(
    private dashboardTickets: DashboardTicketsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.customerSpecific) {
      this.routerLink = `/${SupportUri}/${this.customerId}`;
    } else {
      this.routerLink = `/${SupportUri}`;
    }
    this.refreshTickets();
  }

  ngOnDestroy(): void {
    if (this.getTicketsSubscription) {
      this.getTicketsSubscription.unsubscribe();
    }
  }

  ngOnChanges(): void {
    this.refreshTickets();
  }

  openTicket(ticketId: string): void {
    this.router.navigateByUrl(`${SupportUri}/${TicketsUri}/${ticketId}`);
  }

  openCustomer(customerId: string): void {
    this.router.navigate([`${CustomerDashboardUri}/${customerId}`]);
  }

  private refreshTickets(): void {
    let customerId: string = this.customerId;
    if (this.customerSpecific) {
      this.displayedColumns = this.displayedColumns.filter(
        (e) => e !== 'customerName'
      );
    } else {
      customerId = undefined;
    }

    this.dashboardTickets
      .getTickets(this.LIMIT, customerId, PRIORITY, STATUS)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        (tickets: Ticket[]) => {
          this.tickets = tickets;
          this.dataSource = new MatTableDataSource(this.tickets);
        },

        (error: any) => {
          this.error = true;
        }
      );
  }
}
