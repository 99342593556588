import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GraphDataResponse } from '../models/metrics-graph.model';

@Injectable()
export class MetricsGraphService {
  constructor(private readonly apollo: Apollo) {}

  fetchData(
    siteId: string,
    startDate: Date,
    endDate: Date,
    dataPoints: number
  ): Observable<GraphDataResponse> {
    const namespace: string = 'intellicloud';
    const graphData: string = 'true';

    const startTime: string = this.formatTime(startDate.toISOString());
    const endTime: string = this.formatTime(endDate.toISOString());

    const graphListQuery: any = gql`
      query graphList {
        graphList(
          siteId: $siteId
          startTime: $startTime
          endTime: $endTime
          namespace: $namespace
          dataPoints: $dataPoints
          graphData: $graphData
        ) @rest(type: "GraphData", path: "/service-health/site-usages?{args}") {
          siteId
          graphData
        }
      }
    `;

    return this.apollo
      .query<any>({
        query: graphListQuery,
        variables: {
          siteId,
          startTime,
          endTime,
          namespace,
          dataPoints,
          graphData,
        },
      })
      .pipe(
        map((responseBody: any) => {
          if (responseBody.errors) {
            // Error condition
            throw new Error('Fetching Graph Data failed');
          }

          return responseBody.data.graphList;
        })
      );
  }

  formatTime(time: string): string {
    const timeStr: string[] = time.split('T');
    timeStr[1] = '00:00:00.000Z';

    return timeStr.join().replace(',', 'T');
  }
}
