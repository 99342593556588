import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  DbState,
  DbStatusStrings,
} from '@imc-features/ecosystems/_modules/ecosystems-shared/constants/db-state.constants';
import { ElasticityService } from '@imc-features/ecosystems/_modules/ecosystems-shared/services/elasticity-service/elasticity.service';
import { Site } from '@imc/core/models/sites.model';
import { AppConfigService } from '@imc/core/services';
import { Subscription } from 'rxjs';
import { CLOUD_PLATFORM_INSTANCE_INFO } from '../../constants/site-instance-info.constants';
import { AwsInstanceInfo } from '../../models/aws-instance-info.model';
import { AwsInstanceInfoService } from '../../services/aws-instance-info-service/aws-instance-info.service';
import { IcawsService } from '../../services/icaws-service/icaws.service';
import { GoogleAnalyticsService } from '@imc/core/services/google-analytics/google-analytics.service';

@Component({
  selector: 'imc-aws-scale-up-down-database',
  templateUrl: './scale-up-down-database.component.html',
  styleUrls: ['./scale-up-down-database.component.scss'],
})
export class ScaleUpDownDatabaseComponent implements OnInit, OnDestroy {
  private dbStatusStrings: any = DbStatusStrings;
  private icawsSubscription: Subscription;
  private useLegacyApis: boolean = true;
  @Input() siteInfo: Site;
  dbStateOptions: any = DbState;
  operationInProgress: boolean = false;

  failedOperation: boolean = false;
  displayStatus: boolean = false;
  dbState: number;
  dbMessage: string = '';
  availableInstanceSizes: string[] = [];
  availableInstanceInfoData: AwsInstanceInfo[] = [];
  toBeDisabledInstanceInfoName: string = '';
  toBeDisabledInstanceInfo: AwsInstanceInfo;
  selectedInstanceInfo: AwsInstanceInfo;
  disableButton: boolean = false;

  analyticsEvent: any = {
    awsScaleUpDown: {},
  };

  constructor(
    private readonly analyticsService: GoogleAnalyticsService,
    private readonly icawsService: IcawsService,
    private readonly awsInstanceInfoService: AwsInstanceInfoService,
    private readonly appConfig: AppConfigService,
    private readonly elasticityService: ElasticityService
  ) {}

  ngOnInit(): void {
    this.useLegacyApis = this.appConfig.getConfig().legacy.sqle;
    if (this.siteInfo.sqleApp) {
      this.getDbInfo();
      const dbSize: string = this.siteInfo.sqleApp.instanceSize;

      if (dbSize) {
        this.availableInstanceSizes = this.awsInstanceInfoService.getAvailableInstanceSizes(
          dbSize
        );
      }

      this.availableInstanceSizes.forEach((instanceSize: string) => {
        this.availableInstanceInfoData.push(this.getInstanceInfo(instanceSize));
      });

      const normalizedDbSize: string = this.awsInstanceInfoService.getNormalizedAwsInstanceSizeFromDbsize(
        dbSize
      );

      this.toBeDisabledInstanceInfoName = CLOUD_PLATFORM_INSTANCE_INFO.aws[
        normalizedDbSize
      ]
        ? CLOUD_PLATFORM_INSTANCE_INFO.aws[normalizedDbSize].name
        : undefined;

      this.toBeDisabledInstanceInfo = CLOUD_PLATFORM_INSTANCE_INFO.aws[
        normalizedDbSize
      ]
        ? CLOUD_PLATFORM_INSTANCE_INFO.aws[normalizedDbSize]
        : undefined;

      this.selectedInstanceInfo = this.toBeDisabledInstanceInfo;
    }
  }

  ngOnDestroy(): void {
    if (this.icawsSubscription) {
      this.icawsSubscription.unsubscribe();
    }
  }

  getDbInfo(): void {
    const dbStatus: string = this.siteInfo.sqleApp.status;
    // Incase the db does not have db status
    if (!dbStatus) {
      this.dbState = this.dbStatusStrings.started.dbState;
      this.dbMessage = this.dbStatusStrings.started.dbMessage;
    } else {
      // Incase siteInfo.dbStatus is not in the mapping, setting default to started
      const status: any = this.dbStatusStrings[dbStatus];
      this.dbState = status
        ? status.dbState
        : this.dbStatusStrings.started.dbState;
      this.dbMessage = status
        ? status.dbMessage
        : this.dbStatusStrings.started.dbMessage;
    }
  }

  getInstanceInfo(normalizedDbSize: string): AwsInstanceInfo {
    return this.awsInstanceInfoService.getInstanceInfo(normalizedDbSize);
  }

  onSubmit(formGroup: any): void {
    this.operationInProgress = true;
    const currDbStatus: string = this.siteInfo.sqleApp.status;
    const currOprStatus: string = this.siteInfo.sqleApp.operationalStatus;
    // Immediate update on UI
    this.disableButton = true;
    this.siteInfo.sqleApp.status = 'resizing';
    this.siteInfo.sqleApp.operationalStatus = 'not operational';
    this.getDbInfo();

    // Choose scale up or down then send analytics event
    this.modifyAnalyticsEvent();
    this.analyticsService.sendAnalyticsEvent(
      this.analyticsEvent.awsScaleUpDown
    );

    if (this.useLegacyApis) {
      // call old apis
      this.icawsSubscription = this.icawsService
        .postDatabaseSiteResize(
          this.siteInfo,
          this.selectedInstanceInfo.installation
        )
        .subscribe(
          () => {
            // add any other operations on a success call
            this.operationInProgress = false;
            this.elasticityService.siteDbStatusChanged(this.dbMessage);
            this.elasticityService.refreshSiteInfo();
          },
          () => {
            this.failedOperation = true;
            this.siteInfo.sqleApp.status = currDbStatus;
            this.siteInfo.sqleApp.operationalStatus = currOprStatus;
            this.operationInProgress = false;
          }
        )
        .add(() => {
          this.displayStatus = true;
          this.getDbInfo();
        });
    } else {
      // call new sqle apis
      this.icawsSubscription = this.icawsService
        .postDatabaseSqleAppResize(
          this.siteInfo,
          this.selectedInstanceInfo.installation
        )
        .subscribe(
          () => {
            // add any other operations on a success call
            this.operationInProgress = false;
            this.elasticityService.siteDbStatusChanged(this.dbMessage);
            this.elasticityService.refreshSiteInfo();
          },
          () => {
            this.failedOperation = true;
            this.siteInfo.sqleApp.status = currDbStatus;
            this.siteInfo.sqleApp.operationalStatus = currOprStatus;
            this.operationInProgress = false;
          }
        )
        .add(() => {
          this.displayStatus = true;
          this.getDbInfo();
        });
    }
  }

  private modifyAnalyticsEvent(): void {
    // Check if the operation is scaling up or down
    switch (this.toBeDisabledInstanceInfoName) {
      case 'Small Instance': {
        this.analyticsEvent.awsScaleUpDown.eventKey = 'aws_scale_up';
        break;
      }
      case 'Medium Instance': {
        if (this.selectedInstanceInfo.name.startsWith('Small')) {
          this.analyticsEvent.awsScaleUpDown.eventKey = 'aws_scale_down';
        } else {
          this.analyticsEvent.awsScaleUpDown.eventKey = 'aws_scale_up';
        }
        break;
      }
      case 'Large Instance': {
        this.analyticsEvent.awsScaleUpDown.eventKey = 'aws_scale_down';
        break;
      }
    }
  }
}
