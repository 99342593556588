export enum DbOperation {
  DbStart = 'dbstart',
  DbStop = 'dbstop',
  ExpandStorage = 'expandstorage',
  GetStorageOptions = 'getstorageoptions',
  EnableScaleOutIn = 'enablescaleoutin',
  DbScaleOut = 'dbscaleout',
  DbScaleIn = 'dbscalein',
  DbResize = 'dbvmresize',
  DbProvision = 'provision',
  AddSecurityRule = 'addsecurityrule',
  VNetPeer = 'vnetpeer',
  HsnLaunch = 'hsnlaunch',
  HsnDelete = 'hsndelete',
  HsnUpdate = 'hsnupdate',
  HsnVmStop = 'hsnvmstop',
  HsnVmStart = 'hsnvmstart',
  HsnVmResize = 'hsnvmresize',
}
