import { Injectable } from '@angular/core';

const awsColorCode: string = '#f3753f';
const azureColorCode: string = '#0098c9';
const tmcColorCode: string = '#616d73';
const eChartsAxisLineColorCode: string = '#899296';

const colorCodes: string[] = [
  '#f3753f',
  '#0098c9',
  '#fec64d',
  '#00b2b1',
  '#c3569d',
  '#80ab3e',
  '#cf454a',
  '#486d86',
  '#c37458',
  '#836cb7',
];

@Injectable()
export class ColorPaletteService {
  getAwsColor(): string {
    return awsColorCode;
  }

  getAzureColor(): string {
    return azureColorCode;
  }

  getTMCColor(): string {
    return tmcColorCode;
  }

  getEChartsAxisLineColor(): string {
    return eChartsAxisLineColorCode;
  }

  getColor(index?: number): string {
    if (index === undefined || index < 0) {
      return colorCodes[0];
    }

    if (index < colorCodes.length) {
      return colorCodes[index];
    } else {
      const mod: number = index % colorCodes.length;

      return colorCodes[mod];
    }
  }
}
