import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  LastEvaluatedKey,
  NotifyResponse,
  NotifyUserModel,
} from '../models/notify.model';
@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  notificationUrl: string;
  notificationUserUrl: string;

  constructor(private readonly apollo: Apollo) {}

  /**
   * Gets notifications for the logged in user. Server uses JWT for getting the user specific notifications
   * @param lastSeenNotificationTimestamp Most recent time stamp of the notification seen by the user
   * @param lastEvaluatedKey Presence of this object indicates that there are more notifications to be fetched.
   */

  public getNotifications(
    lastSeenNotificationTimestamp?: string,
    lastEvaluatedKey?: LastEvaluatedKey
  ): Observable<NotifyResponse> {
    const notificationsQuery = gql`
      {
        notificationsList(received_timestamp: $ts)
          @rest(
            type: "NotificationList"
            path: "/notify-service/notifications?{args}"
          ) {
          items @type(name: "Notification") {
            notificationId: notification_id
            channelName: channel_name
            subject
            receivedTimestamp: received_timestamp
            criticality
            message
            unread
          }
          lastEvaluatedKey: last_evaluated_key @type(name: "LastEvaluatedKey") {
            notificationId: notification_id
            userId: user_id
            receivedTimestamp: received_timestamp
          }
        }
      }
    `;

    const noCache: string = 'no-cache';
    const queryInput: any = {
      query: notificationsQuery,
      variables: { ts: lastSeenNotificationTimestamp },
      context: {
        headers: {},
      },
      fetchPolicy: noCache,
    };

    if (lastEvaluatedKey !== undefined && lastEvaluatedKey !== null) {
      queryInput.context.headers['X-Page-Last-Evaluated-Key'] = JSON.stringify({
        notification_id: lastEvaluatedKey.notificationId,
        user_id: lastEvaluatedKey.userId,
        received_timestamp: lastEvaluatedKey.receivedTimestamp,
      });
    }

    return this.apollo.query<any>(queryInput).pipe(
      map((responseBody) => {
        if (responseBody.errors) {
          throw new Error(JSON.stringify(responseBody.errors));
        }

        return (
          responseBody &&
          responseBody.data &&
          responseBody.data.notificationsList
        );
      })
    );
  }

  /**
   * Gets user details related to notification. Server uses JWT for getting the user specific notifications
   */
  public getUserInfoForNotifications(): Observable<NotifyUserModel> {
    const notificationsQuery = gql`
      query notifyUserInfo {
        notificationLastSeenTimestamp
          @rest(
            type: "NotifyUserModel"
            path: "/notify-service/notifications/users"
          ) {
          lastSeenNotificationTimestamp: last_seen_notification_timestamp
        }
      }
    `;

    const noCache: string = 'no-cache';
    const queryInput: any = {
      query: notificationsQuery,
      context: {
        headers: {},
      },
      fetchPolicy: noCache,
    };

    return this.apollo.query<any>(queryInput).pipe(
      map((responseBody) => {
        if (responseBody.errors) {
          throw new Error(JSON.stringify(responseBody.errors));
        }

        return responseBody.data.notificationLastSeenTimestamp;
      })
    );
  }

  /**
   * Posts user details related to notification. Server uses JWT for posting the user specific notifications
   * @notifyUserModel user details related to notification
   */
  public postUserInfoForNotifications(
    notifyUserModel: NotifyUserModel
  ): Observable<void> {
    // Note that the type for $timestamp is any, because we have not got a graphql schema, and we don't have a way
    // to strongly type our graphql variables and make best use of the graphql compiler. The timestamp variable below
    // is being sent as the JSON which will in turn be passed into the "input" variable above. Apollo by default
    // recognizes the "input" variable as the HTTP body that needs to be sent with a POST or a PUT.
    const mutation = gql`
      mutation postUserNotificationTimestamp($timestamp: any!) {
        notificationTimestamp(input: $timestamp)
          @rest(
            type: "Boolean"
            path: "/notify-service/notifications/users"
            method: "POST"
          ) {
          # GraphQL comment: UnusedResponse is not a keyword. It is just a name provided in the result to tell the
          # the developer that the response is unused. GraphQL mutations expect values that are returned just like
          # queries. The type of the response is specified as Boolean as opposed to Boolean! (mandatory) in the rest
          # directive above. By making the result optional, graphql/apollo allows the return value to be an empty
          # object - this REST API returns an empty 200 OK response.
          UnusedResponse
        }
      }
    `;

    const variables = {
      timestamp: {
        last_seen_notification_timestamp:
          notifyUserModel.lastSeenNotificationTimestamp,
      },
    };

    return this.apollo.mutate({ mutation, variables }).pipe(
      map((responseBody) => {
        if (responseBody.errorCode) {
          throw new Error(responseBody.message);
        }
      })
    );
  }
}
