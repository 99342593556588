import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TdMediaService } from '@covalent/core';
import { Customer } from '@imc-features/ecosystems/_modules/ecosystems-shared/models/customer.model';
import { SiteService } from '@imc-features/ecosystems/_modules/ecosystems-shared/services/site-service/ecosystems-site.service';
import { Site } from '@imc/core/models/sites.model';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'imc-list-sites',
  templateUrl: './list-sites.component.html',
  styleUrls: ['./list-sites.component.scss'],
})
export class ListSitesComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'displayName',
    'customerName',
    'cloudPlatform',
    'purpose',
    // 'operationalStatus',
    'isVCM',
  ];
  sites$: Observable<Site[]>;
  isLoading: boolean = true;
  isError: boolean = false;
  sitesSubscription: Subscription;
  dataSource: MatTableDataSource<Site>;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly siteService: SiteService,
    public media: TdMediaService
  ) {}

  ngOnInit(): void {
    this.sites$ = this.activatedRoute.paramMap.pipe(
      switchMap((param: ParamMap) => {
        const customerId: string = param.get('customerId');
        if (customerId) {
          return this.siteService.listSites({
            customerId,
          });
        } else {
          const customers$: Observable<
            Map<string, Customer>
          > = this.siteService.listCustomers();
          const sites$: Observable<any[]> = this.siteService.listSites();

          return forkJoin(customers$, sites$).pipe(
            map((customersSites: any[]) => {
              const customersMap: Map<string, Customer> = customersSites[0];
              const sites: Site[] = customersSites[1];

              return sites
                .filter((site: Site) => customersMap.has(site.customerId))
                .map((site: Site) => ({
                  ...site,
                  customerName: customersMap.get(site.customerId).name,
                }))
                .sort((a: Site, b: Site) =>
                  a.customerName > b.customerName ? 1 : -1
                );
            })
          );
        }
      })
    );

    this.sitesSubscription = this.sites$.subscribe(
      (sites: Site[]) => {
        this.isLoading = false;
        this.dataSource = new MatTableDataSource(sites);
        this.dataSource.sort = this.sort;
      },
      (error: any) => {
        this.isError = true;
        this.isLoading = false;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.sitesSubscription) {
      this.sitesSubscription.unsubscribe();
    }
  }

  viewSiteDetails(site: Site): void {
    // tslint:disable-next-line: no-floating-promises
    this.router.navigate([`sites/${site.siteId}`]);
  }

  viewCustomerDetails(site: Site): void {
    // tslint:disable-next-line: no-floating-promises
    this.router.navigate([`customer-dashboard/${site.customerId}`]);
  }

  applyFilter(filterValue: string): void {
    if (filterValue.trim().length === 0) {
      return;
    }
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  removeFilters(): void {
    this.dataSource.filter = '';
  }
}
