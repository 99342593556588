export enum availableOps {
  START,
  STOP,
  SCALE_UP_DOWN,
  NODE_SCALING,
  HSN_LAUNCH,
  HSN_UPDATE,
  HSN_DELETE,
}

export const elasticityOpsSysops: any = [
  { label: 'Start', value: availableOps.START, feature: 'HIB_FEATURE_NAME' },
  { label: 'Stop', value: availableOps.STOP, feature: 'HIB_FEATURE_NAME' },
  {
    label: 'Scale Up/Down',
    value: availableOps.SCALE_UP_DOWN,
    feature: 'SCA_FEATURE_NAME',
  },
  {
    label: 'Node Operations',
    value: availableOps.NODE_SCALING,
    feature: 'SCALE_OUT_IN_FEATURE_NAME',
  },
  {
    label: 'Hsn Launch',
    value: availableOps.HSN_LAUNCH,
    feature: 'HSN_FEATURE_NAME',
  },
  {
    label: 'Hsn Update',
    value: availableOps.HSN_UPDATE,
    feature: 'HSN_FEATURE_NAME',
  },
  {
    label: 'Hsn Delete',
    value: availableOps.HSN_DELETE,
    feature: 'HSN_FEATURE_NAME',
  },
];

export const elasticityOpsCustomer: any = [
  { label: 'Start', value: availableOps.START, feature: 'HIB_FEATURE_NAME' },
  { label: 'Stop', value: availableOps.STOP, feature: 'HIB_FEATURE_NAME' },
  {
    label: 'Scale Up/Down',
    value: availableOps.SCALE_UP_DOWN,
    feature: 'SCA_FEATURE_NAME',
  },
  {
    label: 'Node Operations',
    value: availableOps.NODE_SCALING,
    feature: 'SCALE_OUT_IN_FEATURE_NAME',
  },
];
