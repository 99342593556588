import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImcMaterialModule } from '@imc/core';

import { AvailabilitiesModule } from './_modules/availabilities/availabilities.module';
import { ReportsDashboardComponent } from './pages/reports-dashboard/reports-dashboard.component';
import { ReportsRoutingModule } from './reports-routing.module';

@NgModule({
  declarations: [ReportsDashboardComponent],
  imports: [
    AvailabilitiesModule,
    CommonModule,
    ReportsRoutingModule,
    ImcMaterialModule,
  ],
  providers: [],
})
export class ReportsModule {}
