import { Component, Input } from '@angular/core';

@Component({
  selector: 'imc-aws-product-feature',
  templateUrl: './productFeature.component.html',
  styleUrls: ['./productFeature.component.scss'],
})
export class ProductFeatureComponent {
  @Input() feature: any;

  private shouldDisplayAttributeValue(attributeValue): boolean {
    return !(
      attributeValue.toLowerCase() === 'included' ||
      attributeValue.toLowerCase() === 'upgrade'
    );
  }

  private shouldDisplayAttribute(attribute): boolean {
    return attribute.value.toLowerCase() !== 'not offered';
  }

  private shouldDisplayFeaturePropertyValue(value: any): boolean {
    return !(value === '0' || value.indexOf('Disabled') >= 0);
  }
}
