// tslint:disable:max-line-length imports
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AZURE_ICONS } from '../../models/azure-provisioning';
import { ControlConfiguration } from '../../models/control-configuration.model';
import { InstanceInfo } from '../../models/instanceInfo.model';
import {
  AvailableDBConfigs,
  VMConfig,
} from '../../models/product-offering.model';
// tslint:enable:max-line-length end imports

@Component({
  selector: 'imc-azure-instances',
  templateUrl: './azure-instances.component.html',
  styleUrls: ['./azure-instances.component.scss'],
})
export class AzureInstancesComponent {
  @Input() control: ControlConfiguration;
  @Input() formGroup: FormGroup;
  // This optional attribute is to disable a particular dbSize from the list.
  // This will be the current dbSize for change size use case.
  @Input() toBeDisabledDbSize?: string;
  @Input() availableDbConfigs: AvailableDBConfigs[];
  @Input() dbSize?: ControlConfiguration;
  public customArray: any = [];
  public dbConfigArray: any = [];

  public instanceInfo(size: string): string[] {
    const instanceInfo: InstanceInfo[] = [];
    let _instanceInfo: InstanceInfo;
    this.customArray = [];
    this.dbConfigArray = [];

    this.availableDbConfigs.forEach((instances: AvailableDBConfigs) => {
      if (size in instances) {
        instances[size].forEach((dbInstance: VMConfig) => {
          _instanceInfo = {
            name: dbInstance.name,
            svgIcon: AZURE_ICONS[size].svgIcon,
            svgSelectedIcon: AZURE_ICONS[size].svgSelectedIcon,
            installation: dbInstance.sku,
            cpu: dbInstance.cpus,
            memory: dbInstance.memory,
            tcore: dbInstance.tcores,
            io: dbInstance.io,
            sku: dbInstance.sku,
          } as InstanceInfo;
          instanceInfo.push(_instanceInfo);
          this.customArray.push(_instanceInfo.name);
        });
      }
    });
    this.dbConfigArray = instanceInfo;

    return this.customArray;
  }

  public sortedValues(): string[] {
    const sortOrder: string[] = ['Small', 'Medium', 'Large'];

    return this.dbSize.valuesList.sort((size1: any, size2: any) => {
      const comparison: any =
        sortOrder.indexOf(size1) > sortOrder.indexOf(size2) ? 1 : -1;

      return comparison;
    });
  }
  // This method will return the instance configuration details
  // based on the instance name that is sent as input.
  public getObject(name: string): VMConfig {
    const instanceDetails: VMConfig = this.dbConfigArray.find(
      (config: any) => config.name === name
    );

    return instanceDetails;
  }
}
