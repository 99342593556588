import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { Observable, of } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';

import { Customer } from '@imc-features/ecosystems/_modules/ecosystems-shared/models/customer.model';
import { SiteService } from '@imc-features/ecosystems/_modules/ecosystems-shared/services/site-service/ecosystems-site.service';
import { LegacyCorrelationObjectService } from '@imc/shared/utilities/legacy-correlation-object-service';

import { AzureSubscription } from '../models/azure-subscription.model';

@Injectable()
export class AzureSubscriptionService {
  constructor(
    private readonly apollo: Apollo,
    private readonly siteService: SiteService,
    private readonly correlationObjectService: LegacyCorrelationObjectService
  ) {}

  getRawSubscriptions(): Observable<any[]> {
    const queryOptions: any = this.getRawSubscriptionsQueryOptions();

    return this.apollo.query(queryOptions).pipe(
      switchMap((responseBody: any) => {
        if (responseBody.errors) {
          throw new Error('Unable to fetch subscriptions');
        }

        return of(responseBody.data && responseBody.data.subscriptions);
      })
    );
  }

  getSubscriptionById(subscriptionId: string): Observable<AzureSubscription> {
    return this.getRawSubscriptions().pipe(
      switchMap((rawSubscriptions: any[]) => {
        const match: any[] = rawSubscriptions.filter(
          (s: any) => s.id === subscriptionId
        );

        return this.getSubscriptionWithCustomer(match[0]);
      }),
      first()
    );
  }

  watchSubscriptions(): Observable<ApolloQueryResult<any>> {
    const queryOptions: any = this.getRawSubscriptionsQueryOptions();

    return this.apollo.watchQuery(queryOptions).valueChanges;
  }

  createSubscription(
    subscriptionId: string,
    name: string,
    owner: string,
    customerId: string,
    customerName: string,
    comments: string
  ): Observable<boolean> {
    const mutation: any = gql`
      mutation postAzureSubscription($data: Any!) {
        subscription(input: $data)
          @rest(
            type: "AzureSubscription"
            method: "POST"
            path: "/management-console/subscriptions"
          ) {
          id: subscriptionId
          name
          customerId
          customerName
          owner
          status: isActivated
          comments
        }
      }
    `;

    const variables: any = {
      data: {
        subscriptionId,
        name,
        owner,
        customerId,
        customerName,
        comments,
        isActivated: true,
      },
    };
    const context: any = { headers: {} };
    context.headers[
      'correlation-object'
    ] = this.correlationObjectService.createDeprecatedCorrelationObject();
    const refetchQueries: any[] = [
      { ...this.getRawSubscriptionsQueryOptions() },
    ];

    return this.apollo
      .mutate({ mutation, context, variables, refetchQueries })
      .pipe(
        switchMap((responseBody: any) => {
          if (!responseBody || responseBody.errors) {
            return of(false);
          }

          return of(!!responseBody.data.subscription);
        }),
        catchError((error: any, caught: Observable<any>) => of(false)),
        first()
      );
  }

  editSubscription(
    subscriptionId: string,
    name: string,
    owner: string,
    customerId: string,
    customerName: string,
    comments: string
  ): Observable<boolean> {
    const mutation: any = gql`
      mutation putAzureSubscription($subscriptionId: String!, $data: Any!) {
        subscription(id: $subscriptionId, input: $data)
          @rest(
            type: "AzureSubscription"
            method: "PUT"
            path: "/management-console/subscriptions/{args.id}"
          ) {
          id: subscriptionId
          name
          customerId
          customerName
          owner
          status: isActivated
          comments
        }
      }
    `;

    const variables: any = {
      subscriptionId,
      data: {
        name,
        owner,
        customerId,
        customerName,
        comments,
      },
    };
    const context: any = { headers: {} };
    context.headers[
      'correlation-object'
    ] = this.correlationObjectService.createDeprecatedCorrelationObject();

    return this.apollo.mutate({ mutation, context, variables }).pipe(
      switchMap((responseBody: any) => {
        if (!responseBody || responseBody.errors) {
          of(false);
        }

        return of(!!responseBody.data.subscription);
      }),
      catchError((error: any, caught: Observable<any>) => of(false)),
      first()
    );
  }

  deactivateSubscription(subscriptionId: string): Observable<boolean> {
    const mutation: any = gql`
      mutation putAzureSubscription($subscriptionId: String!, $data: Any!) {
        subscription(id: $subscriptionId, input: $data)
          @rest(
            type: "AzureSubscription"
            method: "PUT"
            path: "/management-console/subscriptions/{args.id}"
          ) {
          id: subscriptionId
          name
          customerId
          customerName
          owner
          status: isActivated
          comments
        }
      }
    `;

    const variables: any = { subscriptionId, data: { isActivated: false } };
    const context: any = { headers: {} };
    context.headers[
      'correlation-object'
    ] = this.correlationObjectService.createDeprecatedCorrelationObject();

    return this.apollo.mutate({ mutation, context, variables }).pipe(
      switchMap((responseBody: any) => {
        if (!responseBody || responseBody.errors) {
          return of(false);
        }

        return of(!!responseBody.data.subscription);
      }),
      catchError((error: any, caught: Observable<any>) => of(false)),
      first()
    );
  }

  private getSubscriptionWithCustomer(
    rawSubscription: any
  ): Observable<AzureSubscription> {
    if (!rawSubscription) {
      throw new Error('invalid');
    }

    if (!rawSubscription.customerId) {
      return of({
        id: rawSubscription.id,
        name: rawSubscription.name,
        comments: rawSubscription.comments,
        owner: rawSubscription.owner,
        status: rawSubscription.status,
        customerId: rawSubscription.customerId,
        customerName: `Unknown Customer ${
          rawSubscription.customerName
        } with null customerId`,
      });
    }

    return this.siteService.getCustomerById(rawSubscription.customerId).pipe(
      switchMap((customer: Customer) =>
        of({
          id: rawSubscription.id,
          name: rawSubscription.name,
          comments: rawSubscription.comments,
          owner: rawSubscription.owner,
          status: rawSubscription.status,
          customerId: rawSubscription.customerId,
          customerName: customer
            ? customer.name
            : `Unknown Customer ${rawSubscription.customerName} with id ${
                rawSubscription.customerId
              }`,
        })
      )
    );
  }

  private getRawSubscriptionsQueryOptions(): any {
    const query: any = gql`
      query subscriptions {
        subscriptions
          @rest(
            type: "AzureSubscription"
            path: "/management-console/subscriptions"
          ) {
          id: subscriptionId
          name
          customerId
          customerName
          owner
          status: isActivated
          comments
        }
      }
    `;

    const context: any = { headers: {} };
    context.headers[
      'correlation-object'
    ] = this.correlationObjectService.createDeprecatedCorrelationObject();

    return { query, context };
  }
}
