import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TdMediaService } from '@covalent/core';

import { CoreModule, ImcMaterialModule } from '@imc/core';
import { SharedModule } from '@imc/shared/shared.module';

import { EcosystemsSharedModule } from '../ecosystems-shared/ecosystems-shared.module';
import { AzureCustomerTicketsComponent } from './cards/azure-customer-tickets/azure-customer-tickets.component';
import { AzureCustomersComponent } from './cards/azure-customers/azure-customers.component';
import { AzureDevopsReportsComponent } from './cards/azure-devops-reports/azure-devops-reports.component';
import { AzureInboundRulesComponent } from './cards/azure-inbound-rules/azure-inbound-rules.component';
import { AzureInstanceInfoComponent } from './cards/azure-instance-info/azure-instance-info.component';
import { AzureOutboundRulesComponent } from './cards/azure-outbound-rules/azure-outbound-rules.component';
import { AzureSitesComponent } from './cards/azure-sites/azure-sites.component';
import { AzureSubscriptionsComponent } from './cards/azure-subscriptions/azure-subscriptions.component';
import { AzureWorkOrdersComponent } from './cards/azure-work-orders/azure-work-orders.component';
import { CreateAzureSubscriptionComponent } from './components/create-azure-subscription/create-azure-subscription.component';
import { EditAzureSubscriptionComponent } from './components/edit-azure-subscription/edit-azure-subscription.component';
import { HsnDeleteComponent } from './dialogs/hsn-delete/hsn-delete.component';
import { HsnLaunchComponent } from './dialogs/hsn-launch/hsn-launch.component';
import { HsnUpdateComponent } from './dialogs/hsn-update/hsn-update.component';
import { ScaleInOutDatabaseComponent } from './dialogs/scale-in-out-database/scale-in-out-database.component';
import { ScaleUpDownDatabaseComponent } from './dialogs/scale-up-down-database/scale-up-down-database.component';
import { StartDatabaseComponent } from './dialogs/start-database/start-database.component';
import { StopDatabaseComponent } from './dialogs/stop-database/stop-database.component';
import { AzureSiteElasticityComponent } from './layouts/azure-site-elasticity/azure-site-elasticity.component';
import { AzureSiteOverviewComponent } from './layouts/azure-site-overview/azure-site-overview.component';
import { AzureDashboardComponent } from './pages/azure-dashboard/azure-dashboard.component';
import { AzureInstanceInfoService } from './services/azure-instance-info-service/azure-instance-info.service';
import { AzureSecurityRulesService } from './services/azure-security-rules/azure-security-rules.service';
import { AzureSubscriptionService } from './services/azure-subscriptions.service';
import { AzureWorkOrdersService } from './services/azure-work-orders.service';
import { AzureService } from './services/azure.service';

@NgModule({
  declarations: [
    AzureDashboardComponent,
    AzureSitesComponent,
    AzureWorkOrdersComponent,
    AzureSubscriptionsComponent,
    AzureDevopsReportsComponent,
    AzureCustomerTicketsComponent,
    AzureCustomersComponent,
    AzureInstanceInfoComponent,
    AzureSiteOverviewComponent,
    AzureSiteElasticityComponent,
    StartDatabaseComponent,
    StopDatabaseComponent,
    ScaleUpDownDatabaseComponent,
    ScaleInOutDatabaseComponent,
    HsnLaunchComponent,
    HsnUpdateComponent,
    HsnDeleteComponent,
    EditAzureSubscriptionComponent,
    CreateAzureSubscriptionComponent,
    AzureInboundRulesComponent,
    AzureOutboundRulesComponent,
  ],
  entryComponents: [AzureSiteOverviewComponent, AzureSiteElasticityComponent],
  imports: [
    CommonModule,
    ImcMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    EcosystemsSharedModule,
    CoreModule,
    RouterModule,
  ],
  providers: [
    TdMediaService,
    AzureSubscriptionService,
    AzureInstanceInfoService,
    AzureService,
    AzureWorkOrdersService,
    AzureSecurityRulesService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AzureModule {}
