import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LegacyCorrelationObjectService } from '@imc/shared/utilities/legacy-correlation-object-service';

import { WorkOrderCreationRequest } from '../models/work-order-create.model';
import { WorkOrderDetails } from '../models/work-order-details.model';

@Injectable()
export class WorkOrderService {
  constructor(
    private readonly apollo: Apollo,
    private readonly correlationObjectService: LegacyCorrelationObjectService
  ) {}

  postWorkOrder(
    workOrder: WorkOrderCreationRequest
  ): Observable<WorkOrderDetails> {
    const mutation: any = gql`
      mutation postWorkOrder($workOrder: Any!) {
        workOrderDetails(input: $workOrder)
          @rest(
            type: "AzureWorkOrderDetails"
            method: "POST"
            path: "/management-console/workorders"
          ) {
          workOrderId
          customerId
          contractId
          status
          workOrderType
          dbSize
          dbNodeCount
          siteId
          systemName
          networkCidr
          securityAccessCidr
          cloudPlatform
          area
          skus
          tasks
          sfTasks
          isFailed
        }
      }
    `;

    const context: any = { headers: {} };
    context.headers[
      'correlation-object'
    ] = this.correlationObjectService.createDeprecatedCorrelationObject();

    return this.apollo
      .mutate({ mutation, context, variables: { workOrder } })
      .pipe(
        map((responseBody: any) => {
          if (!responseBody || responseBody.errors) {
            throw new Error('The work order could not be created.');
          }

          return responseBody.data.workOrderDetails as WorkOrderDetails;
        })
      );
  }
}
