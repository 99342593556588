import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LegacyCorrelationObjectService } from '@imc/shared/utilities/legacy-correlation-object-service';

import {
  EcosystemOption,
  FeatureAttribute,
  InstanceCount,
  InstanceType,
  OfferStatus,
  ProductOffer,
} from '../models/product-offering.model';

@Injectable()
export class ProductOffersService {
  constructor(
    private readonly apollo: Apollo,
    private readonly correlationObjectService: LegacyCorrelationObjectService
  ) {}

  public getProductOffers(): Observable<ProductOffer[]> {
    const query: any = gql`
      query queryProductOffers {
        productOffers
          @rest(
            type: "ProductOffers"
            path: "/management-console/product-offers"
          ) {
          productOfferId
          productOffer
          platform
          offerDetails
          status
        }
      }
    `;

    const context: any = { headers: {} };
    context.headers[
      'correlation-object'
    ] = this.correlationObjectService.createDeprecatedCorrelationObject();

    return this.apollo.query({ query, context }).pipe(
      map((responseBody: any) => {
        if (!responseBody || responseBody.errors) {
          throw new Error('Unable to get product offers');
        }

        const rawProductOffers: ProductOffer[] = responseBody.data.productOffers.filter(
          (productOffer: ProductOffer) =>
            productOffer.status === OfferStatus.Active
        );

        return rawProductOffers;
      })
    );
  }

  public filterOffersBySelectedFields(
    productOffers: ProductOffer[],
    selectedFields: [string, string | number][]
  ): ProductOffer[] {
    if (!productOffers) {
      return [];
    }
    const filteredProducts: ProductOffer[] = productOffers.filter(
      (productOffer: ProductOffer) => {
        for (const selectedField of selectedFields) {
          if (Array.isArray(productOffer[selectedField[0]])) {
            return true;
          }
          if (
            !Array.isArray(productOffer[selectedField[0]]) &&
            productOffer[selectedField[0]] !== selectedField[1]
          ) {
            return false;
          }
        }

        return true;
      }
    );

    return filteredProducts;
  }

  public getFieldOptions(
    productOffers: ProductOffer[],
    field: string
  ): string[] {
    const options: Set<string> = new Set<string>();
    for (const productOffer of productOffers) {
      if (Array.isArray(productOffer[field])) {
        productOffer[field].forEach((arrayElement) => {
          options.add(productOffer[field][arrayElement]);
        });
      } else {
        options.add(productOffer[field]);
      }
    }

    const retval: string[] = [];
    options.forEach((option: string) => retval.push(option));

    return retval;
  }

  public AddEcosystemBaseAndUpgradeOptions(
    features: FeatureAttribute[],
    productEcosystemOptions: EcosystemOption[]
  ) {
    if (!features || !productEcosystemOptions) {
      return;
    }

    features.forEach((feature) => {
      let featureFound = false; // This is set if the feature is already found in the productEcosystemOptions list
      let ecosystemOption: EcosystemOption = null;
      if (
        feature.featureProperties !== null &&
        feature.featureProperties !== undefined
      ) {
        const featureEcosystemOption = productEcosystemOptions.find(
          (option) => option.name === feature.featureName
        );
        if (featureEcosystemOption) {
          ecosystemOption = featureEcosystemOption;
          featureFound = true;
        } else {
          ecosystemOption = {
            name: feature.featureName,
            version: null,
            instanceTypes: null,
            instanceCount: null,
            isEnabled: true,
          };
        }

        if (feature.version && !ecosystemOption.version) {
          ecosystemOption.version = feature.version;
        }
        const instanceTypes = feature.featureProperties.find(
          (featureProperty) =>
            featureProperty.featurePropertyName.toLowerCase() ===
            'instance type'
        );
        if (instanceTypes !== null || instanceTypes !== undefined) {
          if (!ecosystemOption.instanceTypes) {
            ecosystemOption.instanceTypes = [];
          }
          instanceTypes.featurePropertyValues.forEach(
            (featurePropertyValue) => {
              const instanceType: InstanceType = {
                displayValue: featurePropertyValue.displayName,
                value: featurePropertyValue.value,
                offeringType: feature.value.toLowerCase(),
              };
              ecosystemOption.instanceTypes.push(instanceType);
            }
          );
        }

        const instances = feature.featureProperties.find(
          (featureProperty) =>
            featureProperty.featurePropertyName.toLowerCase() ===
            'instance count'
        );
        if (instances !== null || instances !== undefined) {
          if (!ecosystemOption.instanceCount) {
            ecosystemOption.instanceCount = [];
          }
          instances.featurePropertyValues.forEach((featurePropertyValue) => {
            const instanceCount: InstanceCount = {
              displayValue: featurePropertyValue.displayName,
              value: Number(featurePropertyValue.value),
              offeringType: feature.value.toLowerCase(),
            };
            ecosystemOption.instanceCount.push(instanceCount);
          });
        }
        if (!featureFound) {
          productEcosystemOptions.push(ecosystemOption);
        }
      }
    });
  }
}
