import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigService } from '@imc/core/services';
import { TranslateService } from '@ngx-translate/core';
import { Job } from '../models/backups.model';

@Injectable()
export class BackupService {
  baseUrl: string = `${this.appConfig.getConfig().endpoints.baas}/jobs`;

  constructor(
    private readonly apollo: Apollo,
    private readonly http: HttpClient,
    private readonly appConfig: AppConfigService,
    private readonly translate: TranslateService
  ) {}

  // used to make calls for users with customer role
  getBackupsJobsForCustomerUser(siteId: string): Observable<Job[]> {
    const requestUrl: string = `${
      this.baseUrl
    }?siteId=${siteId}&jobType=BACKUP,REPLICATION,RESTORE`;
    const options: any = { headers: this.createHeaders() };

    return this.http.get(requestUrl, options).pipe(
      map((responseBody: any) => {
        if (!responseBody || responseBody.errorCode) {
          throw new Error(this.translate.instant('job_list_error'));
        }

        return responseBody;
      })
    );
  }

  // used to make calls for users with operator role
  getBackupJobsByCustomer(
    customerId: string,
    siteId: string
  ): Observable<Job[]> {
    const requestUrl: string = `${
      this.baseUrl
    }?customerId=${customerId}&siteId=${siteId}&jobType=BACKUP,REPLICATION,RESTORE`;
    const options: any = { headers: this.createHeaders() };

    return this.http.get(requestUrl, options).pipe(
      map((responseBody: any) => {
        if (!responseBody || responseBody.errorCode) {
          throw new Error(this.translate.instant('job_list_error'));
        }

        return responseBody;
      })
    );
  }

  getJobRunHistoryAndDetails(jobId): Observable<any> {
    const requestUrl: string = `${
      this.baseUrl
    }/${jobId}?include=History&historyScope=retentions`;
    const options: any = { headers: this.createHeaders() };

    return this.http.get(requestUrl, options).pipe(
      map((responseBody: any) => {
        if (!responseBody || responseBody.errorCode) {
          throw new Error(this.translate.instant('job_history_error'));
        }

        return responseBody;
      })
    );
  }

  updateJob(requestBody): Observable<any> {
    const options: any = { headers: this.createHeaders() };

    return this.http.patch(this.baseUrl, requestBody, options).pipe(
      map((responseBody: any) => {
        if (!responseBody || responseBody.errorCode) {
          throw new Error(this.translate.instant('job_update_error'));
        }

        return responseBody;
      })
    );
  }

  updateJobDefinition(jobId, requestBody): Observable<any> {
    const requestUrl: string = `${this.baseUrl}/${jobId}/jobDefinition`;
    const options: any = { headers: this.createHeaders() };

    return this.http.patch(requestUrl, requestBody, options).pipe(
      map((responseBody: any) => {
        if (!responseBody || responseBody.errorCode) {
          throw new Error(this.translate.instant('job_update_error'));
        }

        return responseBody;
      })
    );
  }

  updateJobDefinitionForCustomer(
    jobId,
    customerId,
    requestBody
  ): Observable<any> {
    const requestUrl: string = `${
      this.baseUrl
    }/${jobId}/jobDefinition?customerId=${customerId}`;
    const options: any = { headers: this.createHeaders() };

    return this.http
      .patch(requestUrl, requestBody, options)
      .pipe(map((responseBody: any) => responseBody));
  }

  abortJob(jobId): Observable<any> {
    const mutation = gql`
      mutation postAbort($emptyBody: String!, $jobId: Int!) {
        abort(input: $emptyBody, jobId: $jobId)
          @rest(
            method: "POST"
            type: "Boolean"
            endpoint: "baas"
            path: "/jobs/{args.jobId}/abort"
          ) {
          ignore
        }
      }
    `;

    const variables: any = { emptyBody: {}, jobId };
    return this.apollo.mutate({ mutation, variables }).pipe(
      map((responseBody: any) => {
        if (!responseBody || responseBody.errorCode) {
          throw new Error(this.translate.instant('job_abort_error'));
        }

        return responseBody;
      })
    );
  }

  abortJobForCustomer(customerId, jobId): Observable<any> {
    const mutation = gql`
      mutation postAbort(
        $emptyBody: String!
        $jobId: Int!
        $customerId: String!
      ) {
        abort(input: $emptyBody, jobId: $jobId, customerId: $customerId)
          @rest(
            method: "POST"
            type: "Boolean"
            endpoint: "baas"
            path: "/jobs/{args.jobId}/abort?customerId={args.customerId}"
          ) {
          ignore
        }
      }
    `;

    const variables: any = { emptyBody: {}, jobId, customerId };
    return this.apollo.mutate({ mutation, variables }).pipe(
      map((responseBody: any) => {
        if (!responseBody || responseBody.errorCode) {
          throw new Error(this.translate.instant('job_abort_error'));
        }

        return responseBody;
      })
    );
  }

  getJobRunHistoryAndDetailsForCustomer(
    customerId: string,
    jobId
  ): Observable<any> {
    const requestUrl: string = `${
      this.baseUrl
    }/${jobId}?customerId=${customerId}&include=History&historyScope=retentions`;
    const options: any = { headers: this.createHeaders() };

    return this.http.get(requestUrl, options).pipe(
      map((responseBody: any) => {
        if (!responseBody || responseBody.errorCode) {
          throw new Error(this.translate.instant('job_history_error'));
        }

        return responseBody;
      })
    );
  }

  getJobEventHistory(jobId): Observable<any> {
    const requestUrl: string = `${this.baseUrl}/${jobId}/eventHistory`;
    const options: any = { headers: this.createHeaders() };

    return this.http.get(requestUrl, options).pipe(
      map((responseBody: any) => {
        if (!responseBody || responseBody.errorCode) {
          throw new Error(this.translate.instant('job_history_error'));
        }

        return responseBody;
      })
    );
  }

  getJobEventHistoryForCustomer(jobId, customerId): Observable<any> {
    const requestUrl: string = `${
      this.baseUrl
    }/${jobId}/eventHistory?customerId=${customerId}`;
    const options: any = { headers: this.createHeaders() };

    return this.http.get(requestUrl, options).pipe(
      map((responseBody: any) => {
        if (!responseBody || responseBody.errorCode) {
          throw new Error(this.translate.instant('job_history_error'));
        }

        return responseBody;
      })
    );
  }

  updateJobForCustomer(customerId: string, requestBody): Observable<any> {
    const requestUrl: string = `${this.baseUrl}?customerId=${customerId}`;
    const options: any = { headers: this.createHeaders() };

    return this.http.patch(requestUrl, requestBody, options).pipe(
      map((responseBody: any) => {
        if (!responseBody || responseBody.errorCode) {
          throw new Error(this.translate.instant('job_update_error'));
        }

        return responseBody;
      })
    );
  }

  updateBackupSetRetention(job, backupSetId, isRetained): Observable<any> {
    const requestUrl = `${this.baseUrl}/${job.jobId}/backupSets/${backupSetId}`;
    const requestBody = { isRetained };
    const options: any = {
      headers: this.createHeaders(),
      params: { customerId: job.customerId },
    };

    return this.http.patch(requestUrl, requestBody, options).pipe(
      map((responseBody: any) => {
        if (responseBody && responseBody.errorCode) {
          throw new Error(responseBody.message);
        }

        return responseBody;
      })
    );
  }

  private createHeaders(): HttpHeaders {
    return new HttpHeaders().set(
      'authorization',
      window.localStorage.getItem('access_token')
    );
  }
}
