import { Injectable } from '@angular/core';
import { trackedEvents } from './analyticsEventTracking.map';

declare let ga: Function;

@Injectable()
export class GoogleAnalyticsService {
  sendAnalyticsEvent(analyticsEvent) {
    if (analyticsEvent && analyticsEvent.eventKey) {
      const gaEventObject = Object.assign(
        {},
        trackedEvents[analyticsEvent.eventKey].eventFields
      );

      gaEventObject.hitType = 'event';

      ga(
        'set',
        trackedEvents[analyticsEvent.eventKey].metric,
        trackedEvents[analyticsEvent.eventKey].eventValue
      );
      ga('send', gaEventObject);
    }
  }
}
