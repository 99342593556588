import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { feature } from '../../../features/feature';

@Injectable()
export class FeatureFlagGuard implements CanActivate {
  constructor(private readonly router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const flag = next.data.flag;

    if (flag && feature[flag]) {
      return true;
    } else {
      this.router.navigate(['']); // to be replaced with 404
      return false;
    }
  }
}
