export const convertToCSV: any = (objArray: any[]): string => {
  const array: any[] =
    typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str: string = '';

  array.forEach((value: object, key: number) => {
    let line: string = '';
    for (const index in array[key]) {
      if (line !== '') {
        line += ',';
      }
      if (array[key][index] === null || array[key][index] === undefined) {
        array[key][index] = '';
      }
      line += array[key][index];
    }
    str += `${line}\r\n`;
  });

  if (array[0]) {
    const headers: string = Object.keys(array[0]).join(',');
    str = `${headers}\r\n${str}`;
  }

  return str;
};

export const downloadCSV: any = (fileName: string, data: string): void => {
  const parsedResponse: string = data;
  const blob: Blob = new Blob([parsedResponse], { type: 'text/csv' });
  const url: string = window.URL.createObjectURL(blob);

  const a: HTMLAnchorElement = document.createElement('a');
  a.setAttribute('style', 'display: none');
  a.setAttribute('download', fileName);
  a.href = url;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};
