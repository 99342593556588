// tslint:disable:max-line-length Import paths exceed 120
import { CommonModule, registerLocaleData } from '@angular/common';
import locale_en from '@angular/common/locales/en';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthConfig, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';

import { NotifyModule } from './_modules/notify/notify.module';
import { authConfigFactory } from './configs/auth-config';
import { AnalyticsEventTrackingDirective } from './directives/analyticsEventTracking/analyticsEventTracking.directive';
import { PolicyCheckerDirective } from './directives/policyChecker/policyChecker.directive';
import {
  AuthenticatedUserGuard,
  CustomerGuard,
  LoginRedirectGuard,
} from './guards';
import { ImcMaterialModule } from './imc-material.module';
import { AssumeRoleComponent } from './pages/assume-role/assume-role.component';
import { LoginComponent } from './pages/login/login.component';
import { OAuthenticationService } from './services/authentication/authentication.service';
import { ColorPaletteService } from './services/color-palette/color-palette.service';
import { ErrorService } from './services/error-handling/error.service';
import { PolicyService } from './services/policy-service/policy.service';

[locale_en].forEach(registerLocaleData);
import { NavModule } from './_modules/nav/nav.module';
import { SiteSelectionMenuComponent } from './components/site-selection-menu/site-selection-menu.component';
import { PolicyGuard } from './guards/policy.guard';
import { SysOpsGuard } from './guards/sysops.guard';
import { AppConfigService } from './services/app-config/app-config.service';
import { PolicyCheckerService } from './services/policy-checker-service/policy-checker.service';
import { PolicyButtonComponent } from './components/policy-button/policy-button.component';
import { RouterModule } from '@angular/router';
import { DocConfigService } from './services/doc-config/doc-config.service';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleAnalyticsService } from './services/google-analytics/google-analytics.service';
// We need a factory since localStorage is not available at AOT build time
// tslint:disable-next-line:only-arrow-functions
export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [
    CommonModule,
    NavModule,
    NotifyModule,
    RouterModule,
    ImcMaterialModule,
    TranslateModule,
    OAuthModule.forRoot(),
  ],
  declarations: [
    AnalyticsEventTrackingDirective,
    PolicyCheckerDirective,
    LoginComponent,
    AssumeRoleComponent,
    PolicyButtonComponent,
    SiteSelectionMenuComponent,
  ],
  exports: [
    AnalyticsEventTrackingDirective,
    PolicyCheckerDirective,
    NavModule,
    NotifyModule,
    PolicyButtonComponent,
    SiteSelectionMenuComponent,
  ],
  providers: [
    GoogleAnalyticsService,
    OAuthenticationService,
    AuthenticatedUserGuard,
    LoginRedirectGuard,
    PolicyGuard,
    SysOpsGuard,
    ColorPaletteService,
    ErrorService,
    CustomerGuard,
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    // so that every module gets same instance of Providers
    return {
      ngModule: CoreModule,
      providers: [
        GoogleAnalyticsService,
        OAuthenticationService,
        PolicyService,
        PolicyCheckerService,
        PolicyGuard,
        ColorPaletteService,
        AuthenticatedUserGuard,
        LoginRedirectGuard,
        {
          provide: AuthConfig,
          useFactory: authConfigFactory,
          deps: [AppConfigService],
        },
        { provide: OAuthStorage, useFactory: storageFactory },
        AppConfigService,
        DocConfigService,
      ],
    };
  }
}
