import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'imcDateFormat',
})
export class ImcDateFormatPipe extends DatePipe implements PipeTransform {
  transform(text: any): string {
    if (text === null || text === undefined || text === '') {
      return '';
    }
    return super.transform(text, 'MMMM d, y hh:mm:ss a');
  }
}
