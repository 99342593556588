export const getChartConfig: any = (config: any) => () =>
  JSON.parse(JSON.stringify(config));

export const colorScheme: () => string[] = (): string[] => [
  '#007373',
  '#00b2b1',
  '#007ba3',
  '#0098c9',
  '#43515a',
  '#959ea2',
  '#846cbb',
  '#b0a0d6',
];

export const barTheme: any = getChartConfig({
  color: [...colorScheme()],
  axisLine: { lineStyle: { color: '#899296' } },
});

export const vuBarConfig: any = getChartConfig({
  color: barTheme().color,
  legend: {
    zlevel: 100,
    show: true,
    bottom: 0,
    selectedMode: false,
  },
  tooltip: {},
  xAxis: {
    data: [],
    type: 'category',
    splitLine: { show: false },
    axisLabel: { show: true },
    axisTick: { show: false },
    axisLine: barTheme().axisLine,
  },
  yAxis: {
    type: 'value',
    axisLine: barTheme().axisLine,
  },
  // areastyle for area shading
  series: [],
});

export const vuSmBarConfig: any = getChartConfig({
  ...vuBarConfig(),
  xAxis: {
    data: [],
    type: 'category',
    axisLine: barTheme().axisLine,
  },
  yAxis: {
    type: 'value',
    axisLine: barTheme().axisLine,
    axisLabel: { show: false },
  },
});

export const depconBarConfig: any = getChartConfig({
  color: barTheme().color,
  legend: {
    zlevel: 100,
    show: true,
    bottom: 0,
    selectedMode: false,
  },
  grid: {
    right: 80,
    left: 15,
    containLabel: true,
    top: 10,
    bottom: 10,
  },
  tooltip: {},
  xAxis: { type: 'value', axisLine: barTheme().axisLine },
  yAxis: { type: 'category', axisLine: barTheme().axisLine },
  series: [],
});

export const cdsLineConfig: any = getChartConfig({
  color: ['#F2724B'],
  grid: {
    top: 20,
    height: '73',
  },
  xAxis: {
    data: [],
    type: 'category',
    splitLine: { show: false },
    axisLabel: { show: true },
    axisTick: { show: false },
    axisLine: { show: true, lineStyle: { color: '#899296' } },
  },
  yAxis: {
    data: [],
    axisLabel: { show: false },
    splitLine: { show: false },
    axisTick: { show: false },
    type: 'value',
    scale: true,
    axisLine: { show: true, lineStyle: { color: '#899296' } },
  },
  series: [],
});

export const cdsDetailsLineConfig: any = getChartConfig({
  color: ['#F2724B'],
  tooltip: {},
  xAxis: {
    type: 'category',
    splitLine: { show: false },
    axisLabel: { show: true },
    axisTick: { show: false },
    axisLine: { show: true, lineStyle: { color: '#899296' } },
  },
  yAxis: {
    type: 'value',
    scale: true,
    axisLine: { show: true, lineStyle: { color: '#899296' } },
  },
  // areastyle for area shading
  series: [],
});

export const getSeriesColor: (seriesIndex: number) => string = (
  seriesIndex: number
): string => {
  const colors: string[] = colorScheme();
  const numOfColors: number = colors.length;
  const color: string = colors[seriesIndex % numOfColors];

  return color;
};
