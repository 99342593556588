import { Injectable } from '@angular/core';

@Injectable()
export class TypeUtilService {
  toBoolean(value): boolean {
    if (typeof value === 'string' && value.length) {
      return value && value.toLowerCase() === 'true';
    }
    return value || false;
  }

  isBoolean(val): boolean {
    return typeof val === 'boolean';
  }

  isArray(val): boolean {
    return val instanceof Array;
  }

  isObject(val): boolean {
    return Object.prototype.toString.call(val) === '[object Object]';
  }
}
