import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  DbState,
  DbStatusStrings,
} from '@imc-features/ecosystems/_modules/ecosystems-shared/constants/db-state.constants';
import { Site } from '@imc/core/models/sites.model';
import { Subscription } from 'rxjs';
import {
  HsnOperation,
  HsnState,
  HsnStatus,
  hsnStatusStrings,
} from '../../constants/hsn-status.constants';
import { GoogleAnalyticsService } from '@imc/core/services/google-analytics/google-analytics.service';
import { AzureService } from '../../services/azure.service';

@Component({
  selector: 'imc-azure-hsn-delete',
  templateUrl: './hsn-delete.component.html',
  styleUrls: ['./hsn-delete.component.scss'],
})
export class HsnDeleteComponent implements OnInit, OnDestroy {
  private readonly dbStatusStrings: any = DbStatusStrings;
  private readonly hsnStatusStrings: any = hsnStatusStrings;
  private azureSubscription: Subscription;
  @Input() siteInfo: Site;
  hsnStateOptions: any = HsnState;
  dbStateOptions: any = DbState;
  dbState: number;
  failedOperation: boolean = false;
  displayStatus: boolean = false;
  disableButton: boolean = false;
  hsnState: number;
  hsnMessage: string = '';
  analyticsEvent: any = {
    azureHSNDelete: {
      eventKey: 'azure_hsn_delete',
    },
  };
  constructor(
    private readonly analyticsService: GoogleAnalyticsService,
    private readonly azureService: AzureService
  ) {}

  ngOnInit(): void {
    if (this.siteInfo.sqleApp) {
      this.getDbState();
      this.getHsnInfo();
    }
  }

  showHsnDeleteOption(): boolean {
    return (
      this.siteInfo.sqleApp.actualHsnNodeCount !== 0 &&
      (this.siteInfo.sqleApp.hsnStatus === HsnStatus.STARTED ||
        this.siteInfo.sqleApp.hsnStatus === HsnStatus.DELETE_FAILED)
    );
  }

  ngOnDestroy(): void {
    if (this.azureSubscription) {
      this.azureSubscription.unsubscribe();
    }
  }

  getHsnInfo(): void {
    // Incase the site does not have 'hsnStatus'
    if (!this.siteInfo.sqleApp.hsnStatus) {
      this.hsnState = this.hsnStatusStrings.started.dbState;
      this.hsnMessage = this.hsnStatusStrings.started.dbMessage;
    } else {
      // Incase siteInfo.hsnStatus is not in the mapping, setting default to started
      const status: any = this.hsnStatusStrings[
        this.siteInfo.sqleApp.hsnStatus
      ];
      this.hsnState = status
        ? status.dbState
        : this.hsnStatusStrings.started.dbState;
      this.hsnMessage = status
        ? status.dbMessage
        : this.hsnStatusStrings.started.dbMessage;
    }
  }

  getDbState(): void {
    // Incase the db does not have db status
    if (!this.siteInfo.sqleApp.status) {
      this.dbState = this.dbStatusStrings.started.dbState;
    } else {
      // Incase siteInfo.dbStatus is not in the mapping, setting default to started
      const status: any = this.dbStatusStrings[
        this.siteInfo.sqleApp && this.siteInfo.sqleApp.status
      ];
      this.dbState = status
        ? status.dbState
        : this.dbStatusStrings.started.dbState;
    }
  }

  confirmHsnDelete(): void {
    this.analyticsService.sendAnalyticsEvent(
      this.analyticsEvent.azureHSNDelete
    );
    this.confirmHsnOperation(HsnStatus.DELETE_IN_PROGRESS, HsnOperation.Delete);
  }

  private confirmHsnOperation(
    hsnStatus: HsnStatus,
    hsnOperation: HsnOperation
  ): void {
    // Immediate update on UI
    this.disableButton = true;
    this.siteInfo.sqleApp.hsnStatus = hsnStatus;
    this.getHsnInfo();

    this.azureSubscription = this.azureService
      .postHsnOperation(this.siteInfo, hsnOperation)
      .subscribe(
        () => {
          // add any other operations on a success call
        },
        () => {
          this.failedOperation = true;
        }
      )
      .add(() => {
        this.displayStatus = true;
        this.getHsnInfo();
      });
  }
}
