import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TdMediaService } from '@covalent/core';
import { Customer } from '@imc-features/ecosystems/_modules/ecosystems-shared/models/customer.model';
import { CustomerEcosystemSites } from '@imc-features/ecosystems/_modules/ecosystems-shared/models/ecosystem-site.model';
import { ElasticityService } from '@imc-features/ecosystems/_modules/ecosystems-shared/services/elasticity-service/elasticity.service';
import { SiteService } from '@imc-features/ecosystems/_modules/ecosystems-shared/services/site-service/ecosystems-site.service';
import { Site } from '@imc/core';
import { PolicyOperations } from '@imc/core/models/policyOperations.enum';
import { PolicyResources } from '@imc/core/models/policyResources.enum';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'imc-site-details',
  templateUrl: './site-details.component.html',
  styleUrls: ['./site-details.component.scss'],
})
export class SiteDetailsComponent implements OnInit, OnDestroy {
  private _siteInfo$: BehaviorSubject<Site> = new BehaviorSubject<Site>(
    undefined
  );
  private _sites$: BehaviorSubject<
    CustomerEcosystemSites[]
  > = new BehaviorSubject<CustomerEcosystemSites[]>([]);
  private _siteSelector$: BehaviorSubject<string> = new BehaviorSubject<string>(
    undefined
  );
  private routeSubscription: Subscription;
  private elasticityServiceSubscription: Subscription;

  private getSiteInfoSubscription: Subscription;
  public PolicyOperations: any = PolicyOperations;
  public PolicyResources: any = PolicyResources;
  public siteId: string = '';
  sideSheetOpened: boolean = false;

  public siteInfo$: Observable<Site> = this._siteInfo$.asObservable();
  public sites$: Observable<
    CustomerEcosystemSites[]
  > = this._sites$.asObservable();
  loading: boolean = true;
  error: any;

  constructor(
    public media: TdMediaService,
    private route: ActivatedRoute,
    private router: Router,
    private siteService: SiteService,
    private readonly elasticityService: ElasticityService
  ) {}

  public ngOnInit(): void {
    this.getSiteDetails();
    this.sideSheetOpened = this.router.url.includes('side:elasticity');
    this.elasticityServiceSubscription = this.elasticityService
      .getRefreshStatus()
      .subscribe(() => {
        this.cleanup();
        this.getSiteDetails();
      });
  }

  getSiteDetails(): void {
    this.routeSubscription = this.getCurrentSite();
    this.getSiteInfoSubscription = this.getSiteInfo();
  }

  public ngOnDestroy(): void {
    this.elasticityServiceSubscription.unsubscribe();
    this.cleanup();
  }

  cleanup(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }

    if (this.getSiteInfoSubscription) {
      this.getSiteInfoSubscription.unsubscribe();
    }
  }

  closeSideSheet(): void {
    this.router.navigate(['sites', this.siteId]);
  }

  private getCurrentSite(): Subscription {
    return this.route.params.subscribe((params: any) => {
      if (params && params.siteId) {
        this.siteId = params.siteId;
        this._siteSelector$.next(this.siteId);
      }
    });
  }

  private getSiteInfo(): Subscription {
    return this._siteSelector$
      .pipe(
        mergeMap((siteId: string) => this.siteService.getSiteInfo({ siteId }))
      )
      .pipe(
        mergeMap((site: Site) =>
          this.siteService
            .getCustomerById(site.customerId)
            .pipe(map((customer: Customer) => ({ customer, site })))
        )
      )
      .pipe(
        map((customerSite: { customer: Customer; site: Site }) => {
          if (customerSite.site) {
            customerSite.site.customerName =
              (customerSite.customer && customerSite.customer.name) ||
              customerSite.site.customerName;
          }

          return customerSite.site;
        })
      )
      .subscribe(
        (siteInfo: Site) => {
          this._siteInfo$.next(siteInfo);
          this.loading = false;
        },
        (error: any) => {
          this.loading = false;
          this.error = true;
        }
      );
  }
}
