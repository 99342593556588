import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable()
export class DateUtilService {
  sortOnDateTypeColumn(
    data: any[],
    sortBy: string,
    sortDirection: number
  ): any[] {
    data.sort((a: any, b: any) => {
      const compare = moment(a[sortBy]).valueOf() - moment(b[sortBy]).valueOf();
      return sortDirection * compare;
    });
    return data;
  }
}
