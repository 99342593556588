import { Component, OnDestroy, OnInit } from '@angular/core';
import { Site } from '@imc/core';
import { ColorPaletteService } from '@imc/core/services/color-palette/color-palette.service';
import { SiteService } from '@imc/core/services/site-service/site.service';
import { Subscription } from 'apollo-client/util/Observable';

@Component({
  selector: 'imc-intellicloud-overview',
  templateUrl: './intellicloud-overview.component.html',
  styleUrls: ['./intellicloud-overview.component.scss'],
})
export class IntellicloudOverviewComponent implements OnInit, OnDestroy {
  customerDatabaseSites: Site[] = [];
  aws: Map<string, number> = new Map();
  azure: Map<string, number> = new Map();
  tmc: Map<string, number> = new Map();
  awsSiteCount: number = 0;
  azureSiteCount: number = 0;
  tmcSiteCount: number = 0;
  loaderKey: string = 'intellicloudOverviewLoader';
  loading: boolean = true;
  error: boolean;
  awsColorCode: string;
  azureColorCode: string;
  tmcColorCode: string;

  chartData: object[] = [];
  dbServiceSubscriptionHandle: Subscription;

  constructor(
    private readonly siteService: SiteService,
    private _colorPaletteService: ColorPaletteService
  ) {}

  ngOnInit(): void {
    this.awsColorCode = this._colorPaletteService.getAwsColor();
    this.azureColorCode = this._colorPaletteService.getAzureColor();
    this.tmcColorCode = this._colorPaletteService.getTMCColor();
    this.getCustomerDatabaseSites();
  }

  ngOnDestroy(): void {
    if (this.dbServiceSubscriptionHandle) {
      this.dbServiceSubscriptionHandle.unsubscribe();
    }
  }

  getCustomerDatabaseSites(): void {
    this.dbServiceSubscriptionHandle = this.siteService.listSites().subscribe(
      (siteList: Site[]) => {
        this.customerDatabaseSites = siteList;
        this.prepareChartData();
      },
      (error: any) => {
        this.error = true;
      },
      () => {
        this.loading = false;
      }
    );
  }

  prepareChartData(): void {
    this.customerDatabaseSites.forEach((site: Site) => {
      if (site && site.customerId && site.cloudPlatform) {
        switch (site.cloudPlatform) {
          case 'aws':
            this.awsSiteCount++;
            this.updateMap(site.customerId, this.aws);
            break;

          case 'azure':
            this.azureSiteCount++;
            this.updateMap(site.customerId, this.azure);
            break;

          case 'tmc':
            this.tmcSiteCount++;
            this.updateMap(site.customerId, this.tmc);
            break;

          default:
        }
      }
    });
  }

  updateMap(customerId: string, platform: Map<string, number>): void {
    if (platform.has(customerId)) {
      platform[customerId]++;
    } else {
      platform.set(customerId, 1);
    }
  }
}
