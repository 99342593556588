import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TdMediaService } from '@covalent/core';
import { ImcMaterialModule } from '@imc/core';

import { RouterModule } from '@angular/router';
import { SharedModule } from '@imc/shared/shared.module';
import { EcosystemsSharedModule } from '../ecosystems-shared/ecosystems-shared.module';
import { TmcCcuMigrationOnboardingComponent } from './cards/tmc-ccu-migration-onboarding/tmc-ccu-migration-onboarding.component';
import { TmcInstanceInfoComponent } from './cards/tmc-instance-info/tmc-instance-info.component';
import { TmcSitesComponent } from './cards/tmc-sites/tmc-sites.component';
import { TmcSiteOverviewComponent } from './layouts/tmc-site-overview/tmc-site-overview.component';
import { TmcCcuMigrationDetailsComponent } from './pages/tmc-ccu-migration-details/tmc-ccu-migration-details.component';
import { TmcDashboardComponent } from './pages/tmc-dashboard/tmc-dashboard.component';
import { CcuMigrationService } from './services/ccu-migrations-service/ccu-migrations.service';
import { TmcInstanceInfoService } from './services/tmc-instance-info-service/tmc-instance-info.service';

@NgModule({
  declarations: [
    TmcDashboardComponent,
    TmcSitesComponent,
    TmcCcuMigrationOnboardingComponent,
    TmcInstanceInfoComponent,
    TmcSiteOverviewComponent,
    TmcCcuMigrationDetailsComponent,
  ],
  entryComponents: [TmcSiteOverviewComponent],
  imports: [
    CommonModule,
    RouterModule,
    ImcMaterialModule,
    SharedModule,
    EcosystemsSharedModule,
  ],
  exports: [TmcDashboardComponent, TmcCcuMigrationDetailsComponent],
  providers: [TdMediaService, TmcInstanceInfoService, CcuMigrationService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TmcModule {}
