import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { PrivateLinkData } from '../../models/private-link.model';

@Injectable()
export class AwsPrivateLinkService {
  constructor(private readonly apollo: Apollo) {}

  fetchData(
    siteId: string,
    account: string,
    region: string
  ): Observable<PrivateLinkData[]> {
    const privateListQuery: any = gql`
      query privateLinkList {
        privateLinkList(siteId: $siteId, account: $account, region: $region)
          @rest(
            type: "GraphData"
            path: "/connections/privatelink/{args.account}/{args.region}/{args.siteId}"
            endpoint: "privateLink"
          ) {
          id
          name
          status
          targets
          type
          whitelistedAccounts
        }
      }
    `;

    return this.apollo
      .query<any>({
        query: privateListQuery,
        variables: {
          siteId,
          account,
          region,
        },
      })
      .pipe(
        switchMap((responseBody: any) => {
          if (responseBody.errors) {
            // Error condition
            throw new Error('Fetching Private Link Data failed');
          }

          // Success condition
          return of(responseBody.data && responseBody.data.privateLinkList);
        }),
        first()
      );
  }
}
