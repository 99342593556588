import gql from 'graphql-tag';

export const nodeV1Fragment: any = gql`
  fragment NodeV1Fragment on NodeV1 {
    type
    hostname
    internalIpAddress
    vpcCidr
    privateIp
    publicIp
    internalIpAddress
    nodeSize
    instanceType
    nodeName
    name
    status
    cidr
  }
`;

export const nodeV2Fragment: any = gql`
  fragment NodeV2Fragment on NodeV2 {
    imcEntityName
    operational_status_display_value
    used_for
    ip_address
    purpose
    nat_ip_address
    os_type
    os_version
    u_purpose: purpose
    u_nat_ip_address: nat_ip_address
    u_os_type: os_type
    u_os_version: os_version
  }
`;

export const genericAppV2Fragment: any = gql`
  fragment GenericAppV2Fragment on GenericAppV2 {
    imcEntityName
    used_for
    operational_status_display_value
    short_description
    version
    category
    purpose
    u_purpose: purpose
    u_category: category
    nodes @type(name: "NodeV2") {
      ...NodeV2Fragment
    }
  }
`;

export const sqleAppV2Fragment: any = gql`
  fragment SqleAppV2Fragment on SqleAppV2 {
    imcEntityName
    version
    operational_status_display_value
    short_description
    used_for
    purpose
    base_node_ct
    current_node_ct
    instance_size
    volume_size
    state
    u_purpose: purpose
    u_base_node_ct: base_node_ct
    u_current_node_ct: current_node_ct
    u_instance_size: instance_size
    u_volume_size: volume_size
    u_state: state
    features
    nodes @type(name: "NodeV2") {
      ...NodeV2Fragment
    }
  }
`;

export const mleAppV2Fragment: any = gql`
  fragment MleAppV2Fragment on MleAppV2 {
    imcEntityName
    operational_status_display_value
    short_description
    version
    purpose: u_purpose
    base_node_ct: u_base_node_ct
    current_node_ct: u_current_node_ct
    state: u_state
  }
`;

export const siteDetailsFragment: any = gql`
  fragment SiteDetailsFragment on Site {
    siteId
    siteName
    siteTDPID
    supportSiteId
    region
    cloudPlatform
    purpose
    dbStatus
    dbNodeCount
    baseDBNodeCount
    viewpointAddress
    storageSize
    status
    dbSize
    version
    dbVmConfig
    customerId
    customerName
    entitledStorage
    availableDbConfigs
    actualHsnNodeCount
    expectedHsnNodeCount
    hsnStatus
    incNodeOptions
    operationalStatus
    targetSLA
    features
    isVCM
    resourceVersion
    imcCustomerId
    imcEntityName
    u_account_id
    subAccountId
    nodes @type(name: "NodeV1") {
      ...NodeV1Fragment
    }
    genericApps @type(name: "GenericAppV2") {
      ...GenericAppV2Fragment
    }
    sqleApps @type(name: "SqleAppV2") {
      ...SqleAppV2Fragment
    }
    mleApps @type(name: "MleAppV2") {
      ...MleAppV2Fragment
    }
  }
`;
