import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class DocConfigService {
  private docConfig: Map<string, string>;

  constructor(private http: HttpClient) {}

  loadDocConfig(): Promise<void> {
    return this.http
      .get('/assets/doc-links/doc-links.json')
      .toPromise()
      .then((data: Map<string, string>) => {
        this.docConfig = data;
      });
  }

  getDocConfig(): Map<string, string> {
    return this.docConfig;
  }
}
