import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImcMaterialModule } from '@imc/core';

import { CovalentMediaModule } from '@covalent/core/media';
import { CovalentBarEchartsModule } from '@covalent/echarts/bar';
import { CovalentBaseEchartsModule } from '@covalent/echarts/base';
import { CovalentLineEchartsModule } from '@covalent/echarts/line';
import { CovalentToolboxEchartsModule } from '@covalent/echarts/toolbox';
import { CovalentTooltipEchartsModule } from '@covalent/echarts/tooltip';

import { ErrorService } from '@imc/core/services/error-handling/error.service';
import { SiteService } from '@imc/core/services/site-service/site.service';

import { SharedModule } from '@imc/shared/shared.module';
import { AvailabilityComponent } from './cards/availability/availability.component';
import { ConfigurationComponent } from './cards/configuration/configuration.component';
import { ConsumptionComponent } from './cards/consumption/consumption.component';
import { EnginesComponent } from './cards/engines/engines.component';
import { RecentBackupsComponent } from './cards/recent-backups/recent-backups.component';
import { SupportTicketsComponent } from './cards/support-tickets/support-tickets.component';
import { VantageUnitsComponent } from './cards/vantange-units/vantage-units.component';

import { OAuthenticationService } from '@imc/core/services';
import { AvailabilityService } from './services/availability.service';
import { BackupsService } from './services/backups.service';
import { ConsumptionService } from './services/consumption.service';
import { MetricsGraphService } from './services/metrics-graph.service';
import { TicketsService } from './services/tickets.service';
import { VUThresholdService } from './services/vu-threshold.service';

import { ThresholdAlertComponent } from './cards/threshold-alert/threshold-alert.component';
import { SiteHomepageRoutingModule } from './site-homepage-routing.module';
import { SiteHomepageComponent } from './site-homepage.component';

@NgModule({
  imports: [
    CommonModule,
    SiteHomepageRoutingModule,
    ImcMaterialModule,
    CovalentMediaModule,
    CovalentBaseEchartsModule,
    CovalentTooltipEchartsModule,
    CovalentToolboxEchartsModule,
    CovalentLineEchartsModule,
    CovalentBarEchartsModule,
    SharedModule,
  ],
  declarations: [
    SiteHomepageComponent,
    SupportTicketsComponent,
    VantageUnitsComponent,
    AvailabilityComponent,
    ConfigurationComponent,
    ConsumptionComponent,
    EnginesComponent,
    RecentBackupsComponent,
    ThresholdAlertComponent,
  ],
  exports: [SiteHomepageComponent],
  providers: [
    DatePipe,
    DecimalPipe,
    ErrorService,
    SiteService,
    TicketsService,
    OAuthenticationService,
    AvailabilityService,
    BackupsService,
    ConsumptionService,
    VUThresholdService,
    MetricsGraphService,
  ],
  entryComponents: [],
})
export class SiteHomepageModule {}
