import { Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Site } from '@imc/core/models/sites.model';
import { catchError, first, switchMap, tap } from 'rxjs/operators';

import { PrivateLinkData } from '../../models/private-link.model';
import { AwsPrivateLinkService } from '../../services/aws-private-link-service/aws-private-link.service';

@Component({
  selector: 'imc-aws-private-link',
  templateUrl: './aws-private-link.component.html',
  styleUrls: ['./aws-private-link.component.scss'],
})
export class AwsPrivateLinkComponent {
  privateLinkCardInput$: Observable<any>;
  fetchDataComplete: boolean = false;
  siteDisplayName: string;
  siteIsValid: boolean;

  constructor(private readonly privateLinkService: AwsPrivateLinkService) {}

  @Input() set siteInfo(value: Site) {
    this.siteIsValid = value && value.siteId && value.siteId.trim().length > 0;
    this.siteDisplayName = value.displayName
      ? value.displayName
      : this.siteIsValid
      ? value.siteId
      : undefined;
    const account: string = value.u_account_id
      ? value.u_account_id
      : value.subAccountId;
    if (!value.siteId || !account || !value.region) {
      this.privateLinkCardInput$ = of({
        privateLinkData: undefined,
        site: value,
        error: 'error_loading_aws_private_link_misconfiguration',
        empty: undefined,
      });
      this.fetchDataComplete = true;
    } else {
      this.fetchDataComplete = false;
      this.privateLinkCardInput$ = this.privateLinkService
        .fetchData(value.siteId, account, value.region)
        .pipe(
          switchMap((privateLinkData: PrivateLinkData[]) =>
            of({
              privateLinkData,
              site: value,
              empty:
                privateLinkData && privateLinkData.length > 0
                  ? undefined
                  : 'aws_private_link_empty',
              error: undefined,
            })
          ),
          catchError((error: any, caught: Observable<any>) =>
            of({
              privateLinkData: undefined,
              site: value,
              empty: undefined,
              error: 'error_loading_aws_private_link',
            })
          ),
          tap(() => (this.fetchDataComplete = true)),
          first()
        );
    }
  }

  statusIcon(status: string): string {
    return status.trim().toLowerCase() === 'available'
      ? 'check_circle'
      : 'highlight_off';
  }
}
