import { Component, Input, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';

import { Customer } from '../../models/customer.model';

@Component({
  selector: 'imc-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent {
  hasData: boolean = false;
  displayedColumns: string[] = ['name', 'cloudId'];
  dataSource: MatTableDataSource<Customer>;
  @ViewChild(MatSort) sort: MatSort;

  @Input() loading: boolean = true;
  @Input() loaderKey: string = 'customerLoader';
  @Input() error: boolean = false;

  constructor(private readonly router: Router) {}

  showCustomerDashboard(customerId: string): void {
    this.router.navigate([`customer-dashboard/${customerId}`]);
  }

  @Input()
  set inputCustomers(customers: Customer[]) {
    this.dataSource = new MatTableDataSource(customers);
    this.dataSource.sort = this.sort;
    this.hasData = this.dataSource.data.length > 0;
  }

  viewAllCustomers(): void {
    this.router.navigate(['customers']);
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
