import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDrawer, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { Observable, of, race, Subject } from 'rxjs';
import {
  catchError,
  first,
  flatMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

import { Customer } from '@imc-features/ecosystems/_modules/ecosystems-shared/models/customer.model';
import { SiteService } from '@imc-features/ecosystems/_modules/ecosystems-shared/services/site-service/ecosystems-site.service';
import { PolicyOperations } from '@imc/core/models/policyOperations.enum';
import { PolicyResources } from '@imc/core/models/policyResources.enum';

import { AzureSubscription } from '../../models/azure-subscription.model';
import { AzureSubscriptionService } from '../../services/azure-subscriptions.service';

@Component({
  selector: 'imc-azure-subscriptions',
  templateUrl: './azure-subscriptions.component.html',
  styleUrls: ['./azure-subscriptions.component.scss'],
})
export class AzureSubscriptionsComponent implements OnInit, OnDestroy {
  private dataSource: MatTableDataSource<AzureSubscription>;
  private sort: MatSort;
  private unsubscribeNotifier$: Subject<void> = new Subject<void>();

  subscriptions$: Observable<any>;
  columns: string[] = [
    'id',
    'name',
    'owner',
    'status',
    'customerName',
    'comments',
  ];
  @Input() drawer: MatDrawer;

  PolicyOperations = PolicyOperations;
  PolicyResources = PolicyResources;

  constructor(
    private readonly azureSubscriptionsService: AzureSubscriptionService,
    private readonly siteService: SiteService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.subscriptions$ = this.azureSubscriptionsService
      .watchSubscriptions()
      .pipe(
        takeUntil(this.unsubscribeNotifier$),
        flatMap((responseBody: any) => {
          if (responseBody.errors) {
            throw new Error('Unable to fetch subscriptions');
          }

          const subscriptions: any[] =
            responseBody.data && responseBody.data.subscriptions;

          // withLatestFrom did not work since it seemed to
          // expect the secondary observable to emit for each
          // emission of the primary observable. The table
          // would render the first time, but if you navigated
          // away from the page and came back, the card would spin
          // endlessly
          return this.siteService.listCustomers().pipe(
            switchMap((customers: Map<string, Customer>) =>
              of(this.createDataSource(subscriptions, customers))
            ),
            first()
          );
        }),
        catchError((error: any, caught: Observable<any>) =>
          of({ dataSource: undefined, error: true, noData: false })
        )
      );
  }

  ngOnDestroy(): void {
    this.unsubscribeNotifier$.next();
    this.unsubscribeNotifier$.complete();
  }

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.sort = sort;
    if (this.dataSource) {
      this.dataSource.sort = sort;
    }
  }

  applyFilter(
    dataSource: MatTableDataSource<AzureSubscription>,
    filterValue: string
  ): void {
    dataSource.filter = filterValue.trim().toLowerCase();
  }

  showTooltip(status: boolean): string {
    return status
      ? 'azure_subscription_click_to_edit'
      : 'azure_subscription_cannot_edit_deactivated';
  }

  editSubscription(subscription: AzureSubscription): void {
    if (subscription.status) {
      this.drawer.open();
      this.router.navigate([
        'sites',
        'platform',
        'azure',
        {
          outlets: { azureRightDrawer: `edit-subscription/${subscription.id}` },
        },
      ]);
    }
  }

  createSubscription(): void {
    this.drawer.open();
    this.router.navigate([
      'sites',
      'platform',
      'azure',
      { outlets: { azureRightDrawer: 'create-subscription' } },
    ]);
  }

  statusValue(status: boolean): string {
    return status
      ? 'azure_subscription_status_activated'
      : 'azure_subscription_status_deactivated';
  }

  private sorter(item: any, property: string): string | number {
    switch (property) {
      case 'customerName': {
        return item.customerName ? item.customerName : '';
      }
      case 'id': {
        return item.id;
      }
      case 'name': {
        return item.name ? item.name : '';
      }
      case 'status': {
        return this.statusValue(item.status);
      }
      case 'owner': {
        return item.owner ? item.owner : '';
      }
      case 'comments': {
        return item.comments ? item.comments : 0;
      }
      default: {
        throw new Error('sorting this column is not supported');
      }
    }
  }

  private createDataSource(
    rawSubscriptions: any[],
    customers: Map<string, Customer>
  ): any {
    if (!rawSubscriptions) {
      return { dataSource: undefined, error: true, noData: false };
    } else if (rawSubscriptions.length === 0) {
      return { dataSource: undefined, error: false, noData: true };
    }

    const subscriptions: AzureSubscription[] = composeAzureSubscriptions(
      rawSubscriptions,
      customers
    );
    this.dataSource = new MatTableDataSource(subscriptions);
    this.dataSource.sortingDataAccessor = (
      data: AzureSubscription,
      sortHeaderId: string
    ) => this.sorter(data, sortHeaderId);
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }

    return { dataSource: this.dataSource, error: false, noData: false };
  }
}

const composeAzureSubscriptions: Function = (
  subscriptions: any[],
  customers: Map<string, Customer>
): AzureSubscription[] =>
  subscriptions.map((subscription: any, i: number, array: any[]) => {
    const customer: Customer = customers.has(subscription.customerId)
      ? customers.get(subscription.customerId)
      : undefined;
    const customerName: string = customer
      ? customer.name
      : `Unknown Customer ${subscription.customerName} with id ${
          subscription.customerId
        }`;

    return {
      id: subscription.id,
      name: subscription.name,
      customerId: subscription.customerId,
      customerName,
      owner: subscription.owner,
      status: subscription.status,
      comments: subscription.comments,
    };
  });
