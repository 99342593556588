import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CovalentStepsModule } from '@covalent/core/steps';
import { CovalentDynamicFormsModule } from '@covalent/dynamic-forms';

import { ImcMaterialModule } from '@imc/core';

import { CreateSitesCardComponent } from './cards/create-sites-card/create-sites-card.component';
import { EcosystemConfigurationComponent } from './components/ecosystemConfiguration/ecosystemConfiguration.component';
import { MleConfigurationComponent } from './components/mleConfiguration/mleConfiguration.component';
import { OfferSelectStepComponent } from './components/offerSelectStep/offerSelectStep.component';
import { ParamsInputFormComponent } from './components/paramsInputForm/paramsInputForm.component';
import { ProductFeatureComponent } from './components/productFeature/productFeature.component';
import { ProductFeaturesComponent } from './components/productFeatures/productFeatures.component';
import { AwsCreateSitesComponent } from './pages/create-sites-page/create-sites.component';
import { CustomerService } from './services/customer.service';
import { FormBuilderService } from './services/formBuilder.service';
import { ICAWSOfferingService } from './services/icawsOffering.service';
import { TypeUtilService } from './services/typeUtil.service';

@NgModule({
  declarations: [
    EcosystemConfigurationComponent,
    MleConfigurationComponent,
    OfferSelectStepComponent,
    ParamsInputFormComponent,
    ProductFeatureComponent,
    ProductFeaturesComponent,
    CreateSitesCardComponent,
    AwsCreateSitesComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CovalentDynamicFormsModule,
    CovalentStepsModule,
    ImcMaterialModule,
  ],
  providers: [
    TypeUtilService,
    FormBuilderService,
    ICAWSOfferingService,
    CustomerService,
  ],
})
export class AwsProvisioningModule {}
