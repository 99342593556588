import { SiteLevelFeatureFlags } from '../graphql/models/sites-service.model';

interface SiteEntity {
  name?: string;
  operationalStatus?: string;
  purpose?: string;
}

export interface Node extends SiteEntity {
  usedFor?: string;
  ipAddress?: string;
  publicIpAddress?: string;
  osType?: string;
  osVersion?: string;
  status?: string;
  instanceType?: string;
  cidr?: string;
  vpcCidr?: string;
}

export interface SqleApp extends SiteEntity {
  nodeCount?: number;
  region?: string;
  cidr?: string;
  baseNodeCount?: number;
  instanceSize?: string;
  shortDescription?: string;
  status?: string;
  version?: string;
  usedFor?: string;
  nodes?: Node[];
  actualHsnNodeCount?: number;
  expectedHsnNodeCount?: number;
  dbVmConfig?: VMConfig;
  hsnStatus?: HsnStatus;
  availableDbConfigs?: AvailableDBConfigs[];
  incNodeOptions?: number[];
  storageSizePerNodeTB?: string;
  totalVolumeSizeGibiB?: number;
  entitledStorage?: string;
}

export interface GenericApp extends SiteEntity {
  usedFor?: string;
  type?: string;
  shortDescription?: string;
  version?: string;
  nodes?: Node[];
}

export interface MleApp {
  current_node_ct: number;
  state: string;
}

export interface VMConfig {
  size?: string;
  sku: string;
  cpus: string;
  memory?: string;
  tcores?: string;
  io?: string;
  name?: string;
}

export enum HsnStatus {
  NOT_LAUNCHED = 'not launched',
  LAUNCH_IN_PROGRESS = 'launch in progress',
  LAUNCH_FAILED = 'launch failed',
  DELETE_IN_PROGRESS = 'delete in progress',
  DELETE_FAILED = 'delete failed',
  RESIZE_IN_PROGRESS = 'resize in progress',
  RESIZE_FAILED = 'resize failed',
  STOPPED = 'stopped',
  STOP_IN_PROGRESS = 'stop in progress',
  STOP_FAILED = 'stop failed',
  STARTED = 'started',
  START_IN_PROGRESS = 'start in progress',
  START_FAILED = 'start failed',
  UPDATE_IN_PROGRESS = 'update in progress',
  UPDATE_FAILED = 'update failed',
}

export interface AvailableDBConfigs {
  [dbSize: string]: [VMConfig];
}

export interface Site {
  displayName: string;
  displayLocation: string;
  siteId: string;
  customerId: string;
  purpose: string;
  cloudPlatform: string;
  resourceVersion: string;
  isVCM?: boolean;
  supportSiteId?: string;
  customerName?: string;
  status?: string;
  operationalStatus?: string;
  targetSLA?: number;
  genericApps?: GenericApp[];
  sqleApp?: SqleApp;
  mleApps?: MleApp[];
  region?: string;
  features?: SiteLevelFeatureFlags;
  u_account_id?: string;
  subAccountId?: string;
}

export interface Customer {
  name: string;
  customerId: string;
  cloudId?: string;
}

export interface CustomerToSitesMap {
  [customerName: string]: Site[];
}

export interface CustomerSites {
  customerName: string;
  sites: Site[];
}
