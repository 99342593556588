import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { SiteService } from '@imc-features/ecosystems/_modules/ecosystems-shared/services/site-service/ecosystems-site.service';
import { Site } from '@imc/core/models/sites.model';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'imc-aws-sites',
  templateUrl: './aws-sites.component.html',
  styleUrls: ['./aws-sites.component.scss'],
})
export class AwsSitesComponent implements OnInit, OnDestroy {
  private sitesSubscription: Subscription;
  hasData: boolean = false;
  loading: boolean = true;
  loaderKey: string = 'awsSitesLoader';
  error: boolean = false;
  columns: string[] = ['name', 'purpose', 'status'];
  dataSource: MatTableDataSource<Site>;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private readonly router: Router,
    private readonly sitesService: SiteService
  ) {}

  ngOnInit(): void {
    this.sitesSubscription = this.sitesService
      .listSitesByPlatform('aws')
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        (sites: Site[]) => {
          this.dataSource = new MatTableDataSource(
            sites.filter((site: Site) => site.sqleApp)
          );
          this.dataSource.sort = this.sort;
          this.hasData = true;
        },
        (error: any) => {
          this.error = true;
        }
      );
  }

  ngOnDestroy(): void {
    if (this.sitesSubscription) {
      this.sitesSubscription.unsubscribe();
    }
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  viewSiteDetails(site: Site): void {
    // tslint:disable-next-line:no-floating-promises
    this.router.navigate([`sites/${site.siteId}`]);
  }
}
