import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TdMediaService } from '@covalent/core';
import { SiteService } from '@imc-features/ecosystems/_modules/ecosystems-shared/services/site-service/ecosystems-site.service';
import { Site } from '@imc/core/models/sites.model';
import { BehaviorSubject, Observable, Subscription, zip } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'imc-site-elasticity',
  templateUrl: './site-elasticity.component.html',
  styleUrls: ['./site-elasticity.component.scss'],
})
export class SiteElasticityComponent implements OnInit, OnDestroy {
  private _siteInfo$: BehaviorSubject<Site> = new BehaviorSubject<Site>(
    undefined
  );
  private routeSubscription: Subscription;
  private getSiteInfoSubscription: Subscription;
  private _siteSelector$: BehaviorSubject<string> = new BehaviorSubject<string>(
    undefined
  );

  public siteId: string = '';
  public siteInfo$: Observable<Site> = this._siteInfo$.asObservable();
  loading: boolean = true;
  error: any;

  constructor(
    public media: TdMediaService,
    private route: ActivatedRoute,
    private router: Router,
    private siteService: SiteService
  ) {}

  public ngOnInit(): void {
    this.routeSubscription = this.getCurrentSite();
    this.getSiteInfoSubscription = this.getSiteInfo();
  }

  public ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.getSiteInfoSubscription) {
      this.getSiteInfoSubscription.unsubscribe();
    }
  }

  private getCurrentSite(): Subscription {
    return this.route.parent.params.subscribe((params: any) => {
      if (params && params.siteId) {
        this.siteId = params.siteId;
        this._siteSelector$.next(this.siteId);
      }
    });
  }

  private getSiteInfo(): Subscription {
    return this._siteSelector$
      .pipe(
        mergeMap((siteId: string) => this.siteService.getSiteInfo({ siteId }))
      )
      .subscribe(
        (siteInfo: Site) => {
          this._siteInfo$.next(siteInfo);
          this.loading = false;
        },
        (error: any) => {
          this.loading = false;
          this.error = true;
        }
      );
  }
}
