import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TdMediaService } from '@covalent/core';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { FormControl } from '@angular/forms';
import { Customer } from '../../models/customer.model';
import { DashboardCustomerService } from '../../services/dashboard-customer.service';

@Component({
  selector: 'imc-customer-dashboard',
  templateUrl: './customer-dashboard.component.html',
  styleUrls: ['./customer-dashboard.component.scss'],
})
export class CustomerDashboardComponent implements OnInit, OnDestroy {
  private customersSubscription: Subscription;
  private options: Customer[] = [];
  private _currentCustomer: string;

  public selectControl: FormControl = new FormControl();
  public filteredOptions$: Observable<Customer[]>;
  public customer$: Observable<Customer>;
  loading: boolean = true;
  error: any;

  constructor(
    public media: TdMediaService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly dashboardCustomerService: DashboardCustomerService
  ) {}

  public ngOnInit(): void {
    this.customersSubscription = this.dashboardCustomerService
      .listCustomers()
      .subscribe(
        (customers: Customer[]) => {
          this.options = customers;
        },
        (error: any) => {
          this.error = 'Error fetching customer list';
        },
        () => {
          this.loading = false;
        }
      );

    this.customer$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        const customerId: string = params.get('customerId');
        this._currentCustomer = customerId;

        return this.dashboardCustomerService.getCustomerById(customerId);
      })
    );

    this.filteredOptions$ = this.selectControl.valueChanges.pipe(
      map((value: any) => (typeof value === 'string' ? value : value.name)),
      map((name: any) => (name ? this._filter(name) : this.options.slice()))
    );
  }

  public ngOnDestroy(): void {
    if (this.customersSubscription) {
      this.customersSubscription.unsubscribe();
    }
  }

  public setSelection(option: Customer): void {
    if (option && option.customerId !== this._currentCustomer) {
      const reload: Promise<boolean> = this.router.navigate([
        `/customer-dashboard/${option.customerId}`,
      ]);
      this.selectControl.setValue('');
    }
  }

  // display value of 'select customer' input
  public displayFn(user?: Customer): string | undefined {
    return user ? user.name : undefined;
  }

  private _filter(name: string): Customer[] {
    const filterValue: string = name ? name.toLowerCase() : '';

    return this.options.filter((option: Customer) => {
      if (option.name === null || option.name === undefined) {
        return false;
      } else {
        return option.name.toLowerCase().includes(filterValue);
      }
    });
  }
}
