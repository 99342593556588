/**
 * This is a temporary mapping between roles and policy.
 * In the future, this policy information should be retrieved from
 * the backend during the login call.
 */
export const POLICY_ROLE_MAPPING = {
  ReadOnlySiteAdmin: {
    Get: ['Tickets', 'Sites', 'Metrics', 'BaasSkippedObj'],
    List: ['Tickets', 'Sites', 'Metrics', 'BaasSkippedObj'],
    Update: ['Policy'],
  },
  SiteAdmin: {
    Get: ['Tickets', 'Sites', 'Metrics', 'BaasSkippedObj'],
    List: ['Tickets', 'Sites', 'Metrics', 'BaasSkippedObj'],
    Update: [
      'Policy',
      'Tickets',
      'Sites',
      'Metrics',
      'BaaS',
      'BaasSkippedObj',
      'Database',
      'AzureSubscription',
    ],
    Create: [
      'Tickets',
      'Sites',
      'Metrics',
      'BaaS',
      'BaasSkippedObj',
      'Database',
      'AzureSubscription',
    ],
    Delete: [
      'Tickets',
      'Sites',
      'Metrics',
      'BaaS',
      'BaasSkippedObj',
      'Database',
      'AzureSubscription',
    ],
  },
  SiteAccessManager: {
    Get: ['*'],
    List: ['*'],
    Update: ['Policy', 'Users'],
    Create: ['Users'],
    Delete: ['Users'],
  },
  SiteSuperAdmin: {
    Get: ['*'],
    List: ['*'],
    Update: [
      'Policy',
      'Tickets',
      'Sites',
      'Users',
      'Metrics',
      'BaaS',
      'BaasSkippedObj',
      'Database',
      'AzureSubscription',
    ],
    Create: ['*'],
    Delete: ['*'],
  },
  LimitedBaseUser: {},
  ReadOnlyLimitedSysops: {
    Get: ['*'],
    List: ['*'],
    Update: ['Policy'],
  },
  ReadOnlySysops: {
    Get: ['*'],
    List: ['*'],
    Update: ['Policy'],
  },
  FullSysops: {
    Get: ['*'],
    List: ['*'],
    Update: ['*'],
    Create: ['*'],
    Delete: ['*'],
  },
  SiteOnboardingUser: {
    Get: ['*'],
    List: ['*'],
    Update: ['Policy', 'WorkOrders'],
    Create: ['WorkOrders'],
  },
};

/**
 * Mapping between AD role and IMC role. This mapping should ideally be
 * coming from the backend in the future.
 */
export const AD_ROLE_MAPPING = {
  'Support Contact': 'Read-Only Site Admin',
  'Access Manager': 'Site Access Manager',
  'Security Manager': 'Site Access Manager',
  'Technical Contact': 'Site Administrator',
  'Teradata Cloud IMC Site Onboarding Users': 'Site Onboarding User',
  'Service Owner': 'Site Super Admin',
  'Teradata Cloud TIER 2.5 DB Administrator': 'Full Sysops',
  'Teradata Cloud TIER 2.5 IC Azure Support': 'Full Sysops',
  'Teradata Cloud TIER 2.5 Management Console': 'Full Sysops',
  'Teradata Cloud TIER II AWS Cloud Solution Integration': 'Full Sysops',
  'Teradata Cloud TIER II AWS Core Services': 'Full Sysops',
  'Teradata Cloud TIER II AWS Ecosystem': 'Full Sysops',
  'Teradata Cloud TIER II AWS Operations Engineering': 'Full Sysops',
  'Teradata Cloud TIER II AWS System Operator': 'Full Sysops',
  'Teradata Cloud TIER II AWS UPaaS': 'Full Sysops',
  'Teradata Cloud TIER II BaaS Operator': 'Full Sysops',
  'Teradata Cloud TIER II Backup Operator': 'Full Sysops',
  'Teradata MSPs': 'Read-Only Limited Sysops',
  'Teradata CSRs': 'Read-Only Sysops',
};
