import { AuthConfig } from 'angular-oauth2-oidc';
import { AppConfigService } from '../services/app-config/app-config.service';

export const authConfigFactory: Function = (appConfig: AppConfigService) => {
  const authConfig: AuthConfig = {
    issuer: `${appConfig.getConfig().auth.authDomain}/`,
    clientId: appConfig.getConfig().auth.authClientId,
    redirectUri: `${window.location.origin}`,
    silentRefreshRedirectUri: `${window.location.origin}/silent.html`,
    logoutUrl: `${appConfig.getConfig().auth.authDomain}/v2/logout?client_id=${
      appConfig.getConfig().auth.authClientId
    }&returnTo=${encodeURIComponent(window.location.origin)}`,
    responseType: 'token id_token',
    scope: 'openid profile email',
    // silentRefreshTimeout: 5000, // For faster testing
    // timeoutFactor: 0.50, // For faster testing
    sessionChecksEnabled: false,
    showDebugInformation: false, // Also requires enabling "Verbose" level in devtools
    clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040
    customQueryParams: {
      audience: `${appConfig.getConfig().endpoints.apiDomain}/`,
    },
  };

  return authConfig;
};
