import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable()
export class ICAWSOfferingService {
  constructor(private readonly apollo: Apollo) {}

  getAllOffers(): Observable<any> {
    const query: any = gql`
      query getAllOffers {
        icawsOffers
          @rest(
            type: "IcAwsOffer"
            path: "/offerings"
            endpoint: "icawsOffering"
          ) {
          offering_name
          display_name
          services
        }
      }
    `;

    // Use no-cache so that created offers show up as they are available
    return this.apollo.query({ query, fetchPolicy: 'no-cache' }).pipe(
      map((responseBody: any) => {
        if (!responseBody || responseBody.errors) {
          throw new Error('Unable to get Intellicloud AWS offers');
        }

        return responseBody.data.icawsOffers;
      }),
      first()
    );
  }

  // TODO: add refetch queries
  create(data: any): Observable<boolean> {
    const mutation: any = gql`
      mutation postIcawsOffer($data: Any!) {
        icawsOffer(input: $data)
          @rest(
            type: "IcAwsOffer"
            method: "POST"
            path: "/offerings"
            endpoint: "icawsOffering"
          ) {
          ignore
        }
      }
    `;

    const variables: any = { data };

    return this.apollo
      .mutate({ mutation, variables, fetchPolicy: 'no-cache' })
      .pipe(
        map((responseBody: any) => {
          if (!responseBody.result || responseBody.errors) {
            throw new Error('Unable to create Intellicloud AWS offer');
          }

          return responseBody.data.icawsOffer.result;
        })
      );
  }
}
