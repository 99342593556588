import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from '@imc/shared/pipes/safeHtml/safeHtml.pipe';
import { SharedModule } from '@imc/shared/shared.module';

import { TranslateModule } from '@ngx-translate/core';
import { ImcMaterialModule } from '../../imc-material.module';
import { NotifyBellComponent } from './components/bell/notify-bell.component';
import { NotifyCardComponent } from './components/notify-card/notify-card.component';
import { NotifyDetailsComponent } from './components/notify-details/notify-details.component';
import { NotifyRoutingModule } from './notify-routing.module';

@NgModule({
  declarations: [
    NotifyBellComponent,
    NotifyDetailsComponent,
    NotifyCardComponent,
    SafeHtmlPipe,
  ],
  imports: [
    CommonModule,
    ImcMaterialModule,
    SharedModule,
    NotifyRoutingModule,
    TranslateModule,
  ],
  exports: [NotifyBellComponent, NotifyDetailsComponent],
})
export class NotifyModule {}
