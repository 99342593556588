import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService, OAuthStorage, ParsedIdToken } from 'angular-oauth2-oidc';
import {
  BehaviorSubject,
  combineLatest,
  from,
  Observable,
  of,
  ReplaySubject,
} from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppConfigService } from '../app-config/app-config.service';

@Injectable()
export class OAuthenticationService {
  private _preferCorp: boolean = false;
  private _initialSilentRefresh: boolean = false;

  private isAuthenticatedSubject$: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(false);
  private isDoneLoadingSubject$: ReplaySubject<boolean> = new ReplaySubject<
    boolean
  >();

  private noAuth0ErrorSubject$: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(true);

  public isAuthenticated$: Observable<
    boolean
  > = this.isAuthenticatedSubject$.asObservable();
  public isDoneLoading$: Observable<
    boolean
  > = this.isDoneLoadingSubject$.asObservable();
  public noAuth0Error$: Observable<
    boolean
  > = this.noAuth0ErrorSubject$.asObservable();

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errored, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest(
    this.isAuthenticated$,
    this.isDoneLoading$
  ).pipe(map((values: [boolean, boolean]) => values.every((b: boolean) => b)));

  constructor(
    private readonly appConfig: AppConfigService,
    private readonly oauthService: OAuthService,
    @Inject('localStorage') private readonly oauthStorage: OAuthStorage,
    private router: Router
  ) {
    // Useful for debugging:
    // this.oauthService.events.subscribe((event) => {
    //   if (event instanceof OAuthErrorEvent) {
    //     console.error(event);
    //   } else {
    //     console.warn(event);
    //   }
    // });

    // load up login preference from local storage on instantiation
    this._preferCorp = oauthStorage.getItem('prefer_corp') === 'true';

    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup. TMCMC-2735
    window.addEventListener('storage', (event: any) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      // console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
      this.isAuthenticatedSubject$.next(
        this.oauthService.hasValidAccessToken()
      );

      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    // every time an event happens, emit the isAuthenticate$ with new value
    // TODO: need to come back and think about whether it's correct to emit on every event
    this.oauthService.events.subscribe(() => {
      this.isAuthenticatedSubject$.next(
        this.oauthService.hasValidAccessToken()
      );
    });

    this.oauthService.events
      .pipe(filter((e: any) => ['token_received'].includes(e.type)))
      .subscribe((e: any) => this.oauthService.loadUserProfile());

    this.oauthService.events
      .pipe(
        filter((e: any) =>
          ['session_terminated', 'session_error'].includes(e.type)
        )
      )
      .subscribe((e: any) => {
        this.navigateToLoginPage();
      });

    this.oauthService.events
      .pipe(filter((e: any) => ['silent_refresh_error'].includes(e.type)))
      .subscribe((e: any) => {
        this.logout();
      });

    this.oauthService.events
      .pipe(filter((event: any) => ['token_error'].includes(event.type)))
      .subscribe((event: any) => {
        // event.params will include the Auth0 error, it will look like below
        // {error: "unauthorized", error_description: "loginhash-mismatch", state: "8BkSmw7Ba3kArHuLjwFSIThqkbyWdWOLtbvgnosS;/home"}
        if (
          !this._initialSilentRefresh &&
          event.params &&
          event.params.error === 'unauthorized'
        ) {
          // only emit noAuth0Error false when the error is happening during a user triggered login,
          // i.e. not during initial silent refresh
          this.noAuth0ErrorSubject$.next(false);
        }
      });

    this.oauthService.setupAutomaticSilentRefresh();
  }

  public runInitialLoginSequence(): Promise<void> {
    // Useful for debugging
    // if (location.hash) {
    //   console.log('Encountered hash fragment, plotting as table...');
    //   console.table(
    //     location.hash
    //       .substr(1)
    //       .split('&')
    //       .map((kvp: string) => kvp.split('='))
    //   );
    // }

    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    return (
      this.oauthService
        .loadDiscoveryDocument()
        // 1. HASH LOGIN:
        // Try to log in via hash fragment after redirect back
        // from IdServer from initImplicitFlow:
        .then(() => this.oauthService.tryLogin())

        .then(() => {
          if (
            this.oauthService.hasValidAccessToken() &&
            this.oauthService.hasValidIdToken() &&
            this.oauthService.getIdentityClaims() !== null
          ) {
            return Promise.resolve();
          }

          // 2. SILENT LOGIN:
          // Try to log in via silent refresh because the IdServer
          // might have a cookie to remember the user, so we can
          // prevent doing a redirect:
          this._initialSilentRefresh = true;

          return this.oauthService
            .silentRefresh()
            .then(() => Promise.resolve())
            .catch((result: any) => {
              // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
              // Only the ones where it's reasonably sure that sending the
              // user to the IdServer will help.
              const errorResponsesRequiringUserInteraction: string[] = [
                'interaction_required',
                'login_required',
                'account_selection_required',
                'consent_required',
              ];

              if (
                result &&
                result.reason &&
                errorResponsesRequiringUserInteraction.indexOf(
                  result.reason.error
                ) >= 0
              ) {
                // 3. ASK FOR LOGIN:
                // At this point we know for sure that we have to ask the
                // user to log in, so we redirect them to the IdServer to
                // enter credentials.
                //
                // Enable this to ALWAYS force a user to login.
                this.navigateToLoginPage();
                //
                // Instead, we'll now do this:
                // console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
                this._initialSilentRefresh = false;

                return Promise.resolve();
              }

              // silentRefresh failed, tokens might still be valid for a while, only redirects user to error page
              // on first use of access token when it's expired.
              // We can't handle the truth, just pass on the problem to the
              // next handler.
              this._initialSilentRefresh = false;

              return Promise.reject(result);
            });
        })

        .then(() => {
          this.isDoneLoadingSubject$.next(true);

          // Check for the strings 'undefined' and 'null' just to be sure. Our current
          // login(...) should never have this, but in case someone ever calls
          // initImplicitFlow(undefined | null) this could happen.
          if (
            this.oauthService.state &&
            this.oauthService.state !== 'undefined' &&
            this.oauthService.state !== 'null'
          ) {
            // console.log(`There was state, so we are sending you to: ${this.oauthService.state}`);
            this.router.navigateByUrl(this.oauthService.state);
          }
        })
        .catch(() => this.isDoneLoadingSubject$.next(true))
    );
  }

  public login(targetUrl?: string): void {
    this.oauthService.initImplicitFlow(
      encodeURIComponent(targetUrl || this.router.url)
    );
  }

  public logout(noRedirectToLogoutUrl?: boolean): void {
    this.oauthService.logOut(noRedirectToLogoutUrl);
  }
  public refresh(): void {
    this.oauthService.silentRefresh();
  }

  public get accessToken(): string {
    if (this.oauthService.hasValidAccessToken()) {
      return this.oauthService.getAccessToken();
    }

    return undefined;
  }

  public getIdentityClaims(): Observable<any> {
    if (
      this.oauthService.hasValidAccessToken() &&
      this.oauthService.hasValidIdToken()
    ) {
      return from(
        this.oauthService
          .processIdToken(
            this.oauthService.getIdToken(),
            this.oauthService.getAccessToken()
          )
          .then((result: ParsedIdToken) => result.idTokenClaims)
      );
    }

    return of({});
  }

  public getIdToken(): Observable<string> {
    if (
      this.oauthService.hasValidAccessToken() &&
      this.oauthService.hasValidIdToken()
    ) {
      return from(
        this.oauthService
          .processIdToken(
            this.oauthService.getIdToken(),
            this.oauthService.getAccessToken()
          )
          .then((result: ParsedIdToken) => result.idToken)
      );
    }

    return of(undefined);
  }

  public get logoutUrl(): string {
    return this.oauthService.logoutUrl;
  }

  public useIntelliCloudAD(): void {
    const customQueryParams: any = this.oauthService.customQueryParams;
    customQueryParams.connection = this.appConfig.getConfig().auth.icConnection;
    this.oauthService.customQueryParams = customQueryParams;
  }

  public useCorpAD(): void {
    const customQueryParams: any = this.oauthService.customQueryParams;
    customQueryParams.connection = this.appConfig.getConfig().auth.corpConnection;
    this.oauthService.customQueryParams = customQueryParams;
  }

  public get preferCorp(): boolean {
    return this._preferCorp;
  }

  public set preferCorp(preferCorp: boolean) {
    this._preferCorp = preferCorp;
    this.oauthStorage.setItem('prefer_corp', preferCorp.toString());
  }

  public navigateToLoginPage(): void {
    // TODO: Remember current URL
    if (this._preferCorp) {
      this.router.navigate(['td/login']);
    } else {
      this.useIntelliCloudAD();
      this.login();
    }
  }
}
