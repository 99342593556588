import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppConfigService } from '@imc/core/services';
/**
 * Removes or recreates a portion of the DOM tree based on an feature flag.
 * (modeled after Angular's ngIf directive)
 *
 * If the feature assigned to `mcFeature` evaluates to a falsy value then the element
 * is removed from the DOM, otherwise a clone of the element is reinserted into the DOM.
 *
 *
 * ```
 * <div *mcFeature="FeatureFlags.test">
 *   Test feature is enabled
 * </div>
 * <div *mcDeprecateFeature="FeatureFlags.test">
 *   Test feature is not enabled
 * </div>
 * ```
 *
 * ### Syntax
 *
 * - `<div *mcFeature="FeatureFlags.test">...</div>`
 * - `<div template="mcFeature FeatureFlags.test">...</div>`
 * - `<ng-template [mcFeature]="FeatureFlags.test"><div>...</div></ng-template>`
 *
 * - `<div *mcDeprecateFeature="FeatureFlags.test">...</div>`
 * - `<div template="mcDeprecateFeature FeatureFlags.test">...</div>`
 * - `<ng-template [mcDeprecateFeature]="FeatureFlags.test"><div>...</div></ng-template>`
 *
 */
@Directive({
  selector: '[imcFeature]',
})
export class FeatureDirective {
  @Input('imcFeature') set feature(enabled: boolean) {
    let displayFeature: boolean = enabled;

    if (this.appConfig.getConfig().environment !== 'production') {
      displayFeature = true; // Feature will always be available on all non-prod environments.
    }

    if (displayFeature) {
      this.viewContainer.createEmbeddedView(this.template);
    } else {
      this.viewContainer.clear();
    }
  }

  constructor(
    private readonly viewContainer: ViewContainerRef,
    private readonly template: TemplateRef<any>,
    private readonly appConfig: AppConfigService
  ) {}
}
