import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'backup-retention-confirmation',
  templateUrl: './backupRetentionConfirmation.component.html',
  styleUrls: ['./backupRetentionConfirmation.component.scss'],
})
export class BackupRetentionConfirmationComponent {
  @Input() form: any;
  @Output() closeDialog = new EventEmitter();

  close() {
    this.closeDialog.emit(false);
  }

  confirm() {
    this.closeDialog.emit(true);
  }
}
