import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SiteHomepageComponent } from './site-homepage.component';

const routes: Routes = [
  {
    path: '',
    component: SiteHomepageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SiteHomepageRoutingModule {}
