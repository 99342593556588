import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatSort,
  MatTableDataSource,
} from '@angular/material';
import { Router } from '@angular/router';
import { TdMediaService } from '@covalent/core';
import { Observable, Subscription } from 'rxjs';

import { DocConfigService } from '@imc/core/services/doc-config/doc-config.service';
import { PolicyService } from '@imc/core/services/policy-service/policy.service';

import { AvailabilityService } from '../../services/availability.service';

export interface SlaReport {
  averageAvailability: number;
  lowestAvailability: number;
  siteId: string;
  reportId: string;
  reports: any;
}

const defaultNumberOfMonths: number = 14;

@Component({
  selector: 'imc-sla-reports',
  templateUrl: './sla-reports.component.html',
  styleUrls: ['./sla-reports.component.scss'],
})
export class SlaReportsComponent implements OnDestroy, OnInit {
  private getSLASubscription: Subscription;
  private policySubscription: Subscription;
  private readonly customerColumns: string[] = [
    'siteDisplayName',
    'cloudPlatform',
    'targetSla',
  ];
  private readonly sysOpsColumns: string[] = [
    'customerName',
    'siteDisplayName',
    'sitePurpose',
    'cloudPlatform',
    'targetSla',
    'averageAvailability',
    'lowestAvailability',
  ];

  @ViewChild('helpDialog') publishDialogTemplate: TemplateRef<any>;
  hasData: boolean = false;
  loading: boolean = true;
  slas: SlaReport[] = [];
  report: any;
  error: any;
  openDialog: MatDialogRef<any>;
  slaDoc: string;
  dynamicColumns: any[] = [];
  displayedColumns: string[];
  isCustomer: boolean = false;

  dataSource: MatTableDataSource<SlaReport>;

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (sort) {
      this.dataSource.sort = sort;
    }
  }

  @Input() months: number;

  constructor(
    private readonly router: Router,
    public media: TdMediaService,
    private readonly policyService: PolicyService,
    private readonly dataService: AvailabilityService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly docConfigService: DocConfigService,
    private readonly dialog: MatDialog
  ) {
    // tslint:disable-next-line:no-string-literal
    this.slaDoc = this.docConfigService.getDocConfig()['sla'];
  }

  public ngOnInit(): void {
    this.policySubscription = this.policyService.isCustomer$.subscribe(
      (isCustomer: boolean) => {
        this.isCustomer = isCustomer;
        const staticColumns: string[] = isCustomer
          ? this.customerColumns
          : this.sysOpsColumns;
        const months: number = this.months || defaultNumberOfMonths;
        this.setupDynamicColumns(months, staticColumns, isCustomer);
        this.getSLASubscription = this.getSLAReports(months, isCustomer);
      }
    );
  }

  public ngOnDestroy(): void {
    if (this.getSLASubscription) {
      this.getSLASubscription.unsubscribe();
    }

    if (this.policySubscription) {
      this.policySubscription.unsubscribe();
    }
  }

  public navigate(path: string, id: string): void {
    this.router.navigate([path + id]);
  }

  public viewReport(siteId: string, month: string): void {
    const siteAvailabilities: any = this.slas.find(
      (s: SlaReport) => s.siteId === siteId
    );
    this.report =
      siteAvailabilities &&
      siteAvailabilities.reports &&
      siteAvailabilities.reports[month];
  }

  public getPreviewPublishLabel(): string {
    if (this.report && this.report.published === 'true') {
      return 'Published';
    } else {
      return 'Preview';
    }
  }

  public getToolTip(siteId: string, month: string): string {
    const siteAvailabilities: any = this.getSlaReportForSite(siteId);
    const report: any =
      siteAvailabilities &&
      siteAvailabilities.reports &&
      siteAvailabilities.reports[month];
    if (!report) {
      return 'Unpublished report, click for details.';
    }
    const slaMet: boolean = isSLAMet(
      report.targetSLA,
      report.availabilityPercentage
    );
    const published: boolean = this.isCustomer || report.published === 'true';

    return `${published ? 'Published' : 'Unpublished'} report ${
      !slaMet ? '(SLA not met)' : ''
    }, click for details.`;
  }

  public getStyle(siteId: string, month: string, style: string): string {
    const siteAvailabilities: any = this.getSlaReportForSite(siteId);
    const report: any =
      siteAvailabilities &&
      siteAvailabilities.reports &&
      siteAvailabilities.reports[month];
    if (!report) {
      return '';
    }
    switch (style) {
      case 'border-bottom':
        if (!this.isCustomer) {
          const slaMet: boolean = isSLAMet(
            report.targetSLA || siteAvailabilities.currentSiteTargetSla,
            report.availabilityPercentage
          );
          if (!slaMet) {
            return '2px solid red';
          }
        }

        break;
      case 'color':
        const published: boolean =
          this.isCustomer || report.published === 'true';
        if (published) {
          return 'black';
        } else {
          return 'grey';
        }
      default:
        break;
    }

    return '';
  }

  public applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public millisecondsToHMmSs(timeMilliseconds: number): string {
    if (
      timeMilliseconds === null ||
      timeMilliseconds === undefined ||
      timeMilliseconds <= 0
    ) {
      return '0:00:00';
    }
    const timeInSec: number = Math.floor(timeMilliseconds / 1000) % 60;
    const timeInMin: number = Math.floor(timeMilliseconds / 60 / 1000) % 60;
    const timeInHr: number = Math.floor(timeMilliseconds / 3600 / 1000);

    return `${timeInHr}:${`0${timeInMin}`.slice(-2)}:${`0${timeInSec}`.slice(
      -2
    )}`;
  }

  public onPublished(report: any): void {
    if (
      report &&
      report.published === 'true' &&
      report.reportId === this.report.reportId
    ) {
      this.report.published = report.published;
      this.report.publishedDate = report.publishedDate;
      this.report.publisherUserId = report.publisherUserId;
      this.changeDetectorRef.detectChanges();
    }
  }

  openHelpDialog(): void {
    this.openDialog = this.dialog.open(this.publishDialogTemplate);
  }

  closeHelpDialog(): void {
    this.openDialog.close();
  }

  private getSlaReportForSite(siteId: string): any {
    return this.slas.find((s: SlaReport) => s.siteId === siteId);
  }

  private setupDynamicColumns(
    months: number,
    staticColumns: string[],
    isCustomer: boolean
  ): void {
    const now: Date = new Date();
    const ima: Date = new Date(now.getUTCFullYear(), now.getUTCMonth(), 1);
    let monthsToFetch: number = months;
    if (isCustomer) {
      subtractOneMonth(ima);
      monthsToFetch--;
    }

    for (let i: number = 0; i < monthsToFetch; i++) {
      const reportMonth: string = ima.toISOString().substr(0, 7);
      this.dynamicColumns.push({
        columnDef: reportMonth,
        header: reportMonth,
        cell: (report: SlaReport) => {
          if (report.reports[reportMonth]) {
            return report.reports[reportMonth].availability;
          } else {
            return undefined;
          }
        },
      });

      ima.setUTCMonth(ima.getUTCMonth() - 1);
    }

    this.displayedColumns = [
      ...staticColumns,
      ...this.dynamicColumns.map((x: any) => x.columnDef),
    ];
  }

  private getSLAReports(months: number, isCustomer: boolean): Subscription {
    const observable: Observable<any> = isCustomer
      ? this.dataService.getSlasForCustomer(months)
      : this.dataService.getSLAs(months);

    return observable.subscribe(
      (slas: SlaReport[]) => {
        this.slas = this.isCustomer ? slas : this.slas.concat(slas);
      },
      (error: any) => {
        this.error = 'Error fetching customer list';
      },
      () => {
        this.dataSource = new MatTableDataSource(this.slas);
        this.dataSource.sortingDataAccessor = (
          data: SlaReport,
          sortHeaderId: string
        ) => sorter(data, sortHeaderId);
        this.hasData = Array.isArray(this.dataSource.data);
        this.changeDetectorRef.detectChanges();
        this.loading = false;
      }
    );
  }
}

const isSLAMet: Function = (
  targetSLA: number,
  availabilityPercentage: number
): boolean => {
  if (!targetSLA) {
    return true;
  }

  return availabilityPercentage >= targetSLA;
};

const sorter: Function = (item: any, property: string): string | number => {
  switch (property) {
    case 'customerName': {
      return item.customerName;
    }
    case 'siteDisplayName': {
      return item.siteDisplayName;
    }
    case 'cloudPlatform': {
      return item.cloudPlatform;
    }
    case 'targetSla': {
      return item.currentSiteTargetSla ? item.currentSiteTargetSla : 0;
    }
    case 'sitePurpose': {
      return item.sitePurpose ? item.sitePurpose : '';
    }
    case 'averageAvailability': {
      return item.averageAvailability ? item.averageAvailability : 0;
    }
    case 'lowestAvailability': {
      return item.lowestAvailability ? item.lowestAvailability : 0;
    }
    default: {
      return item.reports &&
        item.reports[property] &&
        item.reports[property].availabilityPercentage
        ? item.reports[property].availabilityPercentage
        : 0;
    }
  }
};

const subtractOneMonth: Function = (d: Date): void => {
  if (d.getMonth() === 0) {
    d.setFullYear(d.getFullYear() - 1);
    d.setMonth(11);
  } else {
    d.setMonth(d.getMonth() - 1);
  }
};
