import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TdMediaService } from '@covalent/core';
import { TdLoadingService } from '@covalent/core/loading';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { BaasPageViewModel } from '../models/site.model';
import { SiteService } from '../services/site.service';

@Component({
  selector: 'imc-legacy-baas-page',
  templateUrl: './legacy-baas.component.html',
  styleUrls: ['./legacy-baas.component.scss'],
})
export class LegacyBaasComponent implements OnInit {
  error: boolean = false;
  viewModel$: Observable<BaasPageViewModel>;
  loading: boolean = true;

  constructor(
    public media: TdMediaService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly siteService: SiteService,
    private readonly loadingService: TdLoadingService
  ) {}

  ngOnInit(): void {
    this.loadingService.register('loading');
    this.viewModel$ = this.route.params.pipe(
      switchMap((params: any) =>
        this.siteService.createBaasPageViewModel(params.siteId).pipe(
          map((vm: BaasPageViewModel) => {
            this.loading = false;

            return vm;
          }),
          catchError((err: any, caught: Observable<BaasPageViewModel>) => {
            this.error = true;
            this.loading = false;

            return caught;
          })
        )
      )
    );
  }

  changeSite(siteId: string): void {
    this.router.navigate([`sites/${siteId}/legacy-baas`]);
  }
}
