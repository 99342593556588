import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material';
import { PolicyUserRole } from '@imc/core/models/roles.enum';
import { Site } from '@imc/core/models/sites.model';
import { UserRoleInfo } from '@imc/core/models/userRoleInfo.model';
import { PolicyService } from '@imc/core/services/policy-service/policy.service';
import { Subscription } from 'rxjs';
import {
  LambdaInvocationType,
  MetricsAgentProxyRequest,
  MetricsAgentProxyResponse,
  ProxyFunctionName,
  ProxyFunctionResponseData,
  SiteRegistrationDetails,
} from '../../models/metrics-agent-proxy.model';
import { MetricsAgentProxy } from '../../services/metrics-agent-proxy/metrics-agent-proxy.service';
import { SiteService } from '../../services/site-service/ecosystems-site.service';

@Component({
  selector: 'imc-metrics-tool',
  templateUrl: './metrics-tool.component.html',
  styleUrls: ['./metrics-tool.component.scss'],
})
export class MetricsToolComponent implements OnChanges, OnDestroy {
  site: Site;
  @Input() siteId: string = undefined;
  metricsRegData: SiteRegistrationDetails;

  siteRegStatus: string = undefined;
  reRegister: boolean = false;

  unRegister: boolean = false;
  unRegisterProgress: string = undefined;
  unRegisterErrorMessage: string = undefined;

  infoTab: boolean = true;
  regTab: boolean = false;
  networkTab: boolean = false;
  selectedTab: any;

  // Manage site registration
  networkTesterForm: FormGroup = undefined;
  networkTesterWaiting: boolean = false;
  networkTesterResult: string = undefined;
  networkTesterStatus: boolean = false;
  networkTesterBackendError: boolean = false;

  isSysOpsUser: boolean = false;

  subscriptionObjects: Subscription[] = [];
  constructor(
    private readonly metricsAgentProxy: MetricsAgentProxy,
    private readonly policyService: PolicyService,
    private readonly siteService: SiteService
  ) {}

  ngOnChanges(): void {
    this.getSiteInfo();
    this.getSiteRegistrationDetails();

    // Resolve if the current user is SysOps or not
    this.subscriptionObjects.push(
      this.policyService.userRoleInfo$.subscribe((role: UserRoleInfo) => {
        if (
          role.currentRole === PolicyUserRole.FullSysops ||
          role.currentRole === PolicyUserRole.ReadOnlySysops ||
          role.currentRole === PolicyUserRole.ReadOnlyLimitedSysops
        ) {
          this.isSysOpsUser = true;
        } else {
          this.isSysOpsUser = false;
        }
      })
    );

    // Manage network tester
    this.networkTesterWaiting = false;
    this.networkTesterResult = undefined;
    this.networkTesterStatus = false;
    this.networkTesterBackendError = false;

    if (this.networkTesterForm) {
      this.networkTesterForm.reset();
    }

    this.siteRegStatus = undefined;
    this.reRegister = false;
  }

  getSiteInfo(): void {
    this.subscriptionObjects.push(
      this.siteService
        .getSiteInfo({ siteId: this.siteId })
        .subscribe((val: Site) => {
          this.site = val;
        })
    );
  }

  ngOnDestroy(): void {
    if (this.subscriptionObjects) {
      for (const entry of this.subscriptionObjects) {
        if (entry) {
          entry.unsubscribe();
        }
      }
    }
  }

  public onNotify(val: boolean): void {
    this.getSiteRegistrationDetails();
    this.reRegister = false;
  }

  confirm_reRegister(): void {
    this.reRegister = true;
  }

  confirm_unRegister(): void {
    this.unRegister = true;
    this.unRegisterProgress = undefined;
    this.unRegisterProgress = undefined;
  }

  cancel_unRegister(): void {
    this.unRegister = false;
  }

  unRegister_complete(): void {
    this.unRegisterProgress = 'in-progress';
    this.unRegisterErrorMessage = undefined;

    const regEvent: MetricsAgentProxyRequest = {
      function: {
        name: ProxyFunctionName.site_un_registration,
        invocation_type: LambdaInvocationType.RequestResponse,
      },
      payload: {
        site_id: this.siteId,
      },
    };

    const unregisterSubscription: Subscription = this.metricsAgentProxy
      .invokeFunction(regEvent)
      .subscribe((getData: MetricsAgentProxyResponse) => {
        if (getData.status) {
          const siteRegResponse: ProxyFunctionResponseData = getData.data;
          if (siteRegResponse.function_return_status) {
            this.unRegisterProgress = 'complete-success';
            if (typeof siteRegResponse.function_return_status === 'undefined') {
              this.unRegisterProgress = 'complete-error';
              this.unRegisterErrorMessage = 'Error: Call failed';
            }

            if (
              siteRegResponse &&
              typeof siteRegResponse.function_return_status === 'undefined'
            ) {
              this.unRegisterProgress = 'complete-error';
              this.unRegisterErrorMessage = JSON.stringify(siteRegResponse);
            }
          } else {
            this.unRegisterProgress = 'complete-error';
            this.unRegisterErrorMessage = JSON.stringify(
              siteRegResponse.function_message
            );
          }
        } else {
          this.unRegisterProgress = 'complete-error';
          this.unRegisterErrorMessage = JSON.stringify(getData.message);
        }
      });

    this.subscriptionObjects.push(unregisterSubscription);
  }

  tabSelect(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.infoTab = true;
      this.regTab = false;
      this.networkTab = false;
      this.selectedTab = 0;
    } else if (event.index === 1) {
      this.infoTab = false;
      this.regTab = true;
      this.networkTab = false;
      this.selectedTab = 1;
    } else if (event.index === 2) {
      this.infoTab = false;
      this.regTab = false;
      this.networkTab = true;
      this.selectedTab = 2;
    }
  }

  tabSelectRegister(): void {
    this.selectedTab = 1;
    const event: MatTabChangeEvent = new MatTabChangeEvent();
    event.index = 1;
    this.tabSelect(event);
  }

  getSiteRegistrationDetails(): void {
    this.siteRegStatus = undefined;
    const getParam: any = {
      function: {
        name: 'get_site_registration_details',
        invocation_type: 'RequestResponse',
      },
      payload: {
        site_id: this.siteId,
      },
    };
    this.metricsRegData = undefined;

    this.subscriptionObjects.push(
      this.metricsAgentProxy
        .invokeFunction(getParam)
        .subscribe((getData: any) => {
          if (getData.status) {
            const res: ProxyFunctionResponseData = getData.data;
            if (res.function_return_status && res.function_data) {
              const x: any = res.function_data;
              this.metricsRegData = x;

              this.siteRegStatus = this.metricsRegData.regStatus;
            }
          }
        })
    );
  }

  submit_network_tester(form: FormGroup): void {
    this.networkTesterForm = form;
    this.networkTesterResult = undefined;
    this.networkTesterWaiting = true;
    if (form.valid) {
      const vpIpAddress: string = form.value.vpIpAddress;
      const portNumber: string = form.value.portNumber;

      this.networkTester(vpIpAddress, portNumber);
    }
  }

  networkTester(vpIpAddress: string, portNumber: string): void {
    this.networkTesterWaiting = true;
    this.networkTesterResult = undefined;
    this.networkTesterBackendError = false;

    const regEvent: MetricsAgentProxyRequest = {
      function: {
        name: ProxyFunctionName.network_tester,
        invocation_type: LambdaInvocationType.RequestResponse,
      },
      payload: {
        host: vpIpAddress,
        port: portNumber,
      },
    };

    const networkTesterSubscription: Subscription = this.metricsAgentProxy
      .invokeFunction(regEvent)
      .subscribe((getData: any) => {
        if (getData.status) {
          const networkTesterResponse: ProxyFunctionResponseData = getData.data;

          this.networkTesterWaiting = false;

          if (networkTesterResponse) {
            this.networkTesterStatus =
              networkTesterResponse.function_return_status;
            this.networkTesterResult = networkTesterResponse.function_message;
          }
          if (
            typeof networkTesterResponse.function_return_status === 'undefined'
          ) {
            this.networkTesterBackendError = true;
            this.networkTesterStatus = false;
          }
        } else {
          const networkTesterResponse: ProxyFunctionResponseData = getData.data;
          this.networkTesterResult = JSON.stringify(
            networkTesterResponse.function_message
          );
          this.networkTesterWaiting = false;
          this.networkTesterStatus = false;
        }
      });

    this.subscriptionObjects.push(networkTesterSubscription);
  }
}
