import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { Site } from '@imc/core/models/sites.model';
import { AwsInstanceInfo } from '../../models/aws-instance-info.model';
import { AwsInstanceInfoService } from '../../services/aws-instance-info-service/aws-instance-info.service';

@Component({
  selector: 'imc-aws-instance-info',
  templateUrl: './aws-instance-info.component.html',
  styleUrls: ['./aws-instance-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AwsInstanceInfoComponent implements OnChanges {
  @Input() site: Site;
  @Input() loading: boolean = true;
  @Input() error: any;
  loaderKey: string = 'awsInstanceInfoLoader';
  subtitle: string = '';
  instanceInfo: AwsInstanceInfo;

  constructor(
    private readonly _awsInstanceInfoService: AwsInstanceInfoService
  ) {}

  ngOnChanges(): void {
    if (this.site !== undefined) {
      this.subtitle = `Instance info on ${this.site.displayName}`;
      if (this.site.sqleApp) {
        this.instanceInfo = this._awsInstanceInfoService.getInstanceInfo(
          this.site.sqleApp.instanceSize
        );
      }
    }
  }
}
