import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OAuthenticationService } from '../services';

@Injectable()
export class AuthenticatedUserGuard implements CanActivate {
  private noAuth0Error: boolean;

  constructor(
    private readonly authService: OAuthenticationService,
    private router: Router
  ) {
    this.authService.noAuth0Error$.subscribe(
      (i: boolean) => (this.noAuth0Error = i)
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.canActivateProtectedRoutes$.pipe(
      tap((canActivateProtectedRoutes: boolean) => {
        if (!canActivateProtectedRoutes) {
          localStorage.setItem('redirect_url', state.url);
          if (this.noAuth0Error) {
            if (this.authService.preferCorp) {
              this.router.navigate(['td/login']);
            } else {
              this.authService.useIntelliCloudAD();
              this.authService.login(state.url);
            }
          } else {
            // Auth0 failed to log in the user, redirect to error page to show the error
            // preseving both the query params and hash fragment
            this.router.navigate(['error'], {
              queryParams: next.queryParams,
              fragment: next.fragment,
            });
          }
        }
      })
    );
  }
}
