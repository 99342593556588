import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AppConfigService } from '@imc/core/services';

import { FormBuilderService } from '../../services/formBuilder.service';
import {
  ECOSYSTEM_OFFER,
  ECOSYSTEM_OFFER_FEATURES,
  RAW_STORAGE,
} from './parameters';
import {
  ADVANCED_TIER_FEATURES,
  BASE_TIER_FEATURES,
  ENTERPRISE_TIER_FEATURES,
} from './productFeatures';

@Component({
  selector: 'imc-aws-offer-select-step',
  templateUrl: './offerSelectStep.component.html',
  styleUrls: ['./offerSelectStep.component.scss'],
})
export class OfferSelectStepComponent {
  private readonly data: any = {
    params: [],
    features: [],
    region: null,
    dbVersion: null,
    tier: null,
    offering: null,
  };

  @Input() offers: any[];
  @Input() regionsWithEKS: string[] = [];

  @Output() nextStep: EventEmitter<any> = new EventEmitter();
  @Output() resetFormParams: EventEmitter<any> = new EventEmitter();

  minStoragePerNode: any;
  config: any;
  formParameters;
  disableForm = false;

  parameters = [];
  regions: any[];
  versions: any[];
  tiers: any[];
  tierFeatures;
  regionControl = new FormControl('', [Validators.required]);
  offerControl = new FormControl('', [Validators.required]);
  versionControl = new FormControl('', [Validators.required]);
  tierControl = new FormControl('', [Validators.required]);

  offerForm: FormGroup = new FormGroup({
    region: this.regionControl,
    offer: this.offerControl,
    version: this.versionControl,
    tier: this.tierControl,
  });

  constructor(
    private formBuilder: FormBuilderService,
    private readonly appConfig: AppConfigService
  ) {
    this.minStoragePerNode = this.appConfig.getConfig().provisioning.aws.minStoragePerNode;
    this.tiers = ['Advanced', 'Enterprise', 'Base'];
    this.regions = [
      'us-east-1',
      'us-east-2',
      'us-west-1',
      'us-west-2',
      'ap-northeast-1',
      'ap-southeast-2',
      'ca-central-1',
      'eu-central-1',
      'eu-west-1',
      'eu-west-2',
    ];

    this.versions = ['16.20'];
  }

  onOfferSelect($event) {
    RAW_STORAGE.minValue = this.minStoragePerNode;
    const params = ECOSYSTEM_OFFER.slice(0);
    const features = ECOSYSTEM_OFFER_FEATURES.slice(0);
    this.data.params = this.formBuilder.buildFormParameters(params, 100);
    this.data.features = this.formBuilder.buildFormParameters(features, 100);
  }

  onTierSelect($event) {
    this.tierFeatures = null;
    const selectedTier: string = this.offerForm.get('tier').value;

    if (selectedTier.toLowerCase() === 'advanced') {
      this.tierFeatures = ADVANCED_TIER_FEATURES;
    }

    if (selectedTier.toLowerCase() === 'enterprise') {
      this.tierFeatures = ENTERPRISE_TIER_FEATURES;
    }

    if (selectedTier.toLowerCase() === 'base') {
      this.tierFeatures = BASE_TIER_FEATURES;
    }
  }

  resetValues() {
    this.formParameters = [];
    this.offerForm.get('offer').setValue(null);
    this.offerForm.get('tier').setValue(null);
    this.offerForm.get('region').setValue(null);
    this.offerForm.get('version').setValue(null);
    this.tierFeatures = null;
    this.resetFormParams.emit();
  }

  goToNextStep() {
    this.data.dbVersion = this.offerForm.get('version').value;
    this.data.region = this.offerForm.get('region').value;
    this.data.tier = this.offerForm.get('tier').value;
    this.data.offering = this.offerForm.get('offer').value;
    this.nextStep.emit(this.data);

    const params = ECOSYSTEM_OFFER.slice(0);
    let features = ECOSYSTEM_OFFER_FEATURES.slice(0);

    // intelligent memory not available in Base
    if (this.data.tier.toLowerCase() === 'base') {
      features = features.filter((i) => i.id !== 'tim_percent');
    }

    this.data.params = this.formBuilder.buildFormParameters(params, 100);
    this.data.features = this.formBuilder.buildFormParameters(features, 100);
  }
}
