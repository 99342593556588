import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { UserRole } from '@imc/core/models/role.model';
import { UserRoleInfo } from '@imc/core/models/userRoleInfo.model';
import { PolicyService } from '@imc/core/services/policy-service/policy.service';
import {
  PolicyUserRolesDetails,
  PolicyUserRole,
} from '@imc/core/models/roles.enum';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HomeUri } from '@imc/core/constants/url';
import { MatDialogRef, MatDialog } from '@angular/material';
import { DocConfigService } from '@imc/core/services/doc-config/doc-config.service';

@Component({
  selector: 'imc-assume-role',
  templateUrl: './assume-role.component.html',
  styleUrls: ['./assume-role.component.scss'],
})
export class AssumeRoleComponent implements OnInit, OnDestroy {
  @ViewChild('helpDialog') publishDialogTemplate: TemplateRef<any>;
  private userRoleInfoSubscription: Subscription;
  openDialog: MatDialogRef<any>;
  private postAssumeRoleSubscription: Subscription;
  private selectedRole: UserRole;
  private roles: UserRole[];
  private userRoleInfo: UserRoleInfo;
  public currentRole: UserRole;
  public isDataLoading: boolean = true;
  public isRoleChangeAvailable: boolean = false;
  public hasAssumeRoleError: boolean = false;
  public assumeRoleDoc: string;

  constructor(
    private readonly dialog: MatDialog,
    private policyService: PolicyService,
    private router: Router,
    private docConfigService: DocConfigService
  ) {
    this.roles = [];
    this.assumeRoleDoc = this.docConfigService.getDocConfig()['assume_role'];
  }

  ngOnInit(): void {
    this.initiateData();
  }

  ngOnDestroy(): void {
    if (this.userRoleInfoSubscription) {
      this.userRoleInfoSubscription.unsubscribe();
    }
    if (this.postAssumeRoleSubscription) {
      this.postAssumeRoleSubscription.unsubscribe();
    }
  }

  initiateData(): void {
    this.userRoleInfoSubscription = this.policyService.userRoleInfo$.subscribe(
      (userRoleInfo: UserRoleInfo) => {
        this.userRoleInfo = userRoleInfo;
        this.currentRole = PolicyUserRolesDetails.find(
          (role) => role.policyUserRole === userRoleInfo.currentRole
        );
        if (userRoleInfo.allowedRoles) {
          this.roles = PolicyUserRolesDetails.filter(
            (role) => role.policyUserRole !== userRoleInfo.currentRole
          ).filter((i) =>
            userRoleInfo.allowedRoles.some((j) => j === i.policyUserRole)
          );
        }
        if (this.roles.length > 0) {
          this.isRoleChangeAvailable = true;
        }
        this.isDataLoading = false;
      }
    );
  }

  closePage(): void {
    this.router.navigate([HomeUri]);
  }

  openHelpDialog(): void {
    this.openDialog = this.dialog.open(this.publishDialogTemplate);
  }

  closeHelpDialog(): void {
    this.openDialog.close();
  }

  private applyRoleChange(): void {
    const rawRolesData: string[] = this.userRoleInfo.rawRolesData;
    const policyUserRole: PolicyUserRole = this.selectedRole.policyUserRole;
    const pattern: string = `${this.userRoleInfo.separator}${policyUserRole}`;
    const count: number = rawRolesData.length;
    let fullRole: string = '';
    for (let i = 0; i < count; i++) {
      if (rawRolesData[i].endsWith(pattern)) {
        fullRole = rawRolesData[i];
      }
    }
    this.postAssumeRoleSubscription = this.policyService
      .postChangeRole(fullRole)
      .subscribe(
        () => {
          this.closePage();
        },
        (error) => {
          this.hasAssumeRoleError = true;
        }
      );
  }
}
