import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TdMediaService, TdTruncatePipe } from '@covalent/core';
import { CovalentBarEchartsModule } from '@covalent/echarts/bar';
import { CovalentBaseEchartsModule } from '@covalent/echarts/base';
import { CovalentScatterEchartsModule } from '@covalent/echarts/scatter';
import { CovalentTooltipEchartsModule } from '@covalent/echarts/tooltip';
import { CovalentTreeEchartsModule } from '@covalent/echarts/tree';

import { EcosystemsModule } from '@imc-features/ecosystems/ecosystems.module';
import { SiteHomepageModule } from '@imc-features/site-homepage';
import { CoreModule, ImcMaterialModule } from '@imc/core';
import { SiteService } from '@imc/core/services/site-service/site.service';
import { SharedModule } from '@imc/shared/shared.module';

import { CustomerDatabaseSitesComponent } from './cards/customer-database-sites/customer-database-sites.component';
import { CustomerComponent } from './cards/customer/customer.component';
import { DbInstanceHoursComponent } from './cards/db-instance-hours/db-instance-hours.component';
import { GeoDistributionComponent } from './cards/geo-distribution/geo-distribution.component';
import { IntellicloudOverviewComponent } from './cards/intellicloud-overview/intellicloud-overview.component';
import { LowestAvailabilityComponent } from './cards/lowest-availability/lowest-availability.component';
import { MostTicketsComponent } from './cards/most-tickets/most-tickets.component';
import { OnboardingComponent } from './cards/onboarding/onboarding.component';
import { RecentTicketsComponent } from './cards/recent-tickets/recent-tickets.component';
import { SystemUsageComponent } from './cards/system-usage/system-usage.component';
import { UsersComponent } from './cards/users/users.component';
import { CustomerDashboardComponent } from './pages/customer-dashboard/customer-dashboard.component';
import { IntellicloudSystemDetailsComponent } from './pages/intellicloud-system-details/intellicloud-system-details.component';
import { ListCustomersComponent } from './pages/list-customers/list-customers.component';
import { SiteLandingComponent } from './pages/site-landing/site-landing.component';
import { SystemDashboardComponent } from './pages/system-dashboard/system-dashboard.component';
import { DashboardCustomerService } from './services/dashboard-customer.service';
import { DashboardMeteringService } from './services/dashboard-metering.service';
import { DashboardTicketsService } from './services/dashboard-tickets.service';
import { DashboardUserService } from './services/dashboard-user.service';
import { SystemDashboardService } from './services/system-dashboard.service';
import { SystemUsageService } from './services/system-usage.service';

@NgModule({
  declarations: [
    CustomerDashboardComponent,
    UsersComponent,
    SystemDashboardComponent,
    LowestAvailabilityComponent,
    RecentTicketsComponent,
    CustomerComponent,
    OnboardingComponent,
    MostTicketsComponent,
    DbInstanceHoursComponent,
    CustomerDatabaseSitesComponent,
    SystemUsageComponent,
    IntellicloudOverviewComponent,
    IntellicloudSystemDetailsComponent,
    ListCustomersComponent,
    GeoDistributionComponent,
    SiteLandingComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ImcMaterialModule,
    CovalentBaseEchartsModule,
    CovalentBarEchartsModule,
    CovalentTooltipEchartsModule,
    CovalentScatterEchartsModule,
    SharedModule,
    CovalentTreeEchartsModule,
    FormsModule,
    ReactiveFormsModule,
    EcosystemsModule,
    SiteHomepageModule,
    SharedModule,
    CoreModule,
  ],
  exports: [],
  providers: [
    TdTruncatePipe,
    TdMediaService,
    DashboardCustomerService,
    DashboardUserService,
    SystemDashboardService,
    DashboardTicketsService,
    SystemUsageService,
    DashboardMeteringService,
    SiteService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DashboardModule {}
