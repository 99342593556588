import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { NotifyModel } from '../../models/notify.model';
import {
  NotificationPollerService,
  NotificationsAlert,
} from '../../services/notification-poller.service';

@Component({
  selector: 'imc-notify-bell',
  templateUrl: './notify-bell.component.html',
  styleUrls: ['./notify-bell.component.scss'],
})
export class NotifyBellComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  notifications: NotifyModel[] = [];
  notificationsCount: number = 0;

  constructor(
    private readonly notifcationPoller: NotificationPollerService,
    private readonly _router: Router,
    private readonly route: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.subscription = this.notifcationPoller.newNotifications.subscribe(
      (notifyAlert: NotificationsAlert) => {
        this.processNotifications(notifyAlert.notifications);
      }
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  processNotifications(notifications: NotifyModel[]): void {
    if (notifications.length > this.notificationsCount) {
      this.notificationsCount = notifications.length;
      this.snackBar.open('You have new notifications', '', {
        duration: 5000,
      });
    }

    this.notifications = notifications;
    this.notificationsCount = notifications.length;
  }

  viewNotificationDetails(notification?: NotifyModel): void {
    if (notification) {
      this._router.navigate(['notifications', notification.notificationId]);
    } else {
      this._router.navigate(['notifications']);
    }
  }
}
