import { Injectable } from '@angular/core';

import { AppConfigService } from '@imc/core/services';

@Injectable()
export class LegacyCorrelationObjectService {
  constructor(private readonly appConfig: AppConfigService) {}

  public createDeprecatedCorrelationObject(): string {
    // TODO: change this when the environment can be determined dynamically
    const identityClaimsStr = window.localStorage.getItem(
      'id_token_claims_obj'
    );
    const key = `${this.appConfig.getConfig().auth.namespace}userId`;
    const userId = identityClaimsStr ? JSON.parse(identityClaimsStr)[key] : '';
    let userSessionId = window.sessionStorage.getItem('sessionId');
    userSessionId = userSessionId
      ? userSessionId
      : this.generateRandomLikeUuid(); // needed for local testing.
    const correlationObject = {
      activityId: this.generateRandomLikeUuid(),
      context: 'console-client',
      environment: this.appConfig.getConfig().environment,
      userId,
      userSessionId,
    };

    return JSON.stringify(correlationObject);
  }

  private generateRandomLikeUuid(): string {
    const now = performance.now();
    const timeVal = performance.timeOrigin + performance.now();
    const uuid8 = this.hashCode(timeVal.toString(16))
      .toString(16)
      .padStart(8, '0');
    const twoUuid4 = this.hashCode(now.toString(16))
      .toString(16)
      .padStart(8, '1');
    const uuid16 = `${timeVal.toString(16).replace('.', '3')}b`;
    const uuid41 = twoUuid4.substring(0, 4);
    const uuid42 = twoUuid4.substring(4, 8);
    const uuid43 = uuid16.substring(0, 4);
    const uuid12 = uuid16.substring(4, 16);
    return `${uuid8}-${uuid41}-${uuid42}-${uuid43}-${uuid12}`;
  }

  private hashCode(s: string): number {
    let hash = 0;
    const l = s.length;
    let i = 0;
    if (l > 0) {
      while (i < l) {
        hash = ((hash << 5) - hash + s.charCodeAt(i++)) | 0;
      }
    }

    return Math.abs(hash);
  }
}
