import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { Customer } from '@imc-features/ecosystems/_modules/ecosystems-shared/models/customer.model';
import { SiteService } from '@imc-features/ecosystems/_modules/ecosystems-shared/services/site-service/ecosystems-site.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
@Component({
  selector: 'imc-azure-customers',
  templateUrl: './azure-customers.component.html',
  styleUrls: ['./azure-customers.component.scss'],
})
export class AzureCustomersComponent implements OnInit, OnDestroy {
  private customersSubscription: Subscription;
  hasData: boolean = false;
  loading: boolean = true;
  loaderKey: string = 'azureCustomersLoader';
  error: boolean = false;
  columns: string[] = ['name', 'cloudId', 'customerOnboard'];
  dataSource: MatTableDataSource<Customer>;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private readonly siteService: SiteService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.customersSubscription = this.siteService
      .listCustomersByPlatform('azure')
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        (customers: Customer[]) => {
          this.dataSource = new MatTableDataSource(customers);
          this.dataSource.sort = this.sort;
          this.hasData = true;
        },
        (error: any) => {
          this.error = true;
        }
      );
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy(): void {
    if (this.customersSubscription) {
      this.customersSubscription.unsubscribe();
    }
  }

  showCustomerDashboard(customerId: string): void {
    // tslint:disable-next-line:no-floating-promises
    this.router.navigate([`customer-dashboard/${customerId}`]);
  }
}
