import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DbState,
  DbStatusStrings,
} from '@imc-features/ecosystems/_modules/ecosystems-shared/constants/db-state.constants';
import { Site } from '@imc/core/models/sites.model';
import { Subscription } from 'rxjs';
import { AzureInstanceInfo } from '../../models/azure-instance-info.model';
import { AzureInstanceInfoService } from '../../services/azure-instance-info-service/azure-instance-info.service';
import { AzureService } from '../../services/azure.service';
import { CLOUD_PLATFORM_INSTANCE_INFO } from '../../constants/site-instance-info.constants';
import { GoogleAnalyticsService } from '@imc/core/services/google-analytics/google-analytics.service';

@Component({
  selector: 'imc-azure-scale-up-down-database',
  templateUrl: './scale-up-down-database.component.html',
  styleUrls: ['./scale-up-down-database.component.scss'],
})
export class ScaleUpDownDatabaseComponent implements OnInit, OnDestroy {
  private dbStatusStrings: any = DbStatusStrings;
  private azureSubscription: Subscription;
  @Input() siteInfo: Site;
  dbStateOptions: any = DbState;
  failedOperation: boolean = false;
  displayStatus: boolean = false;
  dbState: string = '';
  dbMessage: string = '';
  availableInstanceSizes: string[] = [];
  availableInstanceInfoData: AzureInstanceInfo[] = [];
  disableButton: boolean = false;
  selectedInstanceInfo: AzureInstanceInfo;
  toBeDisabledInstanceInfo: AzureInstanceInfo;

  analyticsEvent: any = {
    azureScaleUpDown: {},
  };

  constructor(
    private readonly analyticsService: GoogleAnalyticsService,
    private formBuilder: FormBuilder,
    private readonly azureService: AzureService,
    private readonly azureInstanceInfoService: AzureInstanceInfoService
  ) {}

  ngOnInit(): void {
    if (this.siteInfo.sqleApp) {
      this.getDbInfo();
      this.availableInstanceSizes = this.azureInstanceInfoService.getAvailableInstanceSizes(
        this.siteInfo
      );
      this.availableInstanceInfoData = this.azureInstanceInfoService.getAvailableInstancesInfo(
        this.siteInfo
      );

      this.toBeDisabledInstanceInfo = this.azureService.getDbVmConfig(
        this.siteInfo,
        this.siteInfo.sqleApp.dbVmConfig.name
      );
      this.selectedInstanceInfo = this.toBeDisabledInstanceInfo;
    }
  }

  ngOnDestroy(): void {
    if (this.azureSubscription) {
      this.azureSubscription.unsubscribe();
    }
  }

  getDbInfo(): void {
    // Incase the db does not have db status
    if (!this.siteInfo.sqleApp.status) {
      this.dbState = this.dbStatusStrings.started.dbState;
      this.dbMessage = this.dbStatusStrings.started.dbMessage;
    } else {
      // Incase siteInfo.dbStatus is not in the mapping, setting default to started
      const status: any = this.dbStatusStrings[this.siteInfo.sqleApp.status];
      this.dbState = status
        ? status.dbState
        : this.dbStatusStrings.started.dbState;
      this.dbMessage = status
        ? status.dbMessage
        : this.dbStatusStrings.started.dbMessage;
    }
  }

  onSubmit(formGroup: any): void {
    const currDbStatus: string = this.siteInfo.sqleApp.status;
    const currOprStatus: string = this.siteInfo.sqleApp.operationalStatus;
    // Immediate update on UI
    this.disableButton = true;
    this.siteInfo.sqleApp.status = 'resizing';
    this.siteInfo.sqleApp.operationalStatus = 'not operational';
    this.getDbInfo();
    // Choose scale up or down then send analytics event
    this.modifyAnalyticsEvent();
    this.analyticsService.sendAnalyticsEvent(
      this.analyticsEvent.azureScaleUpDown
    );
    this.azureSubscription = this.azureService
      .postDatabaseSiteResize(this.siteInfo, this.selectedInstanceInfo)
      .subscribe(
        (success: any) => {
          // add any other operations on a success call
        },
        (error: any) => {
          this.failedOperation = true;
          this.siteInfo.sqleApp.status = currDbStatus;
          this.siteInfo.sqleApp.operationalStatus = currOprStatus;
        }
      )
      .add(() => {
        this.displayStatus = true;
        this.getDbInfo();
      });
  }

  private modifyAnalyticsEvent(): void {
    const selectedName = this.selectedInstanceInfo.name;
    const disabledName = this.toBeDisabledInstanceInfo.name;
    const disabledRank =
      CLOUD_PLATFORM_INSTANCE_INFO.instanceSizeRanking[disabledName];
    const selectedRank =
      CLOUD_PLATFORM_INSTANCE_INFO.instanceSizeRanking[selectedName];
    const compare = selectedRank - disabledRank;
    if (compare > 0) {
      this.analyticsEvent.azureScaleUpDown.eventKey = 'azure_scale_up';
    } else {
      this.analyticsEvent.azureScaleUpDown.eventKey = 'azure_scale_down';
    }
  }
}
