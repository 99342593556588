import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { concat, forkJoin, Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  first,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';

import { Customer } from '@imc-features/ecosystems/_modules/ecosystems-shared/models/customer.model';
import { SiteService } from '@imc-features/ecosystems/_modules/ecosystems-shared/services/site-service/ecosystems-site.service';
import { PolicyOperations } from '@imc/core/models/policyOperations.enum';
import { PolicyResources } from '@imc/core/models/policyResources.enum';

import { AzureSubscriptionService } from '../../services/azure-subscriptions.service';

@Component({
  selector: 'imc-create-azure-subscription',
  templateUrl: './create-azure-subscription.component.html',
  styleUrls: ['./create-azure-subscription.component.scss'],
})
export class CreateAzureSubscriptionComponent implements OnInit {
  private disableSubmit: boolean;
  private subscriptionsSet: Set<string>;

  createForm$: Observable<any>;
  submitOperationInProgress$: Observable<boolean>;
  status?: boolean;
  readonly subscriptionIdCtrlName: string = 'subscriptionId';
  readonly subscriptionNameCtrlName: string = 'subscriptionName';
  readonly subscriptionOwnerCtrlName: string = 'owner';
  readonly subscriptionCustomerCtrlName: string = 'customerName';
  readonly subscriptionCommentsCtrlName: string = 'comments';

  PolicyOperations = PolicyOperations;
  PolicyResources = PolicyResources;

  constructor(
    private readonly subscriptionService: AzureSubscriptionService,
    private readonly siteService: SiteService
  ) {}

  ngOnInit(): void {
    this.subscriptionsSet = new Set<string>();
    this.createForm$ = forkJoin(
      this.siteService.listCustomers(),
      this.subscriptionService.getRawSubscriptions().pipe(first())
    ).pipe(
      switchMap(
        ([customerMap, subscriptions]: [Map<string, Customer>, any[]]) => {
          this.status = undefined;
          this.disableSubmit = false;
          subscriptions.forEach((subscription: any) => {
            if (!this.subscriptionsSet.has(subscription.id)) {
              this.subscriptionsSet.add(subscription.id);
            }
          });

          const allCustomers: Customer[] = [];
          customerMap.forEach((value: Customer) => allCustomers.push(value));
          const customers: Customer[] = allCustomers.sort(
            (a: Customer, b: Customer) =>
              a.name < b.name ? -1 : a.name > b.name ? 1 : 0
          );
          const customerNameControl: FormControl = new FormControl(undefined, [
            Validators.required,
            invalidCustomerValidator,
          ]);
          const customerOptions$: Observable<
            Customer[]
          > = customerNameControl.valueChanges.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            startWith(''),
            map((value: string | Customer | undefined) =>
              !value ? '' : typeof value === 'string' ? value : value.name
            ),
            map((value: string) => filterCustomer(value, customers))
          );
          const formGroup: FormGroup = new FormGroup({
            subscriptionId: new FormControl('', [
              Validators.required,
              subscriptionIdAlreadyExistsValidator(this.subscriptionsSet),
              subscriptionIdNotUuidValidator,
            ]),
            subscriptionName: new FormControl('', Validators.required),
            owner: new FormControl('', Validators.required),
            customerName: customerNameControl,
            comments: new FormControl(),
          });

          return of({
            formGroup,
            customerOptions$,
            error: false,
          });
        }
      ),
      catchError((error: any, caught: Observable<any>) =>
        of({
          formGroup: undefined,
          customerOptions: undefined,
          error: true,
        })
      )
    );
  }

  shouldDisableSubmit(formGroup: FormGroup): boolean {
    return this.disableSubmit || formGroup.invalid;
  }

  onSubmit(formGroup: FormGroup): void {
    if (this.shouldDisableSubmit(formGroup)) {
      return;
    }

    const opComplete$: Observable<boolean> = this.createSubmission(formGroup);
    const reset$: Observable<boolean> = of(false).pipe(
      delay(3000),
      map((finish: boolean) => {
        this.resetForm(formGroup);

        return finish;
      }),
      first()
    );

    this.submitOperationInProgress$ = concat(of(true), opComplete$, reset$);
  }

  showError(formGroup: FormGroup, controlName: string): string {
    let errorMessage: string;
    const control: AbstractControl = formGroup.get(controlName);
    if (control.invalid && (control.dirty || control.touched)) {
      if (control.errors.required) {
        errorMessage = `required`;
      } else if (control.errors.subscriptionIdAlreadyExists) {
        errorMessage = control.errors.subscriptionIdAlreadyExists.message;
      } else if (control.errors.subscriptionIdNotUuid) {
        errorMessage = control.errors.subscriptionIdNotUuid.message;
      } else if (control.errors.invalidCustomer) {
        errorMessage = control.errors.invalidCustomer.message;
      } else {
        errorMessage = 'error'; // there is a bug in the validation code, if we get here.
      }
    }

    return errorMessage;
  }

  displayCustomerName(customer?: Customer): string | undefined {
    return customer ? customer.name : '';
  }

  private createSubmission(formGroup: FormGroup): Observable<boolean> {
    this.disableSubmit = true;
    const customer: Customer = formGroup.get(this.subscriptionCustomerCtrlName)
      .value;
    const subscriptionId: string = formGroup.get(this.subscriptionIdCtrlName)
      .value;
    const subscriptionName: string = formGroup.get(
      this.subscriptionNameCtrlName
    ).value;
    const owner: string = formGroup.get(this.subscriptionOwnerCtrlName).value;
    const comments: string = formGroup.get(this.subscriptionCommentsCtrlName)
      .value;
    formGroup.disable();

    return this.subscriptionService
      .createSubscription(
        subscriptionId,
        subscriptionName,
        owner,
        customer.customerId,
        customer.name,
        comments
      )
      .pipe(
        switchMap((opStatus: boolean) => {
          this.status = opStatus;
          if (this.status) {
            this.subscriptionsSet.add(subscriptionId);
          }

          return of(true);
        }),
        first()
      );
  }

  private resetForm(formGroup: FormGroup): void {
    if (this.status === true) {
      formGroup.reset();
    }

    formGroup.enable();
    this.disableSubmit = false;
    this.status = undefined;
  }
}

const filterCustomer: Function = (
  value: string,
  customers: Customer[]
): Customer[] => {
  const filterValue: string =
    value || value.trim().length === 0 ? value.toLowerCase() : undefined;

  return filterValue
    ? customers.filter((customer: Customer) =>
        customer.name.toLowerCase().includes(filterValue)
      )
    : customers;
};

const rfc4122RegexUuid: RegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
const subscriptionIdAlreadyExistsValidator: Function = (
  subscriptions: Set<string>
): ValidatorFn => (control: AbstractControl): { [key: string]: any } | null =>
  subscriptions.has(control.value)
    ? {
        subscriptionIdAlreadyExists: {
          value: control.value,
          message: 'azure_subscriptions_id_already_exists_error',
        },
      }
    : null;

const subscriptionIdNotUuidValidator: ValidatorFn = (
  control: AbstractControl
): { [key: string]: any } | null =>
  rfc4122RegexUuid.test(control.value)
    ? null
    : {
        subscriptionIdNotUuid: {
          value: control.value,
          message: 'azure_subscriptions_id_not_uuid',
        },
      };

const invalidCustomerValidator: ValidatorFn = (
  control: AbstractControl
): { [key: string]: any } | null =>
  typeof control.value === 'string'
    ? { invalidCustomer: { value: control.value, message: 'invalid_customer' } }
    : null;
