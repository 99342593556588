import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '@imc/core/models/app-config.model';

@Injectable()
export class AppConfigService {
  private appConfig: AppConfig;

  constructor(private http: HttpClient) {}

  loadAppConfig(): Promise<void> {
    return this.http
      .get('/assets/config/config.json')
      .toPromise()
      .then((data: AppConfig) => {
        this.appConfig = data;
      });
  }

  getConfig(): AppConfig {
    return this.appConfig;
  }
}
