import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map, switchAll } from 'rxjs/operators';

import { getSiteById, listSites, Site } from '@imc/core';
import { BaasPageViewModel, CustomerSite } from '../models/site.model';

const generateDisplayName: Function = (site: any): string => {
  const siteName: string = site.supportSiteId || site.siteName;

  return site.siteTDPID && siteName
    ? `${site.siteTDPID} (${siteName})`
    : siteName || site.siteId;
};

@Injectable()
export class SiteService {
  constructor(private readonly apollo: Apollo) {}

  getSite(siteId: string): Observable<Site> {
    return getSiteById(this.apollo, { siteId });
  }

  updateSiteFeatures(siteId: string, features: any): Observable<any> {
    const updateSiteMutation: any = gql`
      mutation updateSiteFeatures($siteId: String!, $input: any!) {
        site(siteId: $siteId, input: $input)
          @rest(
            type: "Site"
            method: "PUT"
            path: "/management-console/sites/{args.siteId}/features"
          ) {
          ignore
        }
      }
    `;

    return this.apollo
      .mutate({
        mutation: updateSiteMutation,
        variables: {
          siteId,
          input: features,
        },
      })
      .pipe(
        map((responseBody: any) => {
          if (!responseBody || responseBody.errorCode) {
            throw new Error('Unable to change BaaS features on site');
          }

          return responseBody;
        })
      );
  }

  createBaasPageViewModel(siteId: string): Observable<BaasPageViewModel> {
    return this.listSites().pipe(
      map((sites: Site[]) => this.mapToCustomerSites(siteId, sites)),
      switchAll()
    );
  }

  private mapToCustomerSites(
    siteId: string,
    sites: Site[]
  ): Observable<BaasPageViewModel> {
    const customerSites: CustomerSite[] = [];
    const customerSitesMap: Map<string, CustomerSite> = new Map();
    const addNewCustomerSite: Function = (
      customerId: string,
      customerName: string
    ): void => {
      const customerSite: CustomerSite = {
        customerName,
        sites: [],
      };
      customerSites.push(customerSite);
      customerSitesMap.set(customerId, customerSite);
    }; // end: addNewCustomerSite
    const groupSitesByCustomer: Function = (): BaasPageViewModel => {
      let currentSite: Site;
      sites.forEach((site: Site) => {
        if (!customerSitesMap.has(site.customerId)) {
          addNewCustomerSite(
            site.customerId,
            `No customer name available (${site.customerId})`
          );
        }
        customerSitesMap.get(site.customerId).sites.push(site);
        if (site.siteId === siteId) {
          currentSite = site;
        }
      });

      return {
        currentSite,
        customerSites: customerSites.filter(
          (customerSite: CustomerSite) => customerSite.sites.length > 0
        ),
      };
    }; // end: groupSitesByCustomer

    return this.listCustomers().pipe(
      map((customers: any[]) => {
        customers.forEach((customer: any) =>
          addNewCustomerSite(customer.customerId, customer.name)
        );

        return groupSitesByCustomer();
      })
    );
  }

  private listSites(): Observable<Site[]> {
    return listSites(this.apollo);
  }

  private listCustomers(): Observable<any[]> {
    const listCustomersQuery: any = gql`
      query listCustomers {
        listCustomersResponse
          @rest(type: "Customer", path: "/sites-service/v2/customers") {
          customerId
          name
        }
      }
    `;

    return this.apollo
      .query<any>({
        query: listCustomersQuery,
      })
      .pipe(
        map((responseBody: any) => {
          if (responseBody.errors) {
            throw new Error(JSON.stringify(responseBody.errors));
          }

          return responseBody.data && responseBody.data.listCustomersResponse;
        }),
        map((customers: any[]) =>
          customers.sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
        )
      );
  }
}
