import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { forkJoin, Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { Customer, Site } from '@imc/core/models/sites.model';
import { LegacyCorrelationObjectService } from '@imc/shared/utilities/legacy-correlation-object-service';

import { SiteService } from '../../ecosystems-shared/services/site-service/ecosystems-site.service';
import {
  AzureWorkOrder,
  AzureWorkOrderRaw,
} from '../models/azure-work-order.model';

@Injectable()
export class AzureWorkOrdersService {
  constructor(
    private readonly apollo: Apollo,
    private readonly siteService: SiteService,
    private readonly correlationObjectService: LegacyCorrelationObjectService
  ) {}

  public getWorkOrders(): Observable<AzureWorkOrder[]> {
    return forkJoin(
      this.siteService.listSitesByPlatform('azure'),
      this.siteService.listCustomersByPlatform('azure')
    ).pipe(
      switchMap(([sites, customers]: [Site[], Customer[]]) =>
        this.getWorkOrdersBySiteCustomer(sites, customers)
      )
    );
  }

  private getWorkOrdersBySiteCustomer(
    sites: Site[],
    customers: Customer[]
  ): Observable<AzureWorkOrder[]> {
    const context: any = { headers: {} };
    context.headers[
      'correlation-object'
    ] = this.correlationObjectService.createDeprecatedCorrelationObject();
    const sitesMap: Map<string, string> = new Map<string, string>();
    const customersMap: Map<string, string> = new Map<string, string>();
    customers.forEach((customer: Customer) => {
      // TODO: hardcoding bad customer ids received from the customer service for now.
      if (
        customer.customerId !== sentestmga &&
        !customersMap.has(customer.customerId)
      ) {
        customersMap.set(customer.customerId, customer.name);
      }
    });
    sites.forEach((site: Site) => {
      // TODO: hardcoding bad customer ids received from the customer service for now.
      if (site.customerId !== sentestmga && !sitesMap.has(site.siteId)) {
        sitesMap.set(site.siteId, site.displayName);
      }
    });

    return this.getCustomerWorkOrders(
      new Set<string>(customersMap.keys()),
      context
    ).pipe(
      switchMap((workOrders: any[]) =>
        of(
          workOrders.map(
            (workOrder: any) =>
              <AzureWorkOrder>{
                name: workOrder.name,
                lastUpdated: workOrder.lastUpdated,
                status: workOrder.status,
                id: workOrder.id,
                customerId: workOrder.customerId,
                customerName: customersMap.get(workOrder.customerId),
                siteId: workOrder.siteId,
                siteDisplayName: sitesMap.get(workOrder.siteId),
              }
          )
        )
      ),
      first()
    );
  }

  private getCustomerWorkOrders(
    customerIds: Set<string>,
    context: any
  ): Observable<AzureWorkOrderRaw[]> {
    const customerWorkOrders: Observable<any[]>[] = [];
    customerIds.forEach((customerId: string) => {
      const query: any = gql`
        query workOrders {
          workOrders(customerId: $customerId)
            @rest(
              type: "AzureWorkOrder"
              path: "/management-console/workorders?{args}"
            ) {
            name: displayName
            lastUpdated: lastUpdatedUtc
            status
            id: workOrderId
            customerId
            siteId
          }
        }
      `;

      const workOrders: Observable<AzureWorkOrderRaw[]> = this.apollo
        .query<any>({ query, variables: { customerId }, context })
        .pipe(
          switchMap((responseBody: any) => {
            if (!responseBody || responseBody.errors) {
              throw new Error('Unable to fetch work orders.');
            }

            return of(responseBody.data && responseBody.data.workOrders);
          }),
          first()
        );

      customerWorkOrders.push(workOrders);
    });

    return forkJoin(customerWorkOrders).pipe(
      switchMap((doubleDimWorkOrders: AzureWorkOrderRaw[][]) =>
        of([].concat(...doubleDimWorkOrders))
      )
    );
  }
}

const sentestmga: string = 'sentestmga';
