import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { Observable, Subscription } from 'rxjs';

import { PolicyOperations } from '@imc/core/models/policyOperations.enum';
import { PolicyResources } from '@imc/core/models/policyResources.enum';
import { PolicyUserRole } from '@imc/core/models/roles.enum';
import { UserRoleInfo } from '@imc/core/models/userRoleInfo.model';
import { PolicyService } from '@imc/core/services/policy-service/policy.service';
import { DisplayAsLabelPipe } from '@imc/shared/pipes/display-as-label/display-as-label.pipe';

import { Site } from '@imc/core';
import { PolicyCheckerService } from '@imc/core/services';
import { TranslateService } from '@ngx-translate/core';
import {
  BackupJobSchedule,
  DefaultSiteFlags,
  Job,
  JobRun,
} from '../../models/backups.model';
import { BackupService } from '../../services/backups.service';
import { SiteService } from '../../services/site.service';
import { BackupEditorFormComponent } from '../backupEditorForm/backupEditorForm.component';
import { BaasSiteLevelFeaturesComponent } from './baasSiteLevelFeatures/baasSiteLevelFeatures.component';
import { BackupJobCancelDialogComponent } from './backupJobCancelDialog/backupJobCancelDialog.component';
import {
  BackupsTableContent,
  BackupsTableData,
  BackupsTableState,
  BackupsTableViewModel,
} from './backupsTable.viewModel';
import { ConfirmationDialogComponent } from './confirmationDialog/confirmationDialog.component';
import { ReplicationDetailsDialogComponent } from './replicationDetailsDialog/replicationDetailsDialog.component';
import { SkippedObjectsData } from './skippedObjects.service';
import { SkippedObjectsDialogComponent } from './skippedObjectsDialog/skippedObjectsDialog.component';
import { SkippedObjectsInfoDialogComponent } from './skippedObjectsInfoDialog/skippedObjectsInfoDialog.component';

@Component({
  selector: 'mc-legacy-baas-card',
  templateUrl: './backupsTableCards.component.html',
  styleUrls: ['./backupsTable.component.scss'],
})
export class BackupsTableComponent
  implements BackupsTableViewModel, OnChanges, OnDestroy {
  loading: boolean = true;
  @Input() set setCurrentSite(currentSite: Site) {
    if (currentSite) {
      this.loading = true;
      this.siteSubscription = this.siteService
        .getSite(currentSite.siteId)
        .subscribe((site: Site) => {
          if (!this.isSysOpsUserEvaluated) {
            if (this.userRoleInfoSubscription) {
              this.userRoleInfoSubscription.unsubscribe();
              this.userRoleInfoSubscription = null;
            }

            this.userRoleInfoSubscription = this.policyService.userRoleInfo$.subscribe(
              (role: UserRoleInfo) => {
                this.isSysOpsUserEvaluated = true;
                this.isSysOpsUser =
                  role.currentRole === PolicyUserRole.FullSysops ||
                  role.currentRole === PolicyUserRole.ReadOnlySysops ||
                  role.currentRole === PolicyUserRole.ReadOnlyLimitedSysops;
                this.setup(site);
              }
            );
          } else {
            this.setup(site);
          }
        });
    }
  }

  static AutoAbortEnabledString: string = 'Auto Abort Enabled';
  static AutoAbortDisabledString: string = 'Auto Abort Disabled';
  isSysOpsUser: boolean = false;

  private siteSubscription: Subscription;
  private userRoleInfoSubscription: Subscription;
  private jobRunHistorySubscription: Subscription;
  private jobsForCustomerSubscription: Subscription;
  private jobsForCustomerUserSubscription: Subscription;
  private updateJobForCustomerSubscription: Subscription;
  private updateJobSubscription: Subscription;
  private backupServiceSubscription: Subscription;

  private isSysOpsUserEvaluated: boolean = false;

  PolicyOperations = PolicyOperations;
  PolicyResources = PolicyResources;

  content: BackupsTableContent = {
    emptyContent: 'There are currently no backups.',
    columns: [
      { name: 'reallyLongDate', label: 'Time' },
      { name: 'duration', label: 'Duration' },
      { name: 'dsaJobStatus', label: 'Status' },
      { name: 'backupSize', label: 'Size' },
      { name: 'chip', label: 'Tags' },
      { name: 'menu', label: 'Action', numeric: true },
    ],
    sortable: false,
    sortBy: '',
    selectable: false,
    jobName: '',
    skippedObjectsV2DialogComponent: SkippedObjectsInfoDialogComponent,
    replicationDetailsDialogComponent: ReplicationDetailsDialogComponent,
    skippedObjectsDialogComponent: SkippedObjectsDialogComponent,
    backupJobCancelDialogComponent: BackupJobCancelDialogComponent,
    confirmationDialog: ConfirmationDialogComponent,
  };
  data: BackupsTableData = {};
  state: BackupsTableState = {};

  analyticsEvent = {
    editbackupjob: {
      eventKey: 'edit_backup_job',
    },
  };

  @Output() onSubmit = new EventEmitter();
  @ViewChild(NgForm) form: NgForm;
  openDialog: MatDialogRef<any>;
  @Output() closeDialog = new EventEmitter();
  isAWSSite: boolean;
  isTMCSite: boolean;
  isAzureSite: boolean;
  currentCustomerId: string;

  togglePosition: string = 'before';
  site: Site;
  role: string;
  @ViewChild('backupEditor') openBackupEditorTemplate: TemplateRef<any>;

  @ViewChild('enableBaasDialog') enableBaasDialogTemplate: TemplateRef<any>;
  BAAS_WIDGET_NAME: string = 'baas';
  BAAS_WIDGET_ENABLED: string = 'enabled';
  BAAS_WIDGET_SKIPPED_ENABLED: string = 'skippedObjectsEnabled';
  BAAS_WIDGET_AUTO_ABORT_ENABLED: string = 'autoAbortEnabled';
  BAAS_WIDGET_RETENTION_ENABLED: string = 'backupRetentionEnabled';

  // -- flags to turn on/off feature within the module --
  skippedObjectsFlag: boolean = false;
  DefaultSiteFlags: { [key: string]: boolean } = DefaultSiteFlags;
  autoAbortDisplayString: string =
    BackupsTableComponent.AutoAbortDisabledString;
  autoAbortTimeDisplayString: string;

  constructor(
    private readonly backupService: BackupService,
    private readonly siteService: SiteService,
    private readonly policyService: PolicyService,
    private readonly skippedObjects: SkippedObjectsData,
    private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    private readonly labelPipe: DisplayAsLabelPipe,
    private readonly policyChecker: PolicyCheckerService,
    private translate: TranslateService
  ) {}

  ngOnChanges(): void {
    if (
      this.site &&
      !this.isSiteFeatureDefined(
        this.site,
        this.BAAS_WIDGET_NAME,
        this.BAAS_WIDGET_ENABLED
      )
    ) {
      if (!this.site.features) {
        this.site.features = {};
      }

      if (
        this.site.features.baas === undefined ||
        this.site.features.baas === null
      ) {
        this.site.features.baas = {
          enabled: this.DefaultSiteFlags.enabled,
          skippedObjectsEnabled: this.DefaultSiteFlags.skippedObjectsEnabled,
          backupRetentionEnabled: this.DefaultSiteFlags.backupRetentionEnabled,
        };
      }
    }
    this.initializeFeatures();
  }

  ngOnDestroy(): void {
    if (this.siteSubscription) {
      this.siteSubscription.unsubscribe();
    }

    if (this.userRoleInfoSubscription) {
      this.userRoleInfoSubscription.unsubscribe();
    }

    if (this.jobRunHistorySubscription) {
      this.jobRunHistorySubscription.unsubscribe();
    }

    if (this.jobsForCustomerSubscription) {
      this.jobsForCustomerSubscription.unsubscribe();
    }

    if (this.jobsForCustomerUserSubscription) {
      this.jobsForCustomerUserSubscription.unsubscribe();
    }

    if (this.updateJobForCustomerSubscription) {
      this.updateJobForCustomerSubscription.unsubscribe();
    }

    if (this.updateJobSubscription) {
      this.updateJobSubscription.unsubscribe();
    }

    if (this.backupServiceSubscription) {
      this.backupServiceSubscription.unsubscribe();
    }
  }

  close() {
    this.closeDialog.emit();
    if (this.openDialog) {
      this.openDialog.close();
    }
  }

  openBackupEditor() {
    this.openDialog = this.dialog.open(this.openBackupEditorTemplate);
  }

  closeBackupEditor() {
    if (this.openDialog) {
      this.openDialog.close();
    }
  }

  setAutoAbortDisplay() {
    if (this.state.selectedBackupJob) {
      if (this.state.selectedBackupJob.isAutoAbortActive) {
        this.autoAbortDisplayString =
          BackupsTableComponent.AutoAbortEnabledString;
      } else {
        this.autoAbortDisplayString =
          BackupsTableComponent.AutoAbortDisabledString;
      }

      this.setupAutoAbortStateString(this.state.selectedBackupJob);
    }
  }

  selectBackupJob(backupJob: Job) {
    this.state.selectedBackupJob = backupJob;
    this.content.jobName = this.state.selectedBackupJob.name;
    this.setSelectedBackupJobRunHistory(backupJob);
    this.setAutoAbortDisplay();
  }

  toggleBackupJobState() {
    this.state.selectedBackupJob.isActive = !this.state.selectedBackupJob
      .isActive;
    const updateRequest = {
      jobId: this.state.selectedBackupJob.jobId,
      isActive: this.state.selectedBackupJob.isActive,
    };
    this.updateJob(updateRequest);
  }

  isFeatureEnabled(component: string, feature: string) {
    if (
      !this.site ||
      !this.isSiteFeatureDefined(this.site, component, feature)
    ) {
      return this.DefaultSiteFlags[feature];
    }

    return this.site.features[component][feature] || false;
  }

  formSubmit(event: any) {
    const selectedBackupJob = this.state.selectedBackupJob;
    this.state.selectedBackupJob.backupJobSchedule = {} as BackupJobSchedule;
    this.state.selectedBackupJob.backupJobSchedule.runInterval =
      event.runInterval;
    this.state.selectedBackupJob.backupJobSchedule.runUnit = event.runUnit;
    selectedBackupJob.noOfRetentionCopies = event.retentionPeriod;
    this.state.selectedBackupJob.backupJobSchedule.startDate = event.startDate;
    selectedBackupJob.runInterval = event.runInterval;
    const startDateTime: Date = new Date(
      new Date(event.startDate).toUTCString()
    );
    startDateTime.setUTCHours(parseInt(event.backupStartHourTime, 10));
    startDateTime.setUTCMinutes(parseInt(event.backupStartMinuteTime, 10));
    this.state.selectedBackupJob.backupJobSchedule.startByTime = new Date(
      startDateTime
    ).toISOString();
    this.state.selectedJobSchedule = this.buildSingleScheduleDescriptionFromJobSchedule(
      this.state.selectedBackupJob.backupJobSchedule
    );
    this.state.selectedJobStartDate = this.getStartDate(
      this.state.selectedBackupJob.backupJobSchedule
    );
    this.state.selectedBackupJob.autoAbortInMin = event.autoAbortInMin;
    this.state.selectedBackupJob.isAutoAbortActive = event.isAutoAbortActive;
    this.updateJob(this.getUpdateRequestFromState());
  }

  onManageFeatures() {
    this.close();
    this.openDialog = this.dialog.open(BaasSiteLevelFeaturesComponent, {
      data: {
        siteId: this.site.siteId,
      },
    });
  }

  showEditOptions() {
    return (
      (this.isAWSSite || this.isAzureSite) &&
      (this.state.selectedBackupJob.jobType &&
        this.state.selectedBackupJob.jobType.toUpperCase() === 'BACKUP')
    );
  }

  public updateRetainedSet(jobRun: JobRun, setRetained: boolean) {
    const msg = setRetained
      ? this.translate.instant('backupset_mark_never_delete')
      : this.translate.instant('backupset_remove_never_delete');
    this.close();
    this.openDialog = this.dialog.open(this.content.confirmationDialog, {
      data: {
        confirmMessage: msg,
      },
    });
    this.openDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.backupServiceSubscription = this.backupService
          .updateBackupSetRetention(
            this.state.selectedBackupJob,
            jobRun.backupSetId,
            setRetained
          )
          .subscribe(
            (run) => {
              if (run) {
                // refresh list
                this.setSelectedBackupJobRunHistory(
                  this.state.selectedBackupJob
                );
              }
            },
            (err) => {
              this.snackBar.open(
                err.error.message ||
                  this.translate.instant('backupset_update_error'),
                'OK'
              );
            }
          );
      }
      this.openDialog = null;
    });
  }

  private initializeFeatures() {
    if (
      this.site &&
      !this.isSiteFeatureDefined(
        this.site,
        this.BAAS_WIDGET_NAME,
        this.BAAS_WIDGET_ENABLED
      )
    ) {
      if (!this.site.features) {
        this.site.features = {};
      }

      if (
        this.site.features.baas === undefined ||
        this.site.features.baas === null
      ) {
        this.site.features.baas = {
          enabled: this.DefaultSiteFlags.enabled,
          skippedObjectsEnabled: this.DefaultSiteFlags.skippedObjectsEnabled,
        };
      }
    }
  }

  private setup(site: Site): void {
    this.resetState();
    this.site = site;
    this.isAWSSite =
      this.site.cloudPlatform &&
      this.site.cloudPlatform.toLowerCase() === 'aws';
    this.isTMCSite =
      this.site.cloudPlatform &&
      this.site.cloudPlatform.toLowerCase() === 'tmc';
    this.isAzureSite =
      this.site.cloudPlatform &&
      this.site.cloudPlatform.toLowerCase() === 'azure';
    this.currentCustomerId = this.site.customerId;
    this.getBackupJobs();
    this.initializeFeatures();
  }

  private setupSkippedObjectsData(runs): void {
    this.skippedObjects.setJobRuns(runs);
    this.skippedObjects.setCurrentJob(this.state.selectedBackupJob);
    this.skippedObjects.setCurrentCustomerId(this.currentCustomerId);
  }

  private getBackupJobs() {
    this.loading = true;
    if (this.isSysOpsUserEvaluated) {
      if (this.isSysOpsUser) {
        this.getBackupJobsInSysOpsView();
      } else {
        this.getBackupJobsInCustomerView();
      }
    }
  }

  private getBackupJobsInCustomerView() {
    if (this.site) {
      this.jobsForCustomerUserSubscription = this.backupService
        .getBackupsJobsForCustomerUser(this.site.siteId)
        .subscribe(
          (backupJobsForSite) => {
            if (backupJobsForSite.length >= 1) {
              this.state.backupJobs = backupJobsForSite;
              this.sortJobs(this.state.backupJobs);
              this.state.selectedBackupJob = backupJobsForSite[0];
              this.setSelectedBackupJobRunHistory(backupJobsForSite[0]);
            }
            this.loading = false;
          },
          (err) => {
            // if there is an error in getting, jobs for a site, reset the joblist , so that
            // stale data from another site is not shown
            this.resetJobList();
            this.snackBar.open('Error loading backup jobs.', 'Ok');
            this.loading = false;
          }
        );
    }
  }

  private getBackupJobsInSysOpsView() {
    if (this.site && this.site.siteId && this.currentCustomerId) {
      this.jobsForCustomerSubscription = this.backupService
        .getBackupJobsByCustomer(this.currentCustomerId, this.site.siteId)
        .subscribe(
          (backupJobsForSite) => {
            if (backupJobsForSite.length >= 1) {
              this.state.backupJobs = backupJobsForSite;
              this.sortJobs(this.state.backupJobs);
              this.state.selectedBackupJob = backupJobsForSite[0];
              this.setSelectedBackupJobRunHistory(backupJobsForSite[0]);
            }
            this.loading = false;
          },
          (err) => {
            // if there is an error in getting, jobs for a site, reset the joblist , so that
            // stale data from another site is not shown
            this.resetJobList();
            this.snackBar.open('Error loading backup jobs.', 'Ok');
            this.loading = false;
          }
        );
    }
  }

  private setSelectedBackupJobRunHistory(job: Job) {
    if (job) {
      this.loading = true;
      this.jobRunHistorySubscription = this.getJobRunHistoryAndDetails(job)
        .subscribe(
          (jobDetails) =>
            this.updateWithJobDetailsForSelectedJob(
              jobDetails,
              job,
              this.state,
              this.content,
              this.currentCustomerId
            ),
          () => this.errorFetchingJobRunHistoryAndDetails()
        )
        .add(() => (this.loading = false));
    }
  }

  private getJobRunHistoryAndDetails(job: Job): Observable<any> {
    if (this.isSysOpsUser) {
      // for sysops
      return this.backupService.getJobRunHistoryAndDetailsForCustomer(
        this.currentCustomerId,
        job.jobId
      );
    }

    // for customers
    return this.backupService.getJobRunHistoryAndDetails(job.jobId);
  }

  private errorFetchingJobRunHistoryAndDetails() {
    // if there is an error in getting, job history reset the history annd details , so that
    // stale data is not shown
    this.resetJobSpecificDetails();
    this.snackBar.open('Error loading backup job history.', 'Ok');
  }

  private updateWithJobDetailsForSelectedJob(
    jobDetails: any,
    selectedJob: Job,
    state: BackupsTableState,
    content: BackupsTableContent,
    customerId: string
  ) {
    const jobHistoryKey = 'jobHistory';
    const jobDetailsKey = 'jobDetails';
    const jobScheduleKey = 'jobSchedule';
    const nextRunStartTimeKey = 'nextRunStartTime';
    selectedJob.nextRunStartTime =
      jobDetails[jobDetailsKey][nextRunStartTimeKey];
    const jobType = selectedJob.jobType.toUpperCase();
    this.changeTableContentForJobType(jobType, content);
    const jobRuns = jobDetails[jobHistoryKey];
    let latestJobRunStatus: string = null;
    const jobRunHistory = [];
    if (jobRuns) {
      this.content.jobName = selectedJob.name;
      this.setupSkippedObjectsData(jobRuns);
      this.buildJobRunHistory(
        selectedJob.jobId,
        jobType,
        jobRuns,
        jobRunHistory,
        customerId
      );
      if (jobRuns.length > 0) {
        latestJobRunStatus = this.getJobRunStatus(jobType, jobRuns[0]);
      }
      if (
        latestJobRunStatus !== null &&
        latestJobRunStatus.toUpperCase() === 'QUEUED' &&
        jobType !== 'REPLICATION'
      ) {
        jobRunHistory[0].reallyLongDate = jobRuns[0].startTime;
      }
    }

    if (
      selectedJob.isActive &&
      selectedJob.nextRunStartTime &&
      !(
        latestJobRunStatus !== null &&
        ['RUNNING', 'QUEUED'].indexOf(latestJobRunStatus.toUpperCase()) > -1
      )
    ) {
      const nextRun = {
        jobId: selectedJob.jobId,
        runId: null,
        customerId,
        reallyLongDate: selectedJob.nextRunStartTime,
        duration: null,
        backupSize: null,
        dsaJobStatus: 'Not Started',
        isAbortReady: false,
        isAbortRequested: false,
        skippedObjects: null,
      };
      jobRunHistory.unshift(nextRun);
    }

    state.selectedJobRunHistory = jobRunHistory;
    this.setMenuItemsAndTags();
    this.updateJobSchedule(
      jobDetails[jobDetailsKey][jobScheduleKey],
      state,
      selectedJob
    );

    this.setupAutoAbortStateString(selectedJob);
  }

  private setupAutoAbortStateString(selectedJob: Job) {
    this.autoAbortTimeDisplayString = '';
    if (selectedJob.isAutoAbortActive) {
      const totalMinutes = selectedJob.autoAbortInMin;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes - hours * 60;
      this.autoAbortTimeDisplayString =
        'Max Runtime: ' +
        BackupEditorFormComponent.convertNumberToViewValue(hours) +
        ':' +
        BackupEditorFormComponent.convertNumberToViewValue(minutes) +
        ' (hh:mm)';
    } else {
      this.autoAbortTimeDisplayString = 'Not scheduled.';
    }
  }

  private buildJobRunHistory(
    jobId: number,
    jobType: string,
    jobRuns: JobRun[],
    jobRunHistory: any[],
    customerId: string
  ) {
    for (const jobRun of jobRuns) {
      const jobRunView = this.getRunHistoryForJobType(
        jobId,
        jobType,
        jobRun,
        customerId
      );
      jobRunHistory.push(jobRunView);
    }
  }

  private changeTableContentForJobType(
    jobType: string,
    content: BackupsTableContent
  ): void {
    switch (jobType) {
      case 'REPLICATION':
        content.columns = [
          { name: 'reallyLongDate', label: 'Import Time' },
          { name: 'duration', label: 'Duration' },
          { name: 'status', label: 'Status' },
          { name: 'backupSize', label: 'Backup Size' },
          { name: 'chip', label: 'Tags' },
          { name: 'menu', label: 'Details', numeric: true },
        ];
        break;
      case 'RESTORE':
        content.columns = [
          { name: 'reallyLongDate', label: 'Restore Time' },
          { name: 'duration', label: 'Duration' },
          { name: 'dsaJobStatus', label: 'Status' },
          { name: 'backupSize', label: 'Restore Size' },
          { name: 'chip', label: 'Tags' },
          { name: 'menu', label: 'Action', numeric: true },
        ];
        break;
      default:
        content.columns = [
          { name: 'reallyLongDate', label: 'Time' },
          { name: 'duration', label: 'Duration' },
          { name: 'dsaJobStatus', label: 'Status' },
          { name: 'backupSize', label: 'Size' },
          { name: 'chip', label: 'Tags' },
          { name: 'menu', label: 'Action', numeric: true },
        ];
    }
  }

  private getRunHistoryForJobType(
    jobId: number,
    jobType: string,
    run: JobRun,
    customerId: string
  ) {
    let duration;

    if (
      run.endTime &&
      run.startTime &&
      run.endTime.trim().length !== 0 &&
      run.startTime.trim().length !== 0
    ) {
      const startDate: Date = new Date(run.startTime);
      duration = this.parseMillisecondsIntoReadableTime(
        new Date(run.endTime).valueOf() - startDate.valueOf()
      );
    }
    switch (jobType) {
      case 'REPLICATION':
        return {
          jobId,
          customerId,
          reallyLongDate: run.endTime ? run.endTime : '',
          duration, // Seconds to MS
          backupSize: this.formatBytes(Number(run.backupSetSize), 2),
          replicationDetails: JSON.parse(run.status_details),
          status: run.status,
          backupSetId: run.backupSetId,
          isRetained: run.isRetained,
        };
      case 'RESTORE':
      default:
        let size;
        const jobRunStatus: string = this.getJobRunStatus(jobType, run);
        if (run.backupSetSize !== null) {
          size = this.getJobSize(parseInt(run.backupSetSize, 10), jobRunStatus);
        }

        let formattedStatus = '';
        if (jobRunStatus !== null) {
          formattedStatus = this.labelPipe.transform(jobRunStatus);
        }

        return {
          jobId,
          runId: run.runId,
          customerId,
          reallyLongDate: run.endTime ? run.endTime : '',
          duration, // Seconds to MS
          backupSize: size,
          dsaJobStatus: formattedStatus,
          isAbortReady: run.isAbortReady,
          isAbortRequested: run.isAbortRequested,
          skippedObjects: run.skippedObjects,
          backupSetId: run.backupSetId,
          isRetained: run.isRetained,
        };
    }
  }

  private resetState() {
    this.resetJobList();
    this.resetJobSpecificDetails();
  }

  private resetJobList() {
    this.state.backupJobs = null;
    this.state.selectedBackupJob = null;
  }

  private resetJobSpecificDetails() {
    this.state.selectedJobRunHistory = null;
    this.state.selectedJobStartDate = null;
    this.state.selectedJobSchedule = null;
  }

  private getUpdateRequestFromState(): any {
    const startDateTime: Date = new Date(
      new Date(
        this.state.selectedBackupJob.backupJobSchedule.startByTime
      ).toUTCString()
    );
    const startDateTimeInMillisecs: number = startDateTime.valueOf() / 1000;
    const endDateTime: Date = new Date(
      new Date(
        this.state.selectedBackupJob.backupJobSchedule.startByTime
      ).toUTCString()
    );
    endDateTime.setUTCFullYear(startDateTime.getUTCFullYear() + 5);
    const endDateTimeInMillisecs: number = endDateTime.valueOf() / 1000;
    const hoursHh: string = startDateTime
      .getUTCHours()
      .toString()
      .padStart(2, '0');
    const minutesMm: string = startDateTime
      .getUTCMinutes()
      .toString()
      .padStart(2, '0');
    const secondsSs: string = startDateTime
      .getUTCSeconds()
      .toString()
      .padStart(2, '0');
    const startyByTime: string = `${hoursHh}:${minutesMm}:${secondsSs}`;

    return {
      jobId: this.state.selectedBackupJob.jobId,
      noOfRetentionCopies: this.state.selectedBackupJob.noOfRetentionCopies,
      status: 'SUCCESS',
      jobSchedule: [
        {
          startDate: startDateTimeInMillisecs,
          endDate: endDateTimeInMillisecs,
          runInterval: this.state.selectedBackupJob.backupJobSchedule
            .runInterval,
          runUnit: this.state.selectedBackupJob.backupJobSchedule.runUnit,
          startByTime: startyByTime,
        },
      ],
      isAutoAbortActive: this.state.selectedBackupJob.isAutoAbortActive,
      autoAbortInMin: this.state.selectedBackupJob.autoAbortInMin,
    };
  }

  private parseMillisecondsIntoReadableTime(milliseconds): string {
    // Get hours from milliseconds
    const hours = milliseconds / (1000 * 60 * 60);
    const absoluteHours = Math.floor(hours);
    let formattedDuration = '';
    if (absoluteHours !== 0) {
      formattedDuration = formattedDuration + absoluteHours + 'h';
    }
    // Get remainder from hours and convert to minutes
    const minutes = (hours - absoluteHours) * 60;
    const absoluteMinutes = Math.floor(minutes);
    const m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;
    if (absoluteMinutes !== 0) {
      formattedDuration = formattedDuration + ' ' + absoluteMinutes + 'm';
    }
    // Get remainder from minutes and convert to seconds
    const seconds = (minutes - absoluteMinutes) * 60;
    const absoluteSeconds = Math.floor(seconds);
    const s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;

    if (absoluteSeconds !== 0) {
      formattedDuration = formattedDuration + ' ' + absoluteSeconds + 's';
    }
    return formattedDuration;
  }

  private updateJob(updateJobRequest) {
    // tslint:disable:no-empty
    if (this.isSysOpsUser) {
      this.updateJobForCustomerSubscription = this.backupService
        .updateJobForCustomer(this.currentCustomerId, updateJobRequest)
        .subscribe(
          () => {},
          () => {
            this.snackBar.open('Error updating the Backup job', 'Ok');
          },
          () => {
            this.selectBackupJob(this.state.selectedBackupJob);
          }
        );
    } else {
      this.updateJobSubscription = this.backupService
        .updateJob(updateJobRequest)
        .subscribe(
          () => {},
          () => {
            this.snackBar.open('Error updating the Backup job', 'Ok');
          },
          () => {
            this.selectBackupJob(this.state.selectedBackupJob);
          }
        );
    }
    // tslint:enable:no-empty
  }

  private sortJobs(backupJobsForSite: Job[]) {
    backupJobsForSite.sort((job1, job2) => {
      if (job1.name > job2.name) {
        return 1;
      }
      if (job1.name < job2.name) {
        return -1;
      }
      return 0;
    });
  }

  private updateJobSchedule(
    jobScheduleList: BackupJobSchedule[],
    state: BackupsTableState,
    selectedJob: Job
  ) {
    if (!jobScheduleList || jobScheduleList.length < 1) {
      selectedJob.backupJobSchedule = null;
      state.selectedJobSchedule = null;
      state.selectedJobStartDate = null;
    } else {
      selectedJob.backupJobSchedule = jobScheduleList[0];
      if (jobScheduleList.length > 1) {
        state.selectedJobSchedule = 'Custom Schedule';
      } else {
        state.selectedJobSchedule = this.buildSingleScheduleDescriptionFromJobSchedule(
          selectedJob.backupJobSchedule
        );
      }

      this.setupAutoAbortStateString(selectedJob);
      state.selectedJobStartDate = this.getStartDate(
        selectedJob.backupJobSchedule
      );
    }
  }

  private buildSingleScheduleDescriptionFromJobSchedule(
    selectedJobSchedule: BackupJobSchedule
  ): string {
    const backupStartTimeDate: Date = new Date(selectedJobSchedule.startByTime);

    // HH:mm
    const startTimeString = `${backupStartTimeDate
      .getUTCHours()
      .toString()
      .padStart(2, '0')}:${backupStartTimeDate
      .getUTCMinutes()
      .toString()
      .padStart(2, '0')}`;
    const timeZone = 'UTC';
    let numberPart = '';
    let intervalString = this.labelPipe.transform(selectedJobSchedule.runUnit);
    if (selectedJobSchedule.runInterval > 1) {
      numberPart = ` ${selectedJobSchedule.runInterval} `;
      if (!intervalString.endsWith('s')) {
        intervalString = intervalString.concat('s'); // pluralize
      }
    }

    return (
      'Every ' +
      numberPart +
      intervalString +
      ' @' +
      startTimeString +
      ' ' +
      timeZone
    );
  }

  private getStartDate(jobSchedule: BackupJobSchedule): string {
    if (jobSchedule) {
      const backupStartDate: Date = new Date(
        new Date(jobSchedule.startByTime).toUTCString()
      );
      const monthMmm: string = new Intl.DateTimeFormat('en-US', {
        month: 'short',
      }).format(backupStartDate);

      return `${monthMmm} ${backupStartDate
        .getUTCDate()
        .toString()
        .padStart(2, '0')} ${backupStartDate.getUTCFullYear()} `;
    }

    return 'Not Scheduled';
  }

  private getJobRunStatus(jobType: string, jobRun: JobRun): string {
    if (jobType === 'REPLICATION' || jobRun.dsaJobStatus === null) {
      return jobRun.status;
    }

    return jobRun.dsaJobStatus;
  }

  private getJobSize(size: number, status: string): string {
    const completedStatuses: string[] = [
      'COMPLETED_ERRORS',
      'COMPLETED_SUCCESSFULLY',
      'COMPLETE',
      'SUCCESSFUL',
      'WARNING',
    ];
    // if it is completed
    if (completedStatuses.some((x) => x === status.toUpperCase())) {
      // this covers the case when job size is 0, for a completed job
      // this mitigates the issue of dictionary jobs, when this scenario occurs
      // https://jira.td.teradata.com/jira/browse/TMCDR-801
      if (size === 0) {
        return '<10 MB';
      }
    }
    if (size === null) {
      return null;
    }
    return this.formatBytes(size, 2);
  }

  private openEnableBaasDialog() {
    this.site.features[this.BAAS_WIDGET_NAME][this.BAAS_WIDGET_ENABLED] = !this
      .site.features[this.BAAS_WIDGET_NAME][this.BAAS_WIDGET_ENABLED];
    this.openDialog = this.dialog.open(this.enableBaasDialogTemplate);
  }

  private confirmEnableBaas() {
    this.siteService
      .updateSiteFeatures(this.site.siteId, this.getConstructedFeatureSetBody())
      .subscribe(
        (result) => {
          return;
        },
        (error) => {
          this.site.features[this.BAAS_WIDGET_NAME][
            this.BAAS_WIDGET_ENABLED
          ] = !this.site.features[this.BAAS_WIDGET_NAME][
            this.BAAS_WIDGET_ENABLED
          ];
          this.snackBar.open('Site update operation failed', 'Ok');
        }
      );
    this.close();
  }

  private revertEnableBaas() {
    this.site.features[this.BAAS_WIDGET_NAME][this.BAAS_WIDGET_ENABLED] = !this
      .site.features[this.BAAS_WIDGET_NAME][this.BAAS_WIDGET_ENABLED];
    this.close();
  }

  private getConstructedFeatureSetBody(): any {
    const featureSetBody = { features: {} };
    featureSetBody.features = this.site.features;
    return featureSetBody;
  }

  private formatBytes(bytes, decimals): string {
    if (bytes === 0) {
      return null;
    }
    const k = 1024;
    const dm = decimals || 2;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  private isSiteFeatureDefined(
    site: Site,
    featureSetName: string,
    featureName: string
  ) {
    return (
      site.features !== undefined &&
      site.features !== null &&
      site.features[featureSetName] !== undefined &&
      site.features[featureSetName] !== null &&
      site.features[featureSetName][featureName] !== undefined &&
      site.features[featureSetName][featureName] !== null
    );
  }

  private isCancelJobConfirmed() {
    return BackupJobCancelDialogComponent.isConfirmButtonClicked;
  }

  private showCancelJobDialog(jobInfo) {
    this.close();
    this.openDialog = this.dialog.open(
      this.content.backupJobCancelDialogComponent
    );
    this.openDialog.componentInstance.data = jobInfo;
  }
  private showSkippedObjectsDialogV2(jobInfo) {
    this.close();
    this.openDialog = this.dialog.open(
      this.content.skippedObjectsV2DialogComponent,
      {
        data: jobInfo,
      }
    );
  }

  private showSkippedObjectsDialog(jobInfo) {
    this.close();
    this.openDialog = this.dialog.open(
      this.content.skippedObjectsDialogComponent
    );
    this.openDialog.componentInstance.data = jobInfo;
  }

  private showReplicationDetailsDialog(replicationDetails) {
    this.close();
    this.openDialog = this.dialog.open(
      this.content.replicationDetailsDialogComponent
    );
    this.openDialog.componentInstance.data = replicationDetails;
  }

  private setMenuItemsAndTags() {
    const jobRunsRetained = this.state.selectedJobRunHistory.filter(
      (job: any) => job.isRetained === true
    );

    const anyJobRetained = jobRunsRetained.length !== 0;

    this.state.selectedJobRunHistory.forEach((jobRun: any) => {
      const menu = [];

      if (jobRun.isAbortReady) {
        this.policyChecker
          .isEnabled({
            operation: PolicyOperations.Delete,
            resource: PolicyResources.BaasSkippedObj,
          })
          .subscribe((isEnabled) => {
            if (isEnabled) {
              const menuItem = {
                icon: 'cancel',
                callback: (jobInfo) => {
                  this.showCancelJobDialog(jobInfo);
                },
                disabled: () =>
                  this.isCancelJobConfirmed() || jobRun.isAbortRequested,
                label: jobRun.isAbortRequested
                  ? 'Aborting in Progress'
                  : 'Abort Job',
              };

              menu.push(menuItem);
            }
          });
      }

      if (jobRun.skippedObjects) {
        let menuItem;
        if (
          this.isFeatureEnabled(
            this.BAAS_WIDGET_NAME,
            this.BAAS_WIDGET_SKIPPED_ENABLED
          )
        ) {
          menuItem = {
            icon: 'report_problem',
            label: 'View Errors',
            callback: (run) => {
              this.showSkippedObjectsDialogV2({
                name: this.content.jobName,
                runId: run.runId,
              });
            },
          };
        } else {
          menuItem = {
            icon: 'report_problem',
            label: 'View Errors',
            callback: (run) => {
              this.showSkippedObjectsDialog(run.skippedObjects);
            },
          };
        }
        menu.push(menuItem);
      }

      if (
        this.isFeatureEnabled(
          this.BAAS_WIDGET_NAME,
          this.BAAS_WIDGET_RETENTION_ENABLED
        )
      ) {
        if (jobRun.backupSetId) {
          if (anyJobRetained) {
            let menuItem;
            if (jobRun.isRetained) {
              menuItem = {
                icon: 'remove_circle_outline',
                label: 'Remove Never Delete',
                callback: (run) => {
                  this.updateRetainedSet(run, false);
                },
              };
            } else {
              menuItem = {
                icon: 'save_alt',
                label: 'Mark Never Delete',
                disabled: true,
              };
            }
            menu.push(menuItem);
          } else {
            const menuItem = {
              icon: 'save_alt',
              label: 'Mark Never Delete',
              callback: (run) => {
                this.updateRetainedSet(run, true);
              },
            };
            menu.push(menuItem);
          }
        }

        if (jobRun.isRetained) {
          jobRun.chip = {
            css: 'text-md text-upper bgc-pink-500',
            label: 'never delete',
          };
        }
      }

      if (jobRun.replicationDetails) {
        const menuItem = {
          icon: 'info',
          label: 'Replication Details',
          callback: (run) => {
            this.showReplicationDetailsDialog(run.replicationDetails);
          },
        };
        menu.push(menuItem);
      }

      jobRun.menu = menu;
    });
  }
}
