import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@imc/core/services';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface CustomerQueryRecord {
  number: number;
  customerId: string;
  name: string;
  cloudId: string;
}
@Injectable()
export class CcuMigrationService {
  ccuMigrationsUrl: string;

  constructor(
    private readonly appConfig: AppConfigService,
    private readonly http: HttpClient,
    private readonly apollo: Apollo
  ) {
    this.ccuMigrationsUrl = `${
      this.appConfig.getConfig().endpoints.dmsApiDomain
    }/migrations`;
  }

  public getCcuMigrationList(): Observable<any> {
    const options: any = { headers: this.createHeaders() };

    return this.http
      .get(this.ccuMigrationsUrl, options)
      .pipe(map((responseBody: any) => responseBody.result));
  }

  public getCcuMigrationById(migrationId: string): Observable<any> {
    const options: any = { headers: this.createHeaders() };

    return this.http
      .get(`${this.ccuMigrationsUrl}/${migrationId}`, options)
      .pipe(
        map((responseBody: any) => {
          if (!responseBody.result || responseBody.errorCode) {
            throw new Error(responseBody.message);
          }

          return responseBody.result;
        })
      );
  }

  public getCustomersIdNameMap(): Observable<Map<string, string>> {
    const listCustomersQuery: any = gql`
      query listCustomers {
        listCustomersResponse
          @rest(type: "Customer", path: "/sites-service/v2/customers") {
          customerId
          name
          cloudId
          number: u_teradata_customer_number
        }
      }
    `;

    return this.apollo
      .query<any>({
        query: listCustomersQuery,
      })
      .pipe(
        map((responseBody: any) => {
          if (responseBody.errors) {
            throw new Error(JSON.stringify(responseBody.errors));
          }

          return (
            (responseBody.data && responseBody.data.listCustomersResponse) || []
          );
        }),
        map((customers: CustomerQueryRecord[]) => {
          const customerIdNameMap: Map<string, string> = new Map();
          customers.forEach((record: CustomerQueryRecord) => {
            customerIdNameMap[record.customerId] = record.name;
          });

          return customerIdNameMap;
        })
      );
  }

  private readonly createHeaders = (): HttpHeaders =>
    new HttpHeaders().set(
      'authorization',
      `Bearer ${window.localStorage.getItem('access_token')}`
    );
}
