import { Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { TdDynamicFormsComponent } from '@covalent/dynamic-forms';

@Component({
  selector: 'imc-aws-params-input-form',
  templateUrl: './paramsInputForm.component.html',
  styleUrls: ['./paramsInputForm.component.scss'],
})
export class ParamsInputFormComponent implements OnChanges {
  @Input() params;
  @Input() features;
  @Input() submitText;
  @Input() user;
  @Input() customer;
  @Input() lastStep = true;

  @Output() nextStep: EventEmitter<any> = new EventEmitter();
  @Output() submitForm: EventEmitter<any> = new EventEmitter();

  @ViewChild('form') form: TdDynamicFormsComponent;

  @ViewChild('form2') form2: TdDynamicFormsComponent;

  sizesm4 = [
    // m4
    {
      label: 'Small',
      tcore: '2.23',
      type: 'm4.4xl',
      vCPU: '16',
      memory: '64',
    },
    {
      label: 'Medium',
      tcore: '5.26',
      type: 'm4.10xl',
      vCPU: '40',
      memory: '160',
    },
    {
      label: 'Large',
      tcore: '9.57',
      type: 'm4.16xl',
      vCPU: '64',
      memory: '256',
    },
  ];

  sizesm5 = [
    {
      label: 'Small',
      tcore: '2.6',
      type: 'm5.4xl',
      vCPU: '16',
      memory: '64',
    },
    {
      label: 'Medium',
      tcore: '6.8',
      type: 'm5.12xl',
      vCPU: '48',
      memory: '192',
    },
    {
      label: 'Large',
      tcore: '13.6',
      type: 'm5.24xl',
      vCPU: '96',
      memory: '384',
    },
  ];

  selectedSize: any;

  m4InstanceAvailable: boolean = false;
  m5InstanceAvailable: boolean = true;

  constructor(public location: Location) {}

  ngOnChanges(changes) {
    if (
      changes.params &&
      changes.params.currentValue &&
      changes.params.currentValue.length
    ) {
      this.params = [];
      if (this.form) {
        this.form.refresh();
      }
      this.params = changes.params.currentValue;
    }

    if (
      changes.features &&
      changes.features.currentValue &&
      changes.features.currentValue.length
    ) {
      this.features = [];
      if (this.form2) {
        this.form2.refresh();
      }
      this.features = changes.features.currentValue;
    }
  }

  goToNextStep() {
    this.nextStep.emit({
      params: this.form.value,
      features: this.form2.value,
      size: this.selectedSize,
    });
  }

  submit() {
    this.submitForm.emit({
      params: this.form.value,
      features: this.form2.value,
      size: this.selectedSize,
    });
  }

  isFormDisabled() {
    return (
      !(this.form && this.form.valid) ||
      !this.form ||
      !this.form2 ||
      !(this.form2 && this.form2.valid) ||
      !this.selectedSize
    );
  }
}
