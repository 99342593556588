import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { PolicyService } from '@imc/core/services';
import { forkJoin, Observable, Subscription, zip } from 'rxjs';
import {
  Customer,
  CustomerSites,
  CustomerToSitesMap,
  Site,
} from '../../models/sites.model';
import { SiteService } from '../../services/site-service/site.service';

@Component({
  selector: 'imc-site-selection-menu',
  templateUrl: './site-selection-menu.component.html',
  styleUrls: ['./site-selection-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteSelectionMenuComponent implements OnInit, OnDestroy {
  private listAllSitesSubscription: Subscription;
  private selectedSiteSubscription: Subscription;
  private routerSubscription: Subscription;
  private customerSubscription: Subscription;

  isCustomer: boolean;
  sites: Site[];
  selectedSiteIdentifier: string;
  selectedSiteId: string;
  selectedSite: Site;

  isLoading: boolean;
  customerSites: CustomerSites[] = [];

  constructor(
    private sitesService: SiteService,
    private readonly policyService: PolicyService,
    private router: Router,
    private readonly location: Location,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.listAllSitesSubscription = this.listSites();
    this.selectedSiteIdentifier = this.getSelectedSite();

    this.routerSubscription = this.router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd) {
        this.selectedSiteIdentifier = this.getSelectedSite();
      }
    });

    this.customerSubscription = this.policyService.isCustomer$.subscribe(
      (isCustomer: boolean) => {
        this.isCustomer = isCustomer;
      }
    );

    this.router.events.forEach((event: any) => {
      if (event instanceof NavigationStart) {
        return;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.selectedSiteSubscription) {
      this.selectedSiteSubscription.unsubscribe();
    }

    if (this.listAllSitesSubscription) {
      this.listAllSitesSubscription.unsubscribe();
    }

    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.customerSubscription) {
      this.customerSubscription.unsubscribe();
    }
    if (this.cdr) {
      this.cdr.detach();
    }
  }

  /**
   * Get selected site from router params
   */
  getSelectedSite(): string {
    let currentSiteId: string = '';
    this.location
      .path()
      .split('/')
      .forEach((str: string, index: number, arr: any[]) => {
        if (str === 'sites' && arr.length > index + 1) {
          currentSiteId = arr[index + 1];
        }
      });

    return currentSiteId;
  }

  /**
   * Handle selection on template
   */
  selectSite(siteId: string): void {
    if (
      this.selectedSiteIdentifier &&
      this.selectedSiteIdentifier.includes(siteId)
    ) {
      return;
    }

    const selectedSite: Site = (this.sites || []).find(
      (site: Site) => site.siteId === siteId
    );

    this.navigateToSelectedSite(siteId);
  }

  /**
   * Navigate to selected :siteId while
   * keeping all params intact
   */
  navigateToSelectedSite(siteId: string): void {
    let currentUrl: string = this.router.routerState.snapshot.url;

    const isOutlet: boolean = currentUrl.indexOf('(') > -1;

    if (isOutlet) {
      currentUrl = currentUrl.substr(0, currentUrl.indexOf('(') - 1); // temp fix for side sheet. Removes the router outlet. -1 - to trim the / before the outlet as well
    }

    const nextUrl: string = currentUrl.replace(
      `/${this.selectedSiteIdentifier}`,
      `/${siteId}`
    );

    this.router.navigateByUrl(nextUrl);
  }

  private listSites(): Subscription {
    const customers$: Observable<
      Map<string, Customer>
    > = this.sitesService.listCustomers();
    const sites$: Observable<Site[]> = this.sitesService.listSites();

    return forkJoin(customers$, sites$).subscribe((customersSites: any[]) => {
      const customersMap: Map<string, Customer> = customersSites[0];
      this.sites = customersSites[1].filter((site: Site) =>
        customersMap.has(site.customerId)
      );
      const groupSites: CustomerToSitesMap = this.sites.reduce(
        (acc: CustomerToSitesMap, site: Site) => {
          const customer: Customer = customersMap.get(site.customerId);
          if (!acc[customer.name]) {
            acc[customer.name] = [];
          }
          acc[customer.name].push(site);

          return acc;
        },
        {}
      );

      const listSites: CustomerSites[] = Object.keys(groupSites)
        .sort()
        .reduce((acc: CustomerSites[], customerName: string) => {
          acc.push({
            customerName,
            sites: groupSites[customerName],
          });

          return acc;
        }, []);

      this.customerSites = listSites;

      const selectedSite: Site = (this.sites || []).find(
        (site: Site) =>
          this.selectedSiteIdentifier &&
          this.selectedSiteIdentifier.includes(site.siteId)
      );
      this.selectedSiteId = selectedSite && selectedSite.siteId;
      this.isLoading = false;
      this.cdr.markForCheck();
    });
  }
}
