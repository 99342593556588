import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { TdLoadingService } from '@covalent/core/loading';

import { ErrorService } from '@imc/core/services/error-handling/error.service';
import { PolicyService } from '@imc/core/services/policy-service/policy.service';
import { Availability } from '../../models/availability.model';
import { AvailabilityService } from '../../services/availability.service';

import { Site } from '@imc/core/models/sites.model';
import { Subscription } from 'rxjs';

import moment from 'moment';

@Component({
  selector: 'imc-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailabilityComponent implements OnChanges, OnInit, OnDestroy {
  @Input() site: Site;
  months: number = 4;
  targetSLA: number;
  availability: Availability[];
  isSysops: boolean;
  sub: Subscription;

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _loadingService: TdLoadingService,
    private readonly _availabilityService: AvailabilityService,
    private readonly _errorService: ErrorService,
    public _policyService: PolicyService
  ) {}

  ngOnInit(): void {
    this.sub = this._policyService.isCustomer$.subscribe(
      (isCustomer: boolean) => {
        this.isSysops = !isCustomer;
      }
    );
  }

  async ngOnChanges(): Promise<void> {
    this._loadingService.register('availability');
    if (this.site) {
      await this.getAvailability(this.months);
    }
    this._changeDetectorRef.markForCheck();
    this._loadingService.resolve('availability');
  }

  async getAvailability(months: number): Promise<void> {
    try {
      const siteAvailability: Availability[] = await this._availabilityService
        .getSiteAvailability(
          months,
          this.isSysops ? this.site.customerId : undefined
        )
        .toPromise();

      this.availability = siteAvailability.filter(
        (availability: Availability) => this.site.siteId === availability.siteId
      );
      this.availability.map(
        (availability: Availability) =>
          (availability.monthYear = moment(
            availability.reportId,
            'YYYY/MM'
          ).format('MMM YYYY'))
      );
      const reportTargetSLA: number =
        this.availability.length > 0
          ? this.availability[0].targetSLA
          : undefined;
      this.targetSLA = reportTargetSLA
        ? reportTargetSLA
        : this.site.targetSLA
        ? this.site.targetSLA
        : ['aws', 'azure', 'tmc'].includes(
            this.site.cloudPlatform.toLowerCase()
          )
        ? 99.9
        : undefined;
    } catch (error) {
      setTimeout(() => {
        this._errorService.open(error);
      });
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
