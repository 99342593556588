import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { Site } from '@imc/core/models/sites.model';

@Component({
  selector: 'imc-site-info',
  templateUrl: './site-info.component.html',
  styleUrls: ['./site-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteInfoComponent implements OnChanges {
  @Input() site: Site;
  @Input() loaderKey: string = 'siteInfoLoader';
  @Input() loading: boolean = true;
  @Input() error: any;
  subtitle: string = '';
  version: string;

  ngOnChanges(): void {
    if (this.site !== undefined) {
      this.subtitle = `Site info on ${this.site.displayName}`;
      this.version = this.site.sqleApp && this.site.sqleApp.version;
    }
  }
}
