import { Component, Input } from '@angular/core';
import { NotifyModel } from '../../models/notify.model';

@Component({
  selector: 'imc-notify-card',
  templateUrl: './notify-card.component.html',
  styleUrls: ['./notify-card.component.scss'],
})
export class NotifyCardComponent {
  @Input() notification: NotifyModel;
}
