import { Component, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material';
import { TdMediaService } from '@covalent/core';

@Component({
  selector: 'imc-azure-dashboard',
  templateUrl: './azure-dashboard.component.html',
  styleUrls: ['./azure-dashboard.component.scss'],
})
export class AzureDashboardComponent {
  @ViewChild('azureRightDrawer') matDrawer: MatDrawer;

  constructor(public media: TdMediaService) {}

  closeSideSheet(): void {
    this.matDrawer.close();
  }
}
