import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'imc-tmc-sites',
  templateUrl: './tmc-sites.component.html',
  styleUrls: ['./tmc-sites.component.scss'],
})
export class TmcSitesComponent implements OnInit {
  customerName: string = 'TMC Customer';

  constructor() {}

  ngOnInit() {}
}
